import UIModeAction from '@/Actions/UIMode';
import BaseInteraction from '@/InteractionsManager/Interactions/BaseInteraction';
// import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';
import { selectIsMobile } from '@/Redux/Slices/UISlice';

export default class ActivateAnchorEditInteraction extends BaseInteraction {
  interactionId = 'Activate Anchor Edit';

  getIsMobile() {
    return this.manager?.useSelector?.(selectIsMobile) || false;
  }

  onLongPressActivate(event) {
    const overSelectionBox = this.anchorHitTestSelectionBox(event.center);
    if (overSelectionBox.length < 1) {
      return;
    }
    // activate the anchor editing mode
    const uiMode = this.createAction(UIModeAction);
    uiMode.toAnchorSelection();
  }

  onLongPressRelease() {
    this.release();
  }
}
