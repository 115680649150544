import {
  configureStore,
  combineReducers,
  Middleware,
  ThunkDispatch,
  Store,
  isAction,
  Action,
} from '@reduxjs/toolkit';

import sherpaContainerReducer, {
  middleware as sherpaContainerMiddleware,
} from '@/Redux/Slices/SherpaContainerSlice';
import canvasReducer, {
  middleware as canvasMiddleware,
} from '@/Redux/Slices/CanvasSlice';
import iconSearchReducer from '@/Redux/Slices/IconSearchSlice';
import shaperHubReducer from '@/Redux/Slices/ShaperHubSlice';
import importReducer from '@/Redux/Slices/ImportSlice';
import shapeShifterReducer, {
  middleware as shapeShifterMiddleware,
} from '@/Redux/Slices/ShapeShifterSlice';
import viewportReducer, {
  middleware as viewportMiddleware,
} from '@/Redux/Slices/ViewportSlice';
import selectionReducer, {
  middleware as selectionMiddleware,
} from '@/Redux/Slices/SelectionSlice';
import analyticsMiddleware from '@/Utility/analytics';
import conversionTrackingMiddleware from '@/Utility/conversionTracking';
import { middleware as shaperHubMiddleware } from '@/Redux/Slices/ShaperHubSlice';
import cursorReducer from '@/Redux/Slices/CursorSlice';
import uiReducer, { middleware as uiMiddleware } from '@/Redux/Slices/UISlice';
import { reducer as syncReducer } from '@/Redux/Slices/SyncSlice';
import { syncListenerMiddleware } from '@/Sync/SyncListener';
import { Environment } from '../@types/shaper-types';
import * as Sentry from '@sentry/react';
import { stateSanitizer, actionSanitizer } from './sanitizeStore';
import featureFlagsReducer, {
  featureFlagsMiddleware,
} from '@/Redux/Slices/FeatureFlagsSlice';

const rootReducer = combineReducers({
  canvas: canvasReducer,
  cursor: cursorReducer,
  sherpaContainer: sherpaContainerReducer,
  import: importReducer,
  iconSearch: iconSearchReducer,
  selection: selectionReducer,
  shapeShifter: shapeShifterReducer,
  shaperHub: shaperHubReducer,
  ui: uiReducer,
  viewport: viewportReducer,
  sync: syncReducer,
  featureFlags: featureFlagsReducer,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: stateSanitizer,
  actionTransformer: actionSanitizer,
});

const trapErrorMiddleware: Middleware = (_) => (next) => (action) => {
  if (!isAction(action) || action === undefined || action?.type === undefined) {
    console.log(`Warning - undefined action dispatched!`);
  } else {
    next(action);
  }
};

export type RootState = ReturnType<typeof rootReducer>;

const setupStore = ({
  environment,
  preloadedState,
}: {
  environment: Environment;
  preloadedState?: RootState;
}) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        syncListenerMiddleware.middleware,
        trapErrorMiddleware,
        viewportMiddleware,
        canvasMiddleware,
        selectionMiddleware,
        shapeShifterMiddleware,
        shaperHubMiddleware,
        sherpaContainerMiddleware,
        analyticsMiddleware,
        conversionTrackingMiddleware,
        uiMiddleware,
        featureFlagsMiddleware
      ),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers().concat(sentryReduxEnhancer),
    ...(window.__REDUX_DEVTOOLS_EXTENSION__ &&
      environment !== 'production' && {
        devTools: {
          stateSanitizer: stateSanitizer,
          actionsDenylist: [
            'canvas/setCanvasFromSyncSnapshot',
            'canvas/setCanvasState',
            'sync/setSnapshot',
          ],
        },
      }),
  });

export type ReduxStore = ReturnType<typeof setupStore>;
export type AppDispatch = ReduxStore['dispatch'];
export type AppThunkDispatch = ThunkDispatch<RootState, any, Action>;

export type AppStore = Omit<Store<RootState, Action>, 'dispatch'> & {
  dispatch: AppThunkDispatch;
};

export default setupStore;
