import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

// components
import Icon from '@/Styles/Icons/Icon';
import { useModeSelectionMenuContext } from '../ModeSelectionMenu';

type ItemProps<T> = {
  icon?: string;
  children?: React.ReactNode;
  value?: T;
};

type ToggleProps<T> = {
  children: ReactElement<ReactNode>[];
  onToggle: (active: T) => void;
  value?: T;
};

export default function Toggle<T>(props: ToggleProps<T>) {
  const disabled = useModeSelectionMenuContext() || false;

  const children = React.Children.toArray(props.children);
  const values: T[] = children.map((child) => {
    if (React.isValidElement(child)) {
      return child.props.value;
    }
    return null;
  });
  const index = children.findIndex((child) => {
    if (React.isValidElement(child)) {
      return child.props.value === props.value;
    }
    return false;
  });
  const active = index === 0;
  const side = active ? 'left' : 'right';

  // handle toggling
  function onToggle() {
    if (!disabled) {
      props.onToggle(!active ? values[0] : values[1]);
    }
  }

  const toggleCx = classNames('mode-menu--toggle', { [side]: side, disabled });

  return (
    <div className={toggleCx} onClick={onToggle}>
      {props.children}
    </div>
  );
}

Toggle.Item = function <T>(props: ItemProps<T>) {
  return (
    <div className='mode-menu--toggle-item'>
      {props.icon ? <Icon>{props.icon}</Icon> : props.children}
    </div>
  );
};
