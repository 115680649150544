import {
  updateSvgGroup,
  createSvgGroupOnCanvasFromSvg,
} from '@/Geometry/CanvasOps';
import { createSvgForTool } from '@/Helpers/ShapeCreator';
import { CanvasState, selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import { last } from 'lodash';
import {
  BakeShapeConfig,
  BakeShapeResult,
  IPoint,
  IPointGroup,
  IShapeGroup,
  SvgGroupOrID,
} from './types';
import { RootState } from '@/Redux/store';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import {
  ISvgGroup,
  Shape,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Draft } from '@reduxjs/toolkit';
import { SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';

const limitTo = (val: number, precision = 5) =>
  Math.round(val * 10 ** precision) / 10 ** precision;

const DEFAULT_POINT_SVG = `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1 1" >
    <circle r="0.0001" />
  </svg>
  `;

const DEFAULT_SHAPE_SVG = `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1 1" >
    <path d="M 0 0 L 1 1 L 1 0 Z" />
  </svg>
`;

export function newShape(state: CanvasState): IShapeGroup {
  state.canvas = createSvgGroupOnCanvasFromSvg(
    state.canvas,
    true, // useGroupSize
    { x: 0, y: 0 } as Point, // always the center
    DEFAULT_SHAPE_SVG,
    new Tool(Shape.SHAPE, { width: 0, height: 0, units: '', points: [] })
  );

  // return the most recently added shape
  return last(state.canvas.svgGroupSet) as IShapeGroup;
}

type NewPointParams = {
  position: Point;
  belongsTo: string;
};

export function newPoint(
  state: CanvasState,
  params: NewPointParams
): IPointGroup {
  const { position = { x: 0, y: 0 }, belongsTo } = params || {};

  state.canvas = createSvgGroupOnCanvasFromSvg(
    state.canvas,
    true, // useGroupSize
    position as Point,
    DEFAULT_POINT_SVG,
    new Tool(Shape.POINT, { width: 0, height: 0, units: '', belongsTo })
  );

  return last(state.canvas.svgGroupSet) as IPointGroup;
}

// for the points that are in a path
export function createBakedShape(
  state: RootState,
  id: string,
  config: BakeShapeConfig = {}
) {
  const getGroupById = selectGetGroupById(state);
  const group = getGroupById(id);
  let { closed, pointIds = group.tool?.params?.points } = config;

  // iterate through the points. The config may offer overrides
  // so a translate and shape update can take place in the
  // same update call
  let points = pointIds?.map((pointId: string) => {
    const point = config?.points?.[pointId] ?? getGroupById(pointId)?.position;
    return { ...point };
  });

  // get the bounds
  const xs = [];
  const ys = [];
  for (const { x, y } of points) {
    xs.push(x);
    ys.push(y);
  }

  const left = Math.min(...xs);
  const right = Math.max(...xs);
  const top = Math.min(...ys);
  const bottom = Math.max(...ys);
  const width = right - left;
  const height = bottom - top;
  const cx = left + width * 0.5;
  const cy = top + height * 0.5;

  // scale all values to be in a range of 0-1
  points = (points as IPoint[]).map(({ x, y }) => {
    return {
      x: limitTo((x - left) / width),
      y: limitTo((y - top) / height),
    };
  });

  // get the closed value
  closed = closed ?? !!group.tool?.params?.closed;

  // create the svg
  const tool = new Tool(Shape.SHAPE, {
    width: 0,
    height: 0,
    units: '',
    points: pointIds,
    closed,
  });

  const { svg: rawSVG } = createSvgForTool(tool, { points });

  // create the updated shape info
  return {
    id,
    rawSVG,
    tool: {
      ...group.tool,
      params: {
        points: pointIds,
        closed,
        forceOpenPaths: !closed,
      },
    },
    repairPaths: false,
    position: {
      x: limitTo(cx),
      y: limitTo(cy),
    },
    size: {
      x: limitTo(width),
      y: limitTo(height),
    },
  };
}

export function applyBakedShapeToDraft(
  draft: Draft<CanvasState>,
  id: string,
  bakedShape: BakeShapeResult
) {
  draft.canvas = updateSvgGroup(
    draft.canvas,
    id,
    {
      key: SvgGroupUpdateKey.AbsolutePosition,
      value: bakedShape.position as Point,
    },
    false
  );
  draft.canvas = updateSvgGroup(
    draft.canvas,
    id,
    {
      key: SvgGroupUpdateKey.DisplaySize,
      value: bakedShape.size as Point,
    },
    false
  );

  // draft.canvas = updateSvgGroup(draft.canvas, id, {
  //   key: SvgGroupUpdateKey,
  //   value: true,
  // });

  draft.canvas = updateSvgGroup(
    draft.canvas,
    id,
    {
      key: SvgGroupUpdateKey.ToolSvg,
      value: bakedShape as any,
    },
    false
  );
}

export function bakeShape(
  state: RootState,
  draft: Draft<CanvasState>,
  id: SvgGroupOrID,
  options: BakeShapeConfig
) {
  const result = createBakedShape(
    state,
    (id as ISvgGroup)?.id ?? (id as string),
    options
  );
  applyBakedShapeToDraft(draft, result.id, result);
}

export type Bounds = {
  x: number;
  y: number;
  top: number;
  left: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
};

// gets the bounding box for a polyline shape
export function getShapeBounds(
  shape: ISvgGroup<Shape.SHAPE>,
  groups: ISvgGroup[]
): Bounds {
  const xs = [];
  const ys = [];

  // gather up all points
  for (const pointId of shape.tool!.params.points) {
    const point = groups.find((group) => group.id === pointId);
    if (point && point.position) {
      xs.push(point.position.x);
      ys.push(point.position.y);
    }
  }

  // compute the bounds
  const left = Math.min(...xs);
  const right = Math.max(...xs);
  const top = Math.min(...ys);
  const bottom = Math.max(...ys);
  const width = right - left;
  const height = bottom - top;
  const x = (right + left) * 0.5;
  const y = (bottom + top) * 0.5;
  return { left, right, top, bottom, width, height, x, y };
}
