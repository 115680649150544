import { useSelector, useDispatch } from 'react-redux';

// helpers
import { convertNumToMM } from '@/Geometry/UnitOps';

//selectors
import { selectGridSize, setGridSize } from '@/Redux/Slices/ViewportSlice';
import {
  selectDisplayUnits,
  selectToFormattedDisplayUnitValue,
} from '@/Redux/Slices/SherpaContainerSlice';
import { selectFeatures } from '@/Redux/Slices/UISlice';

// components
import { ModeOptionHeader, ModeOptionItem } from '../ModeOptionMenu';
import UnitSelector from '../Components/UnitSelector';
import CustomAnchorOption from '../Components/CustomAnchorOption';
import { entitlements } from '@/Helpers/Entitlements';

export default function DesignModeOptionsMenu() {
  const dispatch = useDispatch();
  const displayUnits = useSelector(selectDisplayUnits);
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );

  const hasPosition = selectFeatures(entitlements.PRECISION_POSITION);
  const hasGridVisibility = selectFeatures(entitlements.GRID_VISIBILITY);
  const hasCustomAnchor = selectFeatures(entitlements.CUSTOM_ANCHOR);

  // determine the size of the grid to use
  const gridSize = toFormattedDisplayUnitValue(useSelector(selectGridSize));

  function onUpdateGridSize(value: string) {
    let size = parseFloat(value);
    size = convertNumToMM(size, displayUnits);

    // unit was invalid - reject the change
    if (isNaN(size)) {
      return false;
    }

    dispatch(setGridSize(size));
  }

  return (
    <>
      <UnitSelector />

      <ModeOptionHeader.Section
        title='Smart Alignment'
        icon='snapping'
        prop='useSnapping'
        i18nKey='snapping'
        dataCy='snapping'
      />

      {hasPosition && (
        <ModeOptionHeader.Section
          title='Position'
          icon='positioning'
          prop='usePositioning'
          i18nKey='positioning'
          dataCy='usePositioning'
        >
          {hasGridVisibility && (
            <ModeOptionItem.Check
              prop='showGrid'
              label='Show grid'
              i18nKey='show-grid'
              dataCy='showGrid'
            >
              <ModeOptionItem.Input
                defaultValue={gridSize}
                onCommit={onUpdateGridSize}
                captureEvent
                suffix={displayUnits}
                calculate
              />
            </ModeOptionItem.Check>
          )}
          <ModeOptionItem.Check
            prop='alignToGrid'
            label='Snap to grid'
            i18nKey='align-to-grid'
            dataCy='alignToGrid'
          />
          <ModeOptionItem.Check
            prop='showPositionLabels'
            label='Show position labels'
            i18nKey='show-position-labels'
            dataCy='showPositionLabels'
          />
          {hasCustomAnchor && (
            <ModeOptionItem.Check
              prop='showCustomAnchor'
              label='Add Origin Anchor'
              i18nKey='add-anchor'
              dataCy='showCustomAnchor'
              justifyChildren='left'
            >
              <CustomAnchorOption />
            </ModeOptionItem.Check>
          )}
        </ModeOptionHeader.Section>
      )}
    </>
  );
}
