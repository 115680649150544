import React, { MouseEvent } from 'react';
import Icon from '@/Styles/Icons/Icon';
import { useFloatingPanelContext } from '../FloatingPanel';
import classNames from 'classnames';

type Props<T> = {
  children?: React.ReactNode;
  className?: string;
  dataCy?: string;

  // TODO: replace this with the proper icon type once Icon is in TypeScript
  icon?: string;

  value: T;
  onClick: (value: T, event: MouseEvent) => void;
};

export default function Button<T>(props: Props<T>) {
  const disabled = useFloatingPanelContext();

  // handles the click event
  function onClick(event: MouseEvent) {
    if (!disabled) {
      props.onClick(props.value, event);
    }
  }

  const buttonCx = classNames('properties-panel--button', {
    ...(props.className && { [props.className]: props.className }),
    disabled: disabled,
  });

  return (
    <div className={buttonCx} data-cy={props.dataCy} onClick={onClick}>
      {props.icon && <Icon icon={props.icon} />}
      {props.children}
    </div>
  );
}
