import React, { MouseEvent } from 'react';
import Icon from '@/Styles/Icons/Icon';

type Props = {
  onClick: (event: MouseEvent) => void;
};

export default function Close(props: Props) {
  return (
    <div className='footer-menu--close' onClick={props.onClick}>
      <Icon>close</Icon>
    </div>
  );
}
