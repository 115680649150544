import {
  Shape,
  SvgGroup,
  isSvgGroupForShape,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { SvgChange, SvgChangeSet } from './ApplySvgChangeSet';

export default class UpdateTextAction {
  selectedGroups: SvgGroup[];
  text?: string;
  fontDisplayName?: string;
  fontDisplayStyle?: string;

  constructor(selectedGroups: SvgGroup[]) {
    this.selectedGroups = selectedGroups;
  }

  setText = (text: string) => {
    this.text = text;
  };

  setFontName = (name: string) => {
    this.fontDisplayName = name;
  };

  setFontStyle = (style: string) => {
    this.fontDisplayStyle = style;
  };

  // performs a general update
  apply(params: { text: string; fontName: string; fontStyle: string }) {
    (
      [
        ['text', this.setText],
        ['fontName', this.setFontName],
        ['fontStyle', this.setFontStyle],
      ] as const
    ).forEach(([key, updater]) => {
      if (key in params) {
        updater(params[key]);
      }
    });
  }

  // creates a change set for
  resolve(): SvgChangeSet {
    const changes: SvgChangeSet = {};
    const { fontDisplayName, fontDisplayStyle, text } = this;

    for (const group of this.selectedGroups) {
      const id = group.id;

      if (isSvgGroupForShape(group, Shape.TEXT)) {
        // create required params
        changes[id] = {
          textParams: {
            fontDisplayName:
              fontDisplayName || group.tool?.params?.fontDisplayName,
            fontDisplayStyle:
              fontDisplayStyle || group.tool?.params?.fontDisplayStyle,
            text: text || group.tool?.params?.text,
          },
        } as SvgChange;
      }
    }

    return changes;
  }
}
