import React from 'react';
import classNames from 'classnames';
import { useModeSelectionMenuContext } from '../ModeSelectionMenu';

type Props = {
  active?: boolean;
  onToggle: (active: boolean) => void;
};

export default function Switch(props: Props) {
  const disabled = useModeSelectionMenuContext();

  function onToggle() {
    if (!disabled) {
      props.onToggle(!props.active);
    }
  }

  const switchCx = classNames('mode-menu--switch', {
    active: !!props.active,
    disabled,
  });

  return <div className={switchCx} onClick={onToggle} />;
}
