import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';

const DEBUG = false;

export interface Cache<T> {
  generatedTs?: number;
  pathData: BasePath[];
  pathSvg: T;
}

export interface CacheLine<T> {
  [key: string]: Cache<T>;
}

export type GeneratingFunction<T, U> = (
  basePath: BasePath,
  svgGroup: SvgGroup,
  extraArgs: U
) => Cache<T>;

export type AsyncGeneratingFunction<T, U> = (
  basePath: BasePath,
  svgGroup: SvgGroup,
  extraArgs: U
) => Promise<Cache<T>>;

export abstract class GenericPathCache<T> {
  cache: CacheLine<T>;

  constructor() {
    this.cache = {};
  }

  setCache(pathId: string, cacheLine: Cache<T>): void {
    if (DEBUG) {
      console.log(`Adding ${pathId} to cache.`, cacheLine.generatedTs);
    }
    this.cache[pathId] = cacheLine;
  }

  getCachePath(pathId: string): Cache<T> | undefined {
    const foundCacheLine = this.cache[pathId];
    if (DEBUG && foundCacheLine) {
      console.log(`Found ${pathId} in cache.`, foundCacheLine.generatedTs);
    }
    return foundCacheLine;
  }

  getCachedPathTs(pathId: string): Number {
    return this.cache[pathId]?.generatedTs ?? Number.NEGATIVE_INFINITY;
  }

  purge() {
    this.cache = {};
  }

  getCache?(...args: any): Cache<T>;
  getCacheAsync?(...args: any): Promise<Cache<T>>;
}
