import Layer from './Layer';
import UIState from '../State/UIState';

type Props = {
  ui: UIState;
};

export default function CustomAnchor(props: Props) {
  const {
    ui: { viewport },
  } = props;

  function renderCustomAnchor() {
    if (!viewport) {
      return null;
    }

    return (
      <Layer normalizeToViewport className='custom-anchor-layer'>
        <path d={`M 0 -24 V 0 H 14 L 0 -24 Z`} />
      </Layer>
    );
  }

  return <>{renderCustomAnchor()}</>;
}
