import {
  setUIMode,
  resetUI,
  toggleUIModeOptionsMenu,
} from '@/Redux/Slices/UISlice';
import { selectHasSelection } from '@/Redux/Slices/SelectionSlice';
import BaseAction from './BaseAction';
import UIFeatureAction from './UIFeature';
import SetSelectionAction from './SetSelection';
import { Mode } from '@/@types/shaper-types';

export default class UIModeAction extends BaseAction {
  activate(mode: Mode) {
    this.dispatch(setUIMode(mode));

    // for now, switching any modes will deactivate all UIs
    this.dispatch(resetUI());
  }

  get hasSelection() {
    return this.useSelector(selectHasSelection);
  }

  activatePropertiesPanel() {
    const ui = this.createAction(UIFeatureAction);
    ui.toggleEditSelectionProperties(true);
  }

  toggleOptionsMenu(enabled: boolean) {
    this.dispatch(toggleUIModeOptionsMenu(enabled));
  }

  toDefault() {
    this.activate('default');

    if (this.hasSelection) {
      this.activatePropertiesPanel();
    }
  }

  toAnchorSelection() {
    this.activate('anchor-selection');
  }

  toPlan() {
    this.activate('plan');
    const selectionAction = this.createAction(SetSelectionAction);

    if (this.hasSelection) {
      this.activatePropertiesPanel();

      // check for special selection scenarios
      selectionAction.expandSelection();
    } else {
      if (!this.hasSelection) {
        selectionAction.selectAllPaths();
        this.activatePropertiesPanel();
      }
    }
  }

  toReview() {
    this.activate('review');
  }

  toShapeShifter() {
    this.activate('shape-shifter');
  }

  toImport() {
    this.activate('import');
  }
}
