import ShaperSvgXmlAttributes, {
  ShaperSvgXml,
  shaperNs,
} from './ShaperSvgXmlAttributes';

// Extract any Shaper SVG XML attributes from the given element
function getShaperXmlAttributes(element: Element): Partial<ShaperSvgXml> {
  const attributesInNamespace: Partial<ShaperSvgXml> = {};
  for (let attr of element.attributes) {
    if (attr.namespaceURI === shaperNs.URI) {
      if (
        ShaperSvgXmlAttributes.includes(attr.localName as keyof ShaperSvgXml)
      ) {
        attributesInNamespace[attr.localName as keyof ShaperSvgXml] =
          attr.value;
      }
    }
  }

  return attributesInNamespace;
}

export default getShaperXmlAttributes;
