import { useAction } from '@/Actions/useAction';
import ToggleShapeCreatorModeAction from '@/Actions/ToggleShapeCreatorMode';
import GlobalCssHelper from '@/Helpers/GlobalCssHelper';
import UIFeatureAction from '@/Actions/UIFeature';
import BuildBasicShapeAction from '@/Actions/BuildBasicShape';
import UIModeAction from '@/Actions/UIMode';
import SetSelectionAction from '@/Actions/SetSelection';
import ViewportActions from '@/Actions/Viewport';
import StartTrialAction from '@/Actions/StartTrial';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectShaperSubscriptions,
  selectShaperMarketingUrl,
  selectUserIsVerified,
} from '@/Redux/Slices/ShaperHubSlice';
import { getSubscribeLink, getTrialLink } from '@/Utility/subscribe-link';
import ClearCanvasAction from '../../Actions/ClearCanvas';
import UndoRedoAction from '../../Actions/UndoRedoAction';
import ImportGeometryAction from '../../Actions/ImportGeometry';
import { shaperLogo } from './svgs/shaper-logo';
import IconSearchAction from '../../Actions/IconSearch';
import { setFeaturesMode } from '../../Redux/Slices/UISlice';
import { FEATURE_MODES } from '../../Constants/Subscriptions';
import UpdateWorkspaceOptionsAction from '../../Actions/UpdateWorkspaceOptions';
import AlertAction from '../../Actions/Alert';
import { getShaperSubscriptions } from '../../ShaperHub/ShaperHubThunks';
import { FLOW_IDS } from '../../defaults';
import { openFlow } from '../../Utility/userflow';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

const globalCss = new GlobalCssHelper('guided-tour', {});

const TOUR: { trigger?: any } = {};
// @ts-ignore
window.TOUR = TOUR;

export default function GuidedTour() {
  const dispatch = useDispatch();
  const shapeSelectorMode = useAction(ToggleShapeCreatorModeAction);
  const uiActivity = useAction(UIFeatureAction);
  const buildShape = useAction(BuildBasicShapeAction);
  const uiModeAction = useAction(UIModeAction);
  const setSelection = useAction(SetSelectionAction);
  const viewportAction = useAction(ViewportActions);
  const startTrialAction = useAction(StartTrialAction);
  const clearCanvasAction = useAction(ClearCanvasAction);
  const undoRedoAction = useAction(UndoRedoAction);
  const importGeometryAction = useAction(ImportGeometryAction);
  const iconSearchAction = useAction(IconSearchAction);
  const updateWorkspaceOptionsAction = useAction(UpdateWorkspaceOptionsAction);
  const alertAction = useAction(AlertAction);

  const { isTrial, isSubscriber, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const isLite = !isTrial && !isSubscriber;
  const userIsVerified = useSelector(selectUserIsVerified);
  const shaperMarketingUrl = useSelector(selectShaperMarketingUrl);

  const actions = {
    // experimental color change
    test(color: string) {
      globalCss.assign({
        body: {
          background: color,
        },

        '.ui-viewport': {
          background: color,
        },
      });
    },

    // adding shapes
    add_circle() {
      buildShape.build(Shape.CIRCLE);
    },

    add_rectangle() {
      buildShape.build(Shape.RECTANGLE);
    },

    add_ellipse() {
      buildShape.build(Shape.ELLIPSE);
    },

    add_rounded_rectangle() {
      buildShape.build(Shape.ROUNDED_RECT);
    },

    add_polygon() {
      buildShape.build(Shape.POLYGON);
    },

    // show menus for finding art
    toggle_find_art(focus?: boolean) {
      uiActivity.toggleFindArt(true);

      if (focus) {
        setTimeout(() => {
          document
            .querySelector<HTMLElement>('[data-cy="find-art-search"]')
            ?.focus();
        });
      }
    },

    show_shape_selector() {
      shapeSelectorMode.activate();
    },

    toggle_to_plan_mode() {
      uiModeAction.toPlan();
      uiModeAction.toggleOptionsMenu(false);
    },

    toggle_to_review_mode() {
      uiModeAction.toReview();
      uiModeAction.toggleOptionsMenu(false);
    },

    toggle_to_design_mode() {
      uiModeAction.toDefault();
      uiModeAction.toggleOptionsMenu(false);
    },

    sample_shapeshifter() {
      buildShape.build(Shape.CIRCLE, new Point(-10, 0));
      buildShape.build(Shape.RECTANGLE, new Point(10, 0));
      buildShape.build(Shape.POLYGON, new Point(0, -10));
      setTimeout(() => {
        setSelection.selectAllPaths();
      });
      viewportAction.centerTo();
    },

    start_trial() {
      if (isLite || (isSubscriber && isExpired)) {
        if (userIsVerified) {
          startTrialAction.start();
        } else {
          const trialPath = getTrialLink();
          window.open(`${shaperMarketingUrl}/${trialPath}`, '_self');
          openFlow(FLOW_IDS.START_TRIAL);
        }
      }
    },

    start_subscription() {
      const subscribePath = getSubscribeLink();
      window.open(`${shaperMarketingUrl}/${subscribePath}`, '_self');
    },

    clear_canvas() {
      clearCanvasAction.clear();
    },

    undo() {
      undoRedoAction.undo();
    },

    deselect_all() {
      setSelection.clear();
    },

    add_shaper_logo(skipImport = true) {
      importGeometryAction.importSVG(shaperLogo);
      if (skipImport) {
        importGeometryAction.splitAndPlaceImportedGroup();
      }
    },

    open_parameter_panel() {
      setSelection.selectAllPaths();
    },

    close_parameter_panel() {
      uiActivity.toggleEditSelectionProperties(false);
    },

    toggle_mode_menu(open = true) {
      uiModeAction.toggleOptionsMenu(open);
    },

    toggle_main_menu(open = true) {
      updateWorkspaceOptionsAction.update({ showApplicationMenu: open });
    },

    open_create_menu_mobile() {
      this.deselect_all();
      const expander = document.querySelectorAll<HTMLElement>(
        "[data-cy='mobile-menu-expander']"
      );
      if (expander) {
        expander[0].click();
      }
    },

    search_find_art(keyword = 'dog') {
      iconSearchAction.search(keyword);
      setTimeout(() => uiActivity.toggleFindArt(true), 1000);
    },

    enable_all_features() {
      dispatch(setFeaturesMode(FEATURE_MODES.FULL));
    },

    revert_features() {
      dispatch(getShaperSubscriptions());
    },

    dismiss_toasts() {
      alertAction.clear();
    },

    clear_url_params() {
      history.pushState(null, '', window.location.pathname);
    },
  };

  // create an event handler
  function triggerEvent<T extends keyof typeof actions>(
    event: T,
    ...args: Parameters<(typeof actions)[T]>
  ) {
    // @ts-ignore This is correct, but typescript thinks args has to satisfy all argument types and it can't
    actions[event](...args);
  }

  // wire up the tour
  TOUR.trigger = triggerEvent;

  // TODO: other rendering options
  return null;
}
