import React from 'react';
import { useSelector } from 'react-redux';

//selectors
import {
  selectSelectedGroups,
  selectSelectedPaths,
} from '@/Redux/Slices/SelectionSlice';
import * as ui from '@/Redux/Slices/UISlice';

//actions
import ViewportActions from '@/Actions/Viewport';

//components
import Icon from '@/Styles/Icons/Icon';
import { useAction } from '../../Actions/useAction';
import TranslationText from '../TranslationText/TranslationText';
import { selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';
import { Targets } from '@/Viewport/ViewportHelpers';

export default function FitToView() {
  const viewportAction = useAction(ViewportActions);
  const selectedPaths = useSelector(selectSelectedPaths);
  const selectedGroups = useSelector(selectSelectedGroups);
  const allGroups = useSelector(selectSvgGroupSet);
  const isPlanMode = useSelector(ui.selectIsPlanMode);
  const isReviewMode = useSelector(ui.selectIsReviewMode);
  const hasSelection = !!selectedPaths.length && !isReviewMode;
  const icon = hasSelection ? 'zoom-to-selection' : 'recenter';
  const label = hasSelection ? 'Zoom to Selection' : 'Recenter';
  const key = hasSelection ? 'zoom-to-selection' : 'recenter';
  const includeCutPaths = isPlanMode || isReviewMode;

  const isNotNullOrUndefined = <T,>(arg: T | null | undefined): arg is T =>
    arg !== null && arg !== undefined;

  function onRecenter() {
    const source: Targets[] = [];
    if (isPlanMode) {
      source.push(...selectedPaths.filter(isNotNullOrUndefined));
    }
    // expand out all groups
    else {
      const groups = isReviewMode ? allGroups : selectedGroups;
      for (const group of groups) {
        source.push(
          ...group.basePathSet.map((path) => ({
            group: group,
            path: path,
          }))
        );
      }
    }

    viewportAction.centerTo(source, { includeCutPaths });
  }

  return (
    <div className='fit-to-view' data-cy='fit-to-view'>
      <div className='fit-to-view__container' onClick={onRecenter}>
        <div className='fit-to-view__container__button'>
          <Icon icon={icon} />
        </div>
        <div className='fit-to-view__container__button__text'>
          <div className='fit-to-view__container__button__inner-text'>
            <TranslationText i18nKey={key}>{label}</TranslationText>
          </div>
        </div>
      </div>
    </div>
  );
}
