import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
  onClick?: (...args: any[]) => void;
  dataCy?: string;

  animate?: boolean;
  remaining: number;
  limit: number;
};

export default function ExportCount(props: Props) {
  const [current, setCurrent] = useState(props.remaining);
  const [prior, setPrior] = useState(0);
  const [, setCycle] = useState(false);

  const countRef = useRef<HTMLDivElement>(null);
  const ticketRef = useRef<HTMLDivElement>(null);

  // play the cycle animation, if needed
  useEffect(() => {
    if (props.remaining !== current) {
      setPrior(current);
      setCurrent(props.remaining);
      setCycle(true);

      [countRef, ticketRef].forEach((item) =>
        item.current?.classList.remove('cycle')
      );
      setTimeout(() => {
        [countRef, ticketRef].forEach((item) =>
          item.current?.classList.add('cycle')
        );
      }, 32);
    }
  }, [props.remaining]);

  const cx = classNames(
    'export-count',
    props.remaining === 0 && 'empty',
    props.className
  );

  return (
    <>
      <div
        className={cx}
        onClick={props.onClick}
        data-cy={props.dataCy}
        ref={countRef}
      >
        {!!prior && <div className='export-count--prior'>{prior}</div>}

        <div className='export-count--current'>{props.remaining}</div>
        <div className='export-count--separator'>of</div>
        <div className='export-count--total'>{props.limit}</div>
      </div>

      {props.animate && (
        <div className='export-ticket' ref={ticketRef}>
          <div className='export-ticket--fall'>
            <div className='export-ticket--rotate'>
              <Ticket />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const Ticket = () => (
  <svg
    className='export-ticket--ticket'
    width='64'
    height='38'
    viewBox='0 0 64 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M64 0H0V12C3.86599 12 7 15.134 7 19C7 22.866 3.86599 26 0 26V38H64V26C60.134 26 57 22.866 57 19C57 15.134 60.134 12 64 12V0Z'
      fill='currentColor'
    />
    <path
      d='M9 19C9 14.7166 6.00802 11.1328 2 10.2231V2H62V10.2231C57.992 11.1328 55 14.7166 55 19C55 23.2834 57.992 26.8672 62 27.7768V36H2V27.7768C6.00802 26.8672 9 23.2834 9 19Z'
      stroke='white'
    />
  </svg>
);
