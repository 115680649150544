import { prepareInputPreselect } from '@/Helpers/DOM';
import { clearSelection } from '@/Redux/Slices/SelectionSlice';
import { TextParams, toggleTextInsert } from '@/Redux/Slices/UISlice';
import { AppDispatch } from '@/Redux/store';

export default class ToggleTextEditorModeAction {
  dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  // insert mode
  insert = () => {
    const { dispatch } = this;
    prepareInputPreselect();

    dispatch(toggleTextInsert(true));
    dispatch(clearSelection());
  };

  exit = () => {
    const { dispatch } = this;

    dispatch(toggleTextInsert(false));
  };

  // shows the edit mode
  edit = (textParams: TextParams) => {
    const { dispatch } = this;

    dispatch(
      toggleTextInsert({
        textParams,
      })
    );
  };
}
