import { useSelector } from 'react-redux';

// selectors
import { selectReviewModeLoading } from '@/Redux/Slices/UISlice';

// components
import Viewport from '@/UILayer/Components/Viewport';
import Background from './Components/Background';
import Loading from '@/Components/Loading/Loading';
import UIState from '@/UILayer/State/UIState';
import SvgGroup from '@/UILayer/State/Helpers/SvgGroup';
import ReviewModeGroup from '@/UILayer/Components/ReviewModeGroup';
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';

const DEPTH_LEVELS = [0, 0.0254, 3.175, 6.35, 9.525, 12.7, 25.4, 38.1];

function getLowest<T>(find: T, values: T[]): number {
  let bestMatch = 0;

  for (let i = 1; i < values.length; i++) {
    if (values[i] > find) {
      break;
    }

    bestMatch = i;
  }

  return bestMatch + 1;
}

type Props = {
  ui: UIState;
};

export default function ReviewUI(props: Props) {
  const { ui } = props;
  function extendGroup(group: SvgGroup) {
    const { cutDepth } = group;
    const depthLevel = getLowest(cutDepth, DEPTH_LEVELS);

    return {
      className: `cut-depth--${depthLevel}`,
    };
  }
  const nspf = useSelector(selectNonScalingPixelFactor);
  const isReviewModeLoading = useSelector(selectReviewModeLoading);
  return (
    <>
      {isReviewModeLoading && (
        <>
          <div className='sherpa-container--loading'>
            <Loading type='domino' />
          </div>
          <div className='ui-layer disabled'>
            <Viewport viewport={ui.viewport} />
          </div>
        </>
      )}
      {!isReviewModeLoading && (
        <div
          className='ui-layer'
          style={{ '--highlight-width': Math.min(0.1, 0.1 / nspf) } as any}
        >
          <Viewport viewport={ui.viewport}>
            <Background viewport={ui.viewport} />
            <ReviewModeGroup extendGroup={extendGroup} />
          </Viewport>
        </div>
      )}
    </>
  );
}
