import BaseInteraction from '../../../Interactions/BaseInteraction';

// slices
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';
import { selectCanvas, selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import {
  selectInsertPointModeOptions,
  selectIsInsertPointMode,
} from '@/Redux/Slices/UISlice';
import {
  selectScreenToCanvas,
  selectViewport,
} from '@/Redux/Slices/ViewportSlice';

// helpers
import AlignmentHelper from '@/Helpers/Alignment';
import UIState from '@/UILayer/State/UIState';
import InsertPointLocation from '@/UILayer/Components/InsertPointLocation/InsertPointLocation';

// actions
import LineToolAction from '@/Actions/LineTool';
import UIFeatureAction from '@/Actions/UIFeature';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

export default class InsertPointInteraction extends BaseInteraction {
  isLineEditMode() {
    const { useSelector } = this;
    return useSelector(selectIsInsertPointMode);
  }

  didZoomLevelChange = () => {
    const { useSelector } = this;
    const viewport = useSelector(selectViewport);
    return (
      viewport.canvasToScreenScale !==
      this.alignmentHelper?.viewport?.canvasToScreenScale
    );
  };

  getSnapping = () => {
    const {
      alignToGrid: snapToGrid,
      useSnapping: snapToObjects,
      usePositioning,
    } = this.useSelector(selectOptions);

    return { snapToGrid, snapToObjects, usePositioning };
  };

  updateInsertPointLocation({ x, y }, event) {
    const { useSelector } = this;
    const screenToCanvas = useSelector(selectScreenToCanvas);
    const location = screenToCanvas(x, y);
    const snap = {};

    let {
      alignToGrid: snapToGrid,
      useSnapping: snapToObjects,
      usePositioning,
    } = this.useSelector(selectOptions);

    // clear the snap location
    this.mostRecentSnapLocation = null;

    if (this.isLineEditMode() && !this.isActiveLineEditMode) {
      this.updateAlignmentGuides({ x, y });
      this.isActiveLineEditMode = true;
    }

    // check for alignment
    if (
      this.alignmentHelper &&
      (usePositioning || snapToGrid || snapToObjects)
    ) {
      // override key
      if (event.ctrlKey) {
        usePositioning = snapToObjects = snapToGrid = false;
      }

      // get the offset to use
      const offsetX = x - this.origin.x;
      const offsetY = y - this.origin.y;

      // check for snapping
      // check for usePositioning for the grid. Behind the scenes, these options
      // are left on, even when positioning is off. This allows for smart
      // alignment to be used for object and not for the grid
      const { guides } = this.alignmentHelper.update(offsetX, offsetY, {
        snapToGrid: usePositioning,
        snapToObjects,
      });

      // show alignment guides
      UIState.update({ alignmentGuides: guides }).apply();

      // handle snapping a little different here since
      // we actually want to absolute position and not
      // the normal x/y delta provided by the snapping helper
      for (const guide of guides) {
        if ('x' in guide && !('x' in snap)) {
          location.x = snap.x = guide.x;
        }

        if ('y' in guide && !('y' in snap)) {
          location.y = snap.y = guide.y;
        }
      }

      // save the snap
      this.mostRecentSnapLocation = snap;
    }

    InsertPointLocation.setPosition(location.x, location.y);
  }

  exitLineToolMode() {
    delete this.activeGroupId;
    this.setActive(false);

    // clear guides, if any
    UIState.update({ alignmentGuides: [] }).apply();
    UIState.reset();

    // leave line edit mode
    this.isActiveLineEditMode = false;

    // leave the drawing mode
    const uiFeature = this.createAction(UIFeatureAction);
    uiFeature.toggleInsertPoint(false);
  }

  getPoints(at) {
    const getGroupById = this.useSelector(selectGetGroupById);
    const groups = this.getGroupsAt(at);
    return groups
      .map((item) => getGroupById(item.groupId))
      .filter((item) => item.tool?.type === Shape.POINT);
  }

  onLongPressActivate(event) {
    const getGroupById = this.useSelector(selectGetGroupById);
    const nearestPoints = this.getPoints(event.center);

    // check for a start or end point
    for (const point of nearestPoints) {
      // since it's a point, check to see
      const group = getGroupById(point.tool.params?.belongsTo);
      const points = group.tool?.params?.points || [];
      const atFront = points[0] === point.id;
      const atEnd = points[points.length - 1] === point.id;

      // if this is already closed
      if (group.tool?.params?.closed) {
        continue;
      }

      // cannot insert from this spot
      if (!(atFront || atEnd)) {
        continue;
      }

      // activate this group
      this.insertAtFront = atFront;
      this.activeGroupId = group.id;

      // leave the drawing mode
      const uiFeature = this.createAction(UIFeatureAction);
      const type =
        event.nativeEvent?.pointerType === 'touch' ? 'mobile' : 'default';
      uiFeature.toggleInsertPoint(true, {
        origin: event.center,
        insertMode: type,
        groupId: group.id,
        atFront,
      });

      // don't select a point while drawing
      this.clearSelection();

      // update the UI
      this.updateInsertPointLocation(event.center, event);
      this.setActive(true);
      this.updateAlignmentGuides(event.center);

      this.insertModeActivation = true;
      break;
    }
  }

  // handle exiting line edit mode
  onKeyUp(event) {
    if (this.isLineEditMode() && ['escape'].includes(event.key.toLowerCase())) {
      this.exitLineToolMode();
    }
  }

  // update the position of the insert tool
  onMouseMove(event) {
    const isEditMode = this.isLineEditMode();

    if (isEditMode && !event.sourceCapabilities?.firesTouchEvents) {
      if (this.didZoomLevelChange()) {
        this.updateAlignmentGuides(event.center);
      }
      this.updateInsertPointLocation(event.center, event);
    }
  }

  // check that we're within range
  onPointerUp(event) {
    if (!this.isLineEditMode()) {
      delete this.activeGroupId;
      return;
    }

    // TODO: this may cause some clicks to do nothing
    // depending on first press
    if (this.insertModeActivation) {
      this.insertModeActivation = false;
      return false;
    }

    // get the location on the screen
    const { useSelector } = this;
    const screenToCanvas = useSelector(selectScreenToCanvas);
    const target = screenToCanvas(event.center.x, event.center.y);

    // handle touch behaviors
    if (event.isTouch) {
      const { x, y } = InsertPointLocation.getPosition();
      const distance = Math.hypot(x - target.x, y - target.y);

      // if close enough, start interaction
      if (distance < 20) {
        this.setActive(true);
        this.updateAlignmentGuides(event.center);
        return false;
      }
    }
    // otherwise, handle mouse
    else {
      this.setActive(true);
      this.insertPoint(target);
      return false;
    }
  }

  async insertPoint(target) {
    const insert = this.createAction(LineToolAction);
    const mrsl = this.mostRecentSnapLocation || {};
    const x = 'x' in mrsl ? mrsl.x : target.x;
    const y = 'y' in mrsl ? mrsl.y : target.y;

    const { closed, groupId, exitInsertionMode } = await insert.addPoint(
      this.activeGroupId,
      { x, y }
    );

    this.activeGroupId = groupId;

    // closed this tool
    if (closed || exitInsertionMode) {
      this.exitLineToolMode();
      UIState.reset();
    } else {
      // TODO: we need to update the alignment guides, but they probably wont' have
      // settled until after this update has finished - rather not use a setTimeout
      // so make sure this is really needed
      setTimeout(() => this.updateAlignmentGuides({ x, y }));
      this.setActive(true);
    }
  }

  updateAlignmentGuides({ x, y }) {
    const { usePositioning } = this.getSnapping();
    if (!usePositioning) {
      return;
    }

    const { useSelector } = this;
    const screenToCanvas = useSelector(selectScreenToCanvas);
    const viewport = useSelector(selectViewport);
    const canvas = useSelector(selectCanvas);
    const origin = screenToCanvas(x, y);
    this.origin = { x, y };

    this.alignmentHelper = new AlignmentHelper(canvas, viewport, {
      selectedGroups: [],
      ignoreGroupIds: [],
      origin,
    });
  }

  onPointerDown() {
    // when in mobile insert mode, the first press is finding
    // where the starting location is
    const options = this.useSelector(selectInsertPointModeOptions);
    if (options?.type === 'mobile') {
      // start showing the active position
      this.initialMobilePointInsert = true;
      this.updateInsertPointLocation(event.center, event);
      this.setActive(true);
    }
  }

  // always check this and let the internal state decide
  // when to handle inserts
  onEveryPointerMove(event, manager) {
    if (this.initialMobilePointInsert) {
      this.updateInsertPointLocation(event.center, event);
      this.setActive(true);
      manager.preventRemaining();
    }
  }

  onActivePointerMove(event) {
    if (this.didZoomLevelChange()) {
      this.updateAlignmentGuides(event.center);
    }

    this.updateInsertPointLocation(event.center, event);
  }

  onActivePointerMoveEnd(event) {
    this.initialMobilePointInsert = false;

    if (!event.isTouch) {
      return;
    }

    const { useSelector } = this;
    const screenToCanvas = useSelector(selectScreenToCanvas);
    const { x, y } = screenToCanvas(event.center.x, event.center.y);
    this.insertPoint({ x, y });
    this.setActive(false);
  }
}
