import userflow from 'userflow.js';
import { getDeviceType } from './ismobile';

const tryCatch = async (tryer, ...args) => {
  try {
    const result = await tryer(...args);
    return [result, null];
  } catch (error) {
    return [null, error];
  }
};

const flowQueue = [];

export const identifyUser = async (user) => {
  const accountAge = Math.abs(new Date(user.createdDate) - new Date());
  const accountAgeInDays = Math.ceil(accountAge / (1000 * 60 * 60 * 24));
  const identity = {
    email: user.email,
    is_origin_owner: user.hasOriginAccess,
    account_age: accountAgeInDays,
    signed_up_at: user.createdDate,
    device_type: getDeviceType(window),
  };
  await tryCatch(userflow.identify, user._id, identity);
};

export const identifyAnon = () => {
  userflow.identifyAnonymous();
};

export const addAttributeToUser = (attribute, value) => {
  if (userflow.isIdentified()) {
    const update = {};
    update[attribute] = value;
    tryCatch(userflow.updateUser, update);
  }
};

export const addAttributesToUser = (attributeObject) => {
  if (userflow.isIdentified() && typeof attributeObject === 'object') {
    tryCatch(userflow.updateUser, attributeObject);
  }
};

export const removeAttributeFromUser = (attribute) => {
  if (userflow.isIdentified()) {
    tryCatch(userflow.updateUser, { [attribute]: null });
  }
};

export const trackEvent = (eventName, eventValues) => {
  if (userflow.isIdentified() && typeof eventValues === 'object') {
    tryCatch(userflow.track, eventName, eventValues);
  }
};

export const closeHelpMenu = () => {
  userflow.closeResourceCenter();
};

export const toggleHelpMenuVisibility = (visible = true) => {
  userflow.setResourceCenterLauncherHidden(!visible);
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const openFlow = async (flowId) => {
  // go through flows that have been queued
  if (flowQueue.length > 0) {
    const id = flowQueue[0];
    const [, error] = await tryCatch(userflow.start, flowId);
    if (error && flowQueue.includes(flowId)) {
      await delay(2000);
      return openFlow(flowId);
    }
    flowQueue.filter((f) => f !== id);
  } else {
    const [, error] = await tryCatch(userflow.start, flowId);
    if (error) {
      flowQueue.push(flowId);
      return openFlow(flowId);
    }
  }
};

export const closeAll = () => {
  userflow.endAll();
};
