import { clearHistory } from '@/Redux/Slices/CanvasSlice';
import { undoCanvas, redoCanvas } from '@/CanvasContainer/CanvasActions';
import UIFeatureAction from './UIFeature';
import BaseAction from './BaseAction';
import UIState from '@/UILayer/State/UIState';

export default class UndoRedoAction extends BaseAction {
  activatePropertiesPanel() {
    const ui = this.createAction(UIFeatureAction);
    ui.toggleEditSelectionProperties(true);
  }

  undo = () => {
    UIState.reset();

    const { dispatch } = this;
    dispatch(undoCanvas());
  };

  redo = () => {
    UIState.reset();

    const { dispatch } = this;
    dispatch(redoCanvas());
  };

  clearHistory = () => {
    const { dispatch } = this;
    dispatch(clearHistory());
  };
}
