import React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { uniq } from 'lodash';

// consts
import {
  cutTypes as CUT_TYPES,
  disabledCutTypesForOpenPaths,
} from '@/defaults';

// selectors
import { selectSelectedPaths } from '@/Redux/Slices/SelectionSlice';

// actions
import UpdateSvgPathAction from '@/Actions/UpdateSvgPath';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { CutType } from '@shapertools/sherpa-svg-generator/CutParams';

export default function CutTypeSelector() {
  // selectors
  const selectedPaths = useSelector(selectSelectedPaths);

  // actions
  const updateSvgPath = useAction(UpdateSvgPathAction);

  // computed
  const selectedCutTypes = selectedPaths.map(
    (item) => item.path.cutParams.cutType
  );
  const hasOpenPath = selectedPaths.some((item) =>
    item.path.outerPath ? !item.path.outerPath.closed : !item.path.closed
  );
  const isMixed = uniq(selectedCutTypes).length > 1;
  const cutType = isMixed ? 'mixed' : selectedCutTypes[0];

  // handlers
  function onChange(newCutType: CutType | 'mixed') {
    if (newCutType === 'mixed') {
      return;
    }
    updateSvgPath.setCutType(selectedPaths, newCutType);
  }

  const options = CUT_TYPES.map(({ key, label }) => (
    <FloatingPanel.Dropdown.Item
      key={key}
      value={key}
      icon={`cut-type-${key}`}
      disabled={hasOpenPath && disabledCutTypesForOpenPaths.includes(key)}
      dataCy={key}
    >
      <TranslationText i18nKey={key}>{label}</TranslationText>
    </FloatingPanel.Dropdown.Item>
  ));

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='cut-type'>
        <TranslationText i18nKey='cut-type'>Cut Type</TranslationText>
      </FloatingPanel.Label>
      <FloatingPanel.Dropdown
        value={cutType}
        onChange={onChange}
        dataCy='cut-type'
      >
        {isMixed && (
          <FloatingPanel.Dropdown.Item key='mixed' value='mixed' hidden>
            <TranslationText i18nKey='mixed'>Mixed</TranslationText>
          </FloatingPanel.Dropdown.Item>
        )}

        {options}
      </FloatingPanel.Dropdown>
    </FloatingPanel.Group>
  );
}
