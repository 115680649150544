import { Viewbox } from '@/Redux/Slices/ViewportSlice';
import UIState from '../UIState';

const NONE = {};

type Overrides = {
  viewport?: Viewbox;
};

export default class Viewport {
  size: { width: number; height: number };
  gridSize: number;
  x: number;
  y: number;
  width: number;
  height: number;

  constructor(ui: UIState, overrides: Overrides = NONE) {
    const { store } = ui;
    const { viewport, svgViewbox } = store;

    // set the default
    // main viewport size
    this.size = {
      width: viewport.size.x,
      height: viewport.size.y,
    };

    // viewbox size
    this.gridSize = viewport.gridSize;
    this.x = svgViewbox.x;
    this.y = svgViewbox.y;
    this.width = svgViewbox.width;
    this.height = svgViewbox.height;

    // check for extra data
    if (overrides.viewport) {
      this.x += overrides.viewport.x;
      this.y += overrides.viewport.y;

      // replace sizing, if needed
      this.width = overrides.viewport.width || this.width;
      this.height = overrides.viewport.height || this.height;
    }
  }

  // maps canvas to screen coordinates
  canvasToScreen(x: number, y: number) {
    const ratio = this.size.width / this.width;
    const relativeX = x - this.x;
    const relativeY = y - this.y;
    return { x: ratio * relativeX, y: ratio * relativeY };
  }

  screenToCanvas(x: number, y: number) {
    // TODO
  }
}
