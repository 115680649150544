import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { cancelEvent } from '@/Utility/events';

// components
import Icon from '@/Styles/Icons/Icon';
import { useModeSelectionMenuContext } from '../ModeSelectionMenu';

type Props = {
  checked: boolean;
  onToggle: (checked: boolean) => void;
  // TODO use icon string union once Icon is TypeScript
  icon?: string;
};
export default function Check(props: Props) {
  const disabled = useModeSelectionMenuContext();

  function onToggle(event: MouseEvent) {
    if (!disabled) {
      props.onToggle(!props.checked);
      cancelEvent(event);
    }
  }

  const icon = props.icon ?? 'check';
  const iconActive = props.checked ? 'active' : 'inactive';
  const checkCx = classNames('mode-menu--check', {
    checked: !!props.checked,
    disabled,
  });

  return (
    <div className={checkCx} onClick={onToggle}>
      <Icon>{`${icon}-${iconActive}`}</Icon>
    </div>
  );
}
