import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import clipper from './Clipper';

export const generateFragmentsUnionSimplePolygons = async (
  selectedFragmentsSimplePolys: BasePath[]
) => {
  const clonedSimplePolys: BasePath[] = JSON.parse(
    JSON.stringify(selectedFragmentsSimplePolys)
  );

  const clipperSimplePolys = clonedSimplePolys.map((p: BasePath) =>
    clipper.basePathToClipper(p)
  );

  const simplePolygons = await clipper.unionAsync(clipperSimplePolys);
  return simplePolygons;
};
