import getShaperXmlAttributes from './getShaperSvgXmlAttributes';

function getShaperRootXmlAttributes(svgStr: string) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(svgStr, 'image/svg+xml');
  const rootElement = xmlDoc.documentElement;
  return getShaperXmlAttributes(rootElement);
}

export default getShaperRootXmlAttributes;
