import SetSelectionAction from '@/Actions/SetSelection';
import ToggleTextEditorModeAction from '@/Actions/ToggleTextEditorMode';
import { FONTS } from '@/Helpers/TextCreator';
import BaseInteraction from '@/InteractionsManager/Interactions/BaseInteraction';

export default class TextEdit extends BaseInteraction {
  interactionId = 'Text Edit';

  onDoubleClick(event) {
    // get the groups were the double click happened
    let over = this.getGroupsAt({
      ...event.center,
      ...{
        hitDetectEdge: true,
        hitDetectFill: true,
        selectedOnly: false,
        ignoreReferencePaths: true,
      },
    });

    //create action
    if (over.length > 0) {
      const groups = this.getGroupsFromIds(over.map((o) => o.groupId));
      const toggleTextEditorModeAction = this.createAction(
        ToggleTextEditorModeAction
      );
      const selection = this.createAction(SetSelectionAction);

      const textElements = groups.filter((item) => {
        return item.tool.type === 'text-insert';
      });

      if (textElements[0]) {
        // edge case - the text edit action acts on the currently selected object
        // we need to force the current selection to === the text element currently being
        // edited so it doesn't replace the wrong object
        const selectedTextGroup = over.filter(
          (o) => o.groupId === textElements[0].id
        );
        selection.set(selectedTextGroup);

        const { params } = textElements[0].tool;
        const { text, fontDisplayName, fontDisplayStyle, forceOpenPaths } =
          params;
        const font = FONTS.find(
          (item) => item.fontDisplayName === fontDisplayName
        );
        const style = font.fontStyles.find(
          (item) => item.displayStyle === fontDisplayStyle.displayStyle
        );

        // Double click text edit MUST pass the groupId because the `pointerUp` event
        // in the `TransformGroups` interaction will clear out the selectedGroupId.
        // This will ensure that the element will be updated!
        const selectedGroupId = selectedTextGroup[0].groupId;
        toggleTextEditorModeAction.edit({
          text,
          fontDisplayName,
          fontDisplayStyle: style,
          fontSelection: style,
          forceOpenPaths,
          selectedGroupId: selectedGroupId,
        });
        return false;
      }
    }
  }
}
