import React, { PropsWithChildren, useEffect, useState } from 'react';
import Icon from '@/Styles/Icons/Icon';
import classNames from 'classnames';
import TranslationText from '../../TranslationText/TranslationText';
import Tooltip from '@mui/material/Tooltip'; //using mui tooltip to prevent positioning issues

type Props = PropsWithChildren & {
  label?: React.ReactNode | string;
  icon?: string;
  i18nKey?: string;
  onClick?: () => void;
  url?: string;
  target?: string;
  component?: () => React.JSX.Element;
  action?: () => void;
  onHover?: React.JSX.Element | null;
  tooltip?: string;
  disabled?: boolean;
  className?: string;
  dataCy?: string;
};

function InnerSectionOption(
  props: Props & {
    innerRef: React.ForwardedRef<unknown>;
  }
) {
  const {
    children,
    icon,
    i18nKey,
    tooltip,
    onHover,
    innerRef,
    component: Component,
    className,
    dataCy,
  } = props;

  const [isHovering, setIsHovering] = useState(false);

  function onClick() {
    if (!props.disabled) {
      if (props.onClick) {
        props.onClick();
      } else if (props.url) {
        window.open(props.url, props.target || '_self');
      }
    }
  }

  const handleMouseOver = () => {
    if (onHover) {
      setIsHovering(true);
    }
  };

  const handleMouseOut = () => {
    if (onHover) {
      setIsHovering(false);
    }
  };

  useEffect(() => {
    if (!tooltip) {
      setIsHovering(false);
    }
  }, [tooltip]);

  useEffect(() => {
    if (!onHover) {
      setIsHovering(false);
    }
  }, [onHover]);

  const optionCx = classNames('action-list-menu--section-option', {
    disabled: !!props.disabled,
    onHover: isHovering,
    ...(className && { [className]: className }),
  });

  return (
    <div
      className={optionCx}
      onClick={onClick}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      data-cy={dataCy || i18nKey}
      ref={innerRef as React.RefObject<HTMLDivElement>}
    >
      {isHovering ? (
        onHover
      ) : Component ? (
        <>
          <Component />
        </>
      ) : (
        <>
          <div className='action-list-menu--section-option--icon'>
            <Icon icon={icon} />
          </div>
          <div className='action-list-menu--section-option--label'>
            <TranslationText i18nKey={i18nKey}>
              {children || props.label}
            </TranslationText>
          </div>
        </>
      )}
    </div>
  );
}

export default function SectionOption(props: Props) {
  const { tooltip } = props;

  const InnerComponent = React.forwardRef((args, ref) => (
    <InnerSectionOption innerRef={ref} {...props} />
  ));

  return tooltip ? (
    <Tooltip
      placement='right'
      title={tooltip}
      arrow
      classes={{ popper: 'application-menu--tooltip' }}
    >
      <>
        <InnerComponent />
      </>
    </Tooltip>
  ) : (
    <InnerComponent />
  );
}
