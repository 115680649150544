import { Selector, useDispatch } from 'react-redux';
import { Store } from '@reduxjs/toolkit';
import { AppDispatch } from '@/Redux/store';

let store: Store;
export function setStore(source: Store) {
  store = source;
}

export function useSelector<S, TProps>(selector: Selector<S, TProps>) {
  const state = store.getState();
  return selector(state);
}
export type UseSelector = typeof useSelector;

export type ActionLike<T, Args extends Array<any>> = {
  new (dispatch: AppDispatch, useSelectorArg: UseSelector, ...others: Args): T;
};

/*
 * @template T
 * @param {new() => T} Type
 * @returns {T}
 */
export function useAction<T, Args extends Array<any>>(
  Type: ActionLike<T, Args>,
  ...args: Args
): T {
  const dispatch = useDispatch<AppDispatch>();
  return new Type(dispatch, useSelector, ...args);
}

/*
 * @template T
 * @param {new() => T} Type
 * @returns {T}
 */
export function useActionWithDispatch<T, Args extends Array<any>>(
  Type: ActionLike<T, Args>,
  dispatch: AppDispatch,
  useSelectorForAction: UseSelector = useSelector,
  ...args: Args
): T {
  return new Type(dispatch, useSelectorForAction, ...args);
}
