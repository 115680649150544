import {
  addSelectedGroupsToShapeShifter,
  updateShapeShifterCanvas,
} from '@/Redux/Slices/ShapeShifterSlice';
import { setProgressIndicator } from '@/Redux/Slices/UISlice';
import BaseAction from './BaseAction';
import UIModeAction from './UIMode';

export default class BuildActiveShapeAction extends BaseAction {
  build() {
    const { dispatch } = this;
    const uiModeAction = this.createAction(UIModeAction);

    dispatch(setProgressIndicator(true));
    dispatch(addSelectedGroupsToShapeShifter())
      .unwrap()
      .then(() => dispatch(updateShapeShifterCanvas()).unwrap())
      .then(() => {
        dispatch(setProgressIndicator(false));

        uiModeAction.toShapeShifter();
      });
  }
}
