import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// display modes
import DefaultMode from '@/InteractionsManager/Modes/Default/DefaultMode';
import ShapeShifterMode from '@/InteractionsManager/Modes/ShapeShifter/ShapeShifterMode';
import AnchorSelectionMode from '@/InteractionsManager/Modes/AnchorSelection/AnchorSelectionMode';
import PlanMode from '@/InteractionsManager/Modes/Plan/PlanMode';
import ReviewMode from '@/InteractionsManager/Modes/Review/ReviewMode';
import ImportMode from '@/InteractionsManager/Modes/Default/DefaultMode';

// actions
import { useAction } from '@/Actions/useAction';
import SetThemeAction from '@/Actions/SetTheme';
import DisabledMode from './Modes/Default/Interactions/Disabled';
import { selectIsEditMode } from '@/Redux/Slices/SyncSlice';
import { Mode, View } from '@/@types/shaper-types';
import GenericMode from './Modes/InteractionMode';
import { Recognizer } from '@use-gesture/vanilla/dist/declarations/src/Recognizer';

// each interaction manager
const INTERACTION_MODES = {
  default: new DefaultMode(),
  'text-editor': new DefaultMode(),
  'shape-shifter': new ShapeShifterMode(),
  'anchor-selection': new AnchorSelectionMode(),
  import: new ImportMode(),
  plan: new PlanMode(),
  review: new ReviewMode(),
  disabled: new DisabledMode(),
};

type InteractionMode = Mode | 'disabled';

type Props = {
  mode: InteractionMode;
  view: View;
} & PropsWithChildren;

export default function InteractionsModeManager(props: Props) {
  const dispatch = useDispatch();
  const setThemeAction = useAction(SetThemeAction);

  const isViewOnly = !useSelector(selectIsEditMode);
  const ref = useRef<HTMLDivElement>(null);
  const [lastMode, setLastMode] = useState<InteractionMode | null>(null);
  const [lastView, setLastView] = useState<View | null>(null);
  const [gesture, setGesture] = useState<Recognizer<undefined> | null>(null);

  // handles checking for new
  useEffect(() => {
    let mode: GenericMode = INTERACTION_MODES[props.mode];
    let modeName: InteractionMode = props.mode;
    let view: View = props.view;

    if (isViewOnly) {
      mode = INTERACTION_MODES.disabled;
      modeName = 'disabled';
    }

    if (!mode) {
      console.error(`Missing mode: ${props.mode}`);
      return;
    }

    setThemeAction.set(mode.theme);

    if (lastMode !== modeName || lastView !== view) {
      if (gesture) {
        gesture.destroy();
      }
      setGesture(mode.activate(dispatch, ref, view));
      setLastMode(modeName);
      setLastView(view);
    }
  }, [props.mode, props.view, isViewOnly]);

  return (
    <div ref={ref} data-interaction-manager className='data-interaction'>
      {props.children}
    </div>
  );
}
