import { escape } from 'lodash';
import { fontLibrary } from './FontHelper';
import type { FontStyle } from './FontHelper';
import {
  Shape,
  Tool,
  ToolParams,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { AddSvgParams } from '@/Actions/AddGeometry';

// const canv = document.createElement('canvas');
// const ctx = canv.getContext('2d');

/*
resvg/fontdb uses https://docs.microsoft.com/en-us/typography/opentype/spec/os2#fsselection to set 'Style' based on standard values in bitfield (e.g. regular, italic). However, many Sherpa fonts include a style attribute in the ttf.subFamily field, which resvg doesn't parse.

See https://fontdrop.info/ 

To support multiple font styles, I have modified the font familyName fields for certain styles (e.g. Bold, Thin, Heavy) fonts to distinguish them from 'regular fonts.' 

e.g. Alegreya Black font has familyName: "Alegreya Black", style:"regular".  Alegreya bold is familyName: "Alegreya Bold", style: "regular"
*/

const defaultFontDisplayName = 'IBM Plex Sans';
const defaultFontDisplayStyle = 'Regular';
const defaultFontSelection =
  fontLibrary
    .find((f) => f.fontDisplayName === defaultFontDisplayName)
    ?.fontStyles.find((fs) => fs.displayStyle === defaultFontDisplayStyle) ||
  fontLibrary[0].fontStyles[0];

export const defaultFont = {
  fontDisplayName: defaultFontDisplayName,
  fontSelection: defaultFontSelection,
};

export const FONTS = fontLibrary.sort((f1, f2) =>
  f1.fontDisplayName > f2.fontDisplayName ? 1 : -1
);

// current list of available fonts
export const FONT_CATEGORIES = fontLibrary
  .flatMap((fontEntry) => fontEntry.fontCategories)
  .reduce<string[]>((uniqueFontCategories, currentFontCategory) => {
    if (uniqueFontCategories.indexOf(currentFontCategory) === -1) {
      uniqueFontCategories.push(currentFontCategory);
    }

    return uniqueFontCategories;
  }, [])
  .sort();

export const DEFAULT_FONTS = FONT_CATEGORIES.map((category) => ({
  name: category,
  fonts: fontLibrary.filter(
    (fontEntry) => fontEntry.fontCategories[0] === category
  ),
}));

export const getFonts = (hasAllFonts: boolean) => {
  if (hasAllFonts) {
    return FONTS;
  }
  return getFontsByPremiumStatus(false);
};

export const getFontsByPremiumStatus = (isPremium: boolean) => {
  return FONTS.filter((f) => isPremium === !!f.isPremium);
};

export function createTextSvg(
  text: string,
  fontDisplayName: string,
  fontSelection: FontStyle,
  forceOpenPaths: boolean,
  hasTessellationFeatureFlag = false, // TODO: can remove once tessellation feature is complete
  variant = 'normal'
): AddSvgParams {
  const svg = createText(
    text,
    fontSelection,
    variant,
    {},
    hasTessellationFeatureFlag
  );

  const params: ToolParams<Shape.TEXT> = {
    fontDisplayName,
    fontDisplayStyle: fontSelection,
    text,
    forceOpenPaths,

    //TODO:figure out a way to exclude these because they're not actually relevant here
    width: 1,
    height: 1,
    units: 'in',
  };

  // return the result
  return {
    rawSVG: svg,
    tool: new Tool(Shape.TEXT, params),
  };
}

export function createText(
  text: string,
  fontSelection: FontStyle,
  variant = 'normal',
  styles: Record<string, any> = {},
  hasTessellationFeatureFlag = false // TODO: can remove once tessellation feature is complete
) {
  let fontSizePx = hasTessellationFeatureFlag ? 12 : 36; // TODO: can remove once tessellation feature is complete
  let style = `font-style:${fontSelection.fontStyle}; font-variant:${variant}; font-weight:normal; font-stretch:normal; font-family:${fontSelection.fontFamily}; whitespace: no-wrap`;
  if (styles) {
    style += `; ${Object.keys(styles)
      .map((s) => `${s}: ${styles[s]}`)
      .join('; ')};`;
    if ('font-size' in styles) {
      fontSizePx = styles['font-size'];
    } else {
      style += `font-size: ${fontSizePx}`;
    }
  }

  const width = 1.5 * fontSizePx * Math.max(text.length, 4);

  //Need to pad height and width so descenders and slanted fonts don't get clipped against viewbox boundaries. e.g. try 'goodbye' with Great Vibes font
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="${
    4 * fontSizePx
  }px" width="${width}px"><g><text xml:space="preserve" y="${
    2 * fontSizePx
  }" x="${fontSizePx}" style="${style}" >${escape(text)}</text></g></svg>`;

  return svg;
}
