import React, { MouseEvent } from 'react';
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '@/Components/TranslationText/TranslationText';
import New from '../Badge/New';

type Props = {
  id?: string;
  icon?: React.ReactNode;
  content?: React.ReactNode;
  className?: string;
  label?: React.ReactNode;
  children?: React.ReactNode;
  i18nKey?: string;
  onClick?: (event: MouseEvent) => void;
  attrs?: {};
  isNew?: boolean;
};

// renders a menu button
export default function CanvasActionButton({
  id,
  icon,
  content,
  className,
  label,
  children,
  i18nKey,
  onClick,
  attrs = {},
  isNew = false,
}: Props) {
  return (
    <div
      onClick={onClick}
      className={`canvas-action-menu--item ${className}`}
      {...attrs}
    >
      {!!content && (
        <div className='canvas-action-menu--item-content'>{content}</div>
      )}

      {!content && (
        <div className='canvas-action-menu--item-icon'>
          <Icon icon={icon || id} />
        </div>
      )}

      <div className='canvas-action-menu--item-label'>
        <TranslationText i18nKey={i18nKey || ''}>
          {label || children}
        </TranslationText>
      </div>
      {isNew && (
        <div className='canvas-action-menu--new'>
          <New />
        </div>
      )}
    </div>
  );
}
