import { PathId } from '@/Redux/Slices/SelectionSlice';
import _ from 'lodash';

function compareIds(a: PathId, b: PathId) {
  return a.pathId === b.pathId && a.groupId === b.groupId;
}

// checks if the selection is the same as before
export function isSameSelection(a: PathId[], b: PathId[]) {
  const result = _.xorWith(a, b, compareIds);
  return result.length === 0;
}
