import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import TranslationText from '@/Components/TranslationText/TranslationText';

// selectors
import { useSelector } from 'react-redux';
import { selectIsShowingRename } from '@/Redux/Slices/UISlice';

//actions
import { useAction } from '@/Actions/useAction';
import RenameWorkspaceAction from '@/Actions/RenameWorkspace';

//components
import TextField from '@/Components/TextField/TextField';
import Icon from '@/Styles/Icons/Icon';
import { CustomErrors } from '@/Constants/Errors';
import AlertAction from '@/Actions/Alert';
import { ALERT_TYPES, MODAL_TYPES } from '@/defaults';
import { selectWorkspaceName } from '@/Redux/Slices/ShaperHubSlice';

type Props = {
  onClick: () => void;
};

export default function FileName(props: Props) {
  const fileName = useSelector(selectWorkspaceName);

  const isShowingRename = useSelector(selectIsShowingRename);

  const [workspaceName, setWorkspaceName] = useState(fileName);
  const [newWorkspaceName, setNewWorkspaceName] = useState(fileName);
  const [hasError, setHasError] = useState(false);
  const workspaceNameRef = useRef<HTMLInputElement>(null);

  const renameWorkspaceAction = useAction(RenameWorkspaceAction);
  const alertAction = useAction(AlertAction);

  const fileNameCx = classNames('application-menu--file-name');

  const close = () => {
    setWorkspaceName(fileName);
    props.onClick();
  };

  const updateWorkspaceName = (newValue: string) => {
    setHasError(false);
    setNewWorkspaceName(newValue);
  };

  const rename = async () => {
    if (fileName !== newWorkspaceName) {
      const renameResult = await renameWorkspaceAction.renameWorkspace(
        newWorkspaceName
      );
      if (renameResult) {
        setHasError(true);
        if (renameResult === CustomErrors.ALREADY_USED_RENAME) {
          alertAction.set({
            msg: 'Name already in use',
            i18nKey: 'name-in-use',
            type: ALERT_TYPES.ERROR_DISMISSIBLE,
            icon: 'alert-warning',
            modalIcon: 'circle-warning',
            duration: 4000,
          });
        } else if (renameResult === CustomErrors.BAD_CHARACTER_RENAME) {
          alertAction.set({
            msg: 'Invalid character',
            i18nKey: 'invalid-character',
            type: ALERT_TYPES.ERROR_DISMISSIBLE,
            modal: MODAL_TYPES.INVALID_CHARACTERS_MODAL,
            icon: 'alert-warning',
            duration: 4000,
          });
        }
      } else {
        setHasError(false);
      }
    } else {
      setHasError(false);
      close();
    }
  };

  useEffect(() => {
    setWorkspaceName(fileName);
  }, [fileName]);

  useEffect(() => {
    workspaceNameRef.current?.select();
    setHasError(false);
  }, [isShowingRename]);

  const inputCx = classNames('rename-file', {
    error: hasError,
    'error-shake-text': hasError,
  });

  return (
    <div className={fileNameCx}>
      {isShowingRename ? (
        <div
          className='application-menu--file-name--edit'
          data-cy='workspace-rename'
        >
          <div className='application-menu--file-name--text-field'>
            <TextField
              id='text-input'
              type='text'
              className={inputCx}
              inputRef={workspaceNameRef}
              value={workspaceName}
              onChange={updateWorkspaceName}
              onSubmit={rename}
              submitOnEnter
              forceUpdateValue
            />
          </div>
          <div className='application-menu--file-name--action'>
            <div
              className='application-menu--file-name--button'
              data-cy='workspace-rename-commit'
              onClick={rename}
            >
              <TranslationText i18nKey='rename'>Rename</TranslationText>
            </div>
            <div
              className='application-menu--file-name--close-icon'
              onClick={close}
            >
              <Icon icon='close' />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className='application-menu--file-name--text'
            onDoubleClick={props.onClick}
            data-cy='workspace-name'
          >
            {fileName}
          </div>
        </>
      )}
    </div>
  );
}
