import React, { useState } from 'react';

//selector
import { useSelector } from 'react-redux';
import {
  selectShaperHubPath,
  selectShaperHubFolder,
} from '@/Redux/Slices/ShaperHubSlice';

//actions
import { useAction } from '../../Actions/useAction';
import FileSystemAction from '../../Actions/FileSystem';

//components
import Icon from '@/Styles/Icons/Icon';
import classNames from 'classnames';
import { FILETYPES } from '../../defaults';
import TranslationText from '../TranslationText/TranslationText';
import { useTranslation } from 'react-i18next';

export default function ProjectImport(props) {
  const shaperHubPath = useSelector(selectShaperHubPath);
  const shaperHubFolder = useSelector(selectShaperHubFolder);
  const atRoot = shaperHubPath && shaperHubPath.length === 0;

  const [disabled, setDisabled] = useState(true);
  const [selected, setSelected] = useState(null);

  const { t, i18n } = useTranslation();

  const fileSystemAction = useAction(FileSystemAction);

  const buttonCx = classNames('project-import--footer-button', {
    disabled: disabled,
  });

  const select = (file) => {
    if (file.type === FILETYPES.FOLDER) {
      fileSystemAction.selectDirectory(file.name);
    } else {
      setSelected(file);
      setDisabled(false);
    }
  };

  const clear = () => {
    fileSystemAction.clearDirectory(atRoot);
  };

  const importSelected = () => {
    if (selected) {
      if (selected.type === FILETYPES.FILE) {
        fileSystemAction.openFile(selected);
      }
      if (selected.type === FILETYPES.EXTERNAL) {
        fileSystemAction.importWorkspace(selected);
      }
      if (selected.type === FILETYPES.PROJECT_LINK) {
        fileSystemAction.importStudioShare(selected);
      }
    }
  };

  return (
    <>
      <div className='project-import--header'>
        {!atRoot && (
          <div className='project-import--header--back'>
            <Icon icon='arrow-left' onClick={clear} />
          </div>
        )}
        <div className='project-import--header--title'>
          <div className='project-import--header--name'>
            {atRoot
              ? i18n.exists('my-projects')
                ? t('my-projects')
                : 'My Files'
              : shaperHubPath[shaperHubPath.length - 1]}
          </div>
        </div>
      </div>
      <div className='project-import--files scrollable'>
        {shaperHubFolder
          .filter(
            (f) => f.type !== 'projectLink' || f.projectType === 'studioShare'
          )
          .map((f, i) => {
            const icon =
              f.type === FILETYPES.EXTERNAL
                ? 'external'
                : f.type === FILETYPES.PROJECT_LINK
                ? 'external'
                : f.type === FILETYPES.FOLDER
                ? 'file'
                : 'svg-file';
            return (
              <div
                key={i}
                className={`project-import--files--object ${
                  selected === f ? 'selected' : ''
                }`}
                onClick={() => select(f)}
              >
                <Icon
                  icon={icon}
                  className='project-import--files--object-icon'
                />
                <div className='project-import--files--object-name'>
                  {f.name}
                </div>
              </div>
            );
          })}
      </div>
      <div className='project-import--footer'>
        <div className={buttonCx} onClick={importSelected}>
          <TranslationText i18nKey='import'>Import</TranslationText>
        </div>
      </div>
    </>
  );
}
