import React, { useEffect } from 'react';

//actions
import { useAction } from '@/Actions/useAction';
import ModalActions from '@/Actions/Modal';

//components
import Icon from '@/Styles/Icons/Icon';
import Modal from '../Modal';
import TranslationText from '../../TranslationText/TranslationText';
import { batch, useDispatch, useSelector } from 'react-redux';
import OpenSyncedWorkspaceAction from '@/Actions/OpenSyncedWorkspace';
import { ALERT_TYPES, MODAL_TYPES } from '@/defaults';
import { setLoading } from '@/Redux/Slices/SherpaContainerSlice';
import AlertAction from '@/Actions/Alert';
import { duplicate } from '@/Sync/SyncThunks';
import { selectWorkspaceId } from '@/Redux/Slices/SyncSlice';

export default function SyncModal() {
  const modalActions = useAction(ModalActions);
  const openSyncedWorkspaceAction = useAction(OpenSyncedWorkspaceAction);
  const alertAction = useAction(AlertAction);
  const dispatch = useDispatch();

  const workspaceId = useSelector(selectWorkspaceId);

  const onDuplicateWorkspace = () => {
    batch(() => {
      dispatch(duplicate({ atSequence: true }));
      dispatch(setLoading(true));
      alertAction.clear();
      modalActions.closeModal(true);
    });
  };

  const onRefreshWorkspace = () => {
    if (workspaceId) {
      batch(() => {
        openSyncedWorkspaceAction.openSyncedWorkspace(workspaceId);
        modalActions.closeModal(true);
      });
    }
  };

  useEffect(() => {
    alertAction.clear();

    return () => {
      alertAction.set({
        msg: 'Design out of date',
        i18nKey: 'design-out-date',
        type: ALERT_TYPES.OUT_OF_SYNC,
        modal: MODAL_TYPES.SYNC_MODAL,
        className: 'out-of-sync-alert',
        position: 'top',
        modalIcon: 'question-mark-circle-inverse',
      });
    };
  }, []);

  return (
    <Modal className='sync-modal'>
      <Modal.Panel position='center'>
        <Modal.Content>
          <Modal.Title>
            <Icon icon='timelapse' />
            <TranslationText i18nKey='design-out-date'>
              Design out of date
            </TranslationText>
          </Modal.Title>
          <div className='sync-modal--info'>
            <div className='sync-modal--info--text'>
              <TranslationText i18nKey='design-elsewhere'>
                The design has been updated elsewhere.
              </TranslationText>
            </div>
            <div className='sync-modal--info--text'>
              <TranslationText i18nKey='design-update'>
                To show the up to date design, hit the button below.
              </TranslationText>
            </div>
          </div>
          <div className='sync-modal--reload'>
            <Modal.Button onClick={onRefreshWorkspace}>
              <TranslationText i18nKey='reload'>Reload</TranslationText>
            </Modal.Button>
          </div>
          <div className='sync-modal--option'>
            <div className='sync-modal--option--text'>
              <TranslationText i18nKey='outdated-design'>
                If you would like to save the outdated design, save as new.
              </TranslationText>
            </div>
            <div className='sync-modal--option--button'>
              <Modal.Button onClick={onDuplicateWorkspace}>
                <Icon icon='duplicate-workspace' />
                <TranslationText i18nKey='new'>New</TranslationText>
              </Modal.Button>
            </div>
          </div>
        </Modal.Content>
      </Modal.Panel>
    </Modal>
  );
}
