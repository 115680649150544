import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Patch } from 'immer';

const createDeletePatch = (
  id: string,
  canvasObj: SvgGroup[],
  path: string[]
): [Patch, Patch, number] | [null, null, number] => {
  const svgGroup = canvasObj.find((s) => s.id === id);
  if (svgGroup) {
    const elementPosition = canvasObj.indexOf(svgGroup);
    if (elementPosition > -1) {
      // remove patch
      const patch = { op: 'remove', path: [...path, elementPosition] } as Patch;

      // inverse patch is to add the object back at element position
      const inversePatch = {
        op: 'add',
        path: [...path, elementPosition],
        value: svgGroup,
      } as Patch;
      return [patch, inversePatch, elementPosition];
    }
  }

  return [null, null, -1];
};

export const produceDeletePatches = (
  ids: string[] | string,
  canvasObj: SvgGroup[],
  path: string[]
) => {
  const patches = [] as Patch[],
    inversePatches = [] as Patch[];
  if (Array.isArray(ids)) {
    ids.reduce((canvas, deleteId) => {
      const [patch, inversePatch, elementPosition] = createDeletePatch(
        deleteId,
        canvas,
        path
      );
      if (elementPosition > -1 && patch && inversePatch) {
        patches.push(patch);
        inversePatches.unshift(inversePatch);

        canvas.splice(elementPosition, 1);
      }
      return canvas;
    }, canvasObj);

    return [patches, inversePatches];
  }

  const [patch, inversePatch] = createDeletePatch(ids, canvasObj, path);
  return [[patch], [inversePatch]];
};
