import React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// slices
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';

// actions
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';

// components
import { ModeOptionItem } from '../ModeOptionMenu';
import Toggle from '../Components/Toggle';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { DisplayUnits } from '@/@types/shaper-types';

export default function UnitSelector() {
  // selectors
  const options = useSelector(selectOptions);

  // actions
  const updateOptionsAction = useAction(UpdateWorkspaceOptionsAction);

  // handlers
  function onUpdateDisplayUnits(displayUnits: DisplayUnits) {
    updateOptionsAction.update({ displayUnits });
  }

  return (
    <ModeOptionItem icon='unit-type'>
      <div className='mode-selection-menu--options-title'>
        <TranslationText i18nKey='units'>Units</TranslationText>
      </div>
      <Toggle value={options.displayUnits} onToggle={onUpdateDisplayUnits}>
        <Toggle.Item<string> value='in' icon='unit-in' />
        <Toggle.Item<string> value='mm' icon='unit-mm' />
      </Toggle>
    </ModeOptionItem>
  );
}
