import { useState } from 'react';
import { useSelector } from 'react-redux';
import { scalarMul } from '@/Geometry/PointOps';
import { getAABBBounds } from '@/Geometry/AABBOps';

// Actions and Selectors
import {
  selectImportedGroupSvg,
  selectImportedGroup,
} from '@/Redux/Slices/ImportSlice';
import { selectToDisplayUnits } from '@/Redux/Slices/SherpaContainerSlice';
import ImportGeometryAction from '@/Actions/ImportGeometry';
import { selectIsFromTrace } from '@/Redux/Slices/ImportSlice';

// Components
import FooterMenu from '@/Components/FooterMenu/FooterMenu';
import Icon from '@/Styles/Icons/Icon';
import Label from '@/UILayer/Components/Label';
import Loading from '@/Components/Loading/Loading';

// Styling
import UIModeAction from '@/Actions/UIMode';
import { useAction } from '@/Actions/useAction';
import Viewport from '@/UILayer/Components/Viewport';
import TranslationText from '@/Components/TranslationText/TranslationText';
import ToggleButton, {
  ToggleButtonOption,
} from '@/Components/ToggleButton/ToggleButton';

//helpers
import pluralize from '@/Utility/pluralize';
import UIState from '@/UILayer/State/UIState';

//Scale viewport to be 1.5x imported group size, centered on group center
const IMPORTED_GROUP_IMAGE_MARGIN = 1.5;

type Props = {
  ui: UIState;
};

export default function ImportUI(props: Props) {
  //toggle options
  const options = [
    {
      label: 'All Together',
      icon: 'all-together',
      i18nKey: 'all-together',
    },
    {
      label: 'Exploded',
      icon: 'exploded',
      i18nKey: 'exploded',
    },
  ];

  const [showLoading, setShowLoading] = useState(false);
  const [placeOption, setPlaceOption] = useState<ToggleButtonOption>(
    options[0]
  );
  const uiModeAction = useAction(UIModeAction);
  const importGeometryAction = useAction(ImportGeometryAction);
  const importedSvg = useSelector(selectImportedGroupSvg)!;
  const importedSvgGroup = useSelector(selectImportedGroup)!;
  const toDisplayUnits = useSelector(selectToDisplayUnits);
  const fromTrace = useSelector(selectIsFromTrace);

  //Svg viewbox calcs
  const { x, y } = scalarMul(
    importedSvgGroup.transformedAABB.minPoint,
    IMPORTED_GROUP_IMAGE_MARGIN
  );
  const { x: width, y: height } = scalarMul(
    importedSvgGroup.displaySize,
    IMPORTED_GROUP_IMAGE_MARGIN
  );
  const { width: pixelWidth, height: pixelHeight } = props.ui.viewport.size;
  const mmToPixelScaleX = pixelWidth / width;
  const mmToPixelScaleY = pixelHeight / height;

  //Pick minimum so svg fits window
  const mmToPixelScale = Math.min(mmToPixelScaleY, mmToPixelScaleX);

  const svgWidthPixels = mmToPixelScale * width;
  const svgHeightPixels = mmToPixelScale * height;

  //nspf converts pixels to mm
  //We need to set stroke-width in canvas units to be equivalent to desired pixel size (e.g. 2px). The viewportSlice provides a nonScalingPixelFactor, but this is valid only for objects on the main canvas.
  //Here, we're using a separate SVG doc for the import mode, not drawing to the existing viewport with the user-specified zoon level.
  //So we need to do the same computation, but based on this svg's viewport, not the canvas svg's viewport.
  const nonScalingPixelFactor = 1 / mmToPixelScale;

  const viewportProps = {
    x,
    y,
    width,
    height,
    size: { width: svgWidthPixels, height: svgHeightPixels },
  };

  //For bounding rect and dimension display
  const { left, right, top, center, size } = getAABBBounds(
    importedSvgGroup.transformedAABB
  );

  const groupWidthDim = toDisplayUnits(importedSvgGroup.displaySize.x, 3);
  const groupHeightDim = toDisplayUnits(importedSvgGroup.displaySize.y, 3);

  function onPlaceAsGroup() {
    importGeometryAction.placeImportedGroups();
  }

  function onPlaceSeparately() {
    //Show loading animation
    setShowLoading(true);
    //Kick off webworker process
    importGeometryAction.splitAndPlaceImportedGroup();
  }

  function onPlace() {
    if (!placeOption || placeOption.i18nKey === 'all-together') {
      onPlaceAsGroup();
    } else {
      onPlaceSeparately();
    }
  }

  function onCancelImport() {
    uiModeAction.toDefault();
  }

  function toggleOnPlace(opt: ToggleButtonOption) {
    setPlaceOption(opt);
  }

  const objectCount =
    !placeOption || placeOption.i18nKey === 'all-together'
      ? 1
      : importedSvg.length;
  const placeObjectString = pluralize`Place ${objectCount} Object`;

  return (
    <div>
      <style type='text/css'>{`
        #userflow-ui {
          display: none !important;
        }
        path.import {
          stroke-width: 2px;
          fill: #B8CAFF4D;
          stroke: #2C65FF;
          vector-effect: non-scaling-stroke;
        }
        .dim-box{
          fill: none;
          stroke: #B9BCC6;
          stroke-width: ${1 * nonScalingPixelFactor}; 
        }
        .import-ui-viewport{
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .import-ui-label-container {
          fill: rgba(44, 101, 255, 0.08);
        }
        .import-ui-label-text {
          font-weight: 600;
          font-size: 16px;
          line-height: 148%;
          /* identical to box height, or 24px */

          display: flex;
          align-items: center;
          letter-spacing: 0.01em;

          fill: #0546F6;
        }
      }
      `}</style>

      <div className='import-ui-header' data-cy='file-import-ui'>
        <Icon icon='import-file' />
        <div>
          {fromTrace ? (
            <TranslationText i18nKey='import-trace'>
              Import to Studio
            </TranslationText>
          ) : (
            <TranslationText i18nKey='import'>Import</TranslationText>
          )}
        </div>
      </div>
      {showLoading && (
        <div className='sherpa-container--loading'>
          <Loading type='domino' withOverlay />
        </div>
      )}
      <Viewport className='import-ui-viewport' viewport={viewportProps}>
        <rect
          className='dim-box'
          x={left}
          y={top}
          width={size.x}
          height={size.y}
        />
        <Label
          nspf={nonScalingPixelFactor}
          x={center.x}
          y={top - 24 * nonScalingPixelFactor}
          text={
            typeof groupWidthDim === 'string'
              ? groupWidthDim
              : groupWidthDim.label
          }
          classPrefix='import-ui-'
          radius={8}
          fontSize={16}
          paddingX={18}
          paddingY={8}
        />
        <Label
          nspf={nonScalingPixelFactor}
          x={right + 24 * nonScalingPixelFactor}
          y={0}
          rotation={Math.PI / 2}
          text={
            typeof groupHeightDim === 'string'
              ? groupHeightDim
              : groupHeightDim.label
          }
          classPrefix='import-ui-'
          radius={8}
          fontSize={16}
          paddingX={18}
          paddingY={8}
        />
        {<g dangerouslySetInnerHTML={{ __html: importedSvg }} />}
      </Viewport>

      <FooterMenu>
        <FooterMenu.Row>
          <ToggleButton
            className='import-ui-toggle-button'
            value={placeOption}
            options={options}
            onClick={toggleOnPlace}
          />
        </FooterMenu.Row>
        <FooterMenu.Row className='import-ui-bottom-row'>
          <FooterMenu.Button onClick={onPlace}>
            <TranslationText i18nKey='place' count={objectCount}>
              {placeObjectString}
            </TranslationText>
          </FooterMenu.Button>
          <FooterMenu.Close onClick={onCancelImport} />
        </FooterMenu.Row>
      </FooterMenu>
    </div>
  );
}
