/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classNames from 'classnames';

//actions
import { useAction } from '@/Actions/useAction';
import ModalActions from '@/Actions/Modal';

//selectors
import { useSelector } from 'react-redux';
import {
  selectShaperSubscriptions,
  selectShaperMarketingUrl,
  selectLocale,
  selectUserIsVerified,
} from '@/Redux/Slices/ShaperHubSlice';

//components
import Modal from '../Modal';
import Icon from '@/Styles/Icons/Icon';

//helpers
import { getSubscribeLink, getTrialLink } from '@/Utility/subscribe-link';
import StartTrialAction from '@/Actions/StartTrial';
import { localePricing, menuLinks, miscLinks } from '../../../defaults';
import TranslationText from '../../TranslationText/TranslationText';
import { selectFeatureFlags } from '@/Redux/Slices/FeatureFlagsSlice';
import { debounce } from 'lodash';

export default function EducationalModal() {
  const featureFlags = useSelector(selectFeatureFlags);
  const noNewTrials = featureFlags['release-no-new-studio-trials'];
  const { isSubscriber, isTrial, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const userIsVerified = useSelector(selectUserIsVerified);
  const shaperMarketingUrl = useSelector(selectShaperMarketingUrl);
  const hasNeverHadTrial = !isSubscriber && !isTrial;
  const { displayLocale, currency } = useSelector(selectLocale);

  const modalActions = useAction(ModalActions);
  const startTrialAction = useAction(StartTrialAction);

  const debounceStart = debounce(() => {
    startTrialAction.start();
  }, 1000);

  const closeModal = () => {
    modalActions.closeModal();
  };

  const openSubscribeLink = () => {
    const subscribePath = getSubscribeLink();
    window.open(`${shaperMarketingUrl}/${subscribePath}`, '_self');
  };

  const openLink = () => {
    if (hasNeverHadTrial && !noNewTrials) {
      if (userIsVerified) {
        debounceStart();
      } else {
        const trialPath = getTrialLink();
        window.open(`${shaperMarketingUrl}/${trialPath}`, '_self');
      }
    } else {
      openSubscribeLink();
    }
  };

  const subscriptionPricing = currency
    ? localePricing[currency].toLocaleString(displayLocale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : '';

  const titleVariation = (() => {
    if (isSubscriber && isExpired) {
      return (
        <TranslationText i18nKey='subscription-ended'>
          Your subscription has ended
        </TranslationText>
      );
    } else if (isTrial && isExpired) {
      return (
        <TranslationText i18nKey='free-trial-ended'>
          Oh no, your free trial has ended!
        </TranslationText>
      );
    }

    return (
      <TranslationText i18nKey='do-more'>Do More With Studio</TranslationText>
    );
  })();

  return (
    <Modal
      className={classNames('educational-modal', {
        'no-new-trials': noNewTrials,
      })}
    >
      <Modal.Panel position='left'>
        <Modal.Content>
          <Modal.Title>{titleVariation}</Modal.Title>
          <Modal.Button onClick={openLink}>
            {hasNeverHadTrial && !noNewTrials ? (
              <TranslationText i18nKey='start-free-trial'>
                Start a free trial
              </TranslationText>
            ) : (
              <>
                <TranslationText i18nKey='subscribe'>Subscribe</TranslationText>
                {` (${subscriptionPricing}/YR)`}
              </>
            )}
          </Modal.Button>
          {hasNeverHadTrial && !noNewTrials && (
            <div className='educational-modal--left--content--title-subheader'>
              <a onClick={openSubscribeLink}>
                <TranslationText i18nKey='or-subscribe'>
                  or subscribe
                </TranslationText>
              </a>
            </div>
          )}
          <div className='educational-modal--left--content--info-text'>
            {hasNeverHadTrial && !noNewTrials ? (
              <TranslationText i18nKey='try-full-version'>
                With an account, try the full version completely free for 14
                days. We won't ask for payment info and you won't be billed.
              </TranslationText>
            ) : (
              <>
                <div className='educational-modal--left--content--info-terms'>
                  <TranslationText i18nKey='access-designs'>
                    Access millions of pre-made designs
                  </TranslationText>
                </div>
                <div className='educational-modal--left--content--info-terms'>
                  <TranslationText i18nKey='shapeshifter-explanation'>
                    Fast results with Shapeshifter
                  </TranslationText>
                </div>
                <div className='educational-modal--left--content--info-terms'>
                  <TranslationText i18nKey='sync-to-origin'>
                    Sync to Origin from your phone or computer
                  </TranslationText>
                </div>
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className='educational-modal--left--footer--learn-more'>
            <a href={menuLinks.LEARN_MORE}>
              <TranslationText i18nKey='learn-more-modal'>
                Learn More about Studio
              </TranslationText>
            </a>
          </div>
          {noNewTrials && (
            <>
              <div className='educational-modal--left--footer--changes-coming'>
                <TranslationText i18nKey='changes-are-coming'>
                  Changes are coming to Studio plans.
                </TranslationText>
              </div>
              <div className='educational-modal--left--footer--read-blog'>
                <a href={miscLinks.CHANGES_ARE_COMING_BLOG_POST}>
                  <TranslationText i18nKey='read-the-blog-post'>
                    Read the blog post
                  </TranslationText>
                </a>
              </div>
            </>
          )}
        </Modal.Footer>
      </Modal.Panel>
      <Modal.Panel position='right'>
        <div className='educational-modal--close-icon'>
          <Icon icon='close' onClick={closeModal} />
        </div>
        <div className='educational-modal--learn-more'>
          <a
            href={menuLinks.LEARN_MORE}
            className='educational-modal--learn-more-text'
          >
            {noNewTrials ? (
              <TranslationText i18nKey='learn-more-about-studio'>
                Learn More About Studio
              </TranslationText>
            ) : (
              <TranslationText i18nKey='learn-more'>Learn More</TranslationText>
            )}
          </a>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
