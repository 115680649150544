// @ts-nocheck
import BaseAction from '@/Actions/BaseAction';
import SelectionNetAction from '@/Actions/SelectionNet';
import SetCursorAction from '@/Actions/SetCursor';
import SetSelectionAction from '@/Actions/SetSelection';
import { SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY } from '@/Constants/UI';
import { isSameSelection } from '@/Helpers/Selection';
import BaseInteraction from '@/InteractionsManager/Interactions/BaseInteraction';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import { uniqBy } from 'lodash';
import { sortSelectionByArea } from '@/Utility/shapes';
import { selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';
import ResolveGroupSelectionAction from '@/Actions/ResolveGroupSelection';

const toFullId = (item) => `${item.groupId}::${item.pathId}`;

export default class PreviewSelectInteraction extends BaseInteraction {
  interactionId = 'Selection Net';
  longPress?: boolean;
  action?: BaseAction;

  setActive(active: boolean, longPress = false) {
    super.setActive(active);

    // replace the cursor, as needed
    const cursor = this.createAction(SetCursorAction);
    if (this.isActive || longPress) {
      cursor.toMultiSelect();
    } else {
      cursor.toDefault();
    }
  }

  onEveryWindowExit() {
    this.onActivePointerMoveEnd();
  }

  onEveryKeyDown(event) {
    if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      this.longPress = true;
    }
  }

  onEveryKeyUp(event) {
    if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      this.longPress = false;
    }
  }

  onLongPressActivate(event, manager) {
    const over = this.getGroupsAt({ ...event.center, multiSelection: true });

    // only activate if in blank canvas space
    if (!over.length) {
      this.longPress = true;
      this.setActive(true, true);
    }
  }

  onLongPressRelease() {
    setTimeout(this.clearSelectionNet);
  }

  onPointerMoveStart() {
    if (this.longPress) {
      this.setActive();
    }
  }

  onActivePointerMove(event, manager) {
    if (!this.action) {
      // create the selection net action
      this.setActive();
      const originX = event.center.x;
      const originY = event.center.y;
      this.action = this.createAction(SelectionNetAction, originX, originY);
    }

    // handle updates
    const over = this.action.update(event.center.x, event.center.y);

    let selection = uniqBy([...over], toFullId);

    if (this.longPress) {
      if (this.type) {
        if (selection.some((s) => s.type === PATH_TYPE.DESIGN)) {
          this.type = PATH_TYPE.DESIGN;
        }
        const newSelectedGroups = selection.filter((s) => s.type === this.type);
        const groupIds = selection.map((s) => s.groupId);
        const test = newSelectedGroups.filter((f) =>
          groupIds.includes(f.groupId)
        );
        selection = test;
      } else {
        this.type = selection[0]?.type;
      }
    }

    // check if the selection is different or there is no previousSelection
    if (!isSameSelection(this.previousSelection, selection)) {
      const select = this.createAction(SetSelectionAction);
      select.set(selection);

      // updates the menu state
      this.refreshMenuState({
        reason: 'selection',
        selection,
      });
    }

    // always refresh the hovering
    this.refreshHoverState(event.center);

    this.previousSelection = selection;
    manager.isDragging = true;
  }

  clearSelectionNet = () => {
    this.pathType = null;
    delete this.action;
    this.onActivePointerMoveEnd();
  };

  // ui interactions
  onActivePointerMoveEnd() {
    this.setActive(false);

    // always clear alt activation -- require another
    // long press to start again
    this.longPress = false;
    this.type = null;

    // needs to resolve selection
    if (this.action) {
      this.action.resolve();
    }

    // clear
    this.action = null;
  }

  onEveryPointerUp() {
    setTimeout(this.clearSelectionNet);
  }

  onPointerDown(event) {
    this.lastKnownHandle = this.displayedHandle;

    if (this.lastKnownHandle) {
      this.setActive();
    }

    // is a starting over the selection box (no handles considered)
    this.startedOverSelectionBox = this.hitTestSelectionBox(event.center);

    // handle touch events and get a larger handle
    if (event.isTouch) {
      this.mobileHandle = this.getHandleAt(event.center, {
        extendSelection: true,
      });
    }
    this.pointerMoved = false;
  }

  onPointerUp(event, manager) {
    // if for some reason there's an action in progress (which shouldn't happen
    // just skip resolving the selection)
    const { isDragging } = manager;
    if (!this.action && !this.pointerMoved && !isDragging) {
      this.origin = event;

      // check what the pointer is over
      const svgGroupSet = this.useSelector(selectSvgGroupSet);
      let over = this.getGroupsAt({
        ...event.center,
        ...{
          hitDetectEdge: true,
          hitDetectFill: true,
        },
      });

      over = sortSelectionByArea(over, svgGroupSet);

      // check if over the existing selection box
      const overSelectionBox = this.hitTestSelectionBox(event.center);

      // they're the same so it's just a click so void click on the canvas
      if (!overSelectionBox && !over.length) {
        this.refreshMenuState({ reason: 'void-click' });
        this.clearHoverState();
        this.clearSelection();

        // manager.preventRemaining();
        return true;
      }

      // determine what to do with the selection
      const selection = this.createAction(ResolveGroupSelectionAction);
      const appendToSelection = this.shouldAppendToSelection(event);
      this.selection = selection.resolve(over, {
        appendToSelection,
        didSelectLine: false,
      });

      // updates the menu state
      this.refreshMenuState({
        reason: 'selection',
        selection: this.selection,
      });

      // selection cleared
      if (!this.selection.length) {
        this.clearSelection();
      }

      // update the hover state
      // wait a moment for the state to update
      // TODO: ideally, we just pass the new selection state into this function
      setTimeout(() =>
        this.refreshHoverState(event.center, { isTouch: event.isTouch })
      );
    }
    if (isDragging) {
      manager.isDragging = false;
    }
  }

  // checks for events that should append the targeted layer
  // onto the current selection
  shouldAppendToSelection(event) {
    return event.shiftKey || /touch/i.test(event.pointerType);
  }
}
