import { AppDispatch } from '@/Redux/store';
import { getId } from '../BasePathOps';
import { generatePathId } from '@shapertools/sherpa-svg-generator/BasePath';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import {
  createCutPathSvg,
  createSvgImageGroup,
} from '@shapertools/sherpa-svg-generator/SvgGenerator';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { getScaledBasePathCache } from './BasePathCache';
import { AsyncPathCache } from './interfaces/AsyncPathCache';
import { DepthClippedPathCache } from './interfaces/DepthClippedPathCache';
import {
  AsyncGeneratingFunction,
  GeneratingFunction,
} from './interfaces/GenericCache';
import { PathCache } from './interfaces/PathCache';
import { getReviewPathCache } from './ReviewPathCache';
import { getToolPathCache, ToolPathSvgs } from './ToolPathCache';
import { getTypeProperty } from '@shapertools/sherpa-svg-generator/PathTypes';

interface DisplaySettings {
  showToolPaths: boolean;
  showPlanPreviewPaths: boolean;
  showBasePaths: boolean;
  showReviewPaths: boolean;
  showCutPaths: boolean;
}

export type ExtraArgs = {
  hasTessellationFeatureFlag: boolean;
};

export type ToolPathsCache = PathCache<ToolPathSvgs, ExtraArgs>;

export class SvgCache {
  scaledBasePaths: PathCache<string, { useSourceSvg: boolean } & ExtraArgs>;
  toolPaths: ToolPathsCache;
  reviewPaths: AsyncPathCache<
    string,
    { toolPathCache: ToolPathsCache } & ExtraArgs
  >;
  depthClippedPaths: DepthClippedPathCache;
  hasTessellationFeatureFlag: boolean = false;
  dispatch?: AppDispatch;

  constructor(
    basePathsGenFunction: GeneratingFunction<
      string,
      { useSourceSvg: boolean } & ExtraArgs
    >,
    toolPathsGenFunction: GeneratingFunction<ToolPathSvgs, ExtraArgs>,
    reviewPathsGenFunction: AsyncGeneratingFunction<
      string,
      { toolPathCache: ToolPathsCache } & ExtraArgs
    >
  ) {
    this.scaledBasePaths = new PathCache(basePathsGenFunction);
    this.toolPaths = new PathCache(toolPathsGenFunction);
    this.reviewPaths = new AsyncPathCache(reviewPathsGenFunction);
    this.depthClippedPaths = new DepthClippedPathCache();
  }

  purge() {
    this.scaledBasePaths.purge();
    this.toolPaths.purge();
    this.reviewPaths.purge();
    this.depthClippedPaths.purge();
  }

  getSvgGroupDisplaySvg(svgGroup: SvgGroup, pathSelections: DisplaySettings) {
    const {
      showToolPaths,
      showPlanPreviewPaths,
      showBasePaths,
      showReviewPaths,
      showCutPaths,
    } = pathSelections;
    const pathGroupsSvg = [];

    const basePathSet =
      svgGroup?.postProcessPathSet?.length > 0
        ? svgGroup.postProcessPathSet
        : svgGroup.basePathSet;

    for (const basePath of basePathSet) {
      const pathSvg = [];
      const cutParams = basePath.cutParams;

      if (showBasePaths) {
        const { pathSvg: scaledBasePathCacheSvg } =
          this.scaledBasePaths.getCache(basePath, svgGroup, {
            useSourceSvg: true,
            hasTessellationFeatureFlag: this.hasTessellationFeatureFlag,
          });
        pathSvg.push(scaledBasePathCacheSvg);
      }

      if (showToolPaths || showPlanPreviewPaths) {
        const { pathSvg: toolPathCacheSvg } = this.toolPaths.getCache(
          basePath,
          svgGroup,
          { hasTessellationFeatureFlag: this.hasTessellationFeatureFlag }
        );

        const { toolPathSvg, previewPathSvg } = toolPathCacheSvg;

        if (showToolPaths) {
          pathSvg.push(toolPathSvg);
        }

        if (showPlanPreviewPaths) {
          //duplicate toolWidth to display pocket pattern properly
          if (cutParams.cutType === 'pocket') {
            const previewPathSvgPattern = previewPathSvg.replaceAll(
              /oolWidth/g,
              'oolWidthPattern'
            );
            pathSvg.push(previewPathSvgPattern);
          }

          pathSvg.push(previewPathSvg);
        }
      }

      const pathGroupGroupId = generatePathId(
        svgGroup.id,
        getId(basePath) as string,
        'pathGroup'
      );

      const pathGroupGroupId2 = generatePathId(
        svgGroup.id,
        getId(basePath) as string,
        'pathGroup2'
      );

      const closed = basePath.outerPath
        ? basePath.outerPath.closed
        : basePath.closed;
      const pathGroupCSS = ['pathGroup', closed ? 'closed' : 'open'];

      if (showPlanPreviewPaths) {
        pathGroupCSS.push(`cut-type-${cutParams.cutType}`);
        if (showCutPaths) {
          pathGroupCSS.push(`has-cut-paths`);
        }
        pathSvg.unshift(pathSvg.pop());
      }

      const pathGroupSvg = createCutPathSvg(
        pathSvg.join('\n'),
        pathGroupCSS,
        pathGroupGroupId,
        ''
      );

      pathGroupsSvg.push(pathGroupSvg);

      if (svgGroup.type === PATH_TYPE.REFERENCE) {
        const pathGroupSvgDupe = createCutPathSvg(
          pathSvg.join('\n'),
          ['pathGroup2'],
          pathGroupGroupId2
        );

        pathGroupsSvg.push(pathGroupSvgDupe);
      }
    }

    const cssClasses = !(showPlanPreviewPaths && showReviewPaths)
      ? [svgGroup.type?.toLowerCase() || PATH_TYPE.DESIGN.toLowerCase()]
      : [];

    return createSvgImageGroup(
      pathGroupsSvg.join('\n'),
      cssClasses,
      svgGroup.id,
      '',
      svgGroup.transformedAABB
    );
  }

  setDispatch(dispatch: AppDispatch) {
    this.depthClippedPaths.setDispatch(dispatch);
  }

  setFeatureFlag(hasFeatureFlag: boolean) {
    this.hasTessellationFeatureFlag = hasFeatureFlag;
  }

  async updateDepthClippedCache(svgGroupSet: SvgGroup[]) {
    const filteredSvgGroupSet = svgGroupSet.filter((s) =>
      getTypeProperty(s.type, 'review')
    );
    this.depthClippedPaths.updateCache(
      filteredSvgGroupSet,
      this.toolPaths,
      this.hasTessellationFeatureFlag
    );
  }

  getDepthClippedSvg(svgGroupSet: SvgGroup[]) {
    const { pathSvg } = this.depthClippedPaths.getCache(
      svgGroupSet,
      this.toolPaths,
      this.hasTessellationFeatureFlag
    );
    return pathSvg;
  }
}

const svgCache = new SvgCache(
  getScaledBasePathCache,
  getToolPathCache,
  getReviewPathCache
);

export default svgCache;
