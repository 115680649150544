import Bounds from '@/Utility/bounds';
import { unitToMMNum } from '@/Geometry/UnitOps';
import estimateArea from '../../../Helpers/AreaDetection';
import UIState from '../UIState';
import { GroupId } from '@/Redux/Slices/SelectionSlice';
import { IPoint } from '@shapertools/sherpa-svg-generator/Point';
import {
  Tool,
  ToolParams,
  Shape,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Anchor } from '@/@types/shaper-types';
import { CutParams } from '@shapertools/sherpa-svg-generator/CutParams';

const NONE = Object.freeze({});

type Overrides = {
  translate?: IPoint;
  resize?: number[][];
  rotate?: number;
  data?: any;
};

export default class SvgGroup {
  data: any;
  ui: UIState;
  overrides: Overrides;

  width: number;
  height: number;
  left: number;
  right: number;
  top: number;
  bottom: number;

  // centers
  cx: number;
  cy: number;

  // current rotation
  rotation: number;

  type: string;

  // other props
  tool: Tool;
  anchor: Anchor;

  // is selected or not
  selected: boolean;

  // required info
  id: GroupId;

  // allow temporary matrices
  stretchMatrix?: number[][];

  // creates a new Group instance
  constructor(ui: UIState, data: any, overrides: Overrides = NONE) {
    this.data = data;
    this.ui = ui;
    this.overrides = overrides;

    // prepare the group
    // gather initial data
    let { rotation = 0, tool, anchor = 'center', type } = data;
    let { x, y } = data.position;
    let { minPoint: min, maxPoint: max } = data.unrotatedAABB;

    // aabb info
    const aabb = {
      left: data.transformedAABB.minPoint.x,
      right: data.transformedAABB.maxPoint.x,
      top: data.transformedAABB.minPoint.y,
      bottom: data.transformedAABB.maxPoint.y,
    };

    // check for transform overrides
    const { translate, resize, rotate, data: dataOverride } = overrides;

    // defaults
    let width = max.x - min.x;
    let height = max.y - min.y;
    let stretchMatrix;

    // apply offsets
    if (translate) {
      x += translate.x;
      y += translate.y;

      aabb.left += translate.x;
      aabb.right += translate.x;
      aabb.top += translate.y;
      aabb.bottom += translate.y;
    }

    // apply resizing stretchMatrix
    if (resize) {
      [width, height] = [
        width * resize[0][0] + height * resize[0][1],
        height * resize[1][1] + width * resize[1][0],
      ];
      stretchMatrix = resize;
    }

    // check for rotation changes
    if (rotate && !isNaN(rotate)) {
      rotation += rotate;
    }

    // get the default bounding box
    const hw = width * 0.5;
    const hh = height * 0.5;
    const right = x + hw;
    const bottom = y + hh;
    const left = x - hw;
    const top = y - hh;

    // update with group info
    this.type = type;
    this.width = width;
    this.height = height;
    this.left = left;
    this.right = right;
    this.top = top;
    this.bottom = bottom;

    // centers
    this.cx = (left + right) * 0.5;
    this.cy = (top + bottom) * 0.5;

    // current rotation
    this.rotation = rotation;

    // other props
    this.tool = tool;
    this.anchor = anchor;

    // is selected or not
    this.selected = data.selected;

    // required info
    this.id = data.id;

    // allow temporary matrices
    this.stretchMatrix = stretchMatrix;

    if (dataOverride) {
      this.id = dataOverride.id;
      this.data = dataOverride;
    }
  }

  getAABB() {
    const { left, right, top, bottom, cx, cy } = this;
    return { left, right, top, bottom, cy, cx };
  }

  get area() {
    return estimateArea(this.data);
  }

  get cutDepth() {
    // I'm not sure why svgGroup needs the first path's cutParams, but here you go
    const cutParams: CutParams | undefined = this.data.basePathSet[0].cutParams;
    const { cutDepth } = cutParams || {};
    return unitToMMNum(cutDepth, 'in');
  }

  get bitDiameter() {
    // I'm not sure why svgGroup needs the first path's cutParams, but here you go
    const cutParams: CutParams | undefined = this.data.basePathSet[0].cutParams;
    const { toolDia } = cutParams || {};
    return unitToMMNum(toolDia, 'in');
  }

  get cutOffset() {
    // I'm not sure why svgGroup needs the first path's cutParams, but here you go
    const [cutParams]: [CutParams | undefined] = this.data.cutParams;
    const { cutOffset } = cutParams || {};
    return unitToMMNum(cutOffset, 'in');
  }

  get cutType() {
    // I'm not sure why svgGroup needs the first path's cutParams, but here you go
    const cutParams: CutParams | undefined = this.data.basePathSet[0].cutParams;
    const { cutType } = cutParams || {};
    return cutType;
  }

  get aabb(): SvgGroup | Bounds {
    return this.rotation ? new Bounds(this) : this;
  }

  getPoint(
    id: string,
    { a, b }: { a?: string | undefined; b?: string | undefined } = {}
  ) {
    const origin = this.ui.groups.find((group) => group.id === id);
    const parent = this.ui.groups.find(
      (group) =>
        group.id ===
        (origin?.tool?.params as ToolParams<Shape.POINT>)?.belongsTo
    );
    const points =
      (parent!.tool?.params as ToolParams<Shape.SHAPE>)?.points || [];
    const index =
      (points.indexOf(id) + (a ? -1 : 0) + (b ? 1 : 0) + points.length) %
      points.length;
    const otherId = points[index];
    return this.ui.groups.find((group) => group.id === otherId);
  }
}
