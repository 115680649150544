/* eslint-disable valid-jsdoc */
import {
  selectOriginalSnapshot,
  selectSnapshot,
  setSnapshot,
  setStatus,
  updatePendingQueue,
} from '../../Redux/Slices/SyncSlice';
import { update } from './../SyncThunks';
import { PatchSet } from './../PatchGenerator';
import { logError } from './../SyncLog';
import { SyncListenerApi, context } from '../SyncListener';
import { UnknownAction } from '@reduxjs/toolkit';
import { rollbackCanvas } from '@/Redux/Slices/CanvasSlice';
import { AppDispatch } from '@/Redux/store';
import { Snapshot } from '../SyncConstants';
import { RejectedAction } from '@/Redux/hooks';
import { SyncError } from '../SyncError';
import { getStatusFromError } from '../SyncHelpers';
import { setLoading } from '@/Redux/Slices/SherpaContainerSlice';

export const rollbackQueue = (
  dispatch: AppDispatch,
  pendingQueue: PatchSet[],
  queue: PatchSet[],
  originalSnapshot: Snapshot
) => {
  const revertPatchSet = [...pendingQueue, ...queue].reverse();

  logError(`Rolling back queue with length: ${revertPatchSet}`, {
    syncLevel: 'listener',
    revertPatchSet,
  });

  dispatch(setSnapshot(originalSnapshot));
  dispatch(updatePendingQueue([]));
  dispatch(rollbackCanvas(revertPatchSet));
};

/**
 * Rollback listener -- listens to the update thank and routes here if the thunk is rejected
 */
export const addRollbackListener = (startListening: Function) => {
  startListening({
    predicate: (action: UnknownAction) => update.rejected.match(action),
    effect: async (
      action: RejectedAction,
      { dispatch, getState }: SyncListenerApi
    ) => {
      const { sync } = getState();
      const { queue, pendingQueue } = sync;

      const snapshot = selectOriginalSnapshot();

      const { payload } = action;
      logError(
        'There was an error in updating the workspace, had to rollback',
        {
          ...context,
        }
      );

      rollbackQueue(
        dispatch,
        pendingQueue,
        queue,
        snapshot || selectSnapshot()
      );

      const error =
        payload instanceof SyncError
          ? payload
          : new SyncError(
              'generic_error',
              'listener',
              `There was an error in updating the workspace, rolling back the queue`
            );
      dispatch(setStatus(getStatusFromError(error)));
      dispatch(setLoading(false));
      throw error;
    },
  });
};
