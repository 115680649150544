import React from 'react';
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '@/Components/TranslationText/TranslationText';
import ShareBadge from '@/Components/ShareBadge/ShareBadge';
import { useSelector } from 'react-redux';
import {
  selectProject,
  selectStudioShare,
} from '@/Redux/Slices/ShaperHubSlice';
import { selectIsMobile } from '@/Redux/Slices/UISlice';
import { selectWorkspaceId } from '@/Redux/Slices/SyncSlice';

export default function ShareMenuItem() {
  const studioShare = useSelector(selectStudioShare);
  const project = useSelector(selectProject);
  const isMobile = useSelector(selectIsMobile);
  const workspaceId = useSelector(selectWorkspaceId);
  const hasSyncedProject = project?.viewingUserHasSynced || false;

  const clickHelp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const viewOrShare = () => {
    if ((hasSyncedProject && project) || (!hasSyncedProject && workspaceId)) {
      const url = import.meta.env.VITE_SHAPER_URL_HUB;
      const viewOnly =
        (!hasSyncedProject &&
          project &&
          workspaceId &&
          project.workspaceId === workspaceId) ||
        hasSyncedProject;
      const newTabUrl = (() => {
        if (viewOnly) {
          return `${url}/design/${project?._id}`;
        }
        return `${url}/design/new?workspaceId=${workspaceId}`;
      })();
      window.open(newTabUrl, '_blank')?.focus();
    }
  };

  return (
    <div className='share-design--container' onClick={viewOrShare}>
      <div className='share-design--icon'>
        <Icon icon={studioShare ? 'view-page' : 'shaper-hub'} />
      </div>
      <div className='share-design--label'>
        {studioShare ? (
          <TranslationText i18nKey='view-page'>View Page</TranslationText>
        ) : (
          <TranslationText i18nKey='share-design'>Share Design</TranslationText>
        )}
      </div>
      {studioShare && (
        <div className='share-design--badge'>
          <ShareBadge position={isMobile ? 'below' : 'right'} />
        </div>
      )}
      <div
        className='share-design--help'
        onClick={clickHelp}
        data-cy='share-design-tooltip'
      >
        <Icon icon='question-mark-circle-inverse' />
      </div>
    </div>
  );
}
