import React from 'react';

//components
import Group from './Group';
import Label from './Label';
import Input from './Input';
import Separator from './Separator';

type Props = {
  children?: React.ReactNode;
  className: string;

  onClose?: () => void;
};

export default function FloatingPanel(props: Props) {
  return (
    <div className={`secret-menu-panel ${props.className}`}>
      {props.onClose && (
        <div className='secret-menu-panel--close' onClick={props.onClose}>
          &times;
        </div>
      )}

      <div className='secret-menu-panel--content'>{props.children}</div>
    </div>
  );
}

FloatingPanel.Group = Group;
FloatingPanel.Label = Label;
FloatingPanel.Input = Input;
FloatingPanel.Separator = Separator;
