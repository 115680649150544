import { useSelector } from 'react-redux';

// slices
import { selectToFormattedDisplayUnitValue } from '@/Redux/Slices/SherpaContainerSlice';

// helpers
import { rotateAroundPoint } from '@/Utility/rotation';

// components
import OverlayElement from './OverlayElement';
import UIState from '../State/UIState';
import BoundingBox from '../State/Helpers/BoundingBox';
import DesignState from '../State/DesignState';

type Props = {
  ui: UIState;
  boundingBox: BoundingBox;
};

export default function WorkspaceOriginLabels(props: Props) {
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );
  const { ui, boundingBox } = props;

  // don't show while rotating
  if (ui instanceof DesignState && (ui.isRotating || ui.isResizing)) {
    return null;
  }

  // computed
  const { anchor, aabb, centroid } = boundingBox;
  const { left, right, top, bottom } = aabb;
  const [x, y] = rotateAroundPoint(
    centroid.x,
    centroid.y,
    anchor.x,
    anchor.y,
    boundingBox.rotation
  );

  // renders a label
  function renderWorkspaceOriginLabel(
    axis: string,
    minEdge: string,
    maxEdge: string,
    min: number,
    max: number,
    distance: number,
    alt: number
  ) {
    // determine the alt axis
    const invert = axis === 'y' ? -1 : 1;
    const value = toFormattedDisplayUnitValue(distance * invert, {
      includeUnit: true,
    });
    const display = `${axis.toUpperCase()}: ${value}`;
    const farSide = distance < 0;

    // determine the position
    const placement = `on-${farSide ? maxEdge : minEdge}`;
    const position = {
      [axis]: farSide ? max : min,
      [axis === 'x' ? 'y' : 'x']: alt,
    };

    return (
      <OverlayElement ui={ui} {...position}>
        <div className={`workspace-origin--label ${placement}`}>{display}</div>
      </OverlayElement>
    );
  }

  return (
    <>
      {renderWorkspaceOriginLabel('x', 'left', 'right', left, right, x, y)}
      {renderWorkspaceOriginLabel('y', 'top', 'bottom', top, bottom, y, x)}
    </>
  );
}
