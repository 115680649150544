import React from 'react';

//selectors
import { useSelector, useDispatch, batch } from 'react-redux';
import { selectAlert, clearAlert } from '@/Redux/Slices/UISlice';

//components
import Icon from '../../Styles/Icons/Icon';
import { Snackbar } from '@mui/material';
import { setModal } from '../../Redux/Slices/SherpaContainerSlice';
import { ALERT_TYPES, MODAL_TYPES } from '../../defaults';
import TranslationText from '../TranslationText/TranslationText';
import { Button } from '../Modals/Modal';
import { useAction } from '../../Actions/useAction';
import OpenSyncedWorkspaceAction from '../../Actions/OpenSyncedWorkspace';
import AlertAction from '../../Actions/Alert';
import { selectIsMobile } from '@/Redux/Slices/UISlice';
import { selectWorkspaceId } from '@/Redux/Slices/SyncSlice';

export default function SherpaSnackbar() {
  const dispatch = useDispatch();

  const {
    alertMsg,
    i18nKey,
    openAlert = false,
    alertType,
    alertIcon,
    alertModal,
    alertDuration,
    className,
    alertPosition,
    alertModalIcon,
  } = useSelector(selectAlert);
  const isMobile = useSelector(selectIsMobile);
  const type = alertType?.includes('error')
    ? 'error'
    : alertType === ALERT_TYPES.OUT_OF_SYNC
    ? 'out-of-sync'
    : 'default';
  const position = alertPosition && !isMobile ? alertPosition : 'bottom';
  const modalIcon = alertModalIcon ?? 'more-vertical';
  const canDismiss = alertType?.includes('dismissible');
  const autoHideDuration = alertDuration ?? 60000;
  const hideModalIcon = modalIcon === 'none';

  const openSyncedWorkspaceAction = useAction(OpenSyncedWorkspaceAction);
  const alertAction = useAction(AlertAction);
  const workspaceId = useSelector(selectWorkspaceId);

  const openModal = () => {
    if (alertModal) {
      dispatch(setModal(alertModal));
    }
  };

  //Ignore close requests if not dismissible by user
  const handleClose = () => {
    if (canDismiss || alertDuration) {
      dispatch(clearAlert());
    }
  };

  const onRefreshWorkspace = () => {
    batch(() => {
      alertAction.clear();
      if (workspaceId) {
        openSyncedWorkspaceAction.openSyncedWorkspace(workspaceId);
      }
      dispatch(setModal(MODAL_TYPES.NO_MODAL));
    });
  };

  return (
    <>
      {alertType !== ALERT_TYPES.NONE && (
        <Snackbar
          className={`alert-component ${type || ''} ${className || ''}`}
          open={openAlert}
          anchorOrigin={{ vertical: position, horizontal: 'center' }}
          onClose={handleClose}
          autoHideDuration={autoHideDuration}
          message={
            <>
              <div
                className={`alert-component--info ${type || ''} ${
                  className || ''
                }`}
              >
                {alertIcon && <Icon icon={alertIcon} />}
                <div className='alert-component--text'>
                  <TranslationText i18nKey={i18nKey}>
                    {alertMsg}
                  </TranslationText>
                </div>
                {i18nKey === 'design-out-date' && (
                  <Button onClick={onRefreshWorkspace}>RELOAD</Button>
                )}
              </div>
              {alertModal && !hideModalIcon && (
                <div
                  className={`more-vertical ${type || ''}`}
                  data-cy='alert-modal'
                  onClick={openModal}
                >
                  <Icon icon={modalIcon} />
                </div>
              )}
              {canDismiss && (
                <div
                  className={`alert-component--close ${type || ''}`}
                  onClick={() => dispatch(clearAlert())}
                >
                  <Icon icon='close' />
                </div>
              )}
            </>
          }
        />
      )}
    </>
  );
}
