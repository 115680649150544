import React from 'react';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import Icon from '@/Styles/Icons/Icon';
import { useSelector } from 'react-redux';
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';
import { useAction } from '../../../../Actions/useAction';
import UpdateSvgGroupAction from '../../../../Actions/UpdateSvgGroup';
import AlertAction from '../../../../Actions/Alert';
import { ALERT_TYPES, MODAL_TYPES } from '../../../../defaults';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import { Tooltip } from '../../../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { selectFeatures } from '../../../../Redux/Slices/UISlice';
import { entitlements } from '@/Helpers/Entitlements';
import { UpdateSvgGroupPayload } from '@/CanvasContainer/CanvasActions';
import UIState from '@/UILayer/State/UIState';

export default function PathType() {
  const selectedGroups = useSelector(selectSelectedGroups);
  const [selectedGroup] = selectedGroups;
  const { t, i18n } = useTranslation();

  const hasReferencePathsEnabled = selectFeatures(entitlements.REFERENCE_PATHS);

  const updateSvgGroup = useAction(UpdateSvgGroupAction);
  const alert = useAction(AlertAction);

  const selectedType = selectedGroup?.type ?? PATH_TYPE.DESIGN;

  const onSelectType = () => {
    const type =
      selectedType === PATH_TYPE.DESIGN
        ? PATH_TYPE.REFERENCE
        : PATH_TYPE.DESIGN;
    const updates = selectedGroups.map((s) => ({
      id: s.id,
      update: {
        key: 'pathtype',
        value: type,
      },
    })) as UpdateSvgGroupPayload[];
    updateSvgGroup.apply(updates);
    if (type === PATH_TYPE.REFERENCE) {
      alert.set({
        msg: 'Object set to Reference and locked',
        i18nKey: 'set-to-reference',
        type: ALERT_TYPES.DEFAULT,
        modal: MODAL_TYPES.REFERENCE_MODAL,
        className: 'guide-alert',
        position: 'top',
        icon: 'path-type-lock',
        modalIcon: 'circle-warning',
        duration: 5000,
      });
    }

    setTimeout(UIState.reset);
  };

  const multipleTypes = selectedGroups.length > 1;
  const shapeType = () => {
    switch (selectedGroup.tool?.type) {
      case 'rectangle':
        return 'Rectangle';
      case 'rounded_rectangle':
        return 'Rounded Rect';
      case 'circle':
        return 'Circle';
      case 'polygon':
        return 'Polygon';
      case 'text-insert':
        return 'Text';
      default:
        return 'Shape';
    }
  };

  const shapeTypeI18n = () => {
    return selectedGroup.tool?.type ?? 'shape';
  };

  const referenceTranslation = i18n.exists('set-reference')
    ? t('set-reference')
    : 'set as reference & lock';

  return (
    <FloatingPanel.Group className='path-type'>
      <FloatingPanel.Label i18nKey={multipleTypes ? 'shapes' : shapeTypeI18n()}>
        {multipleTypes ? 'Shapes' : shapeType()}
      </FloatingPanel.Label>
      {hasReferencePathsEnabled && (
        <Tooltip tip={referenceTranslation} side='above' align='right'>
          <FloatingPanel.Button
            className='path-type-button'
            dataCy='make-reference-button'
            onClick={onSelectType}
            value={null}
          >
            <Icon icon='path-type-lock' />
          </FloatingPanel.Button>
        </Tooltip>
      )}
    </FloatingPanel.Group>
  );
}
