import { chunk } from 'lodash';
import {
  clipperBasePathToShaperPath,
  shaperBasePathToScaledClipperPaths,
} from './BasePathOps';
import { ClipperOps, ClipperOpsWorkerProxy, ClipperPath } from './ClipperOps';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';

function simplePolygonDifference(
  simplePolygonA: BasePath,
  simplePolygonB: BasePath
) {
  const clipperPathsA = shaperBasePathToScaledClipperPaths(simplePolygonA);
  const clipperPathsB = shaperBasePathToScaledClipperPaths(simplePolygonB);

  const clipperPathResults = ClipperOps.clipperPathsDifference(
    clipperPathsA,
    clipperPathsB
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

function simplePolygonIntersection(
  simplePolygonA: BasePath,
  simplePolygonB: BasePath
) {
  const clipperPathsA = shaperBasePathToScaledClipperPaths(simplePolygonA);
  const clipperPathsB = shaperBasePathToScaledClipperPaths(simplePolygonB);

  const clipperPathResults = ClipperOps.clipperPathsIntersection(
    clipperPathsA,
    clipperPathsB
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

function simplePolygonUnion(
  simplePolygonA: BasePath,
  simplePolygonB: BasePath,
  simplifyPolys = false
) {
  const clipperPathsA =
    simplePolygonA !== undefined
      ? shaperBasePathToScaledClipperPaths(simplePolygonA)
      : [];
  const clipperPathsB =
    simplePolygonB !== undefined
      ? shaperBasePathToScaledClipperPaths(simplePolygonB)
      : [];

  const clipperPathResults = ClipperOps.clipperPathsUnion(
    clipperPathsA,
    clipperPathsB,
    simplifyPolys
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

function NSimplePolygonUnionsSync(simplePolygons: BasePath[]): BasePath[] {
  const clipperPaths = simplePolygons.map((sp) =>
    shaperBasePathToScaledClipperPaths(sp)
  );

  const clipperPathResults = clipperPaths.reduce(
    (unionAcc, cp, idx) => {
      //First pass is unionAcc unioned with itself, so skip
      if (idx === 0) {
        return unionAcc;
      }

      //Converts array of {outerClipperPath: {}, holeClipperPaths: []} to flat array of clipperPaths.
      const unionAccArr = unionAcc
        .map((csp) => [csp.outerClipperPath, ...csp.holeClipperPaths])
        .flat();

      //Returns array of {outerClipperPath: {}, holeClipperPaths: []}
      return ClipperOps.clipperPathsUnion(unionAccArr, cp, true);
    },
    [
      {
        outerClipperPath: clipperPaths[0][0],
        holeClipperPaths: clipperPaths[0].slice(1),
      },
    ]
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

const isOpen = (cp: ClipperPath[]) => {
  return cp.some((path) => !path.closed);
};

const toClipperPaths = (
  result: Awaited<ReturnType<typeof ClipperOpsWorkerProxy.clipperPathsUnion>>
) => {
  return result.flatMap(({ outerClipperPath, holeClipperPaths }) => [
    outerClipperPath,
    ...holeClipperPaths,
  ]);
};

async function NSimplePolygonUnionsAsync(
  simplePolygons: BasePath[],
  options?: {
    simplifyPolys?: boolean;
  }
): Promise<BasePath[]> {
  const simplifyPolys = options?.simplifyPolys ?? true;

  const clipperPaths = simplePolygons.map((sp) =>
    shaperBasePathToScaledClipperPaths(sp)
  );

  const unionPaths = async (paths: ClipperPath[][]) => {
    if (paths.length === 1) {
      return [
        {
          outerClipperPath: paths[0][0],
          holeClipperPaths: paths[0].slice(1),
        },
      ];
    }

    const pairs = chunk(paths, 2);
    const unioned = await Promise.all(
      pairs.map(async ([pathsA, pathsB]) => {
        if (!pathsB) {
          return pathsA;
        }

        // Open paths must be the subject, so we have to run these through independently
        if (isOpen(pathsA) && isOpen(pathsB)) {
          const [resultA, resultB] = await Promise.all([
            ClipperOpsWorkerProxy.clipperPathsUnion(pathsA, [], simplifyPolys),
            ClipperOpsWorkerProxy.clipperPathsUnion(pathsB, [], simplifyPolys),
          ]);

          return ClipperOpsWorkerProxy.clipperPathsUnion(
            toClipperPaths(resultA),
            toClipperPaths(resultB),
            simplifyPolys
          ).then(toClipperPaths);
        }

        // Open paths must be the subject
        const subject = isOpen(pathsB) ? pathsB : pathsA;
        const clipPaths = isOpen(pathsB) ? pathsA : pathsB;

        return ClipperOpsWorkerProxy.clipperPathsUnion(
          subject,
          clipPaths,
          simplifyPolys
        ).then(toClipperPaths);
      })
    );

    return unionPaths(unioned);
  };

  const results = await unionPaths(clipperPaths);

  return results.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

function NSimplePolygonDifferencesSync(
  simplePolygonsA: BasePath,
  simplePolygonsB: BasePath[]
): BasePath[] {
  const clipperPathsA = shaperBasePathToScaledClipperPaths(simplePolygonsA);
  const clipperPathsB = simplePolygonsB
    .map((sp) => shaperBasePathToScaledClipperPaths(sp))
    .flat();

  const clipperPathResults = ClipperOps.clipperPathsDifference(
    clipperPathsA,
    clipperPathsB
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

async function NSimplePolygonDifferencesAsync(
  simplePolygonsA: BasePath,
  simplePolygonsB: BasePath[]
): Promise<BasePath[]> {
  const clipperPathsA = shaperBasePathToScaledClipperPaths(simplePolygonsA);
  const clipperPathsB = simplePolygonsB
    .map((sp) => shaperBasePathToScaledClipperPaths(sp))
    .flat();

  return ClipperOpsWorkerProxy.clipperPathsDifference(
    clipperPathsA,
    clipperPathsB
  ).then(
    (
      clipperPathsResults: {
        outerClipperPath: ClipperPath;
        holeClipperPaths: ClipperPath[];
      }[]
    ) =>
      clipperPathsResults.map((cpr) =>
        clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
      )
  );
}

function cleanSimplePolygon(dirtySimplePoly: BasePath) {
  const clipperPaths = shaperBasePathToScaledClipperPaths(dirtySimplePoly);
  const cleanOuterClipperPath = ClipperOps.cleanClipperPolygon(
    clipperPaths[0].points
  );
  clipperPaths.splice(0, 1);
  const cleanInnerClipperPaths = clipperPaths.map((cp) => ({
    closed: true,
    points: ClipperOps.cleanClipperPolygon(cp.points),
  }));
  return clipperBasePathToShaperPath(
    new ClipperPath(true, cleanOuterClipperPath),
    cleanInnerClipperPaths
  );
}

function toPathArray(simplePolygon: BasePath): BasePath[] {
  return [
    simplePolygon.outerPath,
    ...(simplePolygon.holePaths ?? []),
  ] as BasePath[];
}

export {
  simplePolygonDifference,
  simplePolygonIntersection,
  simplePolygonUnion,
  NSimplePolygonDifferencesSync,
  NSimplePolygonDifferencesAsync,
  NSimplePolygonUnionsSync,
  NSimplePolygonUnionsAsync,
  cleanSimplePolygon,
  toPathArray,
};
