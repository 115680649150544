import React, { MouseEvent } from 'react';
import { cancelEvent } from '@/Utility/events';

type Props = {
  children?: React.ReactNode;
  className?: string;

  disabled?: boolean;
  onClick: () => void;
};

export default function ClickCapture(props: Props) {
  function onClick(event: MouseEvent) {
    cancelEvent(event);
    props.onClick();
  }

  function onCapture(event: MouseEvent) {
    cancelEvent(event);
  }

  // not actively capturing
  if (props.disabled) {
    return null;
  }

  return (
    <div className={`click-capture ${props.className}`} onClick={onClick}>
      {props.children && <div onClick={onCapture}>{props.children}</div>}
    </div>
  );
}
