import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// actions
import BuildBasicShapeAction from '@/Actions/BuildBasicShape';
import UIFeatureAction from '@/Actions/UIFeature';

// components
import CanvasActionMenu from '../CanvasActionMenu';
import { selectViewport } from '@/Redux/Slices/ViewportSlice';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { selectFeatureFlags } from '@/Redux/Slices/FeatureFlagsSlice';

// types of shapes to insert
const SHAPE_TYPES = [
  {
    id: Shape.SHAPE,
    label: 'Line',
    icon: 'line',
    i18nKey: 'line',
  },
  {
    id: Shape.CIRCLE,
    label: 'Circle',
    icon: 'basic-shape-circle',
    i18nKey: 'circle',
  },
  {
    id: Shape.ELLIPSE,
    label: 'Ellipse',
    icon: 'basic-shape-ellipse',
    i18nKey: 'ellipse',
  },
  {
    id: Shape.RECTANGLE,
    label: 'Rectangle',
    icon: 'basic-shape-rectangle',
    i18nKey: 'rectangle',
  },
  {
    id: Shape.ROUNDED_RECT,
    label: 'Rounded Rectangle',
    icon: 'basic-shape-rounded-rectangle',
    i18nKey: 'rounded-rectangle',
  },
  {
    id: Shape.POLYGON,
    label: 'Polygon',
    icon: 'basic-shape-polygon',
    i18nKey: 'polygon',
  },
] as const;

type Props = {
  edge: 'left' | 'right';
};

export default function BasicShapeCreatorActionMenu(props: Props) {
  const buildBasicShape = useAction(BuildBasicShapeAction);
  const uiFeature = useAction(UIFeatureAction);
  const viewport = useSelector(selectViewport);
  const featureFlags = useSelector(selectFeatureFlags);

  // get the shapes to show
  let shapes = [...SHAPE_TYPES];

  // feature flag later
  const allowLineTool = !!featureFlags['studio-polyline-feature'];
  if (!allowLineTool) {
    shapes = shapes.filter((item) => item.id !== Shape.SHAPE);
  }

  // handles requesting a new shape to add
  function onSelectAction(
    shape: (typeof SHAPE_TYPES)[number],
    event: MouseEvent<HTMLElement>
  ) {
    if (shape.id === Shape.SHAPE) {
      // TEMP: pointer type not part of MouseEvent<Element>
      const type =
        (event.nativeEvent as any)?.pointerType === 'touch'
          ? 'mobile'
          : 'default';
      uiFeature.toggleInsertPoint(true, { origin: viewport.position, type });
    } else {
      buildBasicShape.build(shape.id);
      uiFeature.toggleEditSelectionProperties(true);
    }
  }

  return (
    <CanvasActionMenu
      onSelectAction={onSelectAction}
      edge={props.edge}
      className='shape-creator-menu'
      actions={shapes}
    />
  );
}
