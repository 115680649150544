import { useSelector } from 'react-redux';
import { selectSecretOptions } from '../../Redux/Slices/SherpaContainerSlice';
import { selectFeatures } from '@/Redux/Slices/UISlice';
import { entitlements } from '@/Helpers/Entitlements';
import {
  AlignmentGuide,
  GridAlignmentGuide,
  ObjectAlignmentGuide,
  isObjectAlignmentGuide,
} from '@/Helpers/Alignment';
import Viewport from '../State/Helpers/Viewport';

type Props = {
  viewport: Viewport;
  guides: AlignmentGuide[];
};

export default function AlignmentGuides(props: Props) {
  const { viewport } = props;
  const {
    // object alignment guides
    guideLineWidth = 1.5,
    guideLineColor = 'FF274E',
  } = useSelector(selectSecretOptions) || {};

  // grid alignment guides
  const gridGuideLineColor = 'FF274E';
  const gridGuideLineWidth = 1.5;

  const canAlignToObjects = selectFeatures(entitlements.ALIGN_TO_OBJ);
  const canAlignToGrid = selectFeatures(entitlements.ALIGN_TO_GRID);

  function renderGuide(
    guide: ObjectAlignmentGuide,
    index: number,
    cx: string,
    strokeColor: string,
    strokeWidth: number
  ) {
    return 'x' in guide ? (
      <line
        key={`alignment_guide_${index}`}
        className={cx}
        x1={guide.x}
        x2={guide.x}
        y1={guide.top}
        y2={guide.bottom}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    ) : (
      <line
        key={`alignment_guide_${index}`}
        className={cx}
        y1={guide.y}
        y2={guide.y}
        x1={guide.left}
        x2={guide.right}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    );
  }

  function renderObjectAlignmentGuide(
    guide: ObjectAlignmentGuide,
    index: number
  ) {
    return renderGuide(
      guide,
      index,
      'alignment-guide alignment-guide--object',
      `#${guideLineColor}`,
      guideLineWidth
    );
  }

  function renderGridAlignmentGuide(guide: GridAlignmentGuide, index: number) {
    const converted: ObjectAlignmentGuide = {
      ...guide,
      type: 'object',
      ...('x' in guide
        ? {
            top: viewport.y,
            bottom: viewport.y + viewport.height,
          }
        : {
            left: viewport.x,
            right: viewport.x + viewport.width,
          }),
    };

    return renderGuide(
      converted,
      index,
      'alignment-guide alignment-guide--grid',
      `#${gridGuideLineColor}`,
      gridGuideLineWidth
    );
  }

  return props.guides.map((guide, index) =>
    isObjectAlignmentGuide(guide) && canAlignToObjects
      ? renderObjectAlignmentGuide(guide, index)
      : !isObjectAlignmentGuide(guide) && canAlignToGrid
      ? renderGridAlignmentGuide(guide, index)
      : null
  );
}
