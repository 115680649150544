import { batch } from 'react-redux';
//actions
import ClearCanvasAction from './ClearCanvas';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';
import BaseAction from './BaseAction';
import { disconnect } from '@/Sync/SyncThunks';
import { log } from '@/Sync/SyncLog';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';
import { setSessionWorkspaceInfo } from '@/Redux/Slices/SherpaContainerSlice';

export default class CreateWorkspaceAction extends BaseAction {
  clearCanvasAction: ClearCanvasAction;
  updateWorkspaceOptionsAction: UpdateWorkspaceOptionsAction;

  constructor(dispatch: AppDispatch, useSelector: UseSelector) {
    super(dispatch, useSelector);
    this.dispatch = dispatch;
    this.clearCanvasAction = new ClearCanvasAction(dispatch, useSelector);
    this.updateWorkspaceOptionsAction = new UpdateWorkspaceOptionsAction(
      dispatch,
      useSelector
    );
  }

  disconnectCurrentWorkspaceAndCreateNewWorkspace = () => {
    this.dispatch(setSessionWorkspaceInfo(null));

    //If current workspace is open, need to disconnected first before creating new workspace or canvas clear may be added to old workspace.
    log(
      `Emitting disconnect current workspace and create new workspace after disconnect`,
      { syncLevel: 'action' },
      'debug'
    );
    batch(() => {
      this.dispatch(
        disconnect({
          createNewWorkspaceAfterDisconnect: true,
        })
      );
      this.updateWorkspaceOptionsAction.update({ showApplicationMenu: false });
    });
  };
}
