import React, { useEffect, useRef, useState } from 'react';

// consts
const BOTTOM_THRESHOLD_DISTANCE = 400;
const CHECK_BOTTOM_FREQUENCY = 333;

type Props = {
  children: React.ReactNode;
  disabled: boolean;
  bottomThreshold?: number;
  checkBottomFrequency?: number;
  onReachEnd: () => void;
  onScroll?: (value: number) => void;
  className?: string;
  scrollPosition?: number;
};

export default function InfiniteScroll(props: Props) {
  const {
    children,
    disabled,
    bottomThreshold = BOTTOM_THRESHOLD_DISTANCE,
    checkBottomFrequency = CHECK_BOTTOM_FREQUENCY,
    scrollPosition,
  } = props;

  // refs
  const ref = useRef<HTMLDivElement>(null);

  // state
  const [nextAllowedAttempt, setNextAllowedAttempt] = useState(0);

  // determines where the scrolled search results
  // are positioned at
  const getScrollPosition = () => {
    // calculate positions if the container
    // is available to work with
    const container = ref.current;
    if (container) {
      const { bottom, top } = container.getBoundingClientRect();
      const { scrollTop, scrollHeight: height } = container;
      const at = scrollTop + (bottom - top);

      // update the result
      return {
        at,
        scrollTop,
        height,
        hasReachedBottom: at > height - bottomThreshold,
      };
    }
    // container wasn't available
    return { at: 0, scrollTop: 0, height: 0, hasReachedBottom: false };
  };

  // monitors scrolling to determine when the view
  // has reached the bottom
  const onScroll = () => {
    if (disabled) {
      return;
    }

    // don't do this too quickly
    const now = Date.now();
    if (now < nextAllowedAttempt) {
      return;
    }

    // check if scrolled mostly down
    const { scrollTop, hasReachedBottom } = getScrollPosition();
    if (hasReachedBottom) {
      props.onReachEnd();

      // set the next allowed attempt
      setNextAllowedAttempt(now + checkBottomFrequency);
    }
    if (props.onScroll) {
      props.onScroll(scrollTop);
    }
  };

  useEffect(() => {
    if (ref.current && scrollPosition) {
      ref.current.scrollTo({
        top: scrollPosition,
      });
    }
  }, [scrollPosition]);

  return (
    <div
      className={`${props.className || ''} scrollable`}
      ref={ref}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
}
