import React from 'react';
import { connect } from 'react-redux';
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import woodGrainBackground from '@/Styles/Images/wood-grain.png';
import { RootState } from '@/Redux/store';

type Props = {
  viewport: {
    x: number;
    y: number;
  };
};

class Background extends React.PureComponent<Props> {
  // refs
  patternRef = React.createRef();
  rootRef = React.createRef();
  rectRef = React.createRef();
  topRef = React.createRef();

  state = {
    isReady: false,
  };

  renderBackgrounds() {
    const { viewport } = this.props;

    const width = 300;
    const height = 300;

    return (
      <>
        <defs>
          <pattern
            id='review_mode_background'
            patternUnits='userSpaceOnUse'
            height={height}
            width={width}
          >
            <image
              xlinkHref={woodGrainBackground}
              height={height}
              width={width}
              x='0'
              y='0'
            />
          </pattern>
        </defs>

        <rect
          opacity={1}
          className='background'
          x={`${viewport.x}`}
          y={`${viewport.y}`}
          fill={`url(#review_mode_background)`}
          width='100%'
          height='100%'
        />
      </>
    );
  }

  // generate the view
  // SVG is rendered in canvas units and SVG viewbox is set to control pan and zoom on workspace.
  render() {
    return <>{this.renderBackgrounds()}</>;
  }
}

// mapping props
export default connect((state: RootState) => ({
  nonScalingPixelFactor: selectNonScalingPixelFactor(state),
}))(Background);
