import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { setScaleBy, setScaleByAbsolute } from '@/Redux/Slices/ViewportSlice';
import { AppDispatch } from '@/Redux/store';

export default class ScaleViewportAction {
  dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  get screenCenter() {
    return {
      x: window.innerWidth * 0.5,
      y: window.innerHeight * 0.5,
    };
  }

  scaleBy(scale: number, scaleOriginScreen = this.screenCenter) {
    const { dispatch } = this;

    dispatch(
      setScaleBy({
        scale,
        scaleOriginScreen: new Point(scaleOriginScreen.x, scaleOriginScreen.y),
      })
    );
  }

  scaleByAbsolute(scale: number, scaleOriginScreen = this.screenCenter) {
    const { dispatch } = this;

    dispatch(
      setScaleByAbsolute({
        scale,
        scaleOriginScreen: new Point(scaleOriginScreen.x, scaleOriginScreen.y),
      })
    );
  }
}
