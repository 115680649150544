import { round } from 'lodash';
export default class SvgPathBuilder {
  xs: number[] = [];
  ys: number[] = [];
  instructions: [string, ...number[]][] = [];
  closedPath = false;

  add(cmd: string, ...points: number[]) {
    for (let i = 0; i < points.length; i += 2) {
      this.xs.push(points[i]);
      this.ys.push(points[i + 1]);
    }
    this.instructions.push([cmd, ...points]);
  }

  closePath() {
    this.closedPath = true;
  }

  generate({
    alignToCenter,
    normalize,
  }: { alignToCenter?: boolean; normalize?: number } = {}) {
    const { xs, ys, instructions } = this;
    let path;

    const bounds = {
      left: Math.min.apply(null, xs) || 0,
      top: Math.min.apply(null, ys) || 0,
      right: Math.max.apply(null, xs) || 0,
      bottom: Math.max.apply(null, ys) || 0,
    };

    if (normalize) {
      const width = bounds.right - bounds.left;
      const height = bounds.bottom - bounds.top;

      for (let i = 0; i < xs.length; i++) {
        xs[i] = ((xs[i] - bounds.left) / width) * normalize;
        ys[i] = ((ys[i] - bounds.top) / height) * normalize;
      }
    }

    if (alignToCenter) {
      // determine the bounds

      const offsetX = bounds.left + (bounds.right - bounds.left) * 0.5;
      const offsetY = bounds.top + (bounds.bottom - bounds.top) * 0.5;

      // generate the path -- adjust everything to be
      // centered inside of the view
      path = instructions.reduce(
        (d, [i, ...points]) =>
          `${d} ${i}${points.reduce(
            (c, v, j) => `${c} ${round(v - (j % 2 === 0 ? offsetX : offsetY))}`,
            ''
          )}`,
        ''
      );
    } else {
      path = instructions.reduce(
        (d, [i, ...points]) => `${d} ${i}${points.join(' ')} `,
        ''
      );
    }

    if (this.closedPath === true) {
      path += ' Z';
    }

    //In theory, viewbox can be 1:1 with actual units. However, usvg parser tessellates curves with default 0.1 pixel precision. This leads to faceted arcs and splines if we don't upscale the path
    return `<path d="${path}" />`;
  }
}
