import { convertNumToMM } from './UnitOps';
import { subtract, norm, sin, cos } from './PointOps';
import { createSvgImage } from '@shapertools/sherpa-svg-generator/SvgGenerator';
import { SvgOps } from './SvgParser';
import { signedArea } from './PathOps';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { CutParams } from '@shapertools/sherpa-svg-generator/CutParams';

class RoundPath {
  static getCommands(points, isClosed) {
    const commands = [];
    for (let i = 0; i < points.length; i++) {
      commands.push([i === 0 ? 'M' : 'L', points[i].x, points[i].y]);
    }
    if (isClosed) {
      commands.push(['Z']);
    }
    return commands;
  }

  static getPointFromPart(part) {
    return {
      x: parseFloat(part[part.length - 2]),
      y: parseFloat(part[part.length - 1]),
    };
  }

  static pointLength(pointA, pointB) {
    let xs = Math.pow(pointB[1] - pointA[1], 2);
    let ys = Math.pow(pointB[2] - pointA[2], 2);
    return Math.sqrt(xs + ys);
  }

  static distance(a, b) {
    return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
  }

  static scalePoint(pointA, pointB, scale) {
    return {
      x: pointA.x + (pointB.x - pointA.x) * scale,
      y: pointA.y + (pointB.y - pointA.y) * scale,
    };
  }

  static adjustToLength(pointToMove, targetPoint, radius) {
    const width = targetPoint.x - pointToMove.x;
    const height = targetPoint.y - pointToMove.y;

    const dist = this.distance(width, height);

    const scale = dist !== 0 ? Math.min(1, radius / dist) : 1;

    return this.scalePoint(pointToMove, targetPoint, scale);
  }

  static updateCommand(command, newPoint) {
    command.originalPoint = {
      x: command[command.length - 2],
      y: command[command.length - 1],
    };
    command[command.length - 2] = newPoint.x;
    command[command.length - 1] = newPoint.y;
  }

  static pointsToVector(p1, p2) {
    const vector = subtract(p2, p1);

    return new Point(vector.x, vector.y);
  }

  static calculateMaxRadius(
    previousCommand,
    currentCommand,
    nextCommand,
    radius
  ) {
    const prevPt = new Point(previousCommand[1], previousCommand[2]);
    const currentPt = new Point(currentCommand[1], currentCommand[2]);
    const nextPt = new Point(nextCommand[1], nextCommand[2]);

    // create vectors from given points
    let v1 = this.pointsToVector(prevPt, currentPt);
    let v2 = this.pointsToVector(currentPt, nextPt);

    let sinA = sin(v1, v2);
    let sinA90 = cos(v1, v2);

    // could probably optimize out the Math.asin call and replace it with half angle trig identities
    let angle = Math.asin(sinA < -1 ? -1 : sinA > 1 ? 1 : sinA);

    // orient the angle
    if (sinA90 < 0) {
      if (angle < 0) {
        angle = Math.PI + angle;
      } else {
        angle = Math.PI - angle;
      }
    }

    // take half the angle and find the distance from the center
    // of the "circle" drawn between the two points
    let halfAngle = angle / 2;
    let lenOut = Math.abs((Math.cos(halfAngle) * radius) / Math.sin(halfAngle));

    // check if radius will fit between the two points
    // if yes, keep the input radius, otherwise, pick the shortest
    // of the two sides and halve
    let v1Len = norm(v1);
    let v2Len = norm(v2);

    let cRadius = radius;
    let minLen = Math.min(v1Len / 2, v2Len / 2);
    if (lenOut > minLen) {
      lenOut = minLen;
      cRadius = Math.abs((lenOut * Math.sin(halfAngle)) / Math.cos(halfAngle));
    }

    return cRadius;
  }

  static getRoundedPath(commands, radius) {
    const commandParts = [...commands];
    const resultingParts = [];

    if (commandParts.length > 1) {
      const startPoint = this.getPointFromPart(commandParts[0]);

      const closingPoint = ['L', startPoint.x, startPoint.y];
      commandParts[commandParts.length - 1] = closingPoint;

      resultingParts.push(commandParts[0]);

      for (let i = 1; i < commandParts.length; i++) {
        let previousCommand = resultingParts[resultingParts.length - 1];
        let currentCommand = commandParts[i];
        let nextCommand =
          currentCommand === closingPoint
            ? commandParts[1]
            : commandParts[i + 1];

        if (
          nextCommand &&
          previousCommand &&
          previousCommand.length > 2 &&
          currentCommand[0] === 'L' &&
          nextCommand.length > 2 &&
          nextCommand[0] === 'L'
        ) {
          let previousPoint = this.getPointFromPart(previousCommand);
          let currentPoint = this.getPointFromPart(currentCommand);
          let nextPoint = this.getPointFromPart(nextCommand);

          let prevCo = previousCommand.originalPoint
            ? [
                'L',
                previousCommand.originalPoint.x,
                previousCommand.originalPoint.y,
              ]
            : previousCommand;
          let nextCo = nextCommand.originalPoint
            ? ['L', nextCommand.originalPoint.x, nextCommand.originalPoint.y]
            : nextCommand;

          const rad = this.calculateMaxRadius(
            prevCo,
            currentCommand,
            nextCo,
            radius
          );

          let curveStart = this.adjustToLength(
            currentPoint,
            previousPoint,
            rad
          );
          let curveEnd = this.adjustToLength(currentPoint, nextPoint, rad);

          this.updateCommand(currentCommand, curveStart);
          resultingParts.push(currentCommand);

          let curveCommand = ['A', rad, rad, 0, 0, 1, curveEnd.x, curveEnd.y];
          curveCommand.originalPoint = currentPoint;
          resultingParts.push(curveCommand);
        } else {
          resultingParts.push(currentCommand);
        }
      }

      const newStartPoint = this.getPointFromPart(
        resultingParts[resultingParts.length - 1]
      );
      resultingParts.push(['Z']);
      this.updateCommand(resultingParts[0], newStartPoint);

      const result = resultingParts.reduce(
        (str, curve) => str + curve.join(' ') + ' ',
        ''
      );
      return result;
    }
  }

  static getRoundedPathFromPoints(points, radius, isClosed = true) {
    const commands = this.getCommands(points, isClosed);
    return this.getRoundedPath(commands, radius);
  }

  static roundPath({ paths, tool }) {
    const radius = tool.params?.radius;
    const scaledRadius = convertNumToMM(radius, tool.params.units);
    return paths.map((path) => {
      const { points } = path;

      // need to determine if we should reverse the points so
      // that the corners are rounding outward, vs inward
      const flagTest = signedArea(path);

      //Apply rounding to path SVG
      const roundedPathSvg = `<path d="${this.getRoundedPathFromPoints(
        flagTest >= 0 ? points : points.reverse(),
        scaledRadius
      )}"/>`;

      //Convert rounded path SVG to tessellated geometry
      const svg = createSvgImage(roundedPathSvg, path.AABB);

      const { cutPaths } = SvgOps.convertSvgStrToPaths(svg);

      //Copy cutParams from parent basePath to rounded path
      return {
        ...cutPaths[0],
        id: path.id,
        cutParams: new CutParams(path.cutParams),
      };
    });
  }
}

export { RoundPath as default };
