import { saveLocalDemoState } from '@/Redux/Slices/SyncSlice';
import BaseAction from './BaseAction';

import {
  selectShaperMarketingUrl,
  selectSherpaClientUrl,
} from '@/Redux/Slices/ShaperHubSlice';

export default class CreateAccountActions extends BaseAction {
  createAccount() {
    const shaperMarketingUrl = this.useSelector(selectShaperMarketingUrl);
    const sherpaClientUrl = this.useSelector(selectSherpaClientUrl);
    this.dispatch(saveLocalDemoState());
    window.open(
      `${shaperMarketingUrl}/signup?backTo=${sherpaClientUrl}`,
      '_self'
    );
  }
}
