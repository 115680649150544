import { transform, doAABBsIntersect } from '@/Geometry/AABBOps';
import { invert } from '@shapertools/sherpa-svg-generator/Matrix33';
import UIState from '@/UILayer/State/UIState';

import {
  selectSvgGroupSet,
  selectGetGroupById,
} from '@/Redux/Slices/CanvasSlice';
import { PathId, selectSelectedPathIds } from '@/Redux/Slices/SelectionSlice';
import { ViewportState, selectViewport } from '@/Redux/Slices/ViewportSlice';

import { uniq } from 'lodash';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';
import {
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';

type SelectionDetails = {
  id?: string;
  type: PATH_TYPE;
  groupId?: string;
  group?: SvgGroup;
  path?: BasePath;
  pathId?: string;
};

export default class SelectionNetAction {
  dispatch: AppDispatch;
  useSelector: UseSelector;
  groups: SvgGroup[];
  viewportState: ViewportState;
  startingSelection: PathId[];
  x: number;
  y: number;

  constructor(
    dispatch: AppDispatch,
    useSelector: UseSelector,
    originX: number,
    originY: number
  ) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;

    this.groups = useSelector(selectSvgGroupSet);
    this.viewportState = useSelector(selectViewport);
    this.startingSelection = useSelector(selectSelectedPathIds);

    this.x = originX;
    this.y = originY;
  }

  // updates
  update(x: number, y: number) {
    const { groups } = this;
    const { screenToCanvasTransform } = this.viewportState;
    const getGroupById = this.useSelector(selectGetGroupById);

    //Create AABB corresponding to selection net
    const p0 = new Point(this.x, this.y);
    const p1 = new Point(x, y);

    const netAABBScreenSpace = new AABB({
      minPoint: p0,
      maxPoint: p1,
    });

    //Now transform netAABB to canvas space for easier hit detection
    const netAABBCanvasSpace = transform(
      netAABBScreenSpace,
      screenToCanvasTransform
    );

    // create the selection
    const selection = [] as SelectionDetails[];

    // check for overlapped layers
    for (const group of groups) {
      //Does netAABB intersect group AABB? If not, can skip all paths in group.
      if (!doAABBsIntersect(group.transformedAABB, netAABBCanvasSpace)) {
        continue;
      }

      //Because group intersects net, test all paths in group against net
      // OLD - for(const path of group.cutPathSet){
      // NEW
      // group.cutPathSet is deprecated and transformed paths are no longer precalculated.
      // So new approach is to do this hit detection in each group's local coordinate system.

      // First, invert the group TRSMtx to get a transform from canvas to group local space.
      // const canvasToGroupMtx = Matrix33Ops.invert(group.TRSMtx);
      const canvasToGroupMtx = invert(group.TRSMtx);
      // Then, transform the selection net AABB to group local space
      const netAABBGroupSpace = transform(netAABBCanvasSpace, canvasToGroupMtx);

      //Now compare each basePath AABB against the selection net AABB in group local space, not in canvas space
      for (const path of group.basePathSet) {
        if (doAABBsIntersect(path.AABB, netAABBGroupSpace)) {
          //TODO - check downstream for further DOM stuff to optimize out
          selection.push({
            group,
            path,
            pathId: path.id,
            groupId: group.id,
            type: group.type,
          });
        }
      }
    }

    // check if the selection contains a mix of types
    const types = selection.filter(
      (item) => ![Shape.POINT, Shape.SHAPE].includes(item.group!.tool.type)
    );
    const shapeCount = selection.filter(
      (item) => item.group!.tool.type === Shape.SHAPE
    );
    const isMixedSelection = types.length > 0 || shapeCount.length > 1;

    // with the final selection, we need to modify it depending on polyline
    // selections
    // always remove shapes from a selection
    // if only a shape is selected, select all points
    // if a point is selected, and objects/points from other groups are selected, select all points
    [...selection].forEach((selected) => {
      // if this is a shape
      if (selected.group!.tool.type === Shape.SHAPE) {
        let selectAllPoints = isMixedSelection;

        // if not already including all points, check if any
        // point is is already selected
        if (!selectAllPoints) {
          selectAllPoints = !selection.find((item) =>
            (selected.group!.tool as Tool<Shape.SHAPE>).params.points.includes(
              item.groupId!
            )
          );
        }

        // if no other points are selected, then select all
        // of the points in this selection
        if (selectAllPoints) {
          selection.push(
            ...(selected.group!.tool as Tool<Shape.SHAPE>).params.points.map(
              (pointId) => ({
                groupId: pointId,
                pathId: 'none',
                group: getGroupById(pointId),
                type: PATH_TYPE.DESIGN,
              })
            )
          );
        }

        // always remove shapes
        const index = selection.findIndex((item) => item.id === selected.id);
        selection.splice(index, 1);
      }
    });

    // remove duplicates
    const ids: { [key: string]: boolean } = {};
    for (let i = selection.length; i-- > 0; ) {
      const key = `${selection[i].groupId}:${selection[i].pathId}`;

      // has already been added
      if (ids[key]) {
        selection.splice(i, 1);
      }

      ids[key] = true;
    }

    // clean up the selection
    for (const item of selection) {
      delete item.group;
      delete item.path;
    }

    // calculate the size
    UIState.apply((state: any) => {
      const { x: left, y: top } = netAABBCanvasSpace.minPoint;
      const { x: right, y: bottom } = netAABBCanvasSpace.maxPoint;
      state.selectionNet = { left, right, top, bottom };
    });

    // return the final selection which includes any groups
    // that were in the initial selection
    return uniq([...selection, ...this.startingSelection]);
  }

  // end
  resolve() {
    UIState.reset();
  }
}
