import { LocaleResponse } from '@/@types/shaper-types';
import { addAttributesToUser } from './userflow';

const PRICES = {
  // Prices are per month but billed annually
  annual: {
    USD: 5,
    CAD: 7,
    EUR: 6,
    GBP: 5.5,
    CHF: 6,
    AUD: 7.5,
  },
  perpetual: {
    USD: 300,
    CAD: 425,
    EUR: 350,
    GBP: 325,
    CHF: 350,
    AUD: 450,
  },
} as const;

// Function allows us to let JavaScript do the work for Audrey
// and format the currency correctly and show the user their price!
export function setStudioPricesForUserFlow(locale: LocaleResponse) {
  const currency = locale.currency || 'USD';
  const formatter = new Intl.NumberFormat(locale.displayLocale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0, // prevents the cents from being displayed
  });

  addAttributesToUser({
    studio_monthly_subscription_cost: formatter.format(PRICES.annual[currency]),
    studio_perpetual_subscription_cost: formatter.format(
      PRICES.perpetual[currency]
    ),
    currency_disambiguation:
      currency === 'CAD' || currency === 'AUD' ? currency : '',
  });
}
