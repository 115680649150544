import { updateServerSHA } from '@/Redux/Slices/ShaperHubSlice';
import { setLoading } from '../Redux/Slices/SherpaContainerSlice';
import { disconnect, reconnect } from '@/Sync/SyncThunks';
import { log } from '@/Sync/SyncLog';
import { AppDispatch } from '@/Redux/store';

export default class IdleAppAction {
  dispatch: AppDispatch;
  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  goIdle = async () => {
    const { dispatch } = this;

    log(`Emitting disconnect and idling workspace`, { syncLevel: 'action' });
    dispatch(disconnect({ idleApp: true }));
  };

  goActive = async () => {
    const { dispatch } = this;

    log(`Emitting reconnect after idling`, {
      syncLevel: 'action',
    });

    await dispatch(updateServerSHA());
    dispatch(setLoading(true));
    dispatch(reconnect());
  };
}
