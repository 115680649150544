import { Handle } from '@/@types/shaper-types';
import BaseAction from './BaseAction';
import cursorManager from '@/Cursors/CursorComponent';
import SelectionBoxOps from '@/Helpers/SelectionBoxHelper';

export default class SetCursorAction extends BaseAction {
  toDefault() {
    cursorManager.setCursorMode();
  }

  toResize(handle: Handle) {
    const cursor = SelectionBoxOps.getCursorForHandle(handle);
    cursorManager.setCursorMode(cursor);
  }

  toRotate(handle: Handle) {
    const cursor = SelectionBoxOps.getCursorForHandle(handle);
    cursorManager.setCursorMode(cursor);
  }

  toMultiSelect() {
    cursorManager.setCursorMode('multi-select');
  }

  toGrab() {
    cursorManager.setCursorMode('grab');
  }

  toGrabbed() {
    cursorManager.setCursorMode('grabbed');
  }
}
