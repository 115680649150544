import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';
import { useAction } from '@/Actions/useAction';
import ActionMenuList from '@/Components/ActionMenuList/ActionMenuList';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { useAppDispatch } from '@/Redux/hooks';
import { selectProject } from '@/Redux/Slices/ShaperHubSlice';
import { toggleShare } from '@/Redux/Slices/UISlice';
import Icon from '@/Styles/Icons/Icon';
import { Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import { ProjectSummary } from '@shapertools/web-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';

function CopyLinkMenuItem({ linkCopied }: { linkCopied: boolean }) {
  const { t, i18n } = useTranslation();

  const linkCopiedTranslation = i18n.exists('link-copied')
    ? t('link-copied')
    : 'link copied!';
  return (
    <Tooltip
      title={linkCopiedTranslation}
      open={linkCopied}
      placement='top'
      PopperProps={{
        className: 'copy-link-tooltip',
      }}
    >
      <div>
        <div className='action-list-menu--section-option--icon'>
          <Icon icon='content-copy' />
        </div>
        <div className='action-list-menu--section-option--label'>
          <TranslationText i18nKey='copy-link'>Copy link</TranslationText>
        </div>
      </div>
    </Tooltip>
  );
}

function UpdateSharedDesignMenuItem() {
  return (
    <div>
      <Badge
        color='primary'
        variant='dot'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='update-shared-design--badge'
      >
        <>
          <div className='action-list-menu--section-option--icon'>
            <Icon icon='update' />
          </div>
          <div className='action-list-menu--section-option--label'>
            <TranslationText i18nKey='update-design'>
              Update Shared Design
            </TranslationText>
          </div>
        </>
      </Badge>
    </div>
  );
}

export default function ShareMobileMenu() {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const listedTranslation = i18n.exists('listed') ? t('listed') : 'Listed';

  const unlistedTranslation = i18n.exists('unlisted')
    ? t('unlisted')
    : 'Unlisted';
  const project = useSelector(selectProject);
  const [linkCopied, setLinkCopied] = useState(false);

  const updateWorkspaceOptions = useAction(UpdateWorkspaceOptionsAction);

  function backToMenu() {
    dispatch(toggleShare(false));
  }

  function closeMenu() {
    batch(() => {
      dispatch(toggleShare(false));
      updateWorkspaceOptions.update({ showApplicationMenu: false });
    });
  }

  function openProject() {
    window.open(
      `${import.meta.env.VITE_SHAPER_URL_HUB}/design/${project?._id}`,
      '_blank'
    );
  }

  function copyLink() {
    setLinkCopied(true);
    navigator.clipboard.writeText(
      `${import.meta.env.VITE_SHAPER_URL_HUB}/design/${project?._id}`
    );
    setTimeout(() => {
      setLinkCopied(false);
    }, 1500);
  }

  function updateSharedDesign() {
    window.open(
      `${import.meta.env.VITE_SHAPER_URL_HUB}/design/${
        project?._id
      }?update=true`,
      '_self'
    );
  }

  const actions = useMemo(
    () => [
      {
        visible: true,
        className: 'share-mobile--project-summary',
        component: () => (
          <>
            <ProjectSummary
              img={img}
              name={name}
              listed={listed}
              onClick={openProject}
              translationStrings={{
                listed: listedTranslation,
                unlisted: unlistedTranslation,
              }}
            />
          </>
        ),
      },
      {
        visible: true,
        className: 'share-mobile--copy-link',
        component: () => <CopyLinkMenuItem linkCopied={linkCopied} />,
        onClick: copyLink,
      },
      {
        visible: project && project.outOfDate,
        component: () => (
          <div onClick={updateSharedDesign}>
            <UpdateSharedDesignMenuItem />
          </div>
        ),
      },
      {
        label: 'Up to Date',
        i18nkey: 'no-update-design',
        icon: 'update',
        disabled: true,
        visible: project && !project.outOfDate,
      },
    ],
    [project, linkCopied]
  );

  const { img, name, listed } = useMemo(() => {
    if (project) {
      return {
        img: project.images && project.images[0] ? project.images[0]?.url : '',
        name: project?.title || '',
        listed: project?.privacy === 'listed',
      };
    }
    return {
      img: '',
      name: '',
      listed: false,
    };
  }, [project]);
  return (
    <div className='share-mobile'>
      <div className='share-mobile--container'>
        <div className='share-mobile--menu'>
          <div className='share-mobile--menu--icon' onClick={backToMenu}>
            <Icon icon='arrow-left' />
          </div>
          <div className='share-mobile--menu--title'>Shared</div>
          <div className='share-mobile--menu--close' onClick={closeMenu}>
            <Icon icon='close' />
          </div>
        </div>
        <div className='share-mobile--content'>
          <ActionMenuList>
            {actions
              .filter((action) => action.visible)
              .map((m, i) => (
                <ActionMenuList.SectionOption key={i} {...m} />
              ))}
          </ActionMenuList>
        </div>
      </div>
    </div>
  );
}
