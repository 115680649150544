import React from 'react';
import { useSelector } from 'react-redux';

import OpenSyncedWorkspaceAction from '@/Actions/OpenSyncedWorkspace';

import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import { selectWorkspaceId } from '@/Redux/Slices/SyncSlice';
import { useAppDispatch, useAppSelector } from '@/Redux/hooks';

export default function Workspace() {
  const workspaceId = useAppSelector(selectWorkspaceId);
  const dispatch = useAppDispatch();

  const changeWorkspaceId = (newWorkspaceId: string) => {
    const openSyncedWorkspaceAction = new OpenSyncedWorkspaceAction(
      dispatch,
      useSelector
    );
    openSyncedWorkspaceAction.openSyncedWorkspace(newWorkspaceId);
  };

  const changeWorkspaceSeq = (newWorkspaceSeq: boolean) => {
    if (workspaceId) {
      const openSyncedWorkspaceAction = new OpenSyncedWorkspaceAction(
        dispatch,
        useSelector
      );
      openSyncedWorkspaceAction.openSyncedWorkspace(
        workspaceId,
        newWorkspaceSeq
      );
    }
  };

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='import-file'>Workspace ID</FloatingPanel.Label>
      <FloatingPanel.Input
        workspaceId
        onCommit={changeWorkspaceId}
        value={workspaceId}
      />

      <FloatingPanel.Label icon='import-file'>SEQ</FloatingPanel.Label>
      <FloatingPanel.Input
        workspaceId
        onCommit={changeWorkspaceSeq}
        value={''}
      />
    </FloatingPanel.Group>
  );
}
