import React from 'react';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { unitToMMNum } from '@/Geometry/UnitOps';
import { selectToFormattedDisplayUnitValue } from '@/Redux/Slices/SherpaContainerSlice';

// util
import { asFloat } from '@/Utility/sanitize';

// selectors
import { selectDisplayUnits } from '@/Redux/Slices/SherpaContainerSlice';
import {
  selectSelectionBounds,
  selectSelectedGroups,
  selectSelectionAnchorPosition,
} from '@/Redux/Slices/SelectionSlice';

// actions
import TranslateGroupsAction from '@/Actions/TranslateGroups';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';

export default function PositionEditor() {
  const selectedGroups = useSelector(selectSelectedGroups);
  const displayUnits = useSelector(selectDisplayUnits);
  const selectionBounds = useSelector(selectSelectionBounds);
  const selectionPosition = useSelector(selectSelectionAnchorPosition);
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );
  const selectedGroupIds = selectedGroups.map((group) => group.id);
  const [selectedGroup] = selectedGroups;

  // actions
  const translateAction = useAction(TranslateGroupsAction, selectedGroupIds);

  // computed
  const { x: currentX, y: currentY } = selectionPosition;
  const displayX = toFormattedDisplayUnitValue(currentX);
  // Y-axis is flipped for position purposes. Easier to change this here rather than adding a flip transform EVERYWHERE
  const displayY = toFormattedDisplayUnitValue(currentY * -1);
  const disabled =
    PATH_TYPES[selectedGroup.type]?.propertyEditingDisabled || false;

  // handle resize updates
  function onPosition(point) {
    const [x, y] = ['x', 'y'].map((prop) => {
      // make sure there's something
      const value = point[prop];
      if (!isNumber(value)) {
        return undefined;
      }

      // reorient the position to the center
      const setTo = unitToMMNum(value, displayUnits);
      const relativeTo = selectionPosition[prop];
      const centeredTo = selectionBounds.centroidPosition[prop];
      const offset = relativeTo - centeredTo;
      return setTo - offset;
    });

    translateAction.setAbsolute(x, y);
  }

  // setup some handlers for resizing
  const onSetX = (input) => onPosition({ x: asFloat(input) });
  const onSetY = (input) => onPosition({ y: -1 * asFloat(input) }); //Undo the flip here

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label
        icon='position'
        attrs={{ 'data-cy': 'position-editor' }}
      >
        <TranslationText i18nKey='position'>Position</TranslationText>
      </FloatingPanel.Label>
      <FloatingPanel.Input
        calculate
        prefix='X'
        suffix={displayUnits}
        onCommit={onSetX}
        number
        value={displayX}
        disabled={disabled}
        dataCy='position-editor-x'
      />
      <FloatingPanel.Input
        calculate
        prefix='Y'
        suffix={displayUnits}
        onCommit={onSetY}
        number
        value={displayY}
        disabled={disabled}
        dataCy='position-editor-y'
      />
    </FloatingPanel.Group>
  );
}
