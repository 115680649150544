import React from 'react';

//actions
import { useAction } from '../../../Actions/useAction';
import ModalActions from '../../../Actions/Modal';

//components
import Icon from '@/Styles/Icons/Icon';
import Modal from '../Modal';
import TranslationText from '../../TranslationText/TranslationText';
import CreateWorkspaceAction from '../../../Actions/CreateWorkspace';

export default function InvalidModal() {
  const modalActions = useAction(ModalActions);
  const createWorkspaceAction = useAction(CreateWorkspaceAction);

  const closeModal = () => {
    modalActions.closeModal(true);
  };

  const newWorkspace = () => {
    modalActions.closeModal(true);
    createWorkspaceAction.disconnectCurrentWorkspaceAndCreateNewWorkspace();
  };

  return (
    <Modal className='invalid-modal'>
      <div className='invalid-modal--close-icon' onClick={closeModal}>
        <Icon icon='close' />
      </div>
      <Modal.Panel position='center'>
        <Modal.Content>
          <Modal.Title>
            <Icon icon='warning' />
            Invalid Workspace Version
          </Modal.Title>
          <div className='invalid-modal--info'>
            This workspace's version is invalid or does not match the current
            version. Please reach out to support for more information.
          </div>
          <Modal.Button
            className='invalid-modal--button'
            onClick={newWorkspace}
          >
            <TranslationText i18nKey='create-new-workspace'>
              Create New Workspace
            </TranslationText>
          </Modal.Button>
        </Modal.Content>
      </Modal.Panel>
    </Modal>
  );
}
