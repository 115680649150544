import BaseInteraction from './BaseInteraction';
import TranslateViewportAction from '@/Actions/TranslateViewport';
import SetCursorAction from '../../Actions/SetCursor';

import {
  selectUIMode,
  selectIsShowingShapeShifter,
} from '@/Redux/Slices/UISlice';

export default class TranslateViewportInteraction extends BaseInteraction {
  interactionId = 'Translate Viewport';

  isOverInteractable(event) {
    // nothing for mouse drag in these modes
    const mode = this.useSelector(selectUIMode);
    if (['plan', 'review'].includes(mode)) {
      return false;
    }

    return (
      this.getHandleAt(event.center) ||
      this.hitTestSelectionBox(event.center) ||
      !!this.getGroupsAt(event.center)?.length
    );
  }

  onEveryKeyDown({ key }) {
    if (key === ' ') {
      this.forceActivation = true;

      // update the cursor
      const cursor = this.createAction(SetCursorAction);
      cursor.toGrab();
    }
  }

  onEveryKeyUp({ key }) {
    if (key === ' ') {
      this.forceActivation = false;

      // update the cursor
      const cursor = this.createAction(SetCursorAction);
      cursor.toDefault();
    }
  }

  onEveryWindowExit() {
    this.forceActivation = false;

    // update the cursor
    const cursor = this.createAction(SetCursorAction);
    cursor.toDefault();
  }

  onPointerDown() {
    if (this.forceActivation) {
      this.setActive();
      return false;
    }
  }

  onPointerUp() {
    this.release();
  }

  shouldBeginTranslateViewport(event) {
    return this.forceActivation || !this.isOverInteractable(event);
  }

  onPointerMoveStart(event) {
    const isShapeShifterMode = this.useSelector(selectIsShowingShapeShifter);
    if (isShapeShifterMode) {
      if (event.event?.srcElement?.tagName?.includes('path')) {
        return;
      }
    }

    // if this is over a selection box, then we can
    // assume it's a transform attempt for the groups
    if (!this.shouldBeginTranslateViewport(event)) {
      return;
    }

    // activate translation
    this.setActive();
    this.action = this.createAction(TranslateViewportAction);
  }

  onActivePointerMove(event) {
    const { deltaX, deltaY } = event;
    this.action?.panBy(-deltaX, -deltaY);
    return false;
  }

  onActivePointerMoveEnd() {
    this.action?.resolve();

    // allow other interactions
    this.release();

    // update the cursor
    const cursor = this.createAction(SetCursorAction);
    cursor.toDefault();

    return false;
  }
}
