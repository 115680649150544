import cssConfig from '../Styles/Config.scss?inline';

export default class GlobalCssHelper {
  container: HTMLStyleElement;
  important: any;
  breakpoint: any;

  constructor(reason: string, config: Record<string, string> = {}) {
    this.container = document.createElement('style');
    this.container.setAttribute('type', 'text/css');
    this.container.setAttribute('reason', reason);

    // attach to the document -- remove a previous
    // one if needed (development only)
    document.querySelector(`[reason="${reason}"]`)?.remove();
    document.body.appendChild(this.container);

    this.important = config.important;

    // check for config options
    if (config.breakpoint) {
      this.breakpoint = cssConfig[
        `breakpoint_${config.breakpoint}` as any
      ]?.replace(/"/g, '');
    }
  }

  assign(updates: Record<string, Record<string, string>>) {
    const { breakpoint } = this;
    const important = this.important ? '!important' : '';

    // start assembling the new css
    let content = [];

    // check for a media query
    if (breakpoint) {
      content.push(`@media (${breakpoint}) {`);
    }

    for (const selector of Object.keys(updates)) {
      const rules = updates[selector];
      const css = Object.keys(rules)
        .map((rule) => `    ${rule}: ${rules[rule as any]} ${important}`)
        .join('; ');
      content.push(`  ${selector} {
${css}
}`);
    }

    // close the media tag
    if (breakpoint) {
      content.push('}');
    }

    this.container.textContent = content.join('\n');
  }

  clear = () => {
    this.container.textContent = '';
  };

  dispose() {
    this.container.remove();
  }
}
