import { selectWorkspaceId } from '@/Redux/Slices/SyncSlice';
import BaseAction from './BaseAction';
import { exportCanvasSvg } from '@/Redux/Slices/CanvasSlice';
import {
  getExportAccess,
  getWorkspaceExport,
} from '@/ShaperHub/ShaperHubThunks';
import { saveSvg } from '@/Utility/downloadBlob';
import { selectWorkspaceFileName } from '@/Redux/Slices/ShaperHubSlice';

export default class DownloadAction extends BaseAction {
  request = async () => {
    const { dispatch } = this;
    return dispatch(exportCanvasSvg());
  };

  getWorkspaceId(): string {
    return this.useSelector(selectWorkspaceId) as string;
  }

  // performs an export and refreshes available exports, if needed
  async _doExport(action: (id: string) => Promise<void>) {
    const workspaceId = this.getWorkspaceId();
    await action(workspaceId);
    await this.dispatch(getExportAccess());
  }

  // TODO: replace with actual exports
  async exportToOrigin() {
    return this._doExport(async (workspaceId) => {
      await getWorkspaceExport(workspaceId, 'origin');
    });
  }

  // handle exporting the result
  async exportAsSVG() {
    return this._doExport(async (workspaceId) => {
      const result = await getWorkspaceExport(workspaceId, 'svg');
      const { data } = result;
      const fileName = this.useSelector(selectWorkspaceFileName);
      saveSvg(data, fileName);
    });
  }
}
