import {
  resize as viewportResize,
  centerTo as viewportCenterTo,
  setGridSize as viewportSetGridSize,
} from '@/Redux/Slices/ViewportSlice';
import BaseAction from './BaseAction';
import { CenterToPayload, Targets } from '@/Viewport/ViewportHelpers';

export default class ViewportActions extends BaseAction {
  refresh() {
    const { innerWidth: width, innerHeight: height } = window;
    this.resize(width, height);
  }

  resize(width: number, height: number) {
    const { dispatch } = this;
    dispatch(viewportResize({ width, height }));
  }

  setGridSize(size: number) {
    const { dispatch } = this;
    dispatch(viewportSetGridSize(size));
  }

  centerTo(
    targets?: Targets[],
    options: Omit<CenterToPayload, 'targets'> = {}
  ) {
    const { dispatch } = this;
    dispatch(viewportCenterTo({ ...(targets && { targets }), ...options }));
  }
}
