// Consider using UI State when refactoring to TypeScript

import React from 'react';
import { useSelector } from 'react-redux';
import { mmToUnitFormattedNum } from '@/Geometry/UnitOps';

// slices
import { selectInsertPointModeOptions } from '@/Redux/Slices/UISlice';
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import { selectDisplayUnits } from '@/Redux/Slices/SherpaContainerSlice';

// actions
import { useAction } from '@/Actions/useAction';
import LineToolAction from '@/Actions/LineTool';

const INSERT_DISTANCE_LABEL_RADIUS = 4;

export default function InsertPointLocation() {
  const options = useSelector(selectInsertPointModeOptions);
  const displayUnits = useSelector(selectDisplayUnits);
  const nspf = useSelector(selectNonScalingPixelFactor);
  const lineTool = useAction(LineToolAction);
  const isMobile = options.insertMode === 'mobile';
  const { x, y } = options.origin;
  const centerSize = isMobile ? 2.5 : 3;

  // if possible
  let line;
  const shape = lineTool.shapes[options?.groupId];
  const point = options?.atFront ? shape?.head : shape?.tail;
  const insert = InsertPointLocation.getPosition();

  line = point && (
    <>
      <line
        className='insert-line'
        x1={insert?.x}
        y1={insert?.y}
        x2={point?.x}
        y2={point?.y}
      />
      <g
        className='insert-distance'
        data-unit={displayUnits}
        data-nspf={nspf}
        style={{ display: 'none' }}
      >
        <g>
          <rect
            width={90 * nspf}
            height={22 * nspf}
            y={-7 * nspf}
            x={-45 * nspf}
            rx={INSERT_DISTANCE_LABEL_RADIUS * nspf}
            ry={INSERT_DISTANCE_LABEL_RADIUS * nspf}
          />
          <text y={9 * nspf} />
        </g>
      </g>
    </>
  );

  return (
    <>
      {line}

      <g
        className='insert-point-location'
        transform={`translate(${x}, ${y})`}
        x={x}
        y={y}
      >
        {isMobile && (
          <circle className='insert-point-location--ring' r={38 * nspf} />
        )}
        <circle className='insert-point-location--radius' r={20 * nspf} />
        <circle
          className='insert-point-location--center'
          r={centerSize * nspf}
        />
      </g>
    </>
  );
}

Object.assign(InsertPointLocation, {
  getElement() {
    return document.querySelector('.insert-point-location');
  },

  getInsertLine() {
    return document.querySelector('.insert-line');
  },

  getInsertDistance() {
    return document.querySelector('.insert-distance');
  },

  getInsertText() {
    return document.querySelector('.insert-distance text');
  },

  getPosition() {
    const element = InsertPointLocation.getElement();
    const x = 0 | element?.getAttribute('x');
    const y = 0 | element?.getAttribute('y');
    return { x, y };
  },

  setPosition(x, y) {
    const el = InsertPointLocation.getElement();
    const line = InsertPointLocation.getInsertLine();
    const distance = InsertPointLocation.getInsertDistance();
    const label = InsertPointLocation.getInsertText();
    const bg = distance?.querySelector('rect');

    if (el) {
      el.setAttribute('x', x);
      el.setAttribute('y', y);
      el.setAttribute('transform', `translate(${x}, ${y})`);
    }

    if (line) {
      line.setAttribute('x1', x);
      line.setAttribute('y1', y);
    }

    if (line && distance) {
      const x1 = parseFloat(line.getAttribute('x1'));
      const x2 = parseFloat(line.getAttribute('x2'));
      const y1 = parseFloat(line.getAttribute('y1'));
      const y2 = parseFloat(line.getAttribute('y2'));
      const unit = distance.getAttribute('data-unit');
      const nspf = parseFloat(distance.getAttribute('data-nspf'));
      const radians = Math.atan2(y1 - y2, x1 - x2);
      let angle = radians * (180 / Math.PI);
      let offset = radians + Math.PI * 0.5;

      if (bg) {
        bg.setAttribute('rx', INSERT_DISTANCE_LABEL_RADIUS * nspf);
        bg.setAttribute('ry', INSERT_DISTANCE_LABEL_RADIUS * nspf);
      }

      if (distance) {
        distance.style.display = 'block';
      }

      if (angle < -90) {
        angle += 180;
        offset += Math.PI;
      }

      if (angle > 90) {
        angle -= 180;
        offset -= Math.PI;
      }

      let ox = (x2 + x1) * 0.5;
      let oy = (y2 + y1) * 0.5;

      ox += Math.cos(offset) * 15 * nspf;
      oy += Math.sin(offset) * 15 * nspf;

      const displayDistance = mmToUnitFormattedNum(
        Math.hypot(x2 - x1, y2 - y1),
        unit
      );

      distance.setAttribute(
        'transform',
        `translate(${ox}, ${oy}) rotate(${angle})`
      );

      label.setAttribute('font-size', 14 * nspf);
      label.innerHTML = `${displayDistance} ${unit}`;
    }
  },
});
