import React from 'react';
import { useSelector } from 'react-redux';
import ToggleTextEditorModeAction from '@/Actions/ToggleTextEditorMode';
import { useAction } from '@/Actions/useAction';
import { selectTextParams } from '@/Redux/Slices/UISlice';

// components
import { TextEditor } from './TextEditor';
import { withTranslation } from 'react-i18next';
import { AddSvgParams } from '@/Actions/AddGeometry';
import { GroupId } from '@/Redux/Slices/SelectionSlice';

type Props = {
  onCancel: () => void;
  onCommit: (
    output: AddSvgParams,
    isEditing: boolean,
    groupId?: GroupId
  ) => void;
};

function TextTool(props: Props) {
  const textParams = useSelector(selectTextParams);
  const textEditorModeAction = useAction(ToggleTextEditorModeAction);

  const addNewTextSvg = function (
    newTextToolOutput: AddSvgParams,
    isEditing: boolean,
    groupId?: GroupId
  ) {
    props.onCommit(newTextToolOutput, isEditing, groupId);
    textEditorModeAction.exit();
  };

  const ExtendedTextEditor = withTranslation()(TextEditor);

  return (
    <ExtendedTextEditor
      onCommit={addNewTextSvg}
      onCancel={props.onCancel}
      textParams={textParams}
    />
  );
}

export default TextTool;
