import React, { useEffect } from 'react';
import { useAction } from '@/Actions/useAction';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

// helpers
import { SvgOps } from '@/Geometry/SvgParser';
import { getPositionFromSvgViewbox } from '@/Utility/viewbox';
import RotationHandleCursorHelper from '@/Cursors/RotationHandleCursorHelper';
import * as dev from '@/development';

// consts
import { FLOW_IDS, MODAL_TYPES } from '../defaults';

// slices/selectors
import {
  selectActiveUIState,
  selectFeatures,
  selectIsDefaultView,
  selectIsDesignMode,
  selectIsPlanMode,
  selectIsPreviewView,
  selectIsReviewMode,
  selectIsShowingHelpMenu,
  selectIsShowingImportMode,
  selectIsShowingShapeShifter,
  selectIsShowingTextInsert,
  toggleTextInsert,
} from '@/Redux/Slices/UISlice';
import {
  selectLoading,
  selectModal,
} from '@/Redux/Slices/SherpaContainerSlice';
import {
  GroupId,
  selectHasSelection,
  selectSelectedGroupIds,
} from '@/Redux/Slices/SelectionSlice';
import { resize, selectSVGViewbox } from '@/Redux/Slices/ViewportSlice';
import { selectIsEditMode, selectSyncStatus } from '@/Redux/Slices/SyncSlice';
import { selectLoggedIn } from '@/Redux/Slices/ShaperHubSlice';

// actions
import UIModeAction from '@/Actions/UIMode';
import AddGeometryAction, { AddSvgParams } from '@/Actions/AddGeometry';

// components
import FileImportModal from '@/Components/FileImport/FileImport';
import CanvasContainer from '@/CanvasContainer/CanvasContainerClass';
import TextTool from '@/Components/TextEditor/TextTool';
import DefaultActionMenu from '@/Components/CanvasActionMenu/Menus/Default';
import BasicShapeCreatorActionMenu from '@/Components/CanvasActionMenu/Menus/BasicShapeCreator';
import GroupSelectionActionMenu from '@/Components/CanvasActionMenu/Menus/GroupSelection';
import DesignActionMenu from '@/Components/CanvasActionMenu/Menus/DesignAction';
import CircularProgress from '@mui/material/CircularProgress';
import ModeSelectionMenu from '@/Components/ModeMenu/ModeSelectionMenu';
import EditGroupPropertiesPanel from '@/Components/FloatingPanel/Panels/EditGroupProperties/EditGroupProperties';
import EditPathPropertiesPanel from '@/Components/FloatingPanel/Panels/EditPathProperties/EditPathProperties';
import ApplicationMenu from '@/Components/ApplicationMenu/ApplicationMenu';
import ApplicationMenuLauncher from '@/Components/ApplicationMenu/Launcher';
import SecretMenu from '@/Components/SecretMenu/SecretMenu';
import SherpaSnackbar from '@/Components/Snackbar/Snackbar';
import HelpMenu from '../Components/HelpMenu/HelpMenu';
import FindArtPanel from '../Components/FloatingPanel/Panels/FindArt/FindArt';
import LoginModal from '@/Components/Modals/ModalTypes/SignInModal';
import EducationalModal from '@/Components/Modals/ModalTypes/EducationalModal';
import ViewOnlyModal from '../Components/Modals/ModalTypes/ViewOnlyModal';
import OfflineModal from '../Components/Modals/ModalTypes/OfflineModal';
import Loading from '../Components/Loading/Loading';
import UndoRedo from '../Components/UndoRedo/UndoRedo';
import EnablePopupsModal from '../Components/Modals/ModalTypes/EnablePopupsModal';
import SyncModal from '../Components/Modals/ModalTypes/SyncModal';
import SelectionEditor from '../Components/FloatingPanel/Panels/SelectionEditor/SelectionEditor';
import FitToView from '../Components/FitToView/FitToViewButton';
import HoverStateHelper from '../Components/HoverStateHelper/HoverStateHelper';
import GuidedTour from '../Components/GuidedTour/GuidedTour';
import {
  closeAll,
  openFlow,
  toggleHelpMenuVisibility,
} from '../Utility/userflow';
import { isMobile } from '../Utility/ismobile';
import { selectSelectedGroups } from '../Redux/Slices/SelectionSlice';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import ReferenceModal from '../Components/Modals/ModalTypes/ReferenceModal';
import InvalidModal from '../Components/Modals/ModalTypes/InvalidModal';
import InvalidRenameModal from '@/Components/Modals/ModalTypes/InvalidRenameModal';
import { entitlements } from '@/Helpers/Entitlements';
import { isEqual } from 'lodash';
import svgCache from '@/Geometry/SvgCache/SvgCache';
import { AppDispatch } from '@/Redux/store';
import PreviewActionMenu from '@/Components/CanvasActionMenu/Menus/PreviewAction';
import PreviewLabel from '@/Components/PreviewLabel/PreviewLabel';
import ExportProgressModal from '@/Components/Export/ExportModal';

export default function SherpaContainer() {
  const dispatch = useDispatch();

  const {
    isShowingIconSearch,
    isShowingFileImport,
    isShowingProgress,
    isShowingShapeCreator,
    isShowingEditSelectionProperties,
    isShowingSelectionEditor,
  } = useSelector(selectActiveUIState);
  const modal = useSelector(selectModal);

  const isShapeShifterMode = useSelector(selectIsShowingShapeShifter);

  // actions
  const uiModeAction = useAction(UIModeAction);
  const addGeometryAction = useAction(AddGeometryAction);

  const hasSelection = useSelector(selectHasSelection);

  // computed
  const isPlanMode = useSelector(selectIsPlanMode);
  const isReviewMode = useSelector(selectIsReviewMode);
  const isShowingTextInsert = useSelector(selectIsShowingTextInsert);
  const isShowingImportMode = useSelector(selectIsShowingImportMode);
  const isShowingHelpMenu = useSelector(selectIsShowingHelpMenu);
  const isDefaultMode = useSelector(selectIsDesignMode);
  const isDefaultView = useSelector(selectIsDefaultView);
  const isPreviewView = useSelector(selectIsPreviewView);

  const svgViewBox = useSelector(selectSVGViewbox, isEqual);
  const selectedGroupIds = useSelector(selectSelectedGroupIds);
  const selectedGroups = useSelector(selectSelectedGroups, isEqual);
  const selectedType = selectedGroups.some(
    (s) => s?.type === PATH_TYPE.REFERENCE
  )
    ? PATH_TYPE.REFERENCE
    : PATH_TYPE.DESIGN;

  const isLoggedIn = useSelector(selectLoggedIn);

  const isViewOnly = !useSelector(selectIsEditMode);
  const syncStatus = useSelector(selectSyncStatus);

  const isLoading = useSelector(selectLoading);

  const handleCancelInsertText = () => {
    dispatch(toggleTextInsert(false));
  };

  const handleUpdateSVGTextGeometry = (
    svg: AddSvgParams,
    groupId?: GroupId
  ) => {
    if (groupId) {
      addGeometryAction.updateText(svg, [groupId]);
    } else {
      addGeometryAction.updateText(svg, selectedGroupIds);
    }
    uiModeAction.activatePropertiesPanel();
  };

  const handleAddSVGTextGeometry = (
    selectedSVG: AddSvgParams,
    isEditing: boolean,
    groupId?: GroupId
  ) => {
    if (isEditing) {
      return handleUpdateSVGTextGeometry(selectedSVG, groupId);
    }

    addGeometryAction.addSvg(
      'new-text',
      selectedSVG,
      getPositionFromSvgViewbox(svgViewBox)
    );

    uiModeAction.activatePropertiesPanel();
  };

  const renderModal = () => {
    if (dev.ignoreLogin() || !modal) {
      return null;
    }

    switch (modal.type) {
      case MODAL_TYPES.SIGNIN_MODAL:
        return <LoginModal />;
      case MODAL_TYPES.EDU_MODAL:
        return <EducationalModal />;
      case MODAL_TYPES.VIEWONLY_MODAL:
        return <ViewOnlyModal />;
      case MODAL_TYPES.OFFLINE_MODAL:
        return <OfflineModal />;
      case MODAL_TYPES.ENABLE_POPUPS_MODAL:
        return <EnablePopupsModal />;
      case MODAL_TYPES.SYNC_MODAL:
        return <SyncModal />;
      case MODAL_TYPES.INVALID_MODAL:
        return <InvalidModal />;
      case MODAL_TYPES.REFERENCE_MODAL:
        return <ReferenceModal />;
      case MODAL_TYPES.INVALID_CHARACTERS_MODAL:
        return <InvalidRenameModal />;
      case MODAL_TYPES.EXPORT_MODAL:
        return <ExportProgressModal {...modal.props} />;
      default:
        return null;
    }
  };

  const showEditPathPanel = () => {
    return (
      !isShowingSelectionEditor &&
      isPlanMode &&
      isShowingEditSelectionProperties
    );
  };

  const showEditGroupPanel = () => {
    if (isPreviewView && isMobile(window)) {
      return hasSelection;
    }
    return (
      isDefaultMode &&
      !isShowingSelectionEditor &&
      selectedType !== PATH_TYPE.REFERENCE &&
      isShowingEditSelectionProperties &&
      !isShapeShifterMode
    );
  };

  // mount
  useEffect(() => {
    dispatch(
      resize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    );
    SvgOps.initShapeParser('svg-parse');
    if (window.location.href.includes('?success')) {
      openFlow(FLOW_IDS.START_TRIAL);
    }

    // listener to close userflow speech bubbles
    const userflowVoidClick = (e: MouseEvent) => {
      const element = e?.target as HTMLElement;
      if (
        element &&
        element?.classList?.contains('userflowjs-modal-backdrop--visible')
      ) {
        closeAll();
      }
    };

    document.addEventListener('click', userflowVoidClick);

    svgCache.setDispatch(dispatch as AppDispatch);

    const warnOnExit = () => {
      if (syncStatus === 'pending') {
        const confirmationMessage =
          'It looks like you have been editing something. ' +
          'If you leave before saving, your changes will be lost.';

        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }
      return true;
    };

    window.addEventListener('beforeunload', warnOnExit);
    return () => {
      document.removeEventListener('click', userflowVoidClick);
      window.removeEventListener('beforeunload', warnOnExit);
    };
  }, []);

  useEffect(() => {
    if (isMobile(window) && selectedGroupIds.length > 0) {
      toggleHelpMenuVisibility(false);
    } else {
      toggleHelpMenuVisibility(true);
    }
  }, [selectedGroupIds]);

  // active classes
  const containerCx = classNames('sherpa-container', {
    'sherpa-container--blurred':
      isShapeShifterMode || isShowingImportMode || isShowingTextInsert,
  });

  const components = [
    isLoading && (
      <div className='sherpa-container--loading'>
        <Loading type='domino' withOverlay />
      </div>
    ),
    renderModal(),

    !isShapeShifterMode && <ModeSelectionMenu />,

    <ApplicationMenuLauncher />,
    <ApplicationMenu />,
    (isDefaultMode || isPlanMode) && <HelpMenu />,
    (isDefaultMode || isPlanMode) && !isShapeShifterMode && isDefaultView && (
      <UndoRedo />
    ),

    !isShowingTextInsert && !isShowingImportMode && <FitToView />,

    // default mode overlay menus
    isDefaultMode && isDefaultView && isShowingIconSearch && <FindArtPanel />,
    isDefaultMode && isDefaultView && isShowingFileImport && (
      <FileImportModal />
    ),

    //bottom side menus
    isDefaultMode && isDefaultView && !isShapeShifterMode && hasSelection && (
      <DesignActionMenu edge='bottom' />
    ),
    isPreviewView && <PreviewActionMenu edge='bottom' />,

    isDefaultMode &&
      isDefaultView &&
      !isShowingSelectionEditor &&
      !isShowingShapeCreator &&
      !hasSelection && (
        <DefaultActionMenu edge='left' disableAll={isViewOnly} />
      ),

    isDefaultMode &&
      isDefaultView &&
      !isShowingSelectionEditor &&
      isShowingShapeCreator &&
      !hasSelection && <BasicShapeCreatorActionMenu edge='left' />,

    // right side menus
    !isShapeShifterMode && !isShowingHelpMenu && (
      <GroupSelectionActionMenu edge='right' />
    ),

    // misc menus
    selectFeatures(entitlements.SECRET_MENU) &&
      isDefaultMode &&
      isDefaultView &&
      !hasSelection && <SecretMenu />,

    showEditGroupPanel() && <EditGroupPropertiesPanel />,
    showEditPathPanel() && <EditPathPropertiesPanel />,

    selectFeatures(entitlements.SELECTION_MANAGER) &&
      isShowingSelectionEditor &&
      isDefaultView &&
      !isReviewMode && <SelectionEditor />,

    // editing text values
    isShowingTextInsert && (
      <TextTool
        onCommit={handleAddSVGTextGeometry}
        onCancel={handleCancelInsertText}
      />
    ),

    isPreviewView && <PreviewLabel />,
  ];

  return (
    <>
      <HoverStateHelper />

      <GuidedTour />

      <div className='sherpa-container--ui-elements'>
        {components.map((component, idx) =>
          !component ? null : <component.type {...component.props} key={idx} />
        )}
      </div>

      <div>
        <div className={containerCx}>
          <div className='sherpa-container--workspace'>
            <CanvasContainer />
            {isShowingProgress && (
              <CircularProgress
                variant='determinate'
                value={50}
                style={{ position: 'absolute', left: '50%', top: '50%' }}
              />
            )}
          </div>
        </div>

        {isLoggedIn && <SherpaSnackbar />}

        <div
          id='svg-parse'
          style={{ position: 'absolute', width: '0', height: '0' }}
        />

        <RotationHandleCursorHelper />
      </div>
    </>
  );
}
