import Viewport from '../State/Helpers/Viewport';

type Props = {
  viewport: Viewport;
};

export default function BigRedBox(props: Props) {
  const { viewport } = props;
  return (
    <>
      <g className='rect'>
        <rect
          x={viewport.x}
          y={viewport.y}
          width={viewport.width}
          height={viewport.height}
          fill='#FF0000'
        />
      </g>
    </>
  );
}
