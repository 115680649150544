import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createStandaloneGroupSvg } from '@/Geometry/SvgGroupOps';
import { IPoint } from '@shapertools/sherpa-svg-generator/Point';
import { RootState } from '../store';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { selectTessellationFeatureFlag } from './FeatureFlagsSlice';

export interface ImportState {
  importedGroup: SvgGroup | undefined;
  importPosition: IPoint | null;
  fromTrace: boolean;
}

export const initialState: ImportState = {
  importedGroup: undefined,
  fromTrace: false,
  importPosition: null,
};

const slice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    setImportedGroup(
      state,
      action: PayloadAction<{ importGroup: object; fromTrace: boolean }>
    ) {
      state.importedGroup = action.payload.importGroup as SvgGroup;
      state.fromTrace = action.payload.fromTrace;
    },

    setImportPosition(state, action: PayloadAction<IPoint | null>) {
      state.importPosition = action.payload;
    },

    clearImportedGroup(state) {
      state.importedGroup = undefined;
    },
  },
});

export const selectImportedGroup = (state: RootState) =>
  state.import.importedGroup;
export const selectImportPosition = (state: RootState) =>
  state.import.importPosition;
export const selectImportedGroupSvg = (state: RootState) => {
  const importedGroup = selectImportedGroup(state);
  const hasTessellationFeatureFlag = selectTessellationFeatureFlag(state);
  if (importedGroup) {
    return createStandaloneGroupSvg(
      importedGroup as SvgGroup,
      hasTessellationFeatureFlag
    );
  }
};
export const selectIsFromTrace = (state: RootState) => state.import.fromTrace;

export const { setImportedGroup, clearImportedGroup, setImportPosition } =
  slice.actions;
export default slice.reducer;
