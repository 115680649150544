import React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// consts
import { GROUP_ANCHORS } from '@/Constants/UI';

// selectors
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';

// actions
import UIModeAction from '@/Actions/UIMode';

// components
import FloatingPanel, {
  useFloatingPanelContext,
} from '@/Components/FloatingPanel/FloatingPanel';
import TranslationText from '../../../TranslationText/TranslationText';
import ResponsiveContent from '../../../ResponsiveContent';
import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';
import classNames from 'classnames';

export default function AnchorSelectionEditor() {
  const selectedGroups = useSelector(selectSelectedGroups);
  const isSingleSelection = selectedGroups.length === 1;
  const uiMode = useAction(UIModeAction);
  const [selectedGroup] = selectedGroups;
  const disabled =
    PATH_TYPES[selectedGroup?.type]?.propertyEditingDisabled ||
    useFloatingPanelContext() ||
    false;

  // activate the anchor editing mode
  function onActivateAnchorSelectionMode() {
    if (!disabled) {
      uiMode.toAnchorSelection();
    }
  }

  // if this is a single selected object and has an anchor
  let activeAnchor = 'center';
  if (isSingleSelection) {
    activeAnchor = selectedGroups[0].anchor || activeAnchor;
  }

  const anchorCx = classNames('properties-panel--anchor-selection--status', {
    disabled: disabled,
  });

  const anchorStatusCx = classNames(
    'properties-panel--anchor-selection--grid',
    {
      disabled: disabled,
    }
  );

  function renderAnchorStatus() {
    return (
      <div className={anchorStatusCx}>
        {GROUP_ANCHORS.map((item) => (
          <div key={item} className={item === activeAnchor ? 'selected' : ''}>
            &bull;
          </div>
        ))}
      </div>
    );
  }

  const anchorStatus = <div className={anchorCx}>{renderAnchorStatus()}</div>;

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label
        onClick={onActivateAnchorSelectionMode}
        lead={anchorStatus}
        hideOnDesktop
      />
      <ResponsiveContent>
        <div
          className='properties-panel--anchor-selection'
          onClick={onActivateAnchorSelectionMode}
        >
          {anchorStatus}
          <div className='properties-panel--anchor-selection--label'>
            <TranslationText i18nKey='anchor'>Anchor</TranslationText>
          </div>
        </div>
      </ResponsiveContent>
    </FloatingPanel.Group>
  );
}
