import getShaperXmlAttributes from './getShaperSvgXmlAttributes';
import { AnchorOptions, SvgTypeOptions } from './ShaperSvgXmlAttributes';

/*
  Origin workpieces with grids are exported with a custom anchor point at the grid origin. Origin workpieces are also transformed such that the viewbox origin and viewbox axes align with the grid origin and angle
  
  The former approach is for compatibility with other programs
  
  The latter approach is for use with Studio. (Studio cannot currently work with custom anchors in their native form, i.e. we cannot currently store red triangles in the Canvas or export them. Instead, Studio adds a custom anchor at the canvas origin on export.)

  We need to remove the custom anchor when importing an Origin workpiece, otherwise this CAP will be converted to a triangular guide path. On re-export, Studio will insert a new CAP at the canvas origin, which is the same location as the removed CAP.
*/

function removeOriginCustomAnchorFromImportedWorkpiece(svgStr: string) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(svgStr, 'image/svg+xml');
  const rootElement = xmlDoc.documentElement;
  const { svgType } = getShaperXmlAttributes(rootElement);

  const isOriginWorkpiece = svgType?.includes(SvgTypeOptions.originWorkpiece);

  if (!isOriginWorkpiece) {
    return svgStr;
  }

  // Let's do a BFS search, because the workpiece CAP is likely to be at the top-level
  const nodeQueue: Element[] = Array.from(rootElement.children);
  while (nodeQueue.length > 0) {
    const currentNode = nodeQueue.shift();

    if (currentNode === undefined) {
      continue;
    }

    if (currentNode?.hasChildNodes()) {
      nodeQueue.push(...currentNode.children);
    }

    const { anchor } = getShaperXmlAttributes(currentNode);
    if (anchor && anchor === AnchorOptions.customAnchorPoint) {
      currentNode.remove();
      // There should only be one custom anchor element right now, so we can stop now. But this may change in the future.
      break;
    }
  }

  // Now serialize the modified SVG DOM to a string for parsing
  const serializer = new XMLSerializer();
  return serializer.serializeToString(xmlDoc);
}

export default removeOriginCustomAnchorFromImportedWorkpiece;
