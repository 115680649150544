import BaseAction from '@/Actions/BaseAction';
import {
  selectSessionWorkspaceInfo,
  setSherpaInitialized,
} from '@/Redux/Slices/SherpaContainerSlice';
import { selectFeaturesByMode, setAlert } from '@/Redux/Slices/UISlice';
import {
  updateServerSHA,
  getLoginState,
  selectShaperHubLoggedIn,
  selectSyncUrl,
  getShaperSubscriptions,
  getShaperHubExternalItem,
  getLocale,
  getShaperHubFiles,
  selectLocale,
  getUser,
  getExportAccess,
  getFirstWorkspace,
} from '@/Redux/Slices/ShaperHubSlice';
import { open, create, setSandboxMode } from '@/Sync/SyncThunks';
import { checkBrowserForSavedState } from '@/Redux/localStorage';
import ModalActions from './Modal';
import { setI18nLanguage } from '../i18n';
import AlertAction from './Alert';
import { initializeFonts } from '@/Helpers/FontHelper';
import { SvgOps } from '@/Geometry/SvgParser';
import {
  setEnabled,
  setSyncUrl,
  setWorkspaceIdFromUrlParam,
} from '@/Redux/Slices/SyncSlice';
import { entitlements } from '@/Helpers/Entitlements';
import {
  FilesystemObject,
  UserspaceExternalItemFileObject,
} from '@/@types/shaper-types';
import UIModeAction from './UIMode';
import { getStudioShare } from '@/ShaperHub/ShaperHubThunks';
import { setStudioPricesForUserFlow } from '@/Utility/studio-prices';

export type StudioUrlPathParams = {
  path?: string;
  newWorkspace?: boolean;
  workspaceId?: string;
  blobId?: string;
  duplicate?: string;
  projectId?: string;
  preview?: boolean;
};

export default class InitializeAppAction extends BaseAction {
  modalAction: ModalActions;
  alertAction: AlertAction;
  uiModeAction: UIModeAction;

  constructor(...args: ConstructorParameters<typeof BaseAction>) {
    super(...args);
    this.modalAction = this.createAction(ModalActions);
    this.alertAction = this.createAction(AlertAction);
    this.uiModeAction = this.createAction(UIModeAction);
  }

  init = async (studioUrlParams: StudioUrlPathParams) => {
    const { dispatch } = this;

    const projectId = studioUrlParams.projectId;

    await SvgOps.doInit();
    await dispatch(updateServerSHA());
    await dispatch(getLocale());

    const browserHasSavedState = checkBrowserForSavedState();
    await dispatch(getLoginState());
    const loggedIn = this.useSelector(selectShaperHubLoggedIn);
    const useSync = this.useSelector((state) =>
      selectFeaturesByMode(state, entitlements.SYNC)
    );
    const syncUrl = this.useSelector(selectSyncUrl);
    const locale = this.useSelector(selectLocale);

    if (loggedIn) {
      await dispatch(getUser());
      await dispatch(getExportAccess());
      await dispatch(getShaperSubscriptions());
    }

    setI18nLanguage(locale.language);

    setStudioPricesForUserFlow(locale);

    if (useSync) {
      dispatch(setSyncUrl(syncUrl));
      if (loggedIn) {
        dispatch(setEnabled(true));
        if (projectId) {
          this.openProject(projectId);
        } else {
          const newWorkspacePath = studioUrlParams.path;
          const newWorkspace = studioUrlParams.newWorkspace;
          const workspaceId =
            studioUrlParams.workspaceId ??
            (await this.getMostRecentActiveWorkspaceId());
          const blobId = studioUrlParams.blobId;
          const isDuplicate = studioUrlParams.duplicate;
          const blobImportIntoNewCanvas =
            (blobId !== undefined || blobId === null) &&
            studioUrlParams.workspaceId === undefined;

          await dispatch(getFirstWorkspace());

          //Create new workspace if:
          // 1. browser has saved state, create workspace and sync with current client state. It has already been purged from localStorage and sessionStorage at this point.
          // 2. newWorkspace flag is set
          // 3. If workspaceId is not provided and there are no recently modified workspaces to open.

          if (
            browserHasSavedState === true ||
            newWorkspace ||
            blobImportIntoNewCanvas ||
            !workspaceId
          ) {
            await dispatch(
              create({
                ...(newWorkspacePath && { path: newWorkspacePath }),
                ...(blobId && { blobId }),
              })
            );
          } else {
            await dispatch(
              open({
                workspaceId,
                ...(blobId && { blobId }),
              })
            );
            await dispatch(getShaperHubExternalItem(workspaceId));
            if (isDuplicate) {
              this.alertAction.setDuplicateAlert();
            }
          }
        }
      }
    }
    if (!useSync || !loggedIn) {
      if (studioUrlParams.projectId) {
        this.openProject(studioUrlParams.projectId);
      } else {
        if (studioUrlParams.workspaceId) {
          dispatch(setWorkspaceIdFromUrlParam(studioUrlParams.workspaceId));
        }
        dispatch(setSandboxMode());
        this.modalAction.openSignInModal();
      }
    }
    dispatch(setSherpaInitialized());
    await initializeFonts();
  };

  openProject = async (projectId: string) => {
    const { dispatch } = this;

    dispatch(setEnabled(true));
    const { project: studioShare } = await dispatch(
      getStudioShare({ projectId })
    ).unwrap();
    const workspaceId = studioShare.files[0]?.externalItemId;
    if (workspaceId) {
      await dispatch(
        open({
          workspaceId,
          isPreview: true,
        })
      );
    } else {
      dispatch(
        setAlert({
          msg: 'Unable to load project',
          i18nKey: 'unable-load-project',
          type: 'error',
          icon: 'alert-warning',
        })
      );
    }
  };

  getMostRecentActiveWorkspaceId = async () => {
    try {
      const files = await this.dispatch(getShaperHubFiles()).unwrap();
      const isUserspaceExternalItemFileObject = (
        f: FilesystemObject
      ): f is UserspaceExternalItemFileObject => f.type === 'external';

      const sessionWorkspaceInfo = this.useSelector(selectSessionWorkspaceInfo);

      const sortedFiles = files
        .filter(
          (f) =>
            isUserspaceExternalItemFileObject(f) &&
            f.externalItemType === 'studio-workspace' &&
            !!f.externalItemId
        )
        .map((f) => ({
          ...f,
          modifiedAtTimestamp: new Date(f.modified).getTime(),
          viewedAtTimestamp:
            sessionWorkspaceInfo?.workspaceId === f.externalItemId
              ? sessionWorkspaceInfo.viewedAtTimestamp
              : 0,
        }))
        .sort((fileA, fileB) => {
          const getLatestTimestamp = (file: typeof fileA) =>
            Math.max(file.modifiedAtTimestamp, file.viewedAtTimestamp);

          return getLatestTimestamp(fileB) - getLatestTimestamp(fileA);
        });

      const mostRecentWorkspace = sortedFiles.length ? sortedFiles[0] : null;
      return mostRecentWorkspace?.externalItemId || null;
    } catch {
      return null;
    }
  };
}
