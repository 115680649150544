import { actions as uiActions } from '@/Redux/Slices/UISlice';
import BaseAction from './BaseAction';
import { Theme } from '@/@types/shaper-types';

export default class SetThemeAction extends BaseAction {
  set(theme: Theme) {
    const { dispatch } = this;
    dispatch(uiActions.setTheme(theme));
  }
}
