import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { intersection } from 'lodash';
import { Draft } from 'immer';
import { RootState } from '@/Redux/store';

// actions
import { ActionLookupFunction } from '@/CanvasContainer/CanvasActions';

// slices
import { CanvasState } from '@/Redux/Slices/CanvasSlice';
import {
  selectGetShapesForPoints,
  updateTransientState,
} from '@/Redux/Slices/LineToolSlice';

// helpers
import { deleteSvgGroup, updateSvgGroup } from '@/Geometry/CanvasOps';
import {
  applyBakedShapeToDraft,
  bakeShape,
  createBakedShape,
  newPoint,
  newShape,
} from './helpers';

// types
import {
  AddPointToPolylineShapePayload,
  ClosePolylineShapePayload,
  CreatePolylineShapePayload,
  IPointGroup,
  IShapeGroup,
  MergePolylineShapesPayload,
  RemovePolylinePointsPayload,
  SetPolylinePointPositionsPayload,
  UpdatePolylinePointsPayload,
} from './types';
import { SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

export const actionLookup: {
  [key: string]: (...args: any) => ActionLookupFunction;
} = {
  // handles moving and updating point positions and rebaking associated shapes
  setPolylinePointPositions: ({
    payload,
  }: PayloadAction<SetPolylinePointPositionsPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>, state: RootState) => {
      const { points, relative = false } = payload;
      const pointIds = Object.keys(points) as string[];

      // get all associated shapes
      const shapes = selectGetShapesForPoints(state)([
        ...pointIds,
      ]) as IShapeGroup[];

      // set all of the new positions
      for (const id of pointIds) {
        draft.canvas = updateSvgGroup(
          draft.canvas,
          id,
          {
            key: relative
              ? SvgGroupUpdateKey.RelativePosition
              : SvgGroupUpdateKey.AbsolutePosition,
            value: points[id] as Point,
          },
          false
        );
      }

      // bake all shapes
      for (const shape of shapes) {
        const result = createBakedShape(state, shape.id, { points });
        applyBakedShapeToDraft(draft, shape.id, result);
      }
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: any) => {
      return actionPayload;
    },
  }),

  // includes another point into a polyline shape
  addPointToPolylineShape: ({
    payload,
  }: PayloadAction<AddPointToPolylineShapePayload>) => ({
    stateMutator: (draft: Draft<CanvasState>, state: RootState) => {
      const { position, parentId, atFront } = payload;
      const point = newPoint(draft, {
        position: new Point(position.x, position.y),
        belongsTo: parentId,
      }) as IPointGroup;
      const parent = draft.canvas.svgGroupSet.find(
        (group) => group.id === parentId
      ) as IShapeGroup;

      // update the points
      parent.tool.params.points = atFront
        ? [point.id, ...parent.tool.params.points]
        : [...parent.tool.params.points, point.id];

      // bake with the new point data
      bakeShape(state, draft, parentId, {
        points: { [point.id]: point.position },
        pointIds: [...parent.tool.params.points],
      });
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: any) => {
      return actionPayload;
    },
  }),

  // creates an entirely new polyline
  createPolylineShape: ({
    payload,
  }: PayloadAction<CreatePolylineShapePayload>) => ({
    stateMutator: (draft: Draft<CanvasState>, state: RootState) => {
      const { position } = payload;
      const shape = newShape(draft) as IShapeGroup;
      const point = newPoint(draft, {
        position: new Point(position.x, position.y),
        belongsTo: shape.id,
      }) as IPointGroup;
      shape.tool.params.points = [point.id];

      // for selectors
      updateTransientState({
        mostRecentlyAddedShapeID: shape.id,
        mostRecentlyAddedPointID: point.id,
      });
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: any) => {
      return actionPayload;
    },
  }),

  // assigns parameters to a point - not sure if this
  // is really needed anymore
  updatePolylinePoints: ({
    payload,
  }: PayloadAction<UpdatePolylinePointsPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>, state: RootState) => {
      const { points } = payload;
      const parents = {} as { [id: string]: boolean };

      // update each point and gather the parents
      for (const point of points) {
        const group = draft.canvas.svgGroupSet.find(
          ({ id }) => id === point.id
        ) as IPointGroup;

        // save a parent reference
        parents[group.tool.params.belongsTo] = true;

        // apply each change
        for (const key of Object.keys(point)) {
          if (['id'].includes(key)) {
            continue;
          }

          // apply change
          // @ts-ignore -- just need to assign whatever is passed over
          group[key] = point[key];
        }
      }
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: any) => {
      return actionPayload;
    },
  }),

  // deletes polyline points and shapes as required
  removePolylinePoints: ({ payload }: PayloadAction<any>) => ({
    stateMutator: (draft: Draft<CanvasState>, state: RootState) => {
      const { pointId, points } = payload;
      const toRemove = pointId ? [pointId] : points;
      const bake = [] as IShapeGroup[];

      // remove all points that need to be removed from each shape
      draft.canvas.svgGroupSet.forEach((group) => {
        const shape =
          (group as IShapeGroup).tool.type === Shape.SHAPE
            ? (group as IShapeGroup)
            : null;

        // check for points
        const hasPoints =
          shape && intersection(shape.tool?.params?.points, toRemove).length;

        // with points, update
        if (hasPoints) {
          shape.tool.params.points = shape.tool.params.points.filter(
            (id) => !toRemove.includes(id)
          );

          // if this removed all polyline points the shape needs to be removed as well
          if (!shape.tool.params.points.length) {
            toRemove.push(shape.id);
            return;
          }

          // if there's only two or less points, the shape is no longer closed
          if (shape.tool.params.points.length <= 2) {
            shape.tool.params.closed = false;
          }

          // save everything that needs to be baked again
          bake.push(shape);
        }
      });

      // then remove remaining points (and empty shapes)
      draft.canvas.svgGroupSet = draft.canvas.svgGroupSet.filter(
        (group) => !toRemove.includes(group.id)
      );

      // bake all shapes
      bake.forEach((group) => {
        bakeShape(state, draft, group.id, {
          pointIds: [...group.tool.params.points],
          closed: group.tool.params.closed,
        });
      });
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: any) => {
      return actionPayload;
    },
  }),

  // closes a polyline shape and cleans up points as required
  closePolylineShape: ({
    payload,
  }: PayloadAction<ClosePolylineShapePayload>) => ({
    stateMutator: (draft: Draft<CanvasState>, state: RootState) => {
      const { shapeID, removePointID } = payload;

      // close the shape
      const shape = draft.canvas.svgGroupSet.find(
        (group) => group.id === shapeID
      ) as IShapeGroup;
      shape.tool.params.closed = true;

      // if there's a point being removed as well
      if (removePointID) {
        // remove the point from the group
        shape.tool.params.points = shape.tool.params.points.filter(
          (id) => id !== removePointID
        );

        // also remove the object itself
        draft.canvas = deleteSvgGroup(draft.canvas, removePointID);
      }

      // bake with the new point data
      bakeShape(state, draft, shapeID, {
        pointIds: [...shape.tool.params.points],
        closed: true,
      });
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: any) => {
      return actionPayload;
    },
  }),

  mergePolylineShapes: ({
    payload,
  }: PayloadAction<MergePolylineShapesPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>, state: RootState) => {
      const { dispose, mergeTo, points, mergedPointId } = payload;
      draft.canvas = deleteSvgGroup(draft.canvas, dispose.id);
      draft.canvas = deleteSvgGroup(draft.canvas, mergedPointId);

      // update all points
      for (const group of draft.canvas.svgGroupSet) {
        // if a point
        if (points.includes(group.id)) {
          (group as IPointGroup).tool.params.belongsTo = mergeTo.id;
        }
        // if it's the group being updated
        else if (group.id === mergeTo.id) {
          (group as IShapeGroup).tool.params = {
            points: [...points],
            closed: false,
          };

          // save this for baking later
          bakeShape(state, draft, mergeTo.id, {
            pointIds: [...points],
            closed: false,
          });
        }
      }
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: any) => {
      return actionPayload;
    },
  }),
};

export function getActionLookup(key: string | number) {
  return actionLookup[key];
}

export const setPolylinePointPositions =
  createAction<SetPolylinePointPositionsPayload>(
    'polyline/setPolylinePointPositions'
  );

export const addPointToPolylineShape =
  createAction<AddPointToPolylineShapePayload>(
    'polyline/addPointToPolylineShape'
  );

export const createPolylineShape = createAction<CreatePolylineShapePayload>(
  'polyline/createPolylineShape'
);

export const updatePolylinePoints = createAction<UpdatePolylinePointsPayload>(
  'polyline/updatePolylinePoints'
);

export const removePolylinePoints = createAction<RemovePolylinePointsPayload>(
  'polyline/removePolylinePoints'
);

export const closePolylineShape = createAction<ClosePolylineShapePayload>(
  'polyline/closePolylineShape'
);

export const mergePolylineShapes = createAction<MergePolylineShapesPayload>(
  'polyline/mergePolylineShapes'
);

export const actions = {
  setPolylinePointPositions,
  addPointToPolylineShape,
  createPolylineShape,
  updatePolylinePoints,
  removePolylinePoints,
  mergePolylineShapes,
  closePolylineShape,
};
