// helpers
import { invert } from '@shapertools/sherpa-svg-generator/Matrix33';
import { transform } from '@/Geometry/PointOps';
import { isNumber } from 'lodash';

//state
import UIState from '@/UILayer/State/UIState';

// selectors
import { selectViewport } from '@/Redux/Slices/ViewportSlice';
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import { setPolylinePointPositions } from '@/LineTool/LineToolActions';
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';
import { GroupId, selectSelectionBounds } from '@/Redux/Slices/SelectionSlice';
import {
  selectGetPointChangesFromUpdateSet,
  selectSelectedLine,
} from '@/Redux/Slices/LineToolSlice';

// actions
import UpdateSvgGroupAction from '@/Actions/UpdateSvgGroup';
import BaseAction from './BaseAction';
import LineToolAction from './LineTool';
import SetSelectionAction from './SetSelection';

import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';
import { IPoint, Point } from '@shapertools/sherpa-svg-generator/Point';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';
import AlignmentHelper from '@/Helpers/Alignment';
import { SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';
import { UpdateSvgGroupPayload } from '@/CanvasContainer/CanvasActions';
import { Shape, SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { selectTessellationFeatureFlag } from '@/Redux/Slices/FeatureFlagsSlice';

type Options = {
  applyTemporaryTranslations: boolean;
  alignmentHelper?: AlignmentHelper;
};

type SetAbsoluteParams = {
  relativeTo?: IPoint;
};

type ResolveParams = {
  reason?: 'nudge' | undefined;
};

// handles performing a translate for groups
export default class TranslateGroupsAction extends BaseAction {
  selectedGroupIds: GroupId[];
  options: Options;
  alignmentHelper?: AlignmentHelper;
  x: number;
  y: number;

  constructor(
    dispatch: AppDispatch,
    useSelector: UseSelector,
    selection: GroupId[],
    options: Options = { applyTemporaryTranslations: true }
  ) {
    super(dispatch, useSelector);
    // assign props
    this.selectedGroupIds = selection;
    this.options = options;
    this.alignmentHelper = options.alignmentHelper;
    this.x = 0;
    this.y = 0;
  }

  // TODO: this performs the resolution step as well - the other translate
  // function was designed with pixels in mind - We really ought to
  // refactor all of this
  setAbsolute(
    newX: number,
    newY: number,
    { relativeTo }: SetAbsoluteParams = {}
  ) {
    const { useSelector } = this;
    const getGroupById = useSelector(selectGetGroupById);
    const selectionBounds = useSelector(selectSelectionBounds);
    const hasTessellationFeatureFlag = this.useSelector(
      selectTessellationFeatureFlag
    );

    // check if this is relative to a point
    let { x: currentX = NaN, y: currentY = NaN } = relativeTo || {};
    if (isNaN(currentX) || isNaN(currentY)) {
      currentX = (selectionBounds.left + selectionBounds.right) * 0.5;
      currentY = (selectionBounds.top + selectionBounds.bottom) * 0.5;
    }

    // calculate deltas
    const deltaX = !isNumber(newX) ? 0 : newX - currentX;
    const deltaY = !isNumber(newY) ? 0 : newY - currentY;

    // start by removing the points and doing them separately
    const points: { [key: string]: IPoint } = {};
    const ids = [...this.selectedGroupIds];

    // check each ID
    for (let i = ids.length; i-- > 0; ) {
      const group = getGroupById(ids[i]);
      if (group.tool.type === Shape.POINT) {
        points[group.id] = { x: newX ?? currentX, y: newY ?? currentY };

        // remove this ID since it shouldn't be updated
        ids.splice(i, 1);
      }
    }

    // move the points, if any
    if (Object.keys(points).length) {
      this.dispatch(setPolylinePointPositions({ points }));
    }

    // apply if there are actually changes
    if (ids.length && (deltaX || deltaY)) {
      const batch = ids.map(
        (id): UpdateSvgGroupPayload => ({
          id,
          update: {
            key: SvgGroupUpdateKey.RelativePosition,
            value: new Point(deltaX, deltaY),
          },
          hasTessellationFeatureFlag,
        })
      );

      // apply updates
      const update = this.createAction(UpdateSvgGroupAction);
      update.apply(batch);
    }

    UIState.reset();
  }

  // applies translations and updated existing layers
  translateBy(
    dxPixels: number,
    dyPixels: number,
    {
      snapToObjects,
      snapToGrid,
      updateUI = true,
    }: {
      snapToObjects?: boolean;
      snapToGrid?: boolean;
      updateUI?: boolean;
    } = {}
  ) {
    const { useSelector } = this;
    const viewport = useSelector(selectViewport);
    const selectedGroups = useSelector(selectSelectedGroups);
    const cancelResolve = selectedGroups.some(
      (sg) => PATH_TYPES[sg.type].selectability === false
    );

    if (!cancelResolve) {
      let targetX = dxPixels;
      let targetY = dyPixels;

      // check for alignment guides
      let guides;
      if (this.alignmentHelper && (snapToObjects || snapToGrid)) {
        const snap = this.alignmentHelper.update(dxPixels, dyPixels, {
          snapToObjects,
          snapToGrid,
        });
        [targetX, targetY] = snap.applyTo(targetX, targetY);

        // save alignment guides
        guides = snap.guides;
      }

      // convert to canvas units
      const scale = viewport.screenToCanvasTransform[0][0];
      const x = targetX * scale;
      const y = targetY * scale;

      // save the current values
      this.x = targetX;
      this.y = targetY;

      if (updateUI) {
        const ui = UIState.update({
          translate: { x: 0, y: 0 },
          groups: {},
          alignmentGuides: guides,
        });

        // include each group
        for (const id of this.selectedGroupIds) {
          ui.groups[id] = {
            translate: { x, y },
          };

          ui.translate = { x, y };
        }

        ui.apply();
      }
    }
  }

  tryMergePoints(point: SvgGroup) {
    const { x, y, useSelector } = this;
    const viewport = useSelector(selectViewport);
    const lineTool = this.createAction(LineToolAction);
    const MERGE_DISTANCE = 0.1;

    // determine the new location
    const translate = new Point(x, y); // PointOps.createPoint({ x, y });
    const screenTransform = viewport.canvasToScreenTransform;
    const inverse = invert(screenTransform);
    inverse[0][2] = 0;
    inverse[1][2] = 0;

    // get the new point
    const target = transform(translate, inverse); // { mtx: inverse, pt: translate });
    const compare = {
      x: point.position.x + target.x,
      y: point.position.y + target.y,
    };

    // get all points
    const results = lineTool.getPointDistanceFrom(compare);

    // start checking for a point that can be merged
    for (const result of results) {
      // nothing can be merged
      if (result.distance > MERGE_DISTANCE) {
        return { merge: false };
      }

      // check if this is an allowed point
      if (result.point.id !== point.id) {
        return { merge: true, id: result.point.id };
      }
    }

    return { merge: false };
  }

  // creates a manifest of changes
  resolve({ reason }: ResolveParams = {}) {
    const { x, y, useSelector } = this;
    const viewport = useSelector(selectViewport);
    const hasTessellationFeatureFlag = useSelector(
      selectTessellationFeatureFlag
    );
    const transformVal = viewport.canvasToScreenTransform;
    const getGroupById = useSelector(selectGetGroupById);
    const selectedLine = useSelector(selectSelectedLine);
    const getPointUpdates = useSelector(selectGetPointChangesFromUpdateSet);
    const isNudge = reason === 'nudge';
    const lineTool = this.createAction(LineToolAction);
    const setSelection = this.createAction(SetSelectionAction);

    // in the event that this is a single point, check for special rules
    if (this.selectedGroupIds.length === 1) {
      const id = this.selectedGroupIds[0];
      const point = getGroupById(
        (id as unknown as { groupId: string })?.groupId || id
      );
      const isPoint = point?.tool?.type === Shape.POINT;
      if (isPoint && !isNudge) {
        const { merge, id: targetId } = this.tryMergePoints(point);

        // when merging, we need to remove some points
        if (merge) {
          lineTool.mergePoints(id, targetId);
          UIState.reset();
          setSelection.setMostRecentlyMovedPoint(targetId);
          return;
        }

        // mark this as moved
        setSelection.setMostRecentlyMovedPoint(id);
      }
    }
    // when moving multiple shapes or points, always clear
    // the most recently moved point
    else {
      setSelection.setMostRecentlyMovedPoint(null);
    }

    // check for which groups need the transform
    const changes: { [key: string]: Point } = {};
    const translate = new Point(x, y);
    for (const selectedGroupId of this.selectedGroupIds) {
      if (changes[selectedGroupId] === undefined) {
        // invert the scale
        const inverse = invert(transformVal);
        inverse[0][2] = 0;
        inverse[1][2] = 0;

        // get the new point
        const point = transform(translate, inverse);
        changes[selectedGroupId] = point;
      }
    }

    // update the change set
    const { points } = getPointUpdates(changes, { mutateChangeSet: true });

    // clear UI layer changes
    UIState.reset();

    // apply the changes
    const updateBatch = Object.keys(changes).map(
      (groupChangeKey): UpdateSvgGroupPayload => ({
        id: groupChangeKey,
        update: {
          key: SvgGroupUpdateKey.RelativePosition,
          value: changes[groupChangeKey],
        },
        hasTessellationFeatureFlag,
      })
    );

    // apply point/shape baking, if any
    if (Object.keys(points).length) {
      this.dispatch(setPolylinePointPositions({ points }));
    }

    // apply updates, if any
    if (Object.keys(changes).length) {
      const update = this.createAction(UpdateSvgGroupAction);
      update.apply(updateBatch);
    }

    // if this is a selected line, reset the origins for the points
    // to set it back to the default state
    if (this.selectedGroupIds.length === 2 && selectedLine) {
      setTimeout(setSelection.resetSelectedLine);
    }
  }
}
