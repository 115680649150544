import { endSession } from '@/Redux/Slices/ShaperHubSlice';
import { disconnect } from '@/Sync/SyncThunks';
import { log } from '@/Sync/SyncLog';
import { AppDispatch } from '@/Redux/store';

export default class UnloadAppAction {
  dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  unload = async () => {
    const { dispatch } = this;

    log(`Unloading/disconnecting workspace`, { syncLevel: 'action' });
    dispatch(endSession());
    dispatch(disconnect());
  };
}
