import React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// selectors
import { selectSelectedPaths } from '@/Redux/Slices/SelectionSlice';

// actions
import UIFeatureAction from '@/Actions/UIFeature';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import CutTypeSelector from './CutTypeSelector';
import DepthSelector from './Depth';
import OffsetSelector from './Offset';
import BitDiameterSelector from './BitDiameter';
import { selectIsPreviewView } from '@/Redux/Slices/UISlice';

export default function EditPathPropertiesPanel() {
  const selectedPaths = useSelector(selectSelectedPaths);
  const isPreviewView = useSelector(selectIsPreviewView);

  // actions
  const uiAction = useAction(UIFeatureAction);

  function onClose() {
    uiAction.clear();
  }

  // don't render without a selection
  if (!selectedPaths?.length) {
    return null;
  }

  return (
    <FloatingPanel
      edge='right'
      title='Shape Properties'
      i18nTitleKey='shape-properties'
      className='path-properties-panel'
      dataCy='edit-path-properties'
      onClose={onClose}
      disabled={isPreviewView}
      hideHeadersOnDesktop
    >
      <CutTypeSelector />
      <DepthSelector />
      <OffsetSelector />
      <BitDiameterSelector />
    </FloatingPanel>
  );
}
