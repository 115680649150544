import { IPoint } from '@/LineTool/types';
import BaseAction from './BaseAction';

import {
  hideAllFeatures,
  toggleIconSearch,
  toggleEditSelectionProperties,
  toggleSelectionEditor,
  toggleShapeCreator,
  toggleFileImport,
  toggleInsertPoint,
} from '@/Redux/Slices/UISlice';

export type InsertPointOptions = {
  origin: IPoint;
  type?: 'mobile' | 'default';
  insertMode?: 'mobile' | 'default';
  groupId?: string;
  atFront?: boolean;
};

export default class UIFeatureAction extends BaseAction {
  // TODO: add toggles and other behaviors?

  toggleInsertPoint(active: boolean, options: InsertPointOptions) {
    this.dispatch(toggleInsertPoint({ active, options }));
  }

  toggleInsertShape(active: boolean) {
    this.dispatch(toggleShapeCreator(active));
  }

  toggleSelectionEditor(active: boolean) {
    this.dispatch(toggleSelectionEditor(active));
  }

  toggleEditSelectionProperties(active: boolean) {
    this.dispatch(toggleEditSelectionProperties(active));
  }

  toggleFindArt(active: boolean) {
    this.dispatch(toggleIconSearch(active));
  }

  toggleFileImport(active: boolean) {
    this.dispatch(toggleFileImport(active));
  }

  clear() {
    this.dispatch(hideAllFeatures());
  }
}
