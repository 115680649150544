import React from 'react';
import { useSelector } from 'react-redux';
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';
import { selectSelectedGroupIds } from '@/Redux/Slices/SelectionSlice';

export default function ActiveLine({ ui }) {
  const getGroupById = useSelector(selectGetGroupById);
  const selectedLine = useSelector(selectSelectedLine);
  const [a, b] = ui?.overrides?.hoverLine || [];
  const missing = !(a && b);
  const selected = selectedLine?.includes(a) && selectedLine?.includes(b);
  const selectedGroupIds = useSelector(selectSelectedGroupIds);

  // only show if there are two groups and the IDs match the selected line
  if (
    selectedGroupIds.length !== 2 ||
    !selectedGroupIds?.includes(a) ||
    !selectedGroupIds?.includes(b)
  ) {
    return null;
  }

  // nothing to show
  if (missing || selected) {
    return null;
  }

  // get the points
  const pointA = getGroupById(a);
  const pointB = getGroupById(b);

  return (
    <line
      x1={pointA.position.x}
      y1={pointA.position.y}
      x2={pointB.position.x}
      y2={pointB.position.y}
      className='active-line'
    />
  );
}
