import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import {
  AsyncGeneratingFunction,
  Cache,
  GenericPathCache,
} from './GenericCache';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';

export class AsyncPathCache<T, U> extends GenericPathCache<T> {
  asyncPathGeneratingFunction: AsyncGeneratingFunction<T, U>;

  constructor(asyncPathGeneratingFunction: AsyncGeneratingFunction<T, U>) {
    super();
    this.asyncPathGeneratingFunction = asyncPathGeneratingFunction;
  }

  async getCacheAsync(
    basePath: BasePath,
    svgGroup: SvgGroup,
    extraArgs: U
  ): Promise<Cache<T>> {
    const pathId = basePath.id;
    const timestamp = svgGroup.generatedTs;
    const existingCacheLine = this.getCachePath(pathId);
    if (!existingCacheLine || existingCacheLine.generatedTs !== timestamp) {
      const { pathData, pathSvg } = await this.asyncPathGeneratingFunction(
        basePath,
        svgGroup,
        extraArgs
      );
      const newCacheLine = {
        generatedTs: timestamp,
        pathData,
        pathSvg,
      };

      this.setCache(pathId, newCacheLine);
      return newCacheLine;
    }

    return existingCacheLine;
  }
}
