import {
  SecretOptions,
  updateSecretOptions,
} from '../Redux/Slices/SherpaContainerSlice';
import BaseAction from './BaseAction';
import ViewportActions from '@/Actions/Viewport';
import { batch } from 'react-redux';
export default class UpdateSecretOptions extends BaseAction {
  update(options: Partial<SecretOptions>) {
    const refresh = this.createAction(ViewportActions);

    batch(() => {
      this.dispatch(updateSecretOptions(options));
      refresh.refresh();
    });
  }
}
