import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { Cache, GeneratingFunction, GenericPathCache } from './GenericCache';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';

export class PathCache<T, U> extends GenericPathCache<T> {
  pathGeneratingFunction: GeneratingFunction<T, U>;

  constructor(pathGeneratingFunction: GeneratingFunction<T, U>) {
    super();
    this.pathGeneratingFunction = pathGeneratingFunction;
  }

  getCache(basePath: BasePath, svgGroup: SvgGroup, extraArgs: U): Cache<T> {
    const pathId = basePath.id;
    const timestamp = svgGroup.generatedTs;
    const existingCacheLine = this.getCachePath(pathId);

    if (!existingCacheLine || existingCacheLine.generatedTs !== timestamp) {
      const { pathData, pathSvg } = this.pathGeneratingFunction(
        basePath,
        svgGroup,
        extraArgs
      );
      const newCacheLine: Cache<T> = {
        generatedTs: timestamp,
        pathData,
        pathSvg,
      };

      this.setCache(pathId, newCacheLine);
      return newCacheLine;
    }

    return existingCacheLine;
  }
}
