import { batch } from 'react-redux';
import BaseAction from './BaseAction';
import { setLoading } from '../Redux/Slices/SherpaContainerSlice';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';
import { disconnect } from '@/Sync/SyncThunks';
import { log } from '@/Sync/SyncLog';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';

export default class OpenSyncedWorkspaceAction extends BaseAction {
  updateWorkspaceOptionsAction: UpdateWorkspaceOptionsAction;

  constructor(dispatch: AppDispatch, useSelector: UseSelector) {
    super(dispatch, useSelector);
    this.updateWorkspaceOptionsAction = new UpdateWorkspaceOptionsAction(
      dispatch,
      useSelector
    );
  }

  openSyncedWorkspace = (newWorkspaceId: string, newWorkspaceSeq = false) => {
    const { dispatch } = this;
    batch(async () => {
      dispatch(setLoading(true));
      //disconnect from currently open workspace, if needed, before opening new workspace to prevent being stuck in view only when rapidly switching between workspaces
      log(`Disconnecting workspace and opening workspace ${newWorkspaceId}`, {
        syncLevel: 'action',
      });
      dispatch(
        disconnect({
          openWorkspaceAfterDisconnect: {
            workspaceId: newWorkspaceId,
            isDuplicate: false,
          },
        })
      );
      this.updateWorkspaceOptionsAction.update({ showApplicationMenu: false });
    });
  };
}
