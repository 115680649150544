import React from 'react';
import { useAction } from '@/Actions/useAction';
import { useSelector } from 'react-redux';
import {
  SHAPE_EDITOR_POINT_SIZE,
  SHAPE_EDITOR_POINT_SIZE__SELECTED,
} from '@/Constants/UI';
import SvgGroupCls from '../../State/Helpers/SvgGroup';

// actions
import LineToolAction from '@/Actions/LineTool';

// selectors
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import {
  selectMostRecentlyMovedPoint,
  selectSelectedGroupIds,
} from '@/Redux/Slices/SelectionSlice';
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';
import UIState from '@/UILayer/State/UIState';

// components
// import Arc from './Arc';

type Props = {
  group: SvgGroupCls;
  ui: UIState;
  selected: boolean;
  isOnlySelection: boolean;
};

export default function EditablePoint({ group }: Props) {
  const lineTool = useAction(LineToolAction);
  const nspf = useSelector(selectNonScalingPixelFactor);
  const mostRecentlyMovedPoint = useSelector(selectMostRecentlyMovedPoint);
  const selectedGroupIds = useSelector(selectSelectedGroupIds);
  const selectedLine = useSelector(selectSelectedLine);
  const selected = selectedGroupIds.includes(group.id);
  const position = { cx: group.cx, cy: group.cy };

  // const modified = 'translate' in ui.overrides && selected;

  // Prior UI components to display angle and distance
  // between adjacent points - uncomment to restore
  // TODO: refactor Actions/LineTool to be typescript
  const point = (lineTool.points as any)[
    mostRecentlyMovedPoint ?? group.id
  ] as string;
  const isActivePoint =
    selectedLine?.includes(group.id) &&
    selected &&
    mostRecentlyMovedPoint === group.id;

  if (point) {
    // const { pointA, pointB } = point;

    let size = selected
      ? SHAPE_EDITOR_POINT_SIZE__SELECTED
      : SHAPE_EDITOR_POINT_SIZE;

    if (isActivePoint) {
      size *= 1.5;
    }
    // let labelA;
    // let labelB;
    // let arc;

    // show extra data when selecting a single point
    if (selected) {
      // NOTE: enable below to show the distance between two selected points
      // there is other code highlighting these lines elsewhere
      //   if (pointA) {
      //     labelA = (
      //       <DistanceBetweenPoints
      //         origin={group}
      //         target={pointA}
      //         label='A'
      //         modified={modified}
      //       />
      //     );
      //   }
      //   if (pointB) {
      //     labelB = (
      //       <DistanceBetweenPoints
      //         origin={group}
      //         target={pointB}
      //         label='B'
      //         modified={modified}
      //       />
      //     );
      //   }
      //
      // Enable to show an arc between two lines
      // if (pointA && pointB) {
      //   arc = <Arc origin={point ?? group} pointA={pointA} pointB={pointB} />;
      // }
    }

    let pointSize = size * nspf * 0.5;
    let activePointSize = pointSize * 1.5;
    if (isActivePoint) {
      pointSize *= 0.8;
    }

    return (
      <>
        {isActivePoint && (
          <circle
            className='active-line-point'
            r={activePointSize}
            {...position}
          />
        )}
        <circle
          id={`point-${group.id}`}
          className={`path-connection ${selected && 'selected'}`}
          r={pointSize}
          {...position}
        />
        {/* {arc} */}
        {/* 
        {labelA}
        {labelB} */}
      </>
    );
  }
}
