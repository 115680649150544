import { logoutShaperHub } from '@/ShaperHub/ShaperHubThunks';
import { setUIMode, setUIView, toggleLoginMenu } from '@/Redux/Slices/UISlice';
import { FEATURE_MODES } from '../Constants/Subscriptions';
import { setFeaturesMode } from '../Redux/Slices/UISlice';
import ClearCanvasAction from './ClearCanvas';
import ModalActions from './Modal';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';
import { disconnect, setSandboxMode } from '@/Sync/SyncThunks';
import { reset } from '@/Redux/Slices/CanvasSlice';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';
import { clearProject } from '@/Redux/Slices/ShaperHubSlice';

export default class LogOutAction {
  dispatch: AppDispatch;
  clearCanvasAction: ClearCanvasAction;
  updateWorkspaceOptions: UpdateWorkspaceOptionsAction;
  modalActions: ModalActions;

  constructor(dispatch: AppDispatch, useSelector: UseSelector) {
    this.dispatch = dispatch;
    this.clearCanvasAction = new ClearCanvasAction(dispatch, useSelector);
    this.updateWorkspaceOptions = new UpdateWorkspaceOptionsAction(
      dispatch,
      useSelector
    );
    this.modalActions = new ModalActions(dispatch, useSelector);
  }

  logOut = async () => {
    const { dispatch } = this;

    //When logging out, need to revert to sandbox mode.
    //This means the user loses access to the current workspace, because this is controlled by the sync service
    //The use can access this again by logging in and opening the workspaceId
    dispatch(toggleLoginMenu(false));
    dispatch(disconnect());
    this.updateWorkspaceOptions.update({ showApplicationMenu: false });
    await dispatch(logoutShaperHub());
    dispatch(reset());
    dispatch(setUIView('default'));
    dispatch(setUIMode('default'));
    dispatch(clearProject());

    dispatch(setSandboxMode());
    this.modalActions.openSignInModal();
    dispatch(setFeaturesMode(FEATURE_MODES.DEMO));
    history.pushState(null, '', window.location.pathname);
  };
}
