import React from 'react';
import Icon from '@/Styles/Icons/Icon';

type Props = {
  type?: 'domino';
  withOverlay?: boolean;
};

export default function Loading(props: Props) {
  switch (props.type) {
    case 'domino':
      return (
        <>
          <div className='loading-domino' data-cy='loading-domino'>
            <Icon icon='loading-domino' />
          </div>
          {props.withOverlay && <div className='loading-domino--overlay' />}
        </>
      );

    // basic spinner
    default:
      return (
        <div className='loading-activity'>
          <Icon icon='loading-indicator' />
        </div>
      );
  }
}
