import React, { useState } from 'react';
import Icon from '@/Styles/Icons/Icon';
import Tooltip from '@mui/material/Tooltip';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { miscLinks } from '../../../defaults';
import { useModeSelectionMenuContext } from '../ModeSelectionMenu';

export default function CustomAnchorOption() {
  const disabled = useModeSelectionMenuContext() || false;
  const { t, i18n } = useTranslation();

  //state for tooltip
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    if (!disabled) {
      setOpen(false);
    }
  };

  const handleTooltipOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const iconCx = classNames('custom-anchor--icon', {
    open: open,
    disabled,
  });

  const CustomAnchorTooltipChild = React.forwardRef<HTMLDivElement>(
    function TooltipChild(props, ref) {
      return (
        <div ref={ref} style={{ alignItems: 'center', display: 'flex' }}>
          <Icon icon='info' className={iconCx} />
        </div>
      );
    }
  );

  const createCustomAnchorTooltipTranslation = () => {
    const customAnchorHelp = i18n.exists('custom-anchor-help')
      ? t('custom-anchor-help')
      : `Custom Anchors help you position your design on Origin.`;
    const learnMore = i18n.exists('learn-more')
      ? t('learn-more')
      : 'learn more';

    return {
      __html: `${customAnchorHelp} <a class='custom-anchor--learn-more' target="_blank" href="${miscLinks.CUSTOM_ANCHORS}">${learnMore}</a>`,
    };
  };

  return (
    <Tooltip
      title={
        <div
          className='custom-anchor--container'
          dangerouslySetInnerHTML={createCustomAnchorTooltipTranslation()}
        />
      }
      placement='bottom'
      arrow
      classes={{ popper: 'custom-anchor--tooltip' }}
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      leaveTouchDelay={6000}
      open={open}
    >
      <div onClick={handleTooltipOpen}>
        <CustomAnchorTooltipChild />
      </div>
    </Tooltip>
  );
}
