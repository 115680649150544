import React from 'react';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import {
  selectShaperSubscriptions,
  selectLoggedIn,
} from '@/Redux/Slices/ShaperHubSlice';

import TranslationText from '../../TranslationText/TranslationText';
import { selectFeatureFlags } from '@/Redux/Slices/FeatureFlagsSlice';
import Icon from '@/Styles/Icons/Icon';
import { selectIsPreviewView } from '@/Redux/Slices/UISlice';

export default function Logo() {
  const { isSubscriber, isTrial, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const isLoggedIn = useSelector(selectLoggedIn);
  const featureFlags = useSelector(selectFeatureFlags);
  const isPreviewView = useSelector(selectIsPreviewView);
  const isLite = (!isSubscriber && !isTrial) || isExpired;
  const validTrial = isTrial && !isExpired;

  const logoCx = classNames('application-menu--logo', {
    lite: isLite && !isPreviewView,
    demo: !isLite && !isLoggedIn && !isPreviewView,
  });

  return (
    <div className={logoCx}>
      <div className='application-menu--logo--image'>
        <Icon icon='studio-logo' />
      </div>
      {!(featureFlags['release-studio-free'] || isPreviewView) && (
        <>
          {isLoggedIn && isLite && (
            <div className='application-menu--logo--lite'>Lite</div>
          )}
          {!isLoggedIn && (
            <div className='application-menu--logo--demo' data-cy='demo-badge'>
              <TranslationText i18nKey='demo'>Demo</TranslationText>
            </div>
          )}
          {validTrial && !isSubscriber && (
            <div className='application-menu--logo--lite'>
              <TranslationText i18nKey='trial-badge'>Trial</TranslationText>
            </div>
          )}
        </>
      )}
    </div>
  );
}
