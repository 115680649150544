type Props = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export default function SelectionNet(props: Props) {
  return (
    <rect
      className='selection-net'
      x={props.left}
      y={props.top}
      width={props.right - props.left}
      height={props.bottom - props.top}
    />
  );
}
