import BaseAction from './BaseAction';
import { actions as uiSliceActions } from '@/Redux/Slices/UISlice';
import { batch } from 'react-redux';
import {
  readShaperHubFolder,
  updateShaperHubWorkspace,
  getShaperHubFiles,
  selectWorkspaceInformation,
  selectShaperHubPath,
} from '@/Redux/Slices/ShaperHubSlice';
import {
  getShaperHubFile,
  getShaperHubExternalItemSvg,
  getShaperHubStudioShareProject,
} from '@/ShaperHub/ShaperHubThunks';
import ImportGeometryAction from '@/Actions/ImportGeometry';
import OpenSyncedWorkspaceAction from './OpenSyncedWorkspace';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';
import { getShaperHubExternalItem } from '../ShaperHub/ShaperHubThunks';
import {
  setLoading,
  setSessionWorkspaceInfo,
} from '../Redux/Slices/SherpaContainerSlice';
import {
  UserspaceExternalItemFileObject,
  UserspaceFileObject,
  UserspaceProjectLinkFileObject,
} from '@/@types/shaper-types';

export default class FileSystemAction extends BaseAction {
  selectDirectory(name: string) {
    this.dispatch(readShaperHubFolder(name));
  }

  clearDirectory(atRoot: boolean) {
    if (!atRoot) {
      this.dispatch(readShaperHubFolder('..'));
    } else {
      this.dispatch(readShaperHubFolder());
    }
  }

  closeFileSystem() {
    batch(() => {
      this.dispatch(uiSliceActions.toggleMoveMenu(false));
      this.dispatch(uiSliceActions.toggleOpenMenu(false));
    });
  }

  toggleMoveMenu(isShowingMoveMenu: boolean) {
    batch(() => {
      this.dispatch(uiSliceActions.toggleMoveMenu(!isShowingMoveMenu));
      this.dispatch(readShaperHubFolder());
    });
  }

  toggleOpenMenu(isShowingOpenMenu: boolean) {
    batch(() => {
      this.dispatch(uiSliceActions.toggleOpenMenu(!isShowingOpenMenu));
      this.dispatch(getShaperHubFiles());
    });
  }

  toggleMyProjects() {
    this.dispatch(readShaperHubFolder());
  }

  async openFile(file: UserspaceFileObject) {
    const importGeometryAction = this.createAction(ImportGeometryAction);
    const svg = await getShaperHubFile(file);
    importGeometryAction.importSVG(svg);
  }

  async importWorkspace(file: UserspaceExternalItemFileObject) {
    const importGeometryAction = this.createAction(ImportGeometryAction);
    const svg = await getShaperHubExternalItemSvg(file, true);
    importGeometryAction.importSVG(svg);
  }

  async importStudioShare(file: UserspaceProjectLinkFileObject) {
    const project = await getShaperHubStudioShareProject(file, true);

    const importGeometryAction = this.createAction(ImportGeometryAction);
    const svg = await getShaperHubExternalItemSvg(project.files[0], true);
    importGeometryAction.importSVG(svg);
  }

  openWorkspace(file: UserspaceExternalItemFileObject) {
    const openSyncedWorkspaceAction = this.createAction(
      OpenSyncedWorkspaceAction
    );
    const updateWorkspaceOptions = this.createAction(
      UpdateWorkspaceOptionsAction
    );
    batch(async () => {
      this.dispatch(setLoading(true));
      openSyncedWorkspaceAction.openSyncedWorkspace(file.externalItemId);

      // save this as the most recently opened project
      this.dispatch(
        setSessionWorkspaceInfo({
          workspaceId: file.externalItemId,
          viewedAtTimestamp: Date.now(),
        })
      );

      this.dispatch(uiSliceActions.toggleOpenMenu(false));
      updateWorkspaceOptions.update({ showApplicationMenu: false });
    });
  }

  moveWorkspace() {
    const workspace = this.useSelector(selectWorkspaceInformation);
    const workspaceHasPath = (
      arg: typeof workspace
    ): arg is UserspaceExternalItemFileObject => arg !== null && 'path' in arg;
    const currentPath = this.useSelector(selectShaperHubPath);
    if (workspaceHasPath(workspace)) {
      const patch = {
        name: workspace.name,
        path: workspace.path,
        newPath: `/${currentPath.join('/')}/`.replace('//', '/'),
      };

      batch(async () => {
        await this.dispatch(updateShaperHubWorkspace(patch));
        await this.dispatch(getShaperHubExternalItem(workspace.externalItemId));
        this.dispatch(uiSliceActions.toggleMoveMenu(false));
      });
    }
  }
}
