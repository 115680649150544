import BoundingBox from './Helpers/BoundingBox';
import SvgGroup from './Helpers/SvgGroup';
import Viewport from './Helpers/Viewport';

import {
  GroupId,
  selectSelectedGroupIds,
  selectSelectedPathIds,
  selectSelectionBoundsWithGroupOverrides,
} from '@/Redux/Slices/SelectionSlice';
import { selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';
import { selectIsSelectionTwoAdjacentPoints } from '@/Redux/Slices/LineToolSlice';
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';

import { UseSelector, useSelector } from '@/Actions/useAction';
import { isNumber } from 'lodash';
import {
  PATH_TYPES,
  PathType,
} from '@shapertools/sherpa-svg-generator/PathTypes';
import { Mode } from '@/@types/shaper-types';
import { AlignmentGuide } from '@/Helpers/Alignment';
import { IPoint } from '@shapertools/sherpa-svg-generator/Point';
import { Viewbox, ViewportState } from '@/Redux/Slices/ViewportSlice';
import { Matrix33 } from '@shapertools/sherpa-svg-generator/Matrix33';
import SelectionBox from '@/Helpers/SelectionBoxHelper';
import { Shape, ToolParams } from '@shapertools/sherpa-svg-generator/SvgGroup';

const NONE = Object.freeze({});

export type Store = {
  ui: Mode;
  viewport: ViewportState;
  svgViewbox: Viewbox;
};

export type SelectionNet = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

type SvgGroupOverride = {
  rotate?: number;
  translate?: IPoint;
  resize?: Matrix33;
  data?: any;
};

export type Overrides = {
  translate?: IPoint;
  resize?: IPoint & {
    changedVertical?: boolean;
    changedHorizontal?: boolean;
    origin?: string;
    fromNearX: boolean;
    fromNearY: boolean;
    fromCenter: boolean;
  };
  rotate?: number;
  rotation?: number;
  rotatingAnchor?: string;
  isMultiSelection?: boolean;
  groups?: { [key: GroupId]: SvgGroupOverride };
  selectionNet?: SelectionNet;
  alignmentGuides?: AlignmentGuide[];
  viewport?: Viewbox;
};

// eslint-disable-next-line no-use-before-define
export type OverridesWithApply = Overrides & { apply: () => void };

// handles tracking UI state
export default abstract class UIState {
  // set the initial time
  static lastUpdate = Date.now();
  // the temporary values for updating
  static intermediate: Overrides = NONE;
  static listener?: (lastUpdate: number) => void;

  static setListener = (listener: (lastUpdate: number) => void) => {
    UIState.listener = listener;
  };

  // triggers a render
  static render = () => {
    UIState.lastUpdate = Date.now();
    (UIState.listener || (() => {}))(UIState.lastUpdate);
  };

  // allows an action to apply changes immediately
  static apply = (action: (update: OverridesWithApply) => void) => {
    const update = UIState.update();
    action?.(update);
    update.apply();
  };

  // creates an update object for a series of changes
  static update(): OverridesWithApply;
  static update<T extends Overrides>(initialState: T): T & OverridesWithApply;
  static update(initialState: object = {}) {
    const update = {
      ...UIState.intermediate,
      ...initialState,
    };

    const updateWithApply = update as typeof update & { apply: () => void };

    updateWithApply.apply = () => {
      // @ts-ignore
      delete updateWithApply.apply;
      UIState.intermediate = updateWithApply;
      UIState.render();
    };

    return updateWithApply;
  }

  // resets the intermediate layer and renders again
  static reset = (silent: boolean = false) => {
    UIState.intermediate = NONE;

    // render again
    if (!silent) {
      UIState.render();
    }
  };

  // creates a new instance of a UI state
  static create<T extends UIState>(
    ModeCls: new (s: Store, overrides: Overrides) => T,
    store: Store
  ) {
    return new ModeCls(store, UIState.intermediate);
  }

  store: Store;
  overrides: Overrides;

  viewport: Viewport;

  hasChanges: boolean;
  useSelector: UseSelector;

  boundingBox?: BoundingBox;
  alignmentGuides?: AlignmentGuide[];

  // tracking groups and selections
  groups: SvgGroup[] = [];
  selectedGroups: SvgGroup[] = [];
  // eslint-disable-next-line no-unused-vars
  changedGroupIds: { [key in GroupId]?: SvgGroupOverride } = {};
  hasSelection?: boolean;
  selectionType?: PathType;
  isSingleSelection?: boolean;
  isMultiSelection?: boolean;

  selectionNet?: SelectionNet;

  constructor(store: Store, overrides: Overrides) {
    this.store = store;
    this.overrides = overrides;
    this.hasChanges = overrides !== NONE;
    this.useSelector = useSelector;
    this.viewport = new Viewport(this, this.overrides);
  }

  get uiMode() {
    return this.store?.ui;
  }

  get hasBounds() {
    return !!this.boundingBox;
  }

  get hasAlignmentGuides() {
    return !!this.alignmentGuides;
  }

  get hasSelectionNet() {
    return !!this.selectionNet;
  }

  get nspf() {
    return this.useSelector(selectNonScalingPixelFactor);
  }

  get selectionBounds() {
    return this.useSelector(selectSelectionBoundsWithGroupOverrides)(
      Object.values(this.overrides.groups || {}).reduce(
        (obj, group) => ({
          ...obj,
          ...(group.data && {
            [group.data.id]: group.data,
          }),
        }),
        {}
      )
    ) as SelectionBox;
  }

  get selectedGroupIds() {
    return this.useSelector(selectSelectedGroupIds);
  }

  get selectedPathIds() {
    return this.useSelector(selectSelectedPathIds);
  }

  get svgGroups() {
    let groups = [...this.useSelector(selectSvgGroupSet)];

    // special filters
    if (['review', 'plan'].includes(this.uiMode)) {
      groups = groups.filter((group) => group.tool?.type !== Shape.POINT);
    }

    return groups;
  }

  get isSelectingSinglePoint() {
    return (
      this.selectedGroups?.length === 1 &&
      this.selectedGroups?.[0]?.tool?.type === Shape.POINT
    );
  }

  get isSelectingAdjacentPoints() {
    return useSelector(selectIsSelectionTwoAdjacentPoints);
  }

  isShapeBeingActivelyModified(shape: SvgGroup) {
    // if this isn't a shape, then no
    if (shape.tool?.type !== Shape.SHAPE) {
      return false;
    }

    // if there's no overrides, then no
    if (!Object.keys(this.overrides).length) {
      return false;
    }

    // if there's overrides, and the selection includes
    // a point, then it's most likely being edited
    const { selectedPathIds } = this;
    for (const { groupId: id } of selectedPathIds) {
      if ((shape.tool.params as ToolParams<Shape.SHAPE>).points.includes(id)) {
        return true;
      }
    }

    return false;
  }

  applyOverridesToPoint(...args: any[]) {
    const points = [];

    // check the args provided
    for (let arg of args) {
      if (isNumber(arg)) {
        points.push(arg);
      } else if (arg && 'x' in arg && 'y' in arg) {
        points.push(arg.x, arg.y);
      }
    }

    // get the coordinates to use
    let [x, y] = points;

    // check for override changes
    if (this.overrides && !this.overrides?.rotate && !this.overrides?.resize) {
      x += this.overrides?.translate?.x || 0;
      y += this.overrides?.translate?.y || 0;
    }

    return { x, y };
  }

  defineSelectionNet() {
    this.selectionNet = this.overrides.selectionNet;
  }

  defineAlignmentGuides() {
    this.alignmentGuides = this.overrides.alignmentGuides;
  }

  defineBoundingBox() {
    const { selectionBounds, hasSelection, overrides } = this;

    // only bounding box when selected
    if (!(selectionBounds && hasSelection)) {
      return;
    }

    // save a bounding box
    this.boundingBox = new BoundingBox(this, overrides);
  }

  defineGroups() {
    const { overrides, selectedGroupIds, svgGroups } = this;

    this.selectionType = (() => {
      const selected = svgGroups.find((g) =>
        selectedGroupIds.length > 0 ? g.id === selectedGroupIds[0] : null
      );
      if (selected) {
        return PATH_TYPES[selected?.type];
      }
      return PATH_TYPES.Design;
    })();

    for (const data of svgGroups) {
      const override = overrides.groups && overrides.groups[data.id];
      const group = new SvgGroup(this, data, override);
      this.changedGroupIds[group.id] = override;
      this.groups.push(group);

      // check if selected
      if (selectedGroupIds.includes(group.id)) {
        this.selectedGroups.push(group);
      }
    }
  }
}
