// import { batch } from 'react-redux';
import BaseAction from './BaseAction';
import { updatePathsCutParams } from '@/CanvasContainer/CanvasActions';
import { CutParams } from '@shapertools/sherpa-svg-generator/CutParams';
import { Targets } from '@/Viewport/ViewportHelpers';

export default class UpdateSvgPathAction extends BaseAction {
  setCutType(selection: Targets[], cutType: CutParams['cutType']) {
    const idsAndCutParams = selection.map((sel) => ({
      groupId: sel.group.id,
      pathId: sel.path.id,
      cutParams: { ...sel.path.cutParams, cutType },
    }));
    this.dispatch(updatePathsCutParams(idsAndCutParams));
  }

  setBitDiameter(selection: Targets[], toolDia: CutParams['toolDia']) {
    const idsAndCutParams = selection.map((sel) => ({
      groupId: sel.group.id,
      pathId: sel.path.id,
      cutParams: { ...sel.path.cutParams, toolDia },
    }));
    this.dispatch(updatePathsCutParams(idsAndCutParams));
  }

  setDepth(selection: Targets[], cutDepth: CutParams['cutDepth']) {
    const idsAndCutParams = selection.map((sel) => ({
      groupId: sel.group.id,
      pathId: sel.path.id,
      cutParams: { ...sel.path.cutParams, cutDepth },
    }));
    this.dispatch(updatePathsCutParams(idsAndCutParams));
  }

  setOffset(selection: Targets[], cutOffset: CutParams['cutOffset']) {
    const idsAndCutParams = selection.map((sel) => ({
      groupId: sel.group.id,
      pathId: sel.path.id,
      cutParams: { ...sel.path.cutParams, cutOffset },
    }));
    this.dispatch(updatePathsCutParams(idsAndCutParams));
  }
}
