import React from 'react';
import UIState from '../State/UIState';

type Props = {
  children: React.ReactNode;
  className?: string;

  ui: UIState;
  x?: number;
  y?: number;
};

export default function OverlayElement(props: Props) {
  const { x, y } = props.ui.viewport.canvasToScreen(props.x || 0, props.y || 0);
  const style = {
    top: `${y}px`,
    left: `${x}px`,
  };

  return (
    <div
      style={style}
      {...props}
      className={`ui-layer--overlay-element ${props.className || ''}`}
    >
      {props.children}
    </div>
  );
}
