import React, { MouseEvent } from 'react';
import Icon from '@/Styles/Icons/Icon';
import classNames from 'classnames';

type Props<T> = {
  children?: React.ReactNode;
  dataCy?: string;

  value?: T;
  disabled?: boolean;
  onClick: (value: T, event: MouseEvent) => void;

  icon?: React.ReactNode;
  head?: React.ReactNode;
  tail?: React.ReactNode;
  style?: string;
};

export default function Button<T>(props: Props<T>) {
  const { icon, children, head, tail, style, value, disabled, dataCy } = props;

  function onClick(event: MouseEvent) {
    if (disabled) {
      return;
    }

    if (props.onClick) {
      props.onClick(value as T, event);
    }
  }

  const buttonCx = classNames('footer-menu--button', {
    [`footer-menu--button--${style}`]: style,
    'footer-menu--button--disabled': disabled,
  });

  return (
    <div className={buttonCx} onClick={onClick} data-cy={dataCy}>
      {!!head && head}
      {icon && <Icon>{icon}</Icon>}
      {children}
      {!!tail && tail}
    </div>
  );
}
