import React from 'react';
import { useSelector } from 'react-redux';
import { DISTANCE_BETWEEN_LABEL_OFFSET } from '@/Constants/UI';
import classNames from 'classnames';

// selectors
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import {
  selectDisplayUnits,
  selectToFormattedDisplayUnitValue,
} from '@/Redux/Slices/SherpaContainerSlice';

// components
import Label from '../Label';

export default function DistanceBetweenPoints({
  origin,
  target,
  label,
  modified,
  ui,
}) {
  const displayUnits = useSelector(selectDisplayUnits);
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );

  const nspf = useSelector(selectNonScalingPixelFactor);
  let ax = ui.overrides?.[origin.id]?.translate?.x ?? origin.cx;
  let ay = ui.overrides?.[origin.id]?.translate?.y ?? origin.cy;
  let bx = ui.overrides?.[target.id]?.translate?.x ?? target.cx;
  let by = ui.overrides?.[target.id]?.translate?.y ?? target.cy;

  const distance = Math.hypot(ax - bx, ay - by).toFixed(3);
  let x = (ax + bx) * 0.5;
  let y = (ay + by) * 0.5;
  const displayDistance = toFormattedDisplayUnitValue(distance);

  let angle = Math.atan2(ay - by, ax - bx);

  if (angle > Math.PI * 0.5) {
    angle -= Math.PI;
  }

  if (angle < Math.PI * -0.5) {
    angle += Math.PI;
  }

  // offset
  const alt = angle + Math.PI * 0.5;
  const labelCx = classNames('point-distance', { modified });
  x -= Math.cos(alt) * DISTANCE_BETWEEN_LABEL_OFFSET * nspf;
  y -= Math.sin(alt) * DISTANCE_BETWEEN_LABEL_OFFSET * nspf;

  return (
    <>
      <Label
        id={`distance_${origin.id}_${target.id}`}
        radius={4}
        x={x}
        y={y}
        className={labelCx}
        rotation={angle}
        offsetY={5}
      >
        <>
          <tspan className='point-side'>{label} </tspan>
          <tspan className='point-value'>{displayDistance}</tspan>
          <tspan className='point-unit'> {displayUnits}</tspan>
        </>
      </Label>
    </>
  );
}
