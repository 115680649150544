import { clipperScale } from '../ClipperOps';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { Path } from '@shapertools/sherpa-svg-generator/Path';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import * as clipperLib from 'js-angusj-clipper/web';
import * as Comlink from 'comlink';
import {
  ClipperWorker as ClipperWorkerClass,
  ClipperWorkerType,
} from './Clipper.worker';

const ClipperWorker = Comlink.wrap<ClipperWorkerType>(
  new Worker(new URL('./Clipper.worker.ts', import.meta.url), {
    type: 'module',
  })
);

export type Polygon = {
  points: Point[];
  closed: boolean;
};

export type BooleanReturn = {
  outerClipperPath: Polygon;
  holeClipperPaths: Polygon[];
};

class Clipper {
  worker?: Comlink.Remote<ClipperWorkerClass>;
  clipper?: clipperLib.ClipperLibWrapper;

  async initialize() {
    this.worker = await new ClipperWorker();
    await this.worker.initialize();
    this.clipper = await clipperLib.loadNativeClipperLibInstanceAsync(
      clipperLib.NativeClipperLibRequestedFormat.WasmWithAsmJsFallback
    );
  }

  offsetSync(
    basePath: BasePath,
    offsetValue: number,
    roundCorners: boolean = false,
    offsetOnline = false
  ): BasePath[] {
    if (this.clipper) {
      const { outerPath, points, closed } = basePath;
      if (outerPath) {
        if (outerPath || !offsetOnline) {
          const holePoints = basePath.holePaths?.map((p) => p.points) || [];
          const simplePolygons = this.offsetPolygonSync(
            [outerPath.points, ...holePoints],
            offsetValue,
            roundCorners
          );

          const newPolygonPaths = simplePolygons.map((polygon) => {
            const path = this.clipperToShaper(
              polygon.outerClipperPath.points,
              polygon.outerClipperPath.closed
            );
            if (polygon.holeClipperPaths) {
              const holePaths = polygon.holeClipperPaths.map((hole) =>
                this.clipperToShaper(hole.points, hole.closed)
              );
              return new BasePath({
                outerPath: path,
                holePaths,
              });
            }
            return new BasePath({
              ...path,
            });
          });
          return newPolygonPaths;
        }
        return [basePath];
      }
      if (closed || !offsetOnline) {
        const pathToUse = (() => {
          if (closed) {
            return basePath;
          }
          const simplifiedPolygons = this.simplifyPolygon(points);
          if (simplifiedPolygons.length > 1) {
            return simplifiedPolygons;
          }
          return simplifiedPolygons[0] ?? basePath;
        })();
        if (Array.isArray(pathToUse)) {
          const [outerPathToUse] = pathToUse.splice(0, 1);
          return this.offsetSync(
            new BasePath({
              ...basePath,
              outerPath: outerPathToUse,
              holePaths: [...pathToUse],
              points: undefined,
            }),
            offsetValue,
            roundCorners,
            offsetOnline
          );
        }

        const { closedOffsetPaths, openOffsetPaths } = this.offsetPathSync(
          pathToUse.points,
          pathToUse.closed,
          offsetValue,
          roundCorners
        );
        const offsetPaths = closedOffsetPaths.map((path) =>
          this.clipperToShaper(path, true)
        );
        offsetPaths.push(
          ...openOffsetPaths.map((path) => this.clipperToShaper(path, false))
        );
        return offsetPaths.map((p) => new BasePath({ ...p }));
      }
      return [basePath];
    }
    return this.offsetSync(basePath, offsetValue, roundCorners, offsetOnline);
  }

  async offsetAsync(
    basePath: BasePath,
    offsetValue: number,
    roundCorners: boolean = false,
    offsetOnline = false
  ): Promise<BasePath[]> {
    if (this.worker) {
      const { outerPath, points, closed } = basePath;
      if (outerPath) {
        if (outerPath || !offsetOnline) {
          const holePoints = basePath.holePaths?.map((p) => p.points) || [];
          const simplePolygons = await this.worker.offsetPolygon(
            [outerPath.points, ...holePoints],
            offsetValue,
            roundCorners
          );

          const newPolygonPaths = simplePolygons.map((polygon) => {
            const path = this.clipperToShaper(
              polygon.outerClipperPath.points,
              polygon.outerClipperPath.closed
            );
            if (polygon.holeClipperPaths) {
              const holePaths = polygon.holeClipperPaths.map((hole) =>
                this.clipperToShaper(hole.points, hole.closed)
              );
              return new BasePath({
                outerPath: path,
                holePaths,
              });
            }
            return new BasePath({
              ...path,
            });
          });
          return newPolygonPaths;
        }
        return [basePath];
      }
      if (closed || !offsetOnline) {
        const pathToUse = (() => {
          if (closed) {
            return basePath;
          }
          const simplifiedPolygons = this.simplifyPolygon(points);
          if (simplifiedPolygons.length > 1) {
            return simplifiedPolygons;
          }
          return simplifiedPolygons[0] ?? basePath;
        })();
        if (Array.isArray(pathToUse)) {
          const [outerPathToUse] = pathToUse.splice(0, 1);
          return this.offsetAsync(
            new BasePath({
              ...basePath,
              outerPath: outerPathToUse,
              holePaths: [...pathToUse],
              points: undefined,
            }),
            offsetValue,
            roundCorners,
            offsetOnline
          );
        }

        const { closedOffsetPaths, openOffsetPaths } =
          await this.worker.offsetPath(
            pathToUse.points,
            pathToUse.closed,
            offsetValue,
            roundCorners
          );
        const offsetPaths = closedOffsetPaths.map((path) =>
          this.clipperToShaper(path, true)
        );
        offsetPaths.push(
          ...openOffsetPaths.map((path) => this.clipperToShaper(path, false))
        );
        return offsetPaths.map((p) => new BasePath({ ...p }));
      }
      return [];
    }
    await this.initialize();
    return this.offsetAsync(basePath, offsetValue, roundCorners, offsetOnline);
  }

  offsetPathSync(
    points: Point[],
    closed: boolean,
    offset: number,
    roundCorners = false
  ) {
    if (this.clipper) {
      const joinType = roundCorners
        ? clipperLib.JoinType.Round
        : clipperLib.JoinType.Miter;
      const endType = closed
        ? clipperLib.EndType.ClosedPolygon
        : clipperLib.EndType.OpenRound;

      const polyTree = this.clipper.offsetToPolyTree({
        delta: offset * clipperScale,
        offsetInputs: [
          {
            data: points.map((p) => ({
              x: Math.round(p.x * clipperScale),
              y: Math.round(p.y * clipperScale),
            })),
            joinType,
            endType,
          },
        ],
      });

      if (polyTree) {
        const closedOffsetPaths =
          this.clipper.closedPathsFromPolyTree(polyTree);
        const openOffsetPaths = this.clipper.openPathsFromPolyTree(polyTree);
        return { closedOffsetPaths, openOffsetPaths };
      }
    }
    return { closedOffsetPaths: [], openOffsetPaths: [] };
  }

  offsetPolygonSync(
    pointsArray: Point[][],
    offset: number,
    roundCorners = false
  ) {
    if (this.clipper) {
      const joinType = roundCorners
        ? clipperLib.JoinType.Round
        : clipperLib.JoinType.Miter;

      const polyTree = this.clipper.offsetToPolyTree({
        delta: offset * clipperScale,
        offsetInputs: pointsArray.map((points) => ({
          data: points.map((p) => ({
            x: Math.round(p.x * clipperScale),
            y: Math.round(p.y * clipperScale),
          })),
          joinType,
          endType: clipperLib.EndType.ClosedPolygon,
        })),
      });

      if (polyTree) {
        let currentNode = polyTree.getFirst();
        const clipperPathsResults = [];
        while (currentNode !== null && currentNode !== undefined) {
          const currentNodeOuterPath = {
            points: currentNode.contour,
            closed: !currentNode.isOpen,
          };
          const currentNodeHolePaths = currentNode.childs.map((child) => ({
            points: child.contour,
            closed: !child.isOpen,
          }));

          clipperPathsResults.push({
            outerClipperPath: currentNodeOuterPath,
            holeClipperPaths: currentNodeHolePaths,
          });
          do {
            currentNode = (currentNode as clipperLib.PolyNode).getNext();
          } while (
            (currentNode !== null || currentNode !== undefined) &&
            currentNode?.isHole
          );
        }

        return clipperPathsResults;
      }
    }

    return [];
  }

  clipperToShaper(points: clipperLib.ReadonlyPath, closed: boolean) {
    return new Path({
      closed,
      points: points.map(
        (p) => new Point(p.x / clipperScale, p.y / clipperScale)
      ),
    });
  }

  simplifyPolygon(points: Point[]) {
    if (this.clipper) {
      const scaledPoints = points.map((p) => ({
        x: Math.round(p.x * clipperScale),
        y: Math.round(p.y * clipperScale),
      }));
      const simplifiedPoints = this.clipper.simplifyPolygon(
        this.clipper.cleanPolygon(scaledPoints, 1.415),
        clipperLib.PolyFillType.EvenOdd
      );

      return simplifiedPoints.map(
        (p) =>
          new Path({
            points: p.map(
              (pt) => new Point(pt.x / clipperScale, pt.y / clipperScale)
            ),
            closed: true,
          })
      );
    }
    return [];
  }

  doBoolean(
    operation: clipperLib.ClipType,
    pathsA: Polygon[],
    pathsB: Polygon[],
    simplifyPaths: boolean = false
  ): BooleanReturn[] {
    if (this.clipper) {
      const subjects = pathsA.map((a) => ({
        data: a.points,
        closed: a.closed,
      })) as clipperLib.SubjectInput[];
      const clip = [
        {
          data: pathsB.map((b) => b.points),
        },
      ];
      try {
        const polyTree = this.clipper?.clipToPolyTree({
          clipType: operation,
          subjectInputs: subjects,
          clipInputs: clip,
          subjectFillType: clipperLib.PolyFillType.EvenOdd,
          clipFillType: clipperLib.PolyFillType.EvenOdd,
          strictlySimple: simplifyPaths,
        });

        if (polyTree) {
          return this.convertPolyTreeToResults(polyTree);
        }
      } catch (err) {
        console.error(err);
        return [];
      }
    }
    return [];
  }

  unionSync(pathsA: Polygon[], pathsB: Polygon[], simplifyPaths = false) {
    console.log('sync union v2');
    return this.doBoolean(
      clipperLib.ClipType.Union,
      pathsA,
      pathsB,
      simplifyPaths
    );
  }

  async unionAsync(paths: Polygon[][]) {
    if (this.worker) {
      const newPaths = await this.worker?.unionAllPaths(paths);
      return this.clipperToBasePaths(newPaths);
    }
    return [];
  }

  convertPolyTreeToResults = (
    polyTree: clipperLib.PolyTree
  ): BooleanReturn[] => {
    let currentNode = polyTree.getFirst();
    const clipperPathsResults: BooleanReturn[] = [];
    while (currentNode !== null && currentNode !== undefined) {
      const currentNodeOuterPath = {
        points: currentNode.contour,
        closed: !currentNode.isOpen,
      } as Polygon;
      const currentNodeHolePaths = currentNode.childs.map((child) => ({
        points: child.contour,
        closed: !child.isOpen,
      })) as Polygon[];

      clipperPathsResults.push({
        outerClipperPath: currentNodeOuterPath,
        holeClipperPaths: currentNodeHolePaths,
      });
      do {
        currentNode = (currentNode as clipperLib.PolyNode).getNext();
      } while (
        (currentNode !== null || currentNode !== undefined) &&
        currentNode?.isHole
      );
    }

    return clipperPathsResults;
  };

  pathToClipper = (path: Path) => {
    return {
      points: path.points.map((p) => ({
        x: Math.round(p.x * clipperScale),
        y: Math.round(p.y * clipperScale),
      })),
      closed: path.closed,
    } as Polygon;
  };

  basePathToClipper = (basePath: BasePath) => {
    if (basePath.outerPath) {
      const polygon = [this.pathToClipper(basePath.outerPath)];
      const holePaths = basePath.holePaths?.map((hp) => this.pathToClipper(hp));
      if (holePaths && holePaths.length > 0) {
        polygon.push(...holePaths);
      }
      return polygon;
    }
    return [this.pathToClipper(basePath)];
  };

  clipperToBasePaths = (
    paths: {
      outerClipperPath: {
        closed: boolean;
        points: clipperLib.ReadonlyPath;
      };
      holeClipperPaths: {
        closed: boolean;
        points: clipperLib.ReadonlyPath;
      }[];
    }[]
  ) => {
    return paths.map((path) => {
      const outerPath = this.clipperToShaper(
        path.outerClipperPath.points,
        path.outerClipperPath.closed
      );
      if (path.holeClipperPaths) {
        const holePaths = path.holeClipperPaths.map((hole) =>
          this.clipperToShaper(hole.points, hole.closed)
        );
        return new BasePath({
          outerPath,
          holePaths,
        });
      }
      return new BasePath({
        outerPath,
      });
    });
  };
}

const clipper = new Clipper();
export default clipper;
