import { uniqBy } from 'lodash';

// utility
import HitDetection from '@/Helpers/HitDetection';
import SelectionBoxOps from '@/Helpers/SelectionBoxHelper';
import { sortSelectionByArea } from '@/Utility/shapes';
import * as hoverStateHelper from '@/Components/HoverStateHelper/HoverStateHelper';

// selectors
import {
  selectGetGroupById,
  selectSvgGroupSet,
} from '@/Redux/Slices/CanvasSlice';
import {
  selectViewport,
  selectNonScalingPixelFactor,
} from '@/Redux/Slices/ViewportSlice';
import {
  selectSelectionBounds,
  selectSelectedGroupIds,
  selectSelectedGroups,
  selectActiveAnchor,
  selectSelectedPathIds,
  setSelection as setSelectionAction,
} from '@/Redux/Slices/SelectionSlice';
import { selectIsMobile, selectActiveUIState } from '@/Redux/Slices/UISlice';
import { getLastKnownPointerPosition } from '../Modes/InteractionMode';

// consts
import {
  BOUNDING_BOX_HANDLE_DISTANCE,
  TOUCH_AMPLIFY_MODIFIER,
} from '@/Constants/UI';
import { EDGE_HIT_DETECTION_EXPANSION } from '../../Constants/UI';

// actions
import SetSelectionAction from '@/Actions/SetSelection';
import UIFeatureAction from '@/Actions/UIFeature';
import {
  selectSelectionType,
  clearSelection,
} from '@/Redux/Slices/SelectionSlice';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';

import { selectIsSelectionTwoAdjacentPoints } from '@/Redux/Slices/LineToolSlice';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

// quick viewport check
const checkForViewport = (el) => !!el?.classList.contains('ui-viewport');

export default class BaseInteraction {
  constructor(manager) {
    this.manager = manager;
  }

  get dispatch() {
    return this.manager.dispatch;
  }

  get useSelector() {
    return this.manager.useSelector;
  }

  get isMobile() {
    return this.manager?.useSelector?.(selectIsMobile) || false;
  }

  get uiState() {
    return this.manager?.useSelector?.(selectActiveUIState) || {};
  }

  refreshHoverState(position, { usingSelection, isTouch, longPress } = {}) {
    const { useSelector } = this;

    // update for hovers
    const selection = usingSelection || useSelector(selectSelectedGroupIds);
    const svgGroupSet = useSelector(selectSvgGroupSet);

    let over = this.getGroupsAt({
      ...position,
      ...{
        hitDetectEdge: true,
        hitDetectFill: true,
        multiSelection: longPress || false,
      },
    });
    over = sortSelectionByArea(over, svgGroupSet);

    // try and find the next selection
    let lastIndex = -1;
    for (let i = 0; i < over.length; i++) {
      if (selection.indexOf(over[i]) > -1) {
        lastIndex = i;
      }
    }

    // check for the next possible hover and update it
    const next = over[(lastIndex + 1) % over.length];
    if (next) {
      hoverStateHelper.register({ groupId: next, options: { isTouch } });
    } else {
      hoverStateHelper.clear();
    }
  }

  clearHoverState() {
    hoverStateHelper.clear();
  }

  refreshMenuState({ reason, selection }) {
    const { isMobile } = this;
    const { isShowingSelectionEditor, isShowingEditSelectionProperties } =
      this.uiState;
    const ui = this.createAction(UIFeatureAction);
    const setSelection = this.createAction(SetSelectionAction);

    // check if this is cleared
    if (reason === 'void-click') {
      // on mobile, dismiss both menus
      if (
        isMobile &&
        (isShowingSelectionEditor || isShowingEditSelectionProperties)
      ) {
        ui.toggleEditSelectionProperties(false);
        ui.toggleSelectionEditor(false);
      }

      // on desktop, just hide the selection editor and
      // switch to the properties panel
      else if (!isMobile && isShowingSelectionEditor) {
        ui.toggleSelectionEditor(false);
        ui.toggleEditSelectionProperties(true);
      }
      // otherwise, clear the selection
      else {
        setSelection.clear();
      }
    }
    // when selecting groups and paths
    else if (reason === 'selection') {
      // when on desktop, selecting a path should
      // automatically expand Edit props
      if (!isMobile && !isShowingEditSelectionProperties) {
        ui.toggleEditSelectionProperties(true);
      }
    }
  }

  clearSelection() {
    const { dispatch } = this;
    dispatch(clearSelection());
  }

  getNonScalingPixelFactor() {
    const { useSelector } = this;
    return useSelector(selectNonScalingPixelFactor);
  }

  getSelectedPathIds() {
    const { useSelector } = this;
    return useSelector(selectSelectedPathIds);
  }

  getSelectionType() {
    return this.useSelector(selectSelectionType);
  }

  // waits until after the current interaction to
  // set the handler to inactive. This is used for when
  // an interaction wants to go inactive, but still prevent
  // other interactions from firing.
  // NOTE: This might be better suited as something that happens in
  // the manager at the end of the handler cycle, but I'll review that later
  release() {
    setTimeout(() => this.setActive(false));
  }

  setActive(state = true) {
    this.isActive = state;

    if (this.isActive) {
      this.manager.activeInteraction = this.interactionId;
    }

    // clear active, if needed
    if (
      !this.isActive &&
      this.manager.activeInteraction === this.interactionId
    ) {
      delete this.manager.activeInteraction;
    }
  }

  isKey(event, ...compare) {
    for (const item of compare) {
      // TODO: make this more robust
      if (event.key === item.key || event.key === item) {
        return true;
      }
    }
  }

  getGroupById = (id) => {
    const { useSelector } = this;
    const getGroupById = useSelector(selectGetGroupById);
    return getGroupById(id.groupId || id);
  };

  getSelectedGroups = () => {
    const { useSelector } = this;
    return useSelector(selectSelectedGroups);
  };

  getSelectedGroupIds = () => {
    const { useSelector } = this;
    return useSelector(selectSelectedGroupIds);
  };

  getDesignSelectedGroupIds() {
    const groups = this.getSelectedGroups();
    return groups
      .filter((g) => g.type !== PATH_TYPE.REFERENCE)
      .map((g) => g.id);
  }

  getGroupsFromIds = (ids) => {
    const { useSelector } = this;
    const svgGroupSet = useSelector(selectSvgGroupSet);
    return ids.map((id) => svgGroupSet.find((group) => group.id === id));
  };

  getSvgGroups = () => {
    return this.useSelector(selectSvgGroupSet);
  };

  getViewport = () => {
    return this.useSelector(selectViewport);
  };

  // getSelectedPaths() { }
  // getSelectedPathIds() { }

  getSelectedGroupsAt(...args) {
    return this.getGroupsAt({ ...args, selectedOnly: true });
  }

  getPathType(pathId) {
    const { useSelector } = this;
    const svgGroupSet = useSelector(selectSvgGroupSet);
    const paths = svgGroupSet.map((s) => s.basePathSet.flat()).flat();
    const path = paths.find((p) => p.id === pathId);
    return path?.type;
  }

  // TODO
  getPathsAt({ x, y }) {
    const svgGroups = this.getSvgGroups();
    const viewport = this.getViewport();

    // TODO: this might not work with
    const detect = new HitDetection(svgGroups);
    const selected = detect.hitTest(
      x,
      y,
      { useBoundingBox: false, useCutPath: true },
      viewport
    );

    // groups normalize groups
    return selected.map((selection) => {
      if (selection.pathId?.length) {
        return { pathId: selection.pathId, groupId: selection.groupId };
      }
      return undefined;
    });
  }

  getGroupsAt({
    x,
    y,
    useBoundingBox = false,
    hitDetectEdge = false,
    hitDetectFill = false,
    selectedOnly,
    ignoreReferencePaths = false,
  }) {
    const nspf = this.getNonScalingPixelFactor();
    const svgGroups = this.getSvgGroups();
    const viewport = this.getViewport();
    const detect = new HitDetection(svgGroups);
    const extendBy = hitDetectEdge ? EDGE_HIT_DETECTION_EXPANSION * nspf : 0;

    // find the groups that are over
    let over = detect.hitTest(
      x,
      y,
      { useBoundingBox, extendBy, hitDetectFill },
      viewport
    );

    // limit to only unique groups
    over = uniqBy(over, (item) => item.groupId);

    // limit actively selected
    if (selectedOnly) {
      const selectedGroupIds = this.getSelectedGroupIds();
      over = over.filter((match) => selectedGroupIds.includes(match.groupId));
    }
    if (ignoreReferencePaths) {
      over = over.filter((match) => {
        const type = this.getPathType(match.pathId);
        return type !== PATH_TYPE.REFERENCE;
      });
    }
    return over;
  }

  getHandleAt(
    { x, y },
    {
      extendSelection = false,
      threshold = BOUNDING_BOX_HANDLE_DISTANCE,
      amplifyBy = TOUCH_AMPLIFY_MODIFIER,
      filterHidden = true,
    } = {}
  ) {
    const { useSelector } = this;
    const selectedGroups = this.getSelectedGroups();
    const selectionType = this.getSelectionType();
    const selectable = selectionType?.selectability ?? true;
    if (!selectable) {
      return undefined;
    }
    const nonScalingPixelFactor = useSelector(selectNonScalingPixelFactor);
    const isSelectingTwoAdjacentPoints = useSelector(
      selectIsSelectionTwoAdjacentPoints
    );
    const viewport = useSelector(selectViewport);
    const anchor = useSelector(selectActiveAnchor);
    const selectionBounds = useSelector(selectSelectionBounds);
    const handlePositions = SelectionBoxOps.getSelectionHandles(
      selectionBounds,
      nonScalingPixelFactor,
      isSelectingTwoAdjacentPoints,
      selectedGroups
    );

    const captureDistance = threshold * (extendSelection ? amplifyBy : 1);

    return SelectionBoxOps.getNearestHandle(
      x,
      y,
      handlePositions,
      nonScalingPixelFactor,
      viewport,
      captureDistance,
      selectionBounds,
      anchor,
      filterHidden
    );
  }

  selectAllGroups = () => {
    const { dispatch } = this;
    const groupIds = this.getSvgGroups().map((svgGroup) => svgGroup.id);
    dispatch(setSelectionAction(groupIds));
  };

  isHandleSameAsAnchor(handle) {
    const anchor = this.useSelector(selectActiveAnchor);
    return handle === anchor || handle?.substr(-2) === anchor;
  }

  isSelectingSinglePoint() {
    const selected = this.useSelector(selectSelectedGroups);
    return selected.length === 1 && selected[0].tool?.type === Shape.POINT;
  }

  // hit test to see if over the current selection box
  hitTestSelectionBox({ x, y }) {
    return SelectionBoxOps.hitTest(x, y);
  }

  // checks for a unhover event
  onViewportUnhover(action, delay = 100) {
    clearTimeout(this.__unhovered_check__);

    // delay this test since it will be used after the most
    // recent mouse moves
    this.__unhovered_check__ = setTimeout(() => {
      const unhovered = !this.isCursorInViewport();
      if (unhovered) {
        action?.();
      }
    }, delay);
  }

  // check if a UI element has come between the mouse and
  // the viewport itself since it can interfere with hover
  // events in the view
  isCursorInViewport() {
    // last known/current mouse coordinates
    const { x, y } = getLastKnownPointerPosition();

    // get the layers at that point
    const matches = document.elementsFromPoint(x, y);

    // certain layers, like labels, are part of the viewport
    // but shouldn't break hovering
    const isLabel = !!matches.find((item) =>
      item.classList.contains('label-container')
    );

    // since it's a label, maintain hovers
    // we may need to add more things to this
    if (isLabel) {
      return true;
    }

    // check for layers and viewport
    const isSVG = ['path', 'g'].includes(matches?.[0]?.tagName);
    const isViewport =
      checkForViewport(matches?.[0]) ||
      (isSVG && checkForViewport(matches?.[1]));

    return isViewport && !isLabel;
  }

  anchorHitTestSelectionBox({ x, y }) {
    const over = this.getGroupsAt({
      x,
      y,
      selectedOnly: true,
      hitDetectFill: true,
      ignoreReferencePaths: true,
    });
    return over;
  }

  // creates a dispatchable action
  createAction = (Type, ...args) => {
    return this.manager.createAction(Type, ...args);
  };
}
