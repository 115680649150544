import React from 'react';
import { convertNumBetweenUnits } from '@/Geometry/UnitOps';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// util
import { asFloat } from '@/Utility/sanitize';

// selectors
import { selectDisplayUnits } from '@/Redux/Slices/SherpaContainerSlice';
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';

// actions
import UpdateToolParamsAction from '@/Actions/UpdateToolParams';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import { unitsPrecision } from '../../../../defaults';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';
import UIState from '@/UILayer/State/UIState';

export default function RadiusEditor() {
  const selectedGroups = useSelector(selectSelectedGroups);
  const displayUnits = useSelector(selectDisplayUnits);
  const [selectedGroup] = selectedGroups;
  const disabled =
    PATH_TYPES[selectedGroup.type]?.propertyEditingDisabled || false;

  // actions
  const updateToolsAction = useAction(UpdateToolParamsAction, selectedGroups);

  // computed
  const currentRadius = selectedGroup.tool?.params?.radius || 0;
  const precision =
    displayUnits === 'in'
      ? unitsPrecision.imperialPrecision
      : unitsPrecision.metricPrecision;
  const radiusInUnits =
    selectedGroup.tool?.params?.units !== displayUnits
      ? convertNumBetweenUnits(
          currentRadius,
          selectedGroup.tool?.params?.units,
          displayUnits
        ).toFixed(precision)
      : currentRadius;

  // handle resize updates
  function onChange(newRadius) {
    const radius = (() => {
      const value = asFloat(newRadius);
      if (value <= 0) {
        return 0;
      }
      if (selectedGroup.tool?.params?.units !== displayUnits) {
        const correctUnitsValue = convertNumBetweenUnits(
          value,
          displayUnits,
          selectedGroup.tool?.params?.units
        );
        return correctUnitsValue;
      }
      return value;
    })();

    // update
    if (isNumber(radius)) {
      updateToolsAction.apply('rounded_rectangle', {
        radius,
      });
      updateToolsAction.resolve();

      // make sure the UI is reset
      UIState.reset();
    }
  }

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='radius'>
        <TranslationText i18nKey='radius'>Radius</TranslationText>
      </FloatingPanel.Label>
      <FloatingPanel.Input
        prefix='R'
        suffix={displayUnits}
        onCommit={onChange}
        number
        value={radiusInUnits}
        calculate
        disabled={disabled}
      />
    </FloatingPanel.Group>
  );
}
