import { createSlice } from '@reduxjs/toolkit';

function replaceCursor(mode: string) {
  const foo =
    `cursor-${mode} ` +
    (document.body.className || '')
      .split(/ +/g)
      .filter((item) => item.substr(0, 7) !== 'cursor-')
      .join(' ')
      .trim();

  document.body.className = foo;
}

export interface CursorState {
  mode: 'default';
}

export const initialState: CursorState = {
  mode: 'default',
};

const slice = createSlice({
  name: 'cursor',
  initialState,
  reducers: {
    setCursorMode: (state, action) => {
      const mode = action.payload.mode || action.payload || 'default';
      if (state.mode !== mode) {
        state.mode = mode;
        replaceCursor(state.mode);
      }
    },

    // clears the active cursor
    clearCursorMode: (state) => {
      state.mode = 'default';
      replaceCursor(state.mode);
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
