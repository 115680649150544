import { convertNumBetweenUnits, convertUnitNumToMM } from './UnitOps';
import {
  Point,
  transform,
  comparePoints,
  distance,
  distance2,
  IPoint,
} from '@shapertools/sherpa-svg-generator/Point';

function clone(pt: Point): Point {
  return new Point(pt.x, pt.y);
}

function add(p0: Point, p1: Point): Point {
  return new Point(p0.x + p1.x, p0.y + p1.y);
}

function subtract(p0: IPoint, p1: IPoint): Point {
  return new Point(p0.x - p1.x, p0.y - p1.y);
}

function scalarMul(p0: Point, s: number): Point {
  return new Point(p0.x * s, p0.y * s);
}

function convertPointBetweenUnits(
  pt: Point = new Point(),
  oldUnits: string = 'mm',
  newUnits: string = 'in'
): Point {
  return new Point(
    convertNumBetweenUnits(pt.x, oldUnits, newUnits),
    convertNumBetweenUnits(pt.y, oldUnits, newUnits)
  );
}

function createPointFromUnits(x: string = '0mm', y: string = '0mm'): Point {
  return new Point(convertUnitNumToMM(x), convertUnitNumToMM(y));
}

function norm(p: Point): number {
  return Math.hypot(p.x, p.y);
}

function dot(p0: Point, p1: Point): number {
  return p0.x * p1.x + p0.y * p1.y;
}

function crossMag(p0: Point, p1: Point): number {
  return p0.x * p1.y - p0.y * p1.x;
}

function cos(p0: Point, p1: Point): number {
  return dot(p0, p1) / norm(p0) / norm(p1);
}

function sin(p0: Point, p1: Point): number {
  return crossMag(p0, p1) / norm(p0) / norm(p1);
}

function round(p: Point, roundFactor: number = 100): Point {
  return new Point(
    Math.round(p.x * roundFactor) / roundFactor,
    Math.round(p.y * roundFactor) / roundFactor
  );
}

export {
  clone,
  add,
  subtract,
  scalarMul,
  transform,
  convertPointBetweenUnits,
  createPointFromUnits,
  comparePoints,
  norm,
  dot,
  crossMag,
  cos,
  sin,
  distance2,
  distance,
  round,
};
