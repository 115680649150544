type XmlNs = {
  name: string;
  URI: string;
};

const shaperNs: XmlNs = {
  name: 'shaper',
  URI: 'http://www.shapertools.com/namespaces/shaper',
};

// All currently valid XML attributes in the shaper NS
export type ShaperSvgXml = {
  importOptions?: string;
  svgType?: string;
  cutType?: string;
  cutDepth?: string;
  cutOffset?: string;
  toolDia?: string;
  anchor?: string;
};

// Crude attempt to enforce type checking at runtime
const ShaperSvgXmlAttributes: (keyof ShaperSvgXml)[] = [
  'importOptions',
  'svgType',
  'cutType',
  'cutDepth',
  'cutOffset',
  'toolDia',
  'anchor',
];

/* eslint-disable no-unused-vars */
enum ImportOptions {
  ViewBoxOriginIsGridOrigin = 'vb-origin-is-grid-origin',
}

enum AnchorOptions {
  customAnchorPoint = 'custom-anchor-point',
}

enum SvgTypeOptions {
  originWorkpiece = 'origin-workpiece',
}
/* eslint-enable no-unused-vars */

export {
  ShaperSvgXmlAttributes as default,
  shaperNs,
  ImportOptions,
  AnchorOptions,
  SvgTypeOptions,
};
