import { createAsyncThunk, Draft, PayloadAction } from '@reduxjs/toolkit';
import { CanvasState } from '../Redux/Slices/CanvasSlice';
import {
  duplicateGroupsById,
  createSvgGroupOnCanvasFromSvg,
  createSvgGroupOnCanvasFromSimplePolygon,
  deleteSvgGroup as deleteSvgGroupOnCanvas,
  updateSvgGroup as updateSvgGroupOnCanvas,
  addSvgGroups as addSvgGroupsOnCanvas,
  updatePathsCutParams as updatePathsCutParamsOnCanvas,
  UpdatePaths,
} from '@/Geometry/CanvasOps';

import { produceDeletePatches } from '@/Sync/DeletePatch';
import { Canvas } from '@shapertools/sherpa-svg-generator/Canvas';
import { difference } from '@/Geometry/Helpers';
import { applyPatches } from 'immer';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import {
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { ManualPatchGeneratorFcn } from '@/Sync/PatchGenerator';
import { SvgGroupUpdate, SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';

export interface AddSVGGeometryPayload {
  useGroupSize: boolean;
  position: Point;
  rawSVG: string;
  tool: Tool<Shape>;
}

export interface AddSimplePolygonGeometryPayload {
  position: Point;
  simplePolygon: BasePath[];
  tool: Tool;
}

export type DeleteSvgGroupPayload =
  | {
      id: string;
    }
  | string[];

export type UpdateSvgGroupPayload = {
  id: string;
  update: SvgGroupUpdate;
  hasTessellationFeatureFlag: boolean; // TODO: can remove once tessellation feature is complete
};

export interface SimplePolygonsParams {
  position: Point;
  simplePolygon: BasePath[];
  tool: Tool;
}

export interface DeleteAndAddPayload {
  deleteGroupIds: string | string[];
  simplePolygonsParams: SimplePolygonsParams | SimplePolygonsParams[];
}

export interface ClearCanvasPayload {
  undoable: boolean;
}

export interface DuplicateSelectedPayload {
  groupIds: string[];
  offset?: Point;
}

export interface SetGroupAnchorPayload {
  id?: string;
  ids: string[];
  anchor?: any;
}

export interface UpdateVersionPayload {
  canvas: CanvasState;
}

export type ActionLookupFunction = {
  stateMutator: Function;
  addUndoPatches: boolean;
  manualPatchGenerator?: ManualPatchGeneratorFcn;
  payloadLog?: (payload: any) => any;
};

export const actionLookup: {
  [key: string]: (...args: any) => ActionLookupFunction;
} = {
  addSVGGeometry: ({ payload }: PayloadAction<AddSVGGeometryPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas = createSvgGroupOnCanvasFromSvg(
        draft.canvas,
        payload.useGroupSize,
        payload.position,
        payload.rawSVG,
        payload.tool
      );
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: AddSVGGeometryPayload) => {
      const rawSvgLength = actionPayload.rawSVG.length;

      // TODO: would be nice to upload the rawSVG somewhere to see what users are uploading.
      return {
        tool: actionPayload.tool,
        rawSvg: actionPayload.rawSVG.substring(
          0,
          rawSvgLength > 500 ? 500 : rawSvgLength
        ),
      };
    },
  }),
  addSimplePolygonGeometry: ({
    payload,
  }: PayloadAction<
    AddSimplePolygonGeometryPayload | AddSimplePolygonGeometryPayload[]
  >) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      if (Array.isArray(payload)) {
        draft.canvas = payload.reduce(
          (canvasAcc, simplePolyParams) =>
            createSvgGroupOnCanvasFromSimplePolygon(
              canvasAcc,
              simplePolyParams.position,
              simplePolyParams.simplePolygon,
              simplePolyParams.tool
            ),
          draft.canvas
        );
      } else {
        // create the new layer
        draft.canvas = createSvgGroupOnCanvasFromSimplePolygon(
          draft.canvas,
          payload.position,
          payload.simplePolygon,
          payload.tool
        );
      }
    },
    addUndoPatches: true,
    payloadLog: (
      actionPayload:
        | AddSimplePolygonGeometryPayload
        | AddSimplePolygonGeometryPayload[]
    ) => {
      if (Array.isArray(actionPayload)) {
        return {
          tool: actionPayload.map((p) => p.tool),
        };
      }
      return {
        tool: actionPayload.tool,
      };
    },
  }),
  deleteSvgGroup: ({ payload }: PayloadAction<DeleteSvgGroupPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      if (Array.isArray(payload)) {
        draft.canvas = payload.reduce(
          (canvasAcc, deleteId) => deleteSvgGroupOnCanvas(canvasAcc, deleteId),
          draft.canvas
        );
      } else {
        draft.canvas = deleteSvgGroupOnCanvas(draft.canvas, payload.id);
      }
    },
    addUndoPatches: true,
    manualPatchGenerator: (draft: Draft<CanvasState>) => {
      const canvas = JSON.parse(JSON.stringify(draft));
      return produceDeletePatches(
        Array.isArray(payload) ? payload : payload.id,
        canvas.canvas.svgGroupSet,
        ['canvas', 'svgGroupSet']
      );
    },
    payloadLog: (actionPayload: DeleteSvgGroupPayload) => {
      return actionPayload;
    },
  }),
  updateSvgGroup: ({
    payload,
  }: PayloadAction<UpdateSvgGroupPayload | UpdateSvgGroupPayload[]>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      const updatesArray = Array.isArray(payload) ? payload : [payload];
      draft.canvas = updatesArray.reduce(
        (canvasAcc, updateParams) =>
          updateSvgGroupOnCanvas(
            canvasAcc,
            updateParams.id,
            updateParams.update,
            updateParams.hasTessellationFeatureFlag // TODO: can remove once tessellation feature is complete
          ),
        draft.canvas
      );
    },
    addUndoPatches: true,
    payloadLog: (
      actionPayload: UpdateSvgGroupPayload | UpdateSvgGroupPayload[]
    ) => {
      if (Array.isArray(actionPayload)) {
        return actionPayload.map((p) => ({
          id: p.id,
          key: p.update.key,
        }));
      }
      return {
        id: actionPayload.id,
        key: actionPayload.update.key,
      };
    },
  }),
  addSvgGroups: ({ payload }: PayloadAction<SvgGroup[]>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas = addSvgGroupsOnCanvas(draft.canvas, payload);
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: SvgGroup[]) => {
      return actionPayload.map((s) => ({
        tool: s.tool,
      }));
    },
  }),
  deleteGroupIdsAndAddSimplePolygonGeometry: ({
    payload,
  }: PayloadAction<DeleteAndAddPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      const { deleteGroupIds, simplePolygonsParams } = payload;
      if (Array.isArray(deleteGroupIds)) {
        draft.canvas = deleteGroupIds.reduce(
          (canvasAcc, deleteId) => deleteSvgGroupOnCanvas(canvasAcc, deleteId),
          draft.canvas
        );
      } else {
        draft.canvas = deleteSvgGroupOnCanvas(draft.canvas, deleteGroupIds);
      }
      if (Array.isArray(simplePolygonsParams)) {
        draft.canvas = simplePolygonsParams.reduce(
          (canvasAcc, simplePolyParams) =>
            createSvgGroupOnCanvasFromSimplePolygon(
              canvasAcc,
              simplePolyParams.position,
              simplePolyParams.simplePolygon,
              simplePolyParams.tool
            ),
          draft.canvas
        );
      } else {
        // create the new layer
        draft.canvas = createSvgGroupOnCanvasFromSimplePolygon(
          draft.canvas,
          simplePolygonsParams.position,
          simplePolygonsParams.simplePolygon,
          simplePolygonsParams.tool
        );
      }
    },
    addUndoPatches: true,
  }),
  undoCanvas: () => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      if (draft.undo.past.length > 0) {
        const undoPatchSets = draft.undo.past.pop();
        if (undoPatchSets !== undefined) {
          const { inversePatches } = undoPatchSets;
          const newState = applyPatches(draft, inversePatches);
          newState.undo.future.unshift(undoPatchSets);

          return newState;
        }
      }
    },
    addUndoPatches: false,
  }),
  redoCanvas: () => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      if (draft.undo.future.length > 0) {
        const redoPatchSets = draft.undo.future.shift();
        if (redoPatchSets !== undefined) {
          const { patches } = redoPatchSets;
          const newState = applyPatches(draft, patches);
          newState.undo.past.push(redoPatchSets);

          return newState;
        }
      }
    },
    addUndoPatches: false,
  }),
  clearCanvas: ({ payload }: PayloadAction<ClearCanvasPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas = new Canvas();
    },
    addUndoPatches: payload.undoable || false,
  }),
  updatePathsCutParams: ({ payload }: PayloadAction<UpdatePaths[]>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas = updatePathsCutParamsOnCanvas(draft.canvas, payload);
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: UpdatePaths[]) => {
      return actionPayload;
    },
  }),
  duplicateSelectedGroups: ({
    payload,
  }: PayloadAction<DuplicateSelectedPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      const { canvas: newCanvas } = duplicateGroupsById(
        JSON.parse(JSON.stringify(draft.canvas)),
        payload.groupIds,
        payload.offset
      );

      difference(draft.canvas, newCanvas);

      draft.canvas = newCanvas;
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: DuplicateSelectedPayload) => {
      return actionPayload.groupIds;
    },
  }),
  setSvgGroupAnchor: ({ payload }: PayloadAction<SetGroupAnchorPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      // find the IDs to update
      const ids = (() => {
        if (payload.ids) {
          return payload.ids;
        } else if (payload.id) {
          return [payload.id];
        }
        return [];
      })();

      draft.canvas = ids.reduce(
        (canvas: Canvas, id: string) =>
          updateSvgGroupOnCanvas(
            canvas,
            id,
            {
              key: SvgGroupUpdateKey.Anchor,
              value: payload.anchor,
            },
            false
          ),
        draft.canvas
      );
    },
    addUndoPatches: true,
  }),
  setCanvasCustomAnchor: ({ payload }: PayloadAction<boolean>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas.showCustomAnchor = payload;
    },
    addUndoPatches: false,
    payloadLog: (actionPayload: boolean) => {
      return actionPayload;
    },
  }),
  updateVersion: ({ payload }: PayloadAction<UpdateVersionPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      const newCanvas = payload.canvas;
      draft.version = newCanvas.version;
      draft.canvas = newCanvas.canvas;
    },
    addUndoPatches: false,
    payloadLog: (actionPayload: UpdateVersionPayload) => {
      return actionPayload.canvas.version;
    },
  }),
};

export type ActionLookup = keyof typeof actionLookup;

export const getActionLookup = (key: ActionLookup) => {
  return actionLookup[key];
};

export const addSVGGeometry = createAsyncThunk(
  'canvas/addSVGGeometry',
  async (params: AddSVGGeometryPayload) => {
    return Promise.resolve(params);
  }
);

export const addSimplePolygonGeometry = createAsyncThunk(
  'canvas/addSimplePolygonGeometry',
  async (params: AddSimplePolygonGeometryPayload) => {
    return Promise.resolve(params);
  }
);

export const deleteSvgGroup = createAsyncThunk(
  'canvas/deleteSvgGroup',
  async (params: DeleteSvgGroupPayload) => {
    return Promise.resolve(params);
  }
);

export const updateSvgGroup = createAsyncThunk(
  'canvas/updateSvgGroup',
  async (params: UpdateSvgGroupPayload | UpdateSvgGroupPayload[]) => {
    return Promise.resolve(params);
  }
);

export const addSvgGroups = createAsyncThunk(
  'canvas/addSvgGroups',
  async (params: SvgGroup[]) => {
    return Promise.resolve(params);
  }
);

export const deleteGroupIdsAndAddSimplePolygonGeometry = createAsyncThunk(
  'canvas/deleteGroupIdsAndAddSimplePolygonGeometry',
  async (params: DeleteAndAddPayload) => {
    return Promise.resolve(params);
  }
);

export const undoCanvas = createAsyncThunk('canvas/undoCanvas', async () => {
  return Promise.resolve();
});

export const redoCanvas = createAsyncThunk('canvas/redoCanvas', async () => {
  return Promise.resolve();
});

export const clearCanvas = createAsyncThunk(
  'canvas/clearCanvas',
  async (params: ClearCanvasPayload) => {
    return Promise.resolve(params);
  }
);

export const updatePathsCutParams = createAsyncThunk(
  'canvas/updatePathsCutParams',
  async (params: UpdatePaths[]) => {
    return Promise.resolve(params);
  }
);

export const duplicateSelectedGroups = createAsyncThunk(
  'canvas/duplicateSelectedGroups',
  async (params: DuplicateSelectedPayload) => {
    return Promise.resolve(params);
  }
);

export const setSvgGroupAnchor = createAsyncThunk(
  'canvas/setSvgGroupAnchor',
  async (params: SetGroupAnchorPayload) => {
    return Promise.resolve(params);
  }
);

export const setCanvasCustomAnchor = createAsyncThunk(
  'canvas/setCanvasCustomAnchor',
  async (params: boolean) => {
    return Promise.resolve(params);
  }
);

export const updateVersion = createAsyncThunk(
  'canvas/updateVersion',
  async (params: UpdateVersionPayload) => {
    return Promise.resolve(params);
  }
);
