import React from 'react';
import Layer from './Layer';

// selectors
import measureDomElement from '@/Helpers/DomElementMeasurement';

const OFFSETS = {
  right: -0.5,
  left: 0.5,
  top: 0.5,
  bottom: -0.5,
};

type Props = {
  className?: string;
  classPrefix?: string;
  children?: React.ReactNode;

  text: string;
  fontSize: number;

  x?: number;
  y?: number;
  offsetX?: number;
  offsetY?: number;
  nspf?: number;
  radius: number;

  rotation?: number;
  align?: keyof typeof OFFSETS;
  valign?: keyof typeof OFFSETS;

  padding?: number;
  paddingX?: number;
  paddingY?: number;
};

// text label with a background
export default function Label(props: Props) {
  const { x, y, rotation = 0, classPrefix = '', align, valign } = props;

  // figure out label alignment
  const offsetX = align ? OFFSETS[align] : 0;
  const offsetY = valign ? OFFSETS[valign] : 0;

  // determine the background size
  const bounds = measureDomElement(
    props.text || props.children?.toString() || '',
    props.className || ''
  );
  const width = bounds.width + (props.paddingX || props.padding || 0);
  const height = bounds.height + (props.paddingY || props.padding || 0);

  return (
    <Layer
      {...props}
      x={x}
      y={y}
      rotation={rotation}
      normalizeToViewport
      nspf={props.nspf}
    >
      <rect
        className={`${classPrefix}label-container`}
        width={width}
        height={height}
        x={width * -0.5 + width * offsetX}
        y={height * -0.5 + height * offsetY}
        rx={props.radius}
        ry={props.radius}
      />
      <text
        className={`${classPrefix}label-text`}
        textAnchor='middle'
        alignmentBaseline='middle'
        fontSize={props.fontSize || 14}
        x={width * offsetX + (props.offsetX || 0)}
        y={height * offsetY + (props.offsetY || 0)}
      >
        {props.text || props.children}
      </text>
    </Layer>
  );
}
