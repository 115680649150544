import React from 'react';

//actions
import { useAction } from '../../../Actions/useAction';
import ModalActions from '../../../Actions/Modal';

//components
import Icon from '@/Styles/Icons/Icon';
import Modal from '../Modal';
import TranslationText from '../../TranslationText/TranslationText';

export default function OfflineModal() {
  const modalActions = useAction(ModalActions);

  const closeModal = () => {
    modalActions.closeModal(true);
  };

  return (
    <Modal className='offline-modal'>
      <div className='offline-modal--close-icon' onClick={closeModal}>
        <Icon icon='close' />
      </div>
      <Modal.Panel position='center'>
        <Modal.Content>
          <Modal.Title>
            <Icon icon='offline' />
            <TranslationText i18nKey='offline'>Offline</TranslationText>
          </Modal.Title>
          <div className='offline-modal--info'>
            <TranslationText i18nKey='offline-info'>
              This file can’t connect to our servers and is offline.
            </TranslationText>
          </div>
          <div className='offline-modal--info'>
            <TranslationText i18nKey='offline-info-2'>
              Check your network connection or try reloading the page.
            </TranslationText>
          </div>
          <Modal.Button
            className='offline-modal--button'
            onClick={() => window.location.reload()}
          >
            <TranslationText i18nKey='reload'>Reload</TranslationText>
          </Modal.Button>
        </Modal.Content>
      </Modal.Panel>
    </Modal>
  );
}
