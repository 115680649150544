import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';

export const migrations = [
  {
    from: '0.0',
    to: '1.0',
    up: (canvasSchema: any) => {
      const newSchema = {
        ...canvasSchema,
        version: '1.0',
      };
      return newSchema;
    },
    down: (canvasSchema: any) => {
      const { version, ...rest } = canvasSchema;
      return rest;
    },
  },
  {
    from: '1.0',
    to: '2.0',
    up: (canvasSchema: any) => {
      const newSchema = {
        version: '2.0',
        canvas: {
          canvas: {
            ...canvasSchema.canvas.canvas,
            svgGroupSet: canvasSchema.canvas.canvas.svgGroupSet.map(
              (s: any) => ({
                ...s,
                type: PATH_TYPE.DESIGN,
              })
            ),
          },
          version: '2.0',
        },
      };
      return newSchema;
    },
    down: (canvasSchema: any) => {
      const newSchema = {
        version: '1.0',
        canvas: {
          canvas: {
            ...canvasSchema.canvas.canvas,
            svgGroupSet: canvasSchema.canvas.canvas.svgGroupSet.map(
              (s: any) => {
                const { type, ...rest } = s;
                return {
                  ...rest,
                  basePathSet: s.basePathSet.map((b: any) => ({
                    ...b,
                    type: b.outerPath ? 'CutSimplePolygon' : 'CutPath',
                  })),
                };
              }
            ),
          },
          version: '1.0',
        },
      };
      return newSchema;
    },
  },
];

export class MigrationError extends Error {
  fromVersion: string;
  toVersion: string;

  constructor(from: string, to: string) {
    const message = `Migration version ${from} -> ${to} not found`;
    super(message);
    this.name = 'Migration Error';
    this.fromVersion = from;
    this.toVersion = to;
    console.error(message);
  }
}

export class InvalidCanvasVersionError extends Error {
  constructor() {
    super(`Version is invalid`);
    this.name = 'Invalid Version Error';
  }
}
