// TYPESCRIPT CONVERSION NOTE
// check getSelectionAnchorPosition in SelectionSlice for a funky casting
// that should be updated when this is converted to TypeScript

import {
  BOUNDING_BOX_HANDLES,
  BOUNDING_BOX_HANDLE_RESIZE_DIRECTION,
  MINIMUM_BOUNDING_BOX_HANDLE_PROXIMITY,
  ROTATION_HANDLE_DISTANCE,
  RESIZE_HANDLE_DISTANCE,
} from '@/Constants/UI';

import { getAABBSize, mergeAABBs } from '@/Geometry/AABBOps';
import { add, transform, subtract, scalarMul } from '@/Geometry/PointOps';
import { mmToUnitFormattedStrWLabel } from '@/Geometry/UnitOps';
import { createRotationMtx } from '@shapertools/sherpa-svg-generator/Matrix33';
import HitDetection from '@/Helpers/HitDetection';
import { rotateAroundPoint } from '@/Utility/rotation';

import * as DOM from '@/Helpers/DOM';
import {
  SIZE_TO_HIDE_BOUNDING_BOX_HANDLE,
  SIZE_TO_INVERT_HIDDEN_BOUNDING_BOX_HANDLE,
} from '../Constants/UI';
import { IPoint, Point } from '@shapertools/sherpa-svg-generator/Point';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import { Anchor, Handle, RotationHandle } from '@/@types/shaper-types';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { ViewportState } from '@/Redux/Slices/ViewportSlice';
import { isCenterAnchor } from '@/Actions/Mirror';

// eslint-disable-next-line no-unused-vars
const ALLOWED_HANDLES: { [key in Handle]: Handle[] } = {
  tl: ['tl', 'br', 'bm', 'rm'],
  tm: ['tm', 'bm', 'rm', 'lm', 'br', 'bl'],
  tr: ['tr', 'bl', 'bm', 'lm'],
  lm: ['lm', 'rm', 'tr', 'br', 'tm', 'bm'],
  rm: ['rm', 'lm', 'tl', 'bl', 'bm', 'tm'],
  bl: ['bl', 'tr', 'tm', 'rm'],
  bm: ['bm', 'tl', 'tm', 'tr', 'lm', 'rm'],
  br: ['br', 'tl', 'tm', 'lm'],
};

type HandlePosition = Point & { tp: Point };

type HandleMapping = {
  // eslint-disable-next-line no-unused-vars
  [key in Handle | RotationHandle | Anchor]: Point;
};

//Square roots are slow and we don't need exact distance values, so use d^2
function distanceSquared(dx: number, dy: number) {
  return Math.pow(dx, 2) + Math.pow(dy, 2);
}

function createHandle(
  x: number,
  y: number,
  extendX: number,
  extendY: number,
  distance: number,
  nspf: number
) {
  return new Point(
    x + extendX * distance * nspf,
    y + extendY * distance * nspf
  );
}

export default class SelectionBox {
  //SelectionBox is defined by center position, size, and rotation. Rotation is around centroid position, not center position
  static NO_BOUNDS = new SelectionBox();

  static syncSelectionEditorToBoundingBox() {
    // without the editor, this can't happen at all
    const editor = DOM.getSelectionEditor();
    if (!editor) {
      return;
    }

    // without a bounding box, then the editor should
    // just be hidden entirely
    const aabb = DOM.getBoundingBoxOutline();
    if (!aabb) {
      editor.className = '';
      return;
    }

    // align just over the bounding box area
    const { top, right, left } = aabb.getBoundingClientRect();
    editor.style.top = `${top - 50}px`;
    editor.style.left = `${(left + right) / 2}px`;
  }

  static getAnchoredPosition(
    bounds: SelectionBox,
    centroid: IPoint,
    rotation?: number,
    anchor?: Anchor
  ) {
    const { left, right, top, bottom } = bounds;

    // by default, the anchor is the centroid
    let point = { ...centroid };

    // if this is a single selection, if might be another point
    if (anchor) {
      point =
        {
          tr: { x: right, y: top },
          br: { x: right, y: bottom },
          tl: { x: left, y: top },
          bl: { x: left, y: bottom },
          tm: { x: centroid.x, y: top },
          bm: { x: centroid.x, y: bottom },
          lm: { x: left, y: centroid.y },
          rm: { x: right, y: centroid.y },
          center: { x: centroid.x, y: centroid.y },
          centroid: { x: centroid.x, y: centroid.y },
        }[anchor] || point;
    }

    // if there's a rotation, apply it to the anchor
    if (rotation) {
      const [x, y] = rotateAroundPoint(
        centroid.x,
        centroid.y,
        point.x,
        point.y,
        -rotation
      );
      point.x = x;
      point.y = y;
    }

    return point;
  }

  static getInverseHandle(handle: Anchor) {
    for (const pair of [
      ['tr', 'bl'],
      ['tl', 'br'],
      ['tm', 'bm'],
      ['rm', 'lm'],
    ]) {
      const match = pair.indexOf(handle);
      if (match > -1) {
        return pair[(match + 1) % 2];
      }
    }
  }

  static getRotatedSelectionBox(selectedGroup: SvgGroup) {
    const {
      rotation,
      position: centroidPosition,
      position: centerPosition,
    } = selectedGroup;

    const { x: width, y: height } = getAABBSize(selectedGroup.unrotatedAABB);

    return new SelectionBox({
      hasBounds: true,
      anchor: selectedGroup.anchor,
      centerPosition,
      centroidPosition,
      rotation,
      width,
      height,
    });
  }

  static getAASelectionBox(groups: SvgGroup[], temporaryAnchor?: Handle) {
    let selectionAABB = new AABB();

    let centroidPosition = new Point(0, 0);

    // extract the bounds and calculate centroidPosition
    for (const group of groups) {
      centroidPosition = add(group.position, centroidPosition);

      selectionAABB = mergeAABBs(selectionAABB, group.transformedAABB);
    }

    // calculate all bounds
    const width = selectionAABB.maxPoint.x - selectionAABB.minPoint.x;
    const height = selectionAABB.maxPoint.y - selectionAABB.minPoint.y;

    const centerPosition = scalarMul(
      add(selectionAABB.minPoint, selectionAABB.maxPoint),
      0.5
    );

    // if any are non-numeric, fail
    const sum = width + height;
    if (isNaN(sum) || !isFinite(sum)) {
      return SelectionBox.NO_BOUNDS;
    }

    centroidPosition = scalarMul(centroidPosition, 1.0 / groups.length);

    // calculate the x/y based on the anchor, if any
    return new SelectionBox({
      hasBounds: true,
      anchor: temporaryAnchor,
      centerPosition,
      centroidPosition,
      width,
      height,
      rotation: 0,
    });
  }

  static getSelectionBounds(
    selectedGroups: SvgGroup[],
    temporaryAnchor?: Handle,
    isSelectingTwoAdjacentPoints?: boolean
  ) {
    if (isSelectingTwoAdjacentPoints) {
      return SelectionBox;
    }
    switch (selectedGroups.length) {
      case 0:
        return SelectionBox.NO_BOUNDS;

      case 1:
        return this.getRotatedSelectionBox(selectedGroups[0]);

      default:
      case 2:
        return this.getAASelectionBox(selectedGroups, temporaryAnchor);
    }
  }

  static isLeftSideHandle = (handle: Anchor, exact = false) =>
    exact ? handle === 'lm' : /l/.test(handle);
  static isRightSideHandle = (handle: Anchor, exact = false) =>
    exact ? handle === 'rm' : /r/.test(handle);
  static isTopSideHandle = (handle: Anchor, exact = false) =>
    exact ? handle === 'tm' : /t/.test(handle);
  static isBottomSideHandle = (handle: Anchor, exact = false) =>
    exact ? handle === 'bm' : /b/.test(handle);
  static isCornerHandle = (handle: Anchor) =>
    ['tl', 'tr', 'bl', 'br'].indexOf(handle) > -1;
  static isHorizontalHandle = (handle: Anchor) =>
    ['rm', 'lm'].indexOf(handle) > -1;
  static isVerticalHandle = (handle: Anchor) =>
    ['tm', 'bm'].indexOf(handle) > -1;
  static isRotationHandle = (handle: Anchor) => /rotate/.test(handle);
  static isEdgeHandle = (handle: Anchor) =>
    !SelectionBox.isRotationHandle(handle) &&
    !SelectionBox.isCornerHandle(handle);

  static getCenterRelativeHandlePositions(
    bounds = SelectionBox.NO_BOUNDS,
    nonScalingPixelFactor = 1
  ) {
    let { width, height } = bounds;

    const h2 = height / 2.0;
    const w2 = width / 2.0;

    const left = -w2;
    const right = w2;
    const top = -h2;
    const bottom = h2;

    const handles = {} as HandleMapping;

    handles.centroid = new Point(0, 0);

    handles.tl = createHandle(
      left,
      top,
      -1,
      -1,
      RESIZE_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.tm = createHandle(
      0,
      top,
      0,
      -1,
      RESIZE_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.tr = createHandle(
      right,
      top,
      1,
      -1,
      RESIZE_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.br = createHandle(
      right,
      bottom,
      1,
      1,
      RESIZE_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.bl = createHandle(
      left,
      bottom,
      -1,
      1,
      RESIZE_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.bm = createHandle(
      0,
      bottom,
      0,
      1,
      RESIZE_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.rm = createHandle(
      right,
      0,
      1,
      0,
      RESIZE_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.lm = createHandle(
      left,
      0,
      -1,
      0,
      RESIZE_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.rotate_tl = createHandle(
      left,
      top,
      -1,
      -1,
      ROTATION_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.rotate_tr = createHandle(
      right,
      top,
      1,
      -1,
      ROTATION_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.rotate_br = createHandle(
      right,
      bottom,
      1,
      1,
      ROTATION_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    handles.rotate_bl = createHandle(
      left,
      bottom,
      -1,
      1,
      ROTATION_HANDLE_DISTANCE,
      nonScalingPixelFactor
    );

    return handles;
  }

  static getDimensionLabelRelativePositions(bounds = SelectionBox.NO_BOUNDS) {
    return {
      dimLabelX: { x: 0, y: bounds.height / 2 },
      dimLabelY: { x: bounds.width / 2, y: 0 },
    };
  }

  static getDisplayedHandles(
    bounds: { width: number; height: number },
    anchor: Anchor,
    nonScalingPixelFactor: number
  ) {
    const scaledWidth = bounds.width / nonScalingPixelFactor;
    const scaledHeight = bounds.height / nonScalingPixelFactor;

    // in this case, there's nothing to show
    if (
      scaledWidth < SIZE_TO_HIDE_BOUNDING_BOX_HANDLE &&
      scaledHeight < SIZE_TO_HIDE_BOUNDING_BOX_HANDLE
    ) {
      return {};
    }

    // gather up allowed handles
    // eslint-disable-next-line no-unused-vars
    let handles: { [key in `${Handle}` | `rotate_${Handle}`]?: boolean } = {};

    // if one axis is small, and the other is large enough, go ahead and show
    // their handles
    if (
      scaledWidth > SIZE_TO_HIDE_BOUNDING_BOX_HANDLE &&
      scaledHeight < SIZE_TO_INVERT_HIDDEN_BOUNDING_BOX_HANDLE
    ) {
      handles.tl =
        handles.tm =
        handles.tr =
        handles.bl =
        handles.bm =
        handles.br =
          true;
    } else if (
      scaledHeight > SIZE_TO_HIDE_BOUNDING_BOX_HANDLE &&
      scaledWidth < SIZE_TO_INVERT_HIDDEN_BOUNDING_BOX_HANDLE
    ) {
      handles.tl =
        handles.lm =
        handles.tr =
        handles.bl =
        handles.rm =
        handles.br =
          true;
    }
    // all handles are allowed
    else {
      handles.tl =
        handles.lm =
        handles.tr =
        handles.bl =
        handles.rm =
        handles.br =
        handles.tm =
        handles.bm =
          true;
    }

    // if an anchor is provided, remove the disallowed
    if (!isCenterAnchor(anchor)) {
      const allowed = ALLOWED_HANDLES[anchor];
      if (allowed) {
        // eslint-disable-next-line no-unused-vars
        const anchored: { [key in Handle]?: boolean } = {};

        // copy over what was allowed
        for (const handle of allowed) {
          anchored[handle] = handles[handle];
        }

        // always include the anchor
        anchored[anchor] = true;
        handles = anchored;
      }
    }

    // finally, copy rotation
    for (const corner of ['tl', 'tr', 'bl', 'br'] as const) {
      if (handles[corner]) {
        handles[`rotate_${corner}`] = true;
      }
    }

    return handles;
  }

  static getSelectionHandles(
    bounds = SelectionBox.NO_BOUNDS,
    nonScalingPixelFactor = 1,
    isSelectingTwoAdjacentPoints: boolean,
    selectedGroups: SvgGroup[]
  ) {
    const handlePositions = this.getCenterRelativeHandlePositions(
      bounds,
      nonScalingPixelFactor
    );

    let { centroidPosition, centerPosition, rotation: rotationAngle } = bounds;

    // when selecting two points as a line, there are no handles
    if (isSelectingTwoAdjacentPoints && selectedGroups?.length) {
      // TODO: for now, return no handles when selecting a line
      return {};
    }

    const rotationMtx = createRotationMtx(rotationAngle, centroidPosition);

    //Now add center position to each handle and then rotate around centroidPosition
    const handlePositionsMapped = (
      Object.keys(handlePositions) as (keyof typeof handlePositions)[]
    ).reduce((obj, hKey) => {
      const hp = add(handlePositions[hKey], centerPosition) as HandlePosition;
      hp.tp = transform(hp, rotationMtx);
      return {
        ...obj,
        [hKey]: hp,
      };
      // eslint-disable-next-line no-unused-vars
    }, {} as { [key in Handle]: HandlePosition });

    return handlePositionsMapped;
  }

  static getNearestHandle(
    xPixel: number,
    yPixel: number,
    // eslint-disable-next-line no-unused-vars
    handlePositions: { [key in Handle]: HandlePosition },
    nonScalingPixelFactor = 1,
    viewportState: ViewportState,
    distance = MINIMUM_BOUNDING_BOX_HANDLE_PROXIMITY,
    selectionBounds: SelectionBox,
    anchor: Handle,
    filterHidden = true
  ) {
    const minimumDistance = Math.pow(distance * nonScalingPixelFactor * 1.1, 2);

    // find the point on the canvas being tested
    const canvasHitPoint = transform(
      new Point(xPixel, yPixel),
      viewportState.screenToCanvasTransform
    );

    // gather up all handle IDs to check
    let handleIds = Object.keys(handlePositions) as Handle[];

    // remove hidden handles
    if (filterHidden) {
      const allowedHandles = this.getDisplayedHandles(
        selectionBounds,
        anchor,
        nonScalingPixelFactor
      );
      handleIds = handleIds.filter((key) => allowedHandles[key]);
    }

    // start testing for handles and finding the nearest allowed
    // handle of each type
    const nearest: {
      // eslint-disable-next-line no-unused-vars
      [key in 'rotate' | 'translate' | 'resize']?: {
        id: Handle;
        distance: number;
      };
    } = {};
    for (const id of handleIds) {
      const isRotation = /(rotate?(ion)?)/i.test(id);
      const isTranslate = /(cente?r(oid)?)/i.test(id);

      // determine the source handle type to use
      const source = isRotation
        ? 'rotate'
        : isTranslate
        ? 'translate'
        : 'resize';

      // calculate the distance
      const handle = handlePositions[id];
      const diffX = handle.tp.x - canvasHitPoint.x;
      const diffY = handle.tp.y - canvasHitPoint.y;
      const currentDistanceForSource = distanceSquared(diffX, diffY);
      const bestDistanceForSource =
        nearest[source]?.distance || Number.MAX_SAFE_INTEGER;

      // if it's a better match, save it
      if (
        currentDistanceForSource < bestDistanceForSource &&
        currentDistanceForSource < minimumDistance
      ) {
        nearest[source] = { id, distance: currentDistanceForSource };
      }
    }

    // determine the preferred handle
    const { resize, rotate } = nearest;
    if (resize) {
      return resize.id;
    } else if (rotate) {
      return rotate.id;
    }

    return nearest.translate?.id;
  }

  // finds the appropriate cursor for a handle
  static getCursorForHandle(handle: Handle) {
    const index = BOUNDING_BOX_HANDLES.indexOf(handle);
    return BOUNDING_BOX_HANDLE_RESIZE_DIRECTION[index];
  }

  // performs a hit test on this point
  static hitTest(xPixel: number, yPixel: number) {
    const el = document.getElementById('selection-box');
    return !!(
      el &&
      el instanceof SVGGraphicsElement &&
      HitDetection.hitTestSVG(el, xPixel, yPixel)
    );
  }

  static getDisplayLabels(width: string, height: string, displayUnits: string) {
    const x = mmToUnitFormattedStrWLabel(width, displayUnits);
    const y = mmToUnitFormattedStrWLabel(height, displayUnits);
    return { x, y };
  }

  anchor: Point;
  hasBounds: boolean;
  centerPosition: Point;
  centroidPosition: Point;
  rotation: number;
  width: number;
  height: number;
  left: number;
  right: number;
  top: number;
  bottom: number;

  constructor({
    hasBounds = false,
    centerPosition = new Point(0.0, 0.0),
    centroidPosition = new Point(0.0, 0.0),
    anchor = 'center',
    rotation = 0.0,
    width = 0.0,
    height = 0.0,
  } = {}) {
    const { x: cx, y: cy } = centroidPosition;
    const halfWidth = width * 0.5;
    const halfHeight = height * 0.5;
    const left = cx - halfWidth;
    const right = cx + halfWidth;
    const top = cy - halfHeight;
    const bottom = cy + halfHeight;

    // create the anchor as needed
    // anchorPoint needs to take into account the rotation of the selection box
    // This only works for single selection, because selection box for multiple groups automatically resets after transform is committed.
    //Fortunately, we don't support anchors with multiple selections, so this doesn't matter (yet)

    //Anchor points in local group space
    const anchorPointLocal =
      {
        tl: { x: -halfWidth, y: -halfHeight },
        tm: { x: 0, y: -halfHeight },
        tr: { x: halfWidth, y: -halfHeight },
        lm: { x: -halfWidth, y: 0 },
        rm: { x: halfWidth, y: 0 },
        bl: { x: -halfWidth, y: halfHeight },
        bm: { x: 0, y: halfHeight },
        br: { x: halfWidth, y: halfHeight },
      }[anchor] || subtract(centroidPosition, centerPosition); //Centroid position relative to center

    const anchorPointLocalAsPoint =
      anchorPointLocal instanceof Point
        ? anchorPointLocal
        : new Point(anchorPointLocal.x, anchorPointLocal.y);

    const anchorPoint = add(
      transform(anchorPointLocalAsPoint, createRotationMtx(rotation)),
      centerPosition
    );

    this.anchor = anchorPoint;
    this.hasBounds = hasBounds;
    this.centerPosition = centerPosition;
    this.centroidPosition = centroidPosition;
    this.rotation = rotation;
    this.width = width;
    this.height = height;
    this.left = left;
    this.right = right;
    this.top = top;
    this.bottom = bottom;
  }
}
