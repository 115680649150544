import { AMutBMap } from './AMutBMap.js';
import {
  cleanSimplePolygon,
  NSimplePolygonUnionsSync,
  simplePolygonIntersection,
  simplePolygonUnion,
  simplePolygonDifference,
} from '../Geometry/SimplePolygonOps';
import { simplifyPolygonPath } from '../Geometry/PathOps';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { getSvgPathCssClass } from '../Geometry/CutParamsOps';
import { mergeAABBs, doAABBsIntersect } from '../Geometry/AABBOps';
import {
  createSvgImage,
  createSvgPathWithCSSClasses,
} from '@shapertools/sherpa-svg-generator/SvgGenerator';
import { GroupId } from '@/Redux/Slices/SelectionSlice.js';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Path } from '@shapertools/sherpa-svg-generator/Path';
import { getTRSPathSet } from '@/UILayer/UI/ShapeShifter/ShapeShifterHelpers.js';
import { getTRSPathSet_deprecating } from '@/Geometry/SvgGroupOps.js';

export type Fragment = {
  simplePolygon: BasePath;
  groupIdList: GroupId[];
  AABB: AABB;
  batch?: number;
};

export type FragmentList = {
  fragments: { [key: string]: Fragment };
  nextFragmentId: number;
};

export type FragmentSvg = {
  fragmentId: string;
  fragmentIdStr: string;
  fragmentSvgStr: string;
  fragmentAABB: AABB;
};

export class FragmentOps {
  //path should be baked to canvas space

  static createFragment(
    simplePolygon: BasePath,
    groupIdList: GroupId[]
  ): Fragment {
    return {
      simplePolygon,
      groupIdList,
      AABB: simplePolygon.AABB,
    };
  }

  static createFragmentFromPath(path: Path | BasePath, groupIdList: GroupId[]) {
    if (!(path instanceof BasePath)) {
      const simplifiedPaths = simplifyPolygonPath(path);

      return simplifiedPaths.map((sp) =>
        this.createFragment(new BasePath({ outerPath: sp }), groupIdList)
      );
    }
    return [this.createFragment(path, groupIdList)];
  }

  static getFragmentSvg(fragment: Fragment, fragmentId: string): FragmentSvg {
    const fragmentCSS = getSvgPathCssClass('fragment');
    const fragmentIdStr = `fragment-id-${fragmentId}`;
    const fragmentSvgStr = createSvgPathWithCSSClasses(
      fragment.simplePolygon,
      fragmentIdStr,
      fragmentCSS
    );
    const fragmentAABB = fragment.AABB;

    return { fragmentId, fragmentIdStr, fragmentSvgStr, fragmentAABB };
  }
}

export class FragmentListOps {
  static createFragmentList(): FragmentList {
    return {
      fragments: {},
      nextFragmentId: 0,
    };
  }

  static selectFragmentsFromGroupIds(
    fragmentList: FragmentList,
    selectedGroupIds: GroupId[]
  ) {
    return selectedGroupIds
      .map((sId) => {
        let fragArr: { fragmentId: string; fragment: Fragment }[] = [];
        Object.keys(fragmentList.fragments).forEach((fk) => {
          if (fragmentList.fragments[fk].groupIdList.includes(sId)) {
            fragArr.push({
              fragmentId: fk,
              fragment: fragmentList.fragments[fk],
            });
          }
        });
        return fragArr;
      }) //Find all fragments matching any of the selected groupIds
      .flat()
      .filter(
        (fragIdPair, idx, arr) =>
          idx === arr.findIndex((fp) => fp.fragmentId === fragIdPair.fragmentId)
      ); //Remove duplicates
  }

  static selectFragmentsFromFragmentIds(
    fragmentList: FragmentList,
    selectedFragmentIds: string[]
  ) {
    return selectedFragmentIds.map((fId) => fragmentList.fragments[fId]);
  }

  static generateFragmentsSvgs(
    fragmentList: FragmentList,
    selectedGroupIds: GroupId[]
  ) {
    return this.selectFragmentsFromGroupIds(fragmentList, selectedGroupIds).map(
      (sf) => FragmentOps.getFragmentSvg(sf.fragment, sf.fragmentId)
    );
  }

  static generateFragmentsAABB(
    fragmentList: FragmentList,
    selectedGroupIds: GroupId[]
  ) {
    return this.selectFragmentsFromGroupIds(fragmentList, selectedGroupIds)
      .map((f) => f.fragment.AABB)
      .reduce((acc, aabb) => mergeAABBs(acc, aabb));
  }

  static generateFragmentsUnionSimplePolygons(
    selectedFragmentsSimplePolys: BasePath[]
  ) {
    // Prior method was much more complicated, but it turns out Clipper can handle just a dump of N simplePolygons

    //Oops - Spoke too soon:
    //  https://sourceforge.net/p/polyclipping/discussion/1148419/thread/bd521326/
    //  https://sourceforge.net/p/jsclipper/tickets/28/

    //C++ library doesn't have this, or has it very rarely.

    //Immer and Clipper don't play well together, so deep clone selected fragments
    const clonedSimplePolys = JSON.parse(
      JSON.stringify(selectedFragmentsSimplePolys)
    );

    return NSimplePolygonUnionsSync(clonedSimplePolys).map((sp) =>
      cleanSimplePolygon(sp)
    );
  }

  static generateFragmentsUnionBasePathsArray(
    selectedFragmentsSimplePolys: BasePath[]
  ) {
    return this.generateFragmentsUnionSimplePolygons(
      selectedFragmentsSimplePolys
    ); //Returns plain array of simplePolys
  }

  static generateFragmentsUnionPathSvg(
    selectedFragmentsSimplePolys: BasePath[]
  ) {
    const fragmentUnionId = ''; //No id needed at this time
    const fragmentUnionCSS = getSvgPathCssClass('fragment'); //Change if fragment union preview added to ShapeShifter
    const completedUnions = this.generateFragmentsUnionSimplePolygons(
      selectedFragmentsSimplePolys
    );

    return completedUnions
      .map((cu) =>
        createSvgPathWithCSSClasses(cu, fragmentUnionId, fragmentUnionCSS)
      )
      .join(' ');
  }

  static generateFragmentsUnionPathSvgFromIds(
    fragmentList: FragmentList,
    selectedFragmentIds: string[]
  ) {
    const selectedFragmentsSimplePolys = this.selectFragmentsFromFragmentIds(
      fragmentList,
      selectedFragmentIds
    )
      .map((f) => f.simplePolygon)
      .flat();

    return this.generateFragmentsUnionPathSvg(selectedFragmentsSimplePolys);
  }

  static generateFragmentsUnionPathFullSvg(
    fragmentList: FragmentList,
    selectedFragmentIds: string[]
  ) {
    const selectedFragments = this.selectFragmentsFromFragmentIds(
      fragmentList,
      selectedFragmentIds
    );
    const selectedFragmentsSimplePolys = selectedFragments
      .map((f) => f.simplePolygon)
      .flat();

    const svgAABB = selectedFragments
      .map((f) => f.AABB)
      .reduce((acc, aabb) => mergeAABBs(acc, aabb));

    const innerSvgStr = this.generateFragmentsUnionPathSvg(
      selectedFragmentsSimplePolys
    );

    return createSvgImage(innerSvgStr, svgAABB);
  }

  static addFragments(fragmentList: FragmentList, fragments: Fragment[]) {
    fragments.forEach((frag) => {
      fragmentList.fragments[fragmentList.nextFragmentId++] = frag;
    });
    return fragmentList;
  }

  static removeFragment(fragmentList: FragmentList, fragmentId: string) {
    delete fragmentList.fragments[fragmentId];
  }

  //Subtracts each of pathB from each pathA
  static whittleSimplePolygonWithSimplePolygons(
    simplePolygonA: BasePath,
    simplePolygonsB: BasePath[]
  ) {
    const intersectingSimplePolysB = simplePolygonsB.filter((sp) =>
      doAABBsIntersect(sp.AABB, simplePolygonA.AABB)
    );

    //In theory, clipper can subtract multiple shapes from a target shape in one op, but performance is way too slow. Doing this one at a time inside AMutBMap is way more performant (e.g. 1.5sec vs. DNF for complex scenes)

    //NB - each subtraction operation can produce 1 or more output paths, and these multiple outputs must be subtracted against each of the remaining simplePolygons
    // Given A = [A0] and B = [b0, b1, b2, ... bn]
    // A'
    const whittledSimplePolys = AMutBMap(
      [simplePolygonA],
      intersectingSimplePolysB,
      (a, b) => simplePolygonDifference(a, b)
    );

    //Simplify whittledSimplePolys to remove self-intersections
    return whittledSimplePolys
      .map((wsp) => simplePolygonUnion(wsp, wsp, true))
      .flat();
  }

  static getSimplePolygonsIntersection(
    simplePolygonA: BasePath,
    simplePolygonB: BasePath
  ) {
    if (simplePolygonA.AABB === undefined) {
      // debugger;
    }
    if (!doAABBsIntersect(simplePolygonA.AABB, simplePolygonB.AABB)) {
      return false;
    }
    const intersectingSimplePolys = simplePolygonIntersection(
      simplePolygonA,
      simplePolygonB
    );
    if (intersectingSimplePolys.length === 0) {
      return false;
    }

    return intersectingSimplePolys;
  }

  //Returns simplePolys
  static getFragmentsIntersection(fragA: Fragment, fragB: Fragment) {
    return this.getSimplePolygonsIntersection(
      fragA.simplePolygon,
      fragB.simplePolygon
    );
  }

  static generateFragmentsFromGroups(
    fragmentList: FragmentList,
    selectedGroups: SvgGroup[],
    hasTessellationFeatureFlag: boolean,
    batchProcess = true
  ) {
    //For incoming groups, every path is initially assigned to a separate fragment as the outerPath of a simplePolygon

    const incomingFragArr = selectedGroups
      .map((sg, j) => {
        const TRSPathSet = hasTessellationFeatureFlag
          ? getTRSPathSet(sg)
          : getTRSPathSet_deprecating(sg);
        return TRSPathSet.flat().map((bp, i) => {
          if (bp.outerPath) {
            return [bp.outerPath, ...(bp.holePaths || [])].map((path) =>
              FragmentOps.createFragmentFromPath(path, [sg.id])
            );
          }
          return FragmentOps.createFragmentFromPath(bp, [sg.id]);
        });
      })
      .flat(3);

    const priorFragArr = Object.keys(fragmentList.fragments).map(
      (key) => fragmentList.fragments[key]
    );

    //Batch processing
    const fragArray = [...priorFragArr, ...incomingFragArr];

    if (batchProcess) {
      // Benchmarking
      const startTime = performance.now();

      const batchSize = 25;
      let batchedFrags: Fragment[] = [];
      for (let i = 0, j = fragArray.length; i < j; i += batchSize) {
        const thisFragBatch = this.addFragmentsToFragArray(
          fragArray.slice(i, i + batchSize)
        ).map((f) => ({
          ...f,
          batch: i,
        }));
        batchedFrags = this.addFragmentsToFragArray(
          [...batchedFrags, ...thisFragBatch],
          true
        );
      }

      const newFragmentList = FragmentListOps.addFragments(
        FragmentListOps.createFragmentList(),
        batchedFrags
      );

      const endTime = performance.now();
      return {
        fragmentList: newFragmentList,
        processingTime: endTime - startTime,
      };
    }

    //Non-batch processing
    const startTime = performance.now();

    const outputFragmentList = FragmentListOps.addFragments(
      FragmentListOps.createFragmentList(),
      this.addFragmentsToFragArray(fragArray)
    );

    const endTime = performance.now();
    return {
      fragmentList: outputFragmentList,
      processingTime: endTime - startTime,
    };
  }

  static generateCombinedGroupIdList(
    fragAGroupIds: GroupId[],
    fragBGroupIds: GroupId[]
  ) {
    return [...fragAGroupIds, ...fragBGroupIds].filter(
      (id, idx, arr) => idx === arr.indexOf(id)
    ); //filter removes duplicate ids
  }

  /* Given [A,B,C], 
  First test A against [B,C] => A' = [A n B, A n C, A - (A n B) - (A n C)], and B' = [B - A n B] and C' = [C - A n C]
  Then test B' against [C'] => B'' = [B' n C', B' - B' n C'] and C'' = [C' - B' n C']
  Then test C'' against [] => C''' = C''

  Result is [A', B'', C''']
*/

  static addFragmentsToFragArray(
    incomingFragArr: Fragment[],
    batchMerge = false,
    recursionDepth = 0
  ) {
    let currentFrags = [...incomingFragArr];

    if (currentFrags.length === 0) {
      return [];
    }

    const completedFrags: Fragment[] = [];
    do {
      const fragA = currentFrags.pop();
      if (!fragA) {
        break;
      }

      //If no more frags left to compare, add current frag to results and break
      if (currentFrags.length === 0) {
        completedFrags.push(fragA);
        break;
      }

      //Compute array of intersections of fragA with all frags left in currentFrags queue
      //Returns an array with each entry being false if no intersection and an array of one or more paths if there is an intersection.
      ///Need to use an array for each intersection because one intersection can produce multiple separate shapes.
      const intersectingSimplePolys = currentFrags.map((fragB) =>
        batchMerge === true && fragA.batch === fragB.batch
          ? false
          : this.getFragmentsIntersection(fragA, fragB)
      );

      //Build list of combined groupIds for fragA and all intersecting shapes, if any
      //This gets a list of all groupIds for all of the intersectingSimplePolys, with potential duplicates
      const intersectingGroupIdList = intersectingSimplePolys
        .map((p, idx) => (p !== false ? currentFrags[idx].groupIdList : [])) // eslint-disable-line no-loop-func
        .flat();

      if (intersectingGroupIdList.length === 0) {
        //No intersections, so move on
        completedFrags.push(fragA);
        continue;
      }

      //This combines the intersectingGroupIdList with fragA.groupIdList and eliminates duplicates
      const combinedGroupIdList = this.generateCombinedGroupIdList(
        fragA.groupIdList,
        intersectingGroupIdList
      );

      //Filter out non-intersections and flatten so each simplePoly is separate
      //Do this after combined group id determined, as flattened array is not 1:1 with currentFrags
      const flatIntersectingSimplePolys = intersectingSimplePolys
        .filter((i): i is BasePath[] => i !== false)
        .flat();

      //Now subtract each intersecting poly from fragA, with the result being used as the source for the next subtraction.
      //This is 'whittling' fragA by all the intersecting polys, with each subtraction potentially producing 0, 1, or more resulting fragments, then flatten final results
      const fragADiffs = this.whittleSimplePolygonWithSimplePolygons(
        fragA.simplePolygon,
        flatIntersectingSimplePolys
      ).map((fragADiffSimplePoly) =>
        FragmentOps.createFragment(fragADiffSimplePoly, combinedGroupIdList)
      );

      //Create fragments for each of the intersecting simplePolygons
      const intersectingFrags = flatIntersectingSimplePolys.map(
        (intersectingSimplePoly) =>
          FragmentOps.createFragment(
            intersectingSimplePoly,
            combinedGroupIdList
          )
      );

      //RECURSION! Need to test intersecting frags against themselves and fragment further if necessary
      const testedIntersectingFrags = this.addFragmentsToFragArray(
        intersectingFrags,
        batchMerge,
        recursionDepth + 1
      );

      //Add intersecting frags and fragA - intersecting frags to result set
      completedFrags.push(...fragADiffs, ...testedIntersectingFrags);

      //Build new set of frags for next iteration
      const nextFrags = currentFrags
        .map((fragB, idx) => {
          //If fragB didn't intersect fragA, pass on to next set unchanged
          if (intersectingSimplePolys[idx] === false) {
            return fragB;
          }

          //Otherwise, whittle fragB with all the intersecting frags and return 0, 1, or more results.
          //It's ok to use the flatIntersectingPaths instead of the testedIntersectingFrags, because the former is a union of the latter.
          return this.whittleSimplePolygonWithSimplePolygons(
            fragB.simplePolygon,
            flatIntersectingSimplePolys
          ).map((fbp) => FragmentOps.createFragment(fbp, combinedGroupIdList));
        })
        .flat();

      //Alternative to recursion - not as performant
      //Add intersectingFrags to nextFrags
      // completedFrags.push(...fragADiffs);
      // nextFrags.push(...intersectingFrags);

      currentFrags = nextFrags;
    } while (currentFrags.length > 0);

    //Clean final fragments
    const allFrags = completedFrags
      .map((f) => ({
        ...f,
        simplePolygon: cleanSimplePolygon(f.simplePolygon),
      }))
      .filter(
        (f) =>
          (f.simplePolygon.outerPath?.points &&
            f.simplePolygon.outerPath?.points.length > 0) ||
          f.simplePolygon.points.length > 0
      )
      .map((f) =>
        FragmentOps.createFragmentFromPath(f.simplePolygon, f.groupIdList)
      )
      .flat();
    return allFrags;
  }
}
