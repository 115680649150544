import BaseAction from './BaseAction';
import AddGeometryAction from '@/Actions/AddGeometry';
import { getPositionFromSvgViewbox } from '@/Utility/viewbox';
import { selectSVGViewbox } from '@/Redux/Slices/ViewportSlice';

import {
  clearSearchResults,
  newIconSearch,
  setScrollPosition,
} from '@/Redux/Slices/IconSearchSlice';
import {
  Shape,
  Tool,
  ToolParams,
} from '@shapertools/sherpa-svg-generator/SvgGroup';

export default class IconSearchAction extends BaseAction {
  previousQuery?: string;

  search(query: string, page = 1, append = false) {
    this.previousQuery = query;
    this.dispatch(newIconSearch({ query, page, append }));
  }

  clear() {
    this.dispatch(clearSearchResults());
  }

  add(
    icon: { id: string; iconURI: string; iconSVG: string },
    query = this.previousQuery
  ) {
    const { id: iconId, iconURI: uriSVG, iconSVG: rawSVG } = icon;
    const params = { iconId, query } as ToolParams<Shape.ICON>;
    const addGeometryAction = this.createAction(AddGeometryAction);
    const viewbox = this.useSelector(selectSVGViewbox);
    const position = getPositionFromSvgViewbox(viewbox);

    // add to the view
    addGeometryAction.addSvg(
      'icon',
      { rawSVG, uriSVG, tool: new Tool(Shape.ICON, params) },
      position
    );
  }

  setScrollPosition(position: number) {
    this.dispatch(setScrollPosition(position));
  }
}
