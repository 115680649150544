import React from 'react';
import { useSelector } from 'react-redux';
import { selectDepthModeSvg } from '@/Redux/Slices/CanvasSlice';
import SvgGroupCls from '../State/Helpers/SvgGroup';

type Props = {
  className?: string;

  extendGroup?: (group: SvgGroupCls) => object;
};

// renders a view of
export default function ReviewModeGroup(props: Props) {
  //Gets the depth-clipped svg for the entire workspace
  //Note that this is empty unless async review path generator has completed it's work.
  //See use of Loading icon on ReviewUI component
  const depthSvg = useSelector(selectDepthModeSvg)
    .split(/\/>/g)
    .reverse()
    .filter((path) => !!path)

    // extract out all depths and IDs. Maybe refactor the selectDepthModeSvg
    // to include this data as part of the generation step
    .map((path) => {
      const html = `${path} />`;
      const id = html.match(/id="[^"]+/g)?.[0]?.substring(4);
      const depth = html.match(/cut-depth--\d+/g)?.[0]?.substring(11);
      return { id, html, depth };
    });

  // must generate using a string because React/Chrome seem to convert
  // `clipPath` to `clippath` which doesn't actually work?
  const clips = depthSvg
    .map(
      ({ id, html }) => `
    ${html}
    <clipPath id="clip--${id}" >
      <use xlink:href="#${id}" />
    </clipPath>
  `
    )
    .join('\n');

  return (
    <>
      <defs dangerouslySetInnerHTML={{ __html: clips }} />

      {depthSvg.map(({ id, depth }) => (
        <>
          <use
            xlinkHref={`#${id}`}
            className={`review-depth--${depth}`}
            clipPath={`url(#clip--${id})`}
          />
          <g clipPath={`url(#clip--${id})`}>
            <use xlinkHref={`#${id}`} className={`review-shadow--${depth}`} />
          </g>
        </>
      ))}
    </>
  );
}
