import React from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';

// components
import DistanceBetweenPoints from './Polyline/DistanceBetweenPoints';
import { selectSelectedGroupIds } from '@/Redux/Slices/SelectionSlice';

const NO_OVERRIDES = { x: 0, y: 0 };

export default function SelectedLine({ ui }) {
  const [a, b] = useSelector(selectSelectedLine);
  const getGroupById = useSelector(selectGetGroupById);
  const selectedGroupIds = useSelector(selectSelectedGroupIds);

  // only show if there are two groups and the IDs match the selected line
  if (
    selectedGroupIds.length !== 2 ||
    !selectedGroupIds?.includes(a) ||
    !selectedGroupIds?.includes(b)
  ) {
    return null;
  }

  const pointA = getGroupById(a);
  const pointB = getGroupById(b);
  const groupA = ui.groups.find((group) => group.id === a);
  const groupB = ui.groups.find((group) => group.id === b);
  const overridesA =
    ui.overrides?.groups?.[pointA.id]?.translate || NO_OVERRIDES;
  const overridesB =
    ui.overrides?.groups?.[pointB.id]?.translate || NO_OVERRIDES;

  return (
    <>
      <DistanceBetweenPoints
        origin={groupA}
        target={groupB}
        modified={false}
        label={''}
        ui={ui}
      />
      <line
        x1={pointA.position.x + overridesA.x}
        y1={pointA.position.y + overridesA.y}
        x2={pointB.position.x + overridesB.x}
        y2={pointB.position.y + overridesB.y}
        className='selected-line'
      />
    </>
  );
}
