import React from 'react';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

// this will eventually do more
export default function Group(props: Props) {
  return props.className ? (
    <div className={props.className}>{props.children}</div>
  ) : (
    <>{props.children}</>
  );
}
