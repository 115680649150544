import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// actions
import { useAction } from '@/Actions/useAction';
import LineToolAction from '@/Actions/LineTool';

// selectors
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import { selectInsertPointModeOptions } from '@/Redux/Slices/UISlice';
import SvgGroupCls from '../../State/Helpers/SvgGroup';

// components
import Layer from '../Layer';
import UIState from '@/UILayer/State/UIState';

type Props = {
  className?: string;
  group: SvgGroupCls;
  ui: UIState;
  selected: boolean;
};

// renders a preview shape
export function EditableShape({ group, ui, ...props }: Props) {
  const getSvgGroupById = useSelector(selectGetGroupById);
  const lineTool = useAction(LineToolAction);
  const found = getSvgGroupById(group.id);
  const { closed, points = [] } = found.tool?.params;
  const editing = ui.isShapeBeingActivelyModified(group);
  const allSelected = lineTool.allPointsSelected(group);
  const isEditing =
    useSelector(selectInsertPointModeOptions)?.groupId === group.id;
  const selected = isEditing || allSelected;

  const path = points
    .map((id: string, i: number) => {
      const point =
        ui.groups.find((child: SvgGroupCls) => child.id === id) ||
        getSvgGroupById(id);
      if (!point) {
        return '';
      }

      const x = 'cx' in point ? point.cx : point.position?.x;
      const y = 'cy' in point ? point.cy : point.position?.y;

      return `${i === 0 ? 'M' : 'L'} ${x} ${y}`;
    })
    .join(' ');

  const svg = `
    <g id="sg-${group.id}" >
      <g class="pathGroup ${closed ? 'closed' : ''}" >
        <path class="basePath" d="${path} ${closed ? 'Z' : ''}" />
      </g>
    </g>
  `;

  const shapeCx = classNames(
    'svg-group design',
    { selected, editing },
    props.className
  );
  return (
    <Layer
      id={group.id}
      className={shapeCx}
      x={0}
      y={0}
      rotation={group.rotation}
      stretchMatrix={group.stretchMatrix}
      svgGroup={svg}
    />
  );
}
