import Bounds from '@/Utility/bounds';
import { rotateAroundPoint } from '@/Utility/rotation';
import SelectionBox from '../../../Helpers/SelectionBoxHelper';
import {
  initialMatrix33,
  multiplyMatrix33,
  getSVGTransformParams,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import { subtract } from '../../../Geometry/PointOps';
import {
  createScaleMtx,
  createRotationMtx,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import UIState from '../UIState';
import { IPoint } from '@shapertools/sherpa-svg-generator/Point';

const NONE = Object.freeze({});

type Overrides = {
  translate?: IPoint;
  resize?: {
    changedVertical?: boolean;
    changedHorizontal?: boolean;
    origin?: string;
  };
  rotate?: number;
};

export default class BoundingBox {
  // original references
  overrides: Overrides;
  ui: UIState;

  left: number;
  right: number;
  top: number;
  bottom: number;
  width: number;
  height: number;
  hw: number;
  hh: number;
  centroid: IPoint;
  center: IPoint;
  rotation: number;
  anchor: IPoint;
  origin?: string;
  changedVertical?: boolean;
  changedHorizontal?: boolean;

  // special rules
  hideBoundingBoxDetails: boolean;

  centroidRotationSvgTransform: string;
  mirrorX: -1 | 1;
  mirrorY: -1 | 1;

  constructor(ui: UIState, overrides: Overrides = NONE) {
    const {
      selectionBounds,
      selectedGroups,
      isSingleSelection,
      isMultiSelection,
    } = ui;

    let { left, right, top, bottom, centerPosition, centroidPosition } =
      selectionBounds;
    let rotation = 0;
    let width = right - left;
    let height = bottom - top;

    // get the center positions
    const centroid = {
      x: centroidPosition.x,
      y: centroidPosition.y,
    };

    const center = {
      x: centerPosition.x,
      y: centerPosition.y,
    };

    // adjust all position by translation changes
    if (ui.overrides?.translate) {
      const { x, y } = ui.overrides.translate;
      left += x;
      right += x;
      top += y;
      bottom += y;
      center.x += x;
      center.y += y;
      centroid.x += x;
      centroid.y += y;
    }

    // when working with a single group, make sure
    // use the groups current rotation
    if (isSingleSelection) {
      const [group] = selectedGroups;
      rotation = group?.rotation || 0;
    }

    // apply override rotation
    if (ui.overrides?.rotation) {
      rotation += ui.overrides.rotation;
    }

    // apply scaling overrides
    if (ui.overrides?.resize) {
      const { fromNearX, fromNearY, fromCenter, x, y } = ui.overrides.resize;

      // calculate diffs
      const diffX = width * x - width;
      const diffY = height * y - height;
      const hdx = diffX * 0.5;
      const hdy = diffY * 0.5;

      // center resizing
      if (fromCenter) {
        left -= hdx;
        right += hdx;
        top -= hdy;
        bottom += hdy;
      }
      // individual axes
      else {
        // x axes
        if (fromNearX) {
          left -= diffX;
          if (isMultiSelection) {
            center.x -= hdx;
            centroid.x -= hdx;
          }
        } else if (diffX) {
          right += diffX;
          if (isMultiSelection) {
            center.x += hdx;
            centroid.x += hdx;
          }
        }

        if (fromNearY) {
          top -= diffY;
          if (isMultiSelection) {
            center.y -= hdy;
            centroid.y -= hdy;
          }
        } else if (diffY) {
          bottom += diffY;
          if (isMultiSelection) {
            center.y += hdy;
            centroid.y += hdy;
          }
        }
      }

      // recalculate size after update
      width = right - left;
      height = bottom - top;
    }

    // check for scaling
    const changedHorizontal = overrides.resize?.changedHorizontal;
    const changedVertical = overrides.resize?.changedVertical;
    const hw = width * 0.5;
    const hh = height * 0.5;

    // recalculate all actual positions
    // TODO: this might need to be different since centroid isn't
    // always the center
    left = centroid.x - hw;
    right = centroid.x + hw;
    top = centroid.y - hh;
    bottom = centroid.y + hh;

    // find the anchored position
    const anchorFrom = isSingleSelection
      ? selectedGroups[0]?.anchor
      : 'centroid';
    const anchor = SelectionBox.getAnchoredPosition(
      { left, right, top, bottom } as SelectionBox,
      centroid,
      rotation,
      anchorFrom
    );

    // if there's a rotation, apply it to the anchor
    if (rotation) {
      const [x, y] = rotateAroundPoint(
        centroid.x,
        centroid.y,
        anchor.x,
        anchor.y,
        -rotation
      );
      anchor.x = x;
      anchor.y = y;
    }

    //Bounding boxes are rotated around their centroid, not the center of the box. Therefore, we need to compute a rotation matrix with the centroid as the rotation origin. A plain 'rotate()' transform assumes a rotation origin of 0,0, which is not the centroid when multiple groups are selected.

    let centroidRotationMtx;
    if (rotation === 0) {
      centroidRotationMtx = initialMatrix33;
    } else {
      //The outer group already translates the box center to the group position, so we need to use the centroid's relative position WRT the center as the rotation origin for the inner svg group
      const centroidRelativeToCenter = subtract(centroid, center);
      centroidRotationMtx = createRotationMtx(
        rotation,
        centroidRelativeToCenter
      );
    }

    //Negative width and height values for Svg <rect> are illegal, though some browsers render it correctly anyways
    //To avoid this, add a mirror transform if either are negative and change dims to positive values

    const mirrorX = width < 0 ? -1 : 1;
    const mirrorY = height < 0 ? -1 : 1;

    if (mirrorX === -1 || mirrorY === -1) {
      centroidRotationMtx = multiplyMatrix33(
        centroidRotationMtx,
        createScaleMtx(mirrorX, mirrorY)
      );
      width = Math.abs(width);
      height = Math.abs(height);
    }

    const centroidRotationSvgTransform =
      getSVGTransformParams(centroidRotationMtx);

    // original references
    this.overrides = overrides;
    this.ui = ui;

    // create a bounding box data, if needed
    this.left = left;
    this.right = right;
    this.top = top;
    this.bottom = bottom;
    this.width = width;
    this.height = height;
    this.hw = hw;
    this.hh = hh;
    this.centroid = centroid;
    this.center = center;
    this.rotation = rotation;
    this.anchor = anchor;
    this.origin = overrides.resize?.origin;
    this.changedVertical = changedVertical;
    this.changedHorizontal = changedHorizontal;

    // special rules
    this.hideBoundingBoxDetails =
      !!overrides.translate && !(overrides.resize || overrides.rotate);

    this.centroidRotationSvgTransform = centroidRotationSvgTransform;
    this.mirrorX = mirrorX;
    this.mirrorY = mirrorY;
  }

  get aabb(): BoundingBox | Bounds {
    return this.rotation ? new Bounds(this) : this;
  }
}
