// appends the .closest function onto elements
// to make it easier to see when a selector is
// found within the context of an element

if (!Element.prototype.matches) {
  Element.prototype.matches =
    (Element.prototype as any).msMatchesSelector ||
    (Element.prototype as any).webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s: string) {
    let el: Node | null = this;

    do {
      if (Element.prototype.matches.call(el, s)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
