import { duplicateSelectedGroups } from '@/CanvasContainer/CanvasActions';
import BaseAction from './BaseAction';
import { Point } from '@shapertools/sherpa-svg-generator/Point';

export default class DuplicateGroupsAction extends BaseAction {
  duplicate(groupIds: string[], offset = new Point(5, 5)) {
    const { dispatch } = this;
    dispatch(duplicateSelectedGroups({ groupIds, offset }));
  }
}
