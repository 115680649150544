// unit conversions
import math from '@shapertools/sherpa-svg-generator/mathjs';

// debugging helper
const debug = false;
let enableLogs = false;

// internal logging for tests
const log = (...msg: any) => enableLogs && console.log(...msg);
const TESTS = [
  ['mm', '1+1', '2.0000'],
  ['in', '1+1', '2.0000'],
  ['mm', '.25+.05', '0.3000'],
  ['mm', '.1+.1+.1', '0.3000'],
  ['in', '0.1+.1', '0.2000'],
  ['mm', '1in+.1in+0.9in', '50.8000'],
  ['in', '1in+.1in+0.9in', '2.0000'],
  ['in', '2 + 8 / 4', '4.0000'],
  ['in', '(2 + 8) / 4', '2.5000'],
  ['in', '-1-1', '-2.0000'],
  ['mm', '-2mm+-5mm', '-7.0000'],
  ['in', '2/2', '1.0000'],
  ['in', '8*0.25', '2.0000'],
  ['mm', '1m', '1000.0000'],
  ['mm', '1in', '25.4000'],
  ['mm', '5×5', '25.0000'],
  ['mm', '5x5', '25.0000'],
  ['mm', '5 × 5', '25.0000'],
  ['mm', '5 x 5', '25.0000'],
  ['mm', '5 × 1in', '127.0000'],
  ['mm', '5 x 1in', '127.0000'],
  ['mm', '5,00+10,00', '15.0000'],
  ['in', '8-(0.625*2)', '6.7500'],
  ['in', '8 - (0.625*2)', '6.7500'],
  ['in', '8 - (.625*2)', '6.7500'],
  ['in', '8 / -2', '-4.0000'],
  ['in', '4 + 1/4', '4.2500'],
  ['in', '4 1/4', '4.2500'],
];

type Operation = (a: number, b: number) => number;
type Evaluator = [string, Operation];

// expression evaluators
const EVALUATORS: Evaluator[] = [
  ['^', (a, b) => Math.pow(a, b)],
  ['*', (a, b) => a * b],
  ['x', (a, b) => a * b],
  ['×', (a, b) => a * b],
  ['/', (a, b) => a / b],
  ['÷', (a, b) => a / b],
  ['+', (a, b) => a + b],
  ['-', (a, b) => a - b],
];

// perform unit conversions
function convertValue(value: number, fromUnit: string, toUnit: string) {
  let unit = math.unit(`${value} ${fromUnit}`);

  if (fromUnit !== toUnit) {
    unit = unit.to(toUnit);
  }

  return unit.toNumber(toUnit).toFixed(4);
}

// reduce an expression down by finding all
// instances of a specific operator
function reduce(parts: string[], operator: string, operation: Operation) {
  for (let i = parts.length; i-- > 0; ) {
    if (parts[i] === operator) {
      const a = parts[i - 1];
      const b = parts[i + 1];
      const result = operation(parseFloat(a), parseFloat(b));

      // remove the parts
      parts.splice(i, 2);
      parts[i - 1] = result.toFixed(4);

      // can safely skip this part
      i--;
    }
  }
}

function evaluateParenthesis(input: string, outputAs: string) {
  let expression = input;

  // handle parens
  for (let i = expression.length; i-- > 0; ) {
    // find the matching close, since we're doing
    // this in reverse, there shouldn't be any
    // other opens from beyond this point
    if (expression[i] === '(') {
      const close = expression.substr(i).indexOf(')') + i + 1;
      const outer = expression.substr(i, close - i);
      const inner = outer.substr(1, outer.length - 2);

      // recursive function so can't put them in an order that
      // doesn't have one over the other
      // eslint-disable-next-line
      const evaluated = evaluateExpression(inner, outputAs);

      // replace the contents
      expression = [
        expression.substr(0, i),
        evaluated,
        expression.substr(close),
      ].join(' ');
    }
  }

  return expression;
}

// convert fractions into decimals
function evaluateFractions(expression: string) {
  return expression.replace(
    /\d+(\.\d*)?\/\d+(\.\d*)?/gi,
    (fraction: string, p1: number, p2: number, index: number) => {
      const [numerator, denominator] = fraction.split('/');
      return `${parseFloat(numerator) / parseFloat(denominator)}`;
      // // check the prior string to see if this should be
      // // added on or not
      // let add = true;
      // for (let i = index; i-- > 0; ) {
      //   const char = expression[i];

      //   // if an operator is specified, then no addition is
      //   // required for this part
      //   if (['+', '-', '/', '*', 'x', '×'].includes(char)) {
      //     add = false;
      //     break;
      //   }

      //   // if this is anything except for a space, then we can stop
      //   // checking
      //   if (char !== ' ') {
      //     break;
      //   }
      // }

      // const [numerator, denominator] = fraction.split('/');
      // return `${add ? '+ ' : ''} ${
      //   parseFloat(numerator) / parseFloat(denominator)
      // }`;
    }
  );
}

// replace special symbols for units
function evaluateSpecialSymbols(expression: string) {
  return expression.replace(`"`, 'in').replace(`'`, 'ft');
}

function evaluateCommasAsDecimals(expression: string) {
  // if there are any decimals, just remove all
  // commas and use numbers as usual
  if (/\./.test(expression)) {
    return expression.replace(/,/g, '');
  }

  // since no decimals were identified, just replace
  // any commas (if any) with decimals
  return expression.replace(/,/g, '.');
}

// make breathing room for other operators
function cleanUpInput(input: string) {
  let expression = ` ${input} `;

  // before we start, anything that's separated by spaces only (with no operators) should
  // be converted into an addition operator
  expression = expression.replace(/\d +\d/g, (match) => {
    return match.replace(/ +/g, '+');
  });

  return (
    expression
      .replace(/ */g, '')

      // check for negative numbers that have spaces in front
      // .replace(/\-[^0-9\.]+[0-9\.]/g, match => match.replace(' ', ''))
      .replace(/\+[^-]+-/g, '-')

      // make sure that negatives have an operator before them - this
      // prevents compacted expression from causing issues
      // (for example) 2m-1m is converted to 2m + -1m
      .replace(/[^+-/÷*×x] *-/g, (match) => match.replace('-', '+ -'))

      // puts spacing around math operators -- this skips the minus since
      // it could be interpreted as a negative number. This is handled
      // separately below
      .replace(/[+/^÷*×x]/gi, (match) => ` ${match} `)

      // when inputting a decimal, but without a
      // leading number, make sure to add a zero
      // for example .125 will become 0.125
      .replace(/.*\.\d+/i, (match) => {
        const decimal = match.indexOf('.');

        // if just a decimal with no number before it
        return /\d/.test(match[decimal - 1]) ? match : match.replace('.', '0.');
      })

      // when inputing a decimal, but without a leading number, make sure to add a zero
      // for example .125 will become 0.125
      .replace(/[^\d]\.\d+/gi, (match) => {
        const decimal = match.indexOf('.');
        log('decimal replace', match, decimal);

        // if just a decimal with no number before it
        return /\d/.test(match[decimal - 1]) ? match : match.replace('.', '0.');
      })

      // can't start with an operator
      .replace(/^ *[+/^÷*x×]/gi, (match) => '')

      // removes trailing and leading whitespace along with any
      // excessive spacings within the expression
      .replace(/(^ *| *$)/gi, '')
      .replace(/ +/, ' ')
      .replace(/^ *| *$/, '')
  );
}

// replace numbers with reduced forms
function convertMismatchedUnits(expression: string, outputAs: string) {
  const convert = (match: string) => {
    let fromUnit = outputAs;

    // detect (and remove) the unit, if any
    let number = match.replace(/[a-z]+/gi, (innerMatch) => {
      fromUnit = innerMatch.toLowerCase();

      // clear out the unit
      return '';
    });

    // return the converted value
    const converted = convertValue(parseFloat(number), fromUnit, outputAs);
    log('convert mismatched', number, fromUnit, outputAs, converted);
    return `${converted} `;
  };

  // perform two passes to prevent mm and m from
  return expression.replace(/\d+(\.\d*)? ?(in|mm|m|ft)?/gi, convert);
  // .replace(/\d+(\.\d*)? ?(m)?/gi, convert);
}

// performs the final calculation using the operators and numbers
function performCalculation(expression: string): string {
  // now start reducing
  const parts = expression.split(/ +/);
  for (const evaluator of EVALUATORS) {
    reduce(parts, ...evaluator);
  }

  const [result] = parts;
  return result;
}

// evaluates an expression
export default function evaluateExpression(input: string, outputAs: string) {
  let expression = input;

  // replace fractions with numbers
  expression = evaluateParenthesis(expression, outputAs);
  log('after evaluateParenthesis', expression);
  expression = evaluateFractions(expression);
  log('after evaluateFractions', expression);
  expression = evaluateSpecialSymbols(expression);
  log('after evaluateSpecialSymbols', expression);
  expression = evaluateCommasAsDecimals(expression);
  log('after evaluateCommasAsDecimals', expression);
  expression = cleanUpInput(expression);
  log('after cleanUpInput', expression);

  // is using specified units
  if (outputAs) {
    expression = convertMismatchedUnits(expression, outputAs);
    log('after convertMismatchedUnits', expression);
  }

  // calculate the final result
  const result = performCalculation(expression);
  log('result', result);

  // give back the result
  return !Number.isNaN(result) &&
    result !== null &&
    result !== undefined &&
    result !== ''
    ? parseFloat(result)
    : NaN;
}

// runs a series of evaluation test
function runTests() {
  let errors = 0;
  TESTS.forEach(([unit, expression, expected]) => {
    const result = evaluateExpression(expression, unit).toFixed?.(4);
    if (result !== expected) {
      console.error(
        'Failed!',
        expression,
        'for',
        unit,
        '\n',
        `    result: ${result}`,
        '\n',
        `  expected: ${expected}`
      );
      errors++;

      // run it again and dump the contents
      enableLogs = true;
      evaluateExpression(expression, unit);
      log('\n');
      enableLogs = false;
    }
  });

  if (!errors) {
    console.log(`All ${TESTS.length} expression tests passed`);
  }
}

// inline tests to make sure not to break anything
// we should consider making these unit tests eventually
if (debug) {
  log('\n\n\ntesting ========');
  setTimeout(runTests, 1000);
}
