import classNames from 'classnames';
import React from 'react';

type Props = {
  classNames?: string;
};

export default function Separator(props: Props) {
  const classCx = classNames(
    'application-menu--separator',
    props.classNames && {
      [props.classNames]: true,
    }
  );

  return <div className={classCx} />;
}
