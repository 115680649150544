import { createPortal } from '@/Utility/react';
import { useEffect, useState } from 'react';
import { useAction } from '@/Actions/useAction';
import { useSelector } from 'react-redux';

import { selectExportAccess } from '@/Redux/Slices/ShaperHubSlice';

import { addAttributeToUser, openFlow } from '@/Utility/userflow';
import waitFor from '@/Utility/wait-for';
import { cancelEvent } from '@/Utility/events';

import ModalActions from '@/Actions/Modal';
import DownloadAction from '@/Actions/Download';
import AlertAction from '@/Actions/Alert';

import ExportCount from './ExportCount';
import Button from '../Button';
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '../TranslationText/TranslationText';

import { ALERT_TYPES, FLOW_IDS, MODAL_TYPES } from '@/defaults';

export type ExportTargets = 'svg' | 'origin' | 'dxf';

type ExportStates = 'upgrade' | 'download' | 'exhausted' | 'done';
type Props = {
  target: ExportTargets;
};

export default function ExportProgressModal(props: Props) {
  const modalActions = useAction(ModalActions);
  const { unlimited, remaining, limit } = useSelector(selectExportAccess);
  const { target } = props;
  const initialStatus =
    remaining === 0 ? 'exhausted' : unlimited ? 'download' : 'upgrade';

  // set the count to show, if any
  const [count, setCount] = useState(remaining);
  const [status, setStatus] = useState<ExportStates>(initialStatus);
  const isDone = status === 'done';

  // handle downloading
  const downloadAction = useAction(DownloadAction);
  const alertAction = useAction(AlertAction);

  // close the process
  function onClose() {
    modalActions.closeModal();
  }

  async function onUpgrade() {
    onClose();
    addAttributeToUser('exports_remaining', remaining);
    openFlow(FLOW_IDS.UPGRADE_STUDIO);
  }

  // when opening the modal, if the user has unlimited downloads
  // then there's nothing to do except show the progress
  useEffect(() => {
    if (unlimited) {
      onDownload();
    }
  }, [unlimited]);

  // handles closing the menu
  function onDismissExportMenu() {
    onClose();
  }

  // performs the download
  async function onDownload() {
    const requestedAt = Date.now();

    // change the status for downloads
    setStatus('download');
    await waitFor(500);
    setCount(count! - 1);

    // TODO: kick off actual download
    try {
      if (target === 'origin') {
        await downloadAction.exportToOrigin();
        alertAction.set({
          icon: 'check',
          modalIcon: 'none',
          msg: 'SVG exported to My Files',
          i18nKey: `export-to-origin-successful`,
          type: ALERT_TYPES.DEFAULT,
          modal: MODAL_TYPES.SUCCESS_MODAL,
          duration: 4000,
        });
      } else if (target === 'svg') {
        await downloadAction.exportAsSVG();
      }
    } catch (ex) {
      // failed to export
      alertAction.set({
        msg: 'Unable to export project',
        i18nKey: 'export-failed',
        type: ALERT_TYPES.ERROR_DISMISSIBLE,
        modal: MODAL_TYPES.INVALID_MODAL,
        icon: 'alert-warning',
        duration: 4000,
      });
    }

    // wait a minimum time
    if (!unlimited) {
      const elapsed = Date.now() - requestedAt;
      const wait = Math.max(0, 2000 - elapsed);

      // wait if there's any remaining time
      if (wait > 0) {
        await waitFor(wait);
      }
    }

    // if there's no remaining downloads, show a warning
    if (!unlimited && count! - 1 === 0) {
      setStatus('exhausted');
      return;
    }

    // make the button done
    setStatus('done');
    await waitFor(500);

    // otherwise, close the modal
    onClose();
  }

  return createPortal(
    <div className='export-modal' onClick={onDismissExportMenu}>
      <div className='export-modal--content' onClick={cancelEvent}>
        <div className='export-modal--close' onClick={onDismissExportMenu}>
          &times;
        </div>

        <div className='export-modal--header'>
          <TranslationText i18nKey={`export-as-${target?.toLowerCase()}`}>
            Export to {target?.toUpperCase()}
          </TranslationText>
        </div>

        {/* initial upgrade message */}
        {['upgrade'].includes(status) && (
          <>
            <div className='export-modal--summary'>
              <ExportCount remaining={remaining!} limit={limit!} />{' '}
              <TranslationText i18nKey='remaining-exports'>
                exports remaining this month
              </TranslationText>
            </div>

            <div className='export-modal--actions multi'>
              <Button
                className='pill ghost outline'
                size='modal-action'
                onClick={onUpgrade}
              >
                <TranslationText i18nKey='upgrade'>Upgrade</TranslationText>
              </Button>
              <Button className='pill' size='modal-action' onClick={onDownload}>
                <TranslationText i18nKey='continue'>Continue</TranslationText>
              </Button>
            </div>
          </>
        )}

        {/* downloading assets */}
        {['download', 'done'].includes(status) && (
          <>
            {!unlimited && (
              <ExportCount
                className='export-modal--count'
                animate
                remaining={count!}
                limit={limit!}
              />
            )}

            <div className='export-modal--actions'>
              <Button
                className={`pill full ${isDone ? 'export-done' : ''}`}
                disabled={isDone}
                size='modal-action'
              >
                {status === 'download' && (
                  <TranslationText i18nKey='exporting'>
                    Exporting...
                  </TranslationText>
                )}
                {status === 'done' && <Icon icon='check' />}
              </Button>
            </div>
          </>
        )}

        {/* no more downloads available message */}
        {['exhausted'].includes(status) && (
          <>
            <ExportCount remaining={count!} limit={limit!} />
            <div className='export-modal--message'>
              <TranslationText i18nKey='out-of-exports'>
                You've run out of exports this month! Upgrade to unlock
                unlimited exports.
              </TranslationText>
            </div>

            <div className='export-modal--actions'>
              <Button
                className='pill ghost outline full'
                size='modal-action'
                onClick={onUpgrade}
              >
                <TranslationText i18nKey='upgrade'>Upgrade</TranslationText>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>,
    null,
    { appendOnly: true }
  );
}
