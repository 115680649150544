/* eslint-disable valid-jsdoc */
import { SyncListenerApi } from '../SyncListener';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CanvasState,
  setCanvasFromSyncSnapshot,
  setCanvasState,
} from '@/Redux/Slices/CanvasSlice';
import { setLoading } from '@/Redux/Slices/SherpaContainerSlice';
import { centerTo } from '@/Redux/Slices/ViewportSlice';

export const addUpdateCanvasListener = (startListening: Function) => {
  startListening({
    actionCreator: setCanvasFromSyncSnapshot,
    effect: (
      action: PayloadAction<CanvasState | null | undefined>,
      { dispatch }: SyncListenerApi
    ) => {
      const { payload } = action;
      dispatch(setCanvasState({ state: payload }));
      dispatch(setLoading(false));
      dispatch(centerTo({}));
    },
  });
};
