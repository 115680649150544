import { IPoint } from '@shapertools/sherpa-svg-generator/Point';

export function getPositionFromSvgViewbox({
  x,
  y,
  width,
  height,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
}): IPoint {
  return { x: x + width / 2, y: y + height / 2 };
}
