//Polyfill for Array.prototype.flat
//Not supported in Jest
if (!Array.prototype.flat) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, 'flat', {
    value: function <T extends Array<any>>(depth = 1): T {
      return (this as T).reduce(function (flat, toFlatten) {
        return flat.concat(
          Array.isArray(toFlatten) && depth > 1
            ? toFlatten.flat(depth - 1)
            : toFlatten
        );
      }, []);
    },
    configurable: true,
  });
}

// Applies fn(A,B) for each aSet, bSet.
// After each iteration, aSet may grow, shrink, or stay the same size
// Returns final set
// function fn returns a single value, an array of multiple values, or undefined if no value
// final set is flattened and undefined values removed

export const AMutBMap = function <A, B>(
  aSet: A[],
  bSet: B[],
  fn: (a: A, b: B) => A | A[] | undefined
): A[] {
  let nextSet = aSet;
  for (const b of bSet) {
    nextSet = nextSet
      .map((n) => fn(n, b))
      .flat()
      .filter((n) => n !== undefined) as A[];
  }
  return nextSet;
};
