import { AppDispatch } from '@/Redux/store';
import { ActionLike, useActionWithDispatch, UseSelector } from './useAction';

export default class BaseAction {
  dispatch: AppDispatch;
  useSelector: UseSelector;

  constructor(dispatch: AppDispatch, useSelector: UseSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;
  }

  // naming consistency
  useAction<T, Args extends Array<any>>(
    action: ActionLike<T, Args>,
    ...args: Args
  ) {
    return this.createAction(action, ...args);
  }

  createAction<T, Args extends Array<any>>(
    action: ActionLike<T, Args>,
    ...args: Args
  ) {
    return useActionWithDispatch(
      action,
      this.dispatch,
      this.useSelector,
      ...args
    );
  }
}
