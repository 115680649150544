import { batch } from 'react-redux';
import { updateShaperHubWorkspace } from '@/Redux/Slices/ShaperHubSlice';
import { selectWorkspaceInformation } from '@/Redux/Slices/ShaperHubSlice';
import BaseAction from './BaseAction';
import { toggleRename } from '@/Redux/Slices/UISlice';
import { getShaperHubExternalItem } from '../ShaperHub/ShaperHubThunks';
import { UserspaceExternalItemFileObject } from '@/@types/shaper-types';

export default class RenameWorkspaceAction extends BaseAction {
  renameWorkspace = async (newName: string) => {
    const workspace = this.useSelector(selectWorkspaceInformation);
    const workspaceHasPath = (
      arg: typeof workspace
    ): arg is UserspaceExternalItemFileObject => arg !== null && 'path' in arg;
    if (!workspaceHasPath(workspace)) {
      return 0;
    }
    const patch = {
      name: workspace.name,
      newName,
      path: workspace.path,
    };

    return batch(async () => {
      const update = await this.dispatch(updateShaperHubWorkspace(patch));
      if ((update as any).error) {
        return (update as any).error.message;
      }
      this.dispatch(getShaperHubExternalItem(workspace.externalItemId));
      this.dispatch(toggleRename(false));
      return 0;
    });
  };
}
