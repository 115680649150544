import {
  UpdateSvgGroupPayload,
  updateSvgGroup,
} from '@/CanvasContainer/CanvasActions';
import UpdateSelectionAction from './UpdateSelection';
import { useActionWithDispatch } from './useAction';
import { AppDispatch } from '@/Redux/store';

export default class UpdateSvgGroupAction {
  dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  apply = (
    data: UpdateSvgGroupPayload | UpdateSvgGroupPayload[],
    skipRefresh?: boolean
  ) => {
    const { dispatch } = this;
    const selection = useActionWithDispatch(UpdateSelectionAction, dispatch);

    dispatch(updateSvgGroup(data));

    if (!skipRefresh) {
      selection.refresh();
    }
  };
}
