import classNames from 'classnames';
import React, { ReactNode, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export type Side = 'left' | 'right' | 'under' | 'above';
export type Align = 'center' | 'right';

type Props = {
  side?: Side;
  align?: Align;
  className?: string;
  tip: string | React.ReactNode;
  children: ReactNode;
  absoluteRef?: React.MutableRefObject<HTMLElement> | undefined;
  inline?: boolean;
  allowWrap?: boolean;
};

// hovering tooltip
export function Tooltip(props: Props) {
  const {
    side = 'above',
    align = 'center',
    className,
    tip,
    children,
    inline,
    allowWrap,
    absoluteRef,
  } = props;

  const [, setRender] = useState<Number>();
  const [show, setShow] = useState<Boolean>();
  const containerCx = `component--tooltip--container placement-${side} alignment-${align} ${
    className || ''
  }`;
  const contentCx = classNames('component--tooltip--content', {
    wrap: allowWrap,
  });

  // base tooltip content
  const tooltip = <div className={contentCx}>{tip}</div>;

  // update the state for tooltips
  const handleShowTooltip = () => setShow(true);
  const handleHideTooltip = () => setShow(false);

  // for absolutely positioned tooltips, we need to manually
  // handle showing and hiding the tooltip
  const hover = absoluteRef
    ? {
        onMouseEnter: handleShowTooltip,
        onMouseOver: handleShowTooltip,
        onMouseLeave: handleHideTooltip,
      }
    : null;

  let content = (
    <>
      {!absoluteRef?.current && tooltip}
      {children}
    </>
  );

  // create the inner content
  content = inline ? (
    <span {...hover} className={containerCx}>
      {content}
    </span>
  ) : (
    <div {...hover} className={containerCx}>
      {content}
    </div>
  );

  // make sure to sync the ref if the layout changes
  useLayoutEffect(() => {
    setRender(Date.now());
  }, [absoluteRef]);

  // when absolutely positioned, we need to calculate the
  // origin position for the tooltip
  if (absoluteRef?.current) {
    const {
      left,
      right,
      top: above,
      bottom: under,
    } = absoluteRef.current?.getBoundingClientRect();

    const cx = (left + right) >> 1;
    const cy = (above + under) >> 1;
    const x = { left, right, above: cx, under: cx }[side] ?? cx;
    const y = { above, under, right: cy, left: cy }[side] ?? cy;

    // rebuild the content as separate and put the tooltip
    // in a portal of its own
    content = (
      <>
        {content}
        {show &&
          createPortal(
            <div
              className={`component--tooltip--absolute-container side--${side}`}
              style={{ left: x, top: y }}
            >
              <div className={containerCx}>{tooltip}</div>
            </div>,
            document.body
          )}
      </>
    );
  }

  return content;
}

// helper function to wrap content with tooltips
Tooltip.wrap = function (
  tip: string | React.ReactNode | undefined,
  side?: Side,
  content?: ReactNode,
  align?: Align,
  params?: Partial<Props & React.JSX.IntrinsicAttributes>
) {
  return tip ? (
    <Tooltip tip={tip} side={side || 'above'} align={align} {...params}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
