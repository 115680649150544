import React, { useMemo, useState } from 'react';
import { SharedBadge, Popover, ProjectSummary } from '@shapertools/web-ui';
import UpdateIcon from '@mui/icons-material/Update';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from 'react-redux';
import { selectProject } from '@/Redux/Slices/ShaperHubSlice';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectIsMobile, toggleShare } from '@/Redux/Slices/UISlice';
import { useAppDispatch } from '@/Redux/hooks';

type Props = {
  position?: 'below' | 'right';
};

export default function ShareBadge({ position = 'below' }: Props) {
  const dispatch = useAppDispatch();

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const project = useSelector(selectProject);
  const isMobile = useSelector(selectIsMobile);
  const { t, i18n } = useTranslation();

  const linkCopiedTranslation = i18n.exists('link-copied')
    ? t('link-copied')
    : 'link copied!';
  const copyLinkTranslation = i18n.exists('copy-link')
    ? t('copy-link')
    : 'Copy Link';
  const updateDesignTranslation = i18n.exists('update-design')
    ? t('update-design')
    : 'Update Shared Design';
  const upToDateTranslation = i18n.exists('no-update-design')
    ? t('no-update-design')
    : 'Up to Date';
  const listedTranslation = i18n.exists('listed') ? t('listed') : 'Listed';
  const unlistedTranslation = i18n.exists('unlisted')
    ? t('unlisted')
    : 'Unlisted';
  const sharedTranslation = i18n.exists('shared') ? t('shared') : 'Shared';

  const { img, name, listed } = useMemo(() => {
    if (project) {
      return {
        img: project.images && project.images[0] ? project.images[0]?.url : '',
        name: project?.title || '',
        listed: project?.privacy === 'listed',
      };
    }
    return {
      img: '',
      name: '',
      listed: false,
    };
  }, [project]);

  const close = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setOpen(false);
    setAnchor(null);
  };

  const onClick = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isMobile) {
      dispatch(toggleShare(true));
    } else {
      setOpen(true);
      setAnchor(event?.currentTarget || null);
    }
    event?.stopPropagation();
  };

  const openProject = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    window.open(
      `${import.meta.env.VITE_SHAPER_URL_HUB}/design/${project?._id}`,
      '_blank'
    );
    event?.stopPropagation();
  };

  const copyLink = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setLinkCopied(true);
    navigator.clipboard.writeText(
      `${import.meta.env.VITE_SHAPER_URL_HUB}/design/${project?._id}`
    );
    setTimeout(() => {
      setLinkCopied(false);
    }, 1500);
    event?.stopPropagation();
  };

  const updateSharedDesign = (
    event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    window.open(
      `${import.meta.env.VITE_SHAPER_URL_HUB}/design/${
        project?._id
      }?update=true`,
      '_self'
    );
    event?.stopPropagation();
  };

  const doNothing = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event?.stopPropagation();
  };

  return (
    <>
      <SharedBadge
        showChip={project?.outOfDate || false}
        onClick={onClick}
        label={sharedTranslation}
      />
      {!isMobile && (
        <Popover
          open={open}
          anchor={anchor}
          onClose={close}
          position={position}
        >
          <Popover.Item onClick={openProject}>
            <ProjectSummary
              img={img}
              name={name}
              listed={listed}
              translationStrings={{
                listed: listedTranslation,
                unlisted: unlistedTranslation,
              }}
            />
          </Popover.Item>
          <Tooltip
            title={linkCopiedTranslation}
            open={linkCopied}
            PopperProps={{
              className: 'copy-link-tooltip',
            }}
          >
            <Box>
              <Popover.Item
                Icon={ContentCopyIcon}
                label={copyLinkTranslation}
                onClick={copyLink}
              />
            </Box>
          </Tooltip>
          {project?.outOfDate ? (
            <Popover.Item
              Icon={UpdateIcon}
              label={updateDesignTranslation}
              hasBadge={true}
              onClick={updateSharedDesign}
            />
          ) : (
            <Popover.Item
              Icon={UpdateIcon}
              label={upToDateTranslation}
              onClick={doNothing}
              disabled={true}
            />
          )}
        </Popover>
      )}
    </>
  );
}
