import React, { useEffect, useRef } from 'react';

type Props = {
  children?: React.ReactNode;
  className?: string;

  el: React.RefObject<HTMLElement>;
  side?: string;
};
export default function AttachToElement(props: Props) {
  const { el, side = 'top' } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const top = /top/i.test(side);
  const left = /left/i.test(side);
  const right = /right/i.test(side);
  const bottom = /bottom/i.test(side);

  const horizontal = left ? '0%' : right ? '-100%' : '-50%';
  const vertical = top ? '0%' : bottom ? '-100%' : '-50%';
  const transform = `translate(${horizontal}, ${vertical})`;
  const style = { transform };

  useEffect(() => {
    // match the menu to the position
    function syncPosition() {
      const refCurrent = props.el.current;
      if (!refCurrent) {
        return;
      }
      const bounds = refCurrent.getBoundingClientRect();

      const x = left
        ? bounds.right
        : right
        ? bounds.left
        : (bounds.left + bounds.right) * 0.5;

      const y = top
        ? bounds.bottom
        : bottom
        ? bounds.top
        : (bounds.top + bounds.bottom) * 0.5;

      // save the position
      containerRef.current!.style.left = `${x}px`;
      containerRef.current!.style.top = `${y}px`;
      containerRef.current!.style.transform = transform;
    }

    window.addEventListener('resize', syncPosition);
    window.addEventListener('scroll', syncPosition);
    if (el.current) {
      // first setup
      syncPosition();
    }

    return () => {
      window.removeEventListener('resize', syncPosition);
      window.removeEventListener('scroll', syncPosition);
    };
  }, [el]);

  return (
    <div
      className={`attach-to-element ${props.className}`}
      ref={containerRef}
      style={style}
    >
      {props.children}
    </div>
  );
}
