import { useSelector } from 'react-redux';

// selectors
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import { selectSecretOptions } from '@/Redux/Slices/SherpaContainerSlice';
import UIState from '../State/UIState';

type Props = {
  ui: UIState;
  hideGuides: boolean;
};

// guides referencing back to the 0/0 axis
export default function WorkspaceOrigin(props: Props) {
  const { hideGuides, ui } = props;
  const { viewport, selectionBounds } = ui;
  const nspf = useSelector(selectNonScalingPixelFactor);
  const secretOptions = useSelector(selectSecretOptions);

  // renders guides to show the 0/0 axes
  function renderWorkspaceOrigin() {
    if (!viewport) {
      return null;
    }

    // compute as needed
    const x = viewport.x;
    const y = viewport.y;

    // TODO: probably would prefer to use the container
    const w = window.innerWidth * nspf;
    const h = window.innerHeight * nspf;

    return (
      <>
        <line
          x1={0}
          x2={0}
          y1={y}
          y2={y + h}
          className='workspace-origin--axis'
        />
        <line
          x1={x}
          x2={x + w}
          y1={0}
          y2={0}
          className='workspace-origin--axis'
        />
      </>
    );
  }

  // renders guides for the selected object back to the center
  function renderSelectionOriginGuides() {
    const anchor = ui.applyOverridesToPoint(selectionBounds.anchor);
    const { x: rx, y: ry } = anchor;

    // create each guide
    return [
      [ry, rx, 'x', 'y'],
      [rx, ry, 'y', 'x'],
    ].map(([altAxisPosition, distance, axis, altAxis]) => {
      // check which side to start from
      const guide = {
        [`${altAxis}1`]: altAxisPosition,
        [`${altAxis}2`]: altAxisPosition,
        [`${axis}1`]: distance,
        [`${axis}2`]: 0,
      };

      const strokeDashArray = undefined; // `${secretOptions?.positionDashWidth} ${secretOptions?.positionGapWidth}`;
      const strokeWidth = secretOptions?.positionStrokeWidth;
      const strokeColor = `#${secretOptions?.positionColor}`;

      return (
        <line
          key={`guide_${axis}`}
          className='workspace-origin--guide'
          {...guide}
          stroke={strokeColor}
          strokeDasharray={strokeDashArray}
          strokeWidth={strokeWidth}
        />
      );
    });
  }

  return (
    <>
      {!hideGuides && selectionBounds && renderSelectionOriginGuides()}
      {renderWorkspaceOrigin()}
    </>
  );
}
