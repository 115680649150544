import { Snapshot } from '@/Sync/SyncConstants';
import { CANVAS_DATA_VERSION } from '../defaults';
import { migrations, MigrationError } from './migrationHelper';

class MigrateCanvas {
  static upOrDown(fromVersion: string, toVersion: string) {
    const fromNumbers = fromVersion.split('.').map((el) => Number(el));
    const toNumbers = toVersion.split('.').map((el) => Number(el));
    for (let i = 0; i < fromNumbers.length; i++) {
      if (fromNumbers[i] < toNumbers[i]) {
        return 'up';
      }
      if (fromNumbers[i] > toNumbers[i]) {
        return 'down';
      }
    }
    return 'same';
  }

  static findMigration(direction: 'up' | 'down', fromVersion: string) {
    return migrations.find(
      (m) => m[direction === 'up' ? 'from' : 'to'] === fromVersion
    );
  }

  static migrate(schema: Snapshot, toVersion: string): any {
    const fromVersion = canvasVersionSelector(schema) ?? '0.0';
    const direction = this.upOrDown(fromVersion, toVersion);
    if (direction === 'same') {
      return schema;
    }

    const currentMigration = this.findMigration(direction, fromVersion);
    if (currentMigration === undefined) {
      throw new MigrationError(fromVersion, toVersion);
    }
    const newSchema = currentMigration[direction](schema);
    return this.migrate(newSchema, toVersion);
  }
}

export const canvasVersionSelector = (state: Snapshot) => {
  return state?.canvas?.version || (state as any)?.version;
};

export const canvasRequiresMigration = (incomingSnapshot: Snapshot) => {
  const toVersion = CANVAS_DATA_VERSION;
  const fromVersion = canvasVersionSelector(incomingSnapshot);
  if (incomingSnapshot !== undefined && toVersion !== fromVersion) {
    return true;
  }
  return false;
};

export const migrateCanvas = (incomingSnapshot: Snapshot) => {
  const toVersion = CANVAS_DATA_VERSION;
  const migratedCanvas = MigrateCanvas.migrate(incomingSnapshot, toVersion);
  return migratedCanvas;
};
