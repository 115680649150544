import { batch } from 'react-redux';
import {
  AlertActionPayload,
  setAlert,
  clearAlert,
} from '@/Redux/Slices/UISlice';
import BaseAction from './BaseAction';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';
import { toggleRename } from '@/Redux/Slices/UISlice';

export default class AlertAction extends BaseAction {
  clear() {
    this.dispatch(clearAlert());
  }

  set(alert: AlertActionPayload) {
    this.dispatch(setAlert(alert));
  }

  setDuplicateAlert() {
    const updateWorkspaceOptions = this.createAction(
      UpdateWorkspaceOptionsAction
    );
    batch(() => {
      this.set({
        msg: 'Design Duplicated',
        i18nKey: 'design-duplicated',
        type: 'default-dismissible',
      });
      updateWorkspaceOptions.update({ showApplicationMenu: true });
      this.dispatch(toggleRename(true));
    });
  }
}
