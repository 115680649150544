import { batch } from 'react-redux';
import BaseAction from './BaseAction';

import LineToolAction from './LineTool';
import { deleteSvgGroup } from '@/CanvasContainer/CanvasActions';

import UIState from '@/UILayer/State/UIState';

import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import { clearSelection } from '@/Redux/Slices/SelectionSlice';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

export default class DeleteGroupsAction extends BaseAction {
  delete(groupIds: string[], deleteOnly: boolean = false) {
    const { dispatch, useSelector } = this;
    const getGroupById = useSelector(selectGetGroupById);

    const remove = [...groupIds];

    // check for points to delete
    const pointsToRemove = [];
    for (let i = remove.length; i-- > 0; ) {
      const target = getGroupById(remove[i]);

      // not a point, no special instructions
      if (target.tool?.type !== Shape.POINT) {
        continue;
      }

      // remove from the list
      pointsToRemove.push(target.id);
      remove.splice(i, 1);
    }

    // clean up points, if any
    if (pointsToRemove.length) {
      const lineTool = this.createAction(LineToolAction);
      lineTool.removePoints(pointsToRemove);
      UIState.reset();
    }

    // remove any remaining IDs, if any
    if (!remove.length) {
      return;
    }

    if (deleteOnly) {
      return dispatch(deleteSvgGroup(remove));
    }

    return batch(async () => {
      dispatch(clearSelection());
      const deleteResult = dispatch(deleteSvgGroup(remove));
      return deleteResult;
    });
  }
}
