import { Shape, ToolParams } from '@shapertools/sherpa-svg-generator/SvgGroup';
import UIState from '@/UILayer/State/UIState';

// common state management
export default class DesignState extends UIState {
  isResizing?: boolean;
  isRotating?: boolean;
  isTranslating?: boolean;

  // prepare relevant state
  constructor(...args: ConstructorParameters<typeof UIState>) {
    super(...args);
    const { selectedGroupIds, overrides } = this;

    // predefine a few things
    this.isSingleSelection = selectedGroupIds.length === 1;
    this.isMultiSelection = selectedGroupIds.length > 1;
    this.hasSelection = selectedGroupIds.length >= 1;

    // interactions
    this.isResizing = !!overrides?.resize;
    this.isRotating = !!overrides?.rotate;
    this.isTranslating = !!overrides?.translate;

    // define required data
    this.defineSelectionNet();
    this.defineGroups();
    this.defineBoundingBox();
    this.defineAlignmentGuides();
    this.defineShapeUpdates();
  }

  // marks shapes as "changed" if a point has been updated
  defineShapeUpdates() {
    for (const group of this.groups) {
      if (group.tool?.type === Shape.POINT && this.changedGroupIds[group.id]) {
        this.changedGroupIds[
          (group.tool.params as ToolParams<Shape.POINT>)?.belongsTo
        ] = {};
      }
    }
  }
}
