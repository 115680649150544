import React, { RefObject, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { InputProps } from '@mui/material';

type TextFieldInputProps = InputProps & { 'data-cy'?: string };

type Props = {
  id?: string;
  value?: string;
  onChange?: (value: string) => void;
  submitOnBlur?: boolean;
  onSubmit?: (value: string) => void;
  onBlur?: (value: string) => void;
  submitOnEnter?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: () => void;
  parentKey?: string;
  className?: string;
  placeholder?: string;
  numericOnly?: boolean;
  type?: string;
  inputRef?: RefObject<HTMLInputElement | undefined>;
  inputProps?: TextFieldInputProps;
  defaultValue?: string;
  min?: number;
  dataCy?: string;
  onClick?: () => void;
  forceUpdateValue?: boolean;
};

function SherpaTextField(props: Props) {
  const { value = '', inputRef } = props;
  const [currentValue, setCurrentValue] = useState(value);

  // https://stackoverflow.com/questions/35791074/reactjs-how-can-i-set-a-value-for-textfield-from-material-ui

  const updateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  // check for blurred controls
  const onBlur = () => {
    if (props.submitOnBlur && props.onSubmit) {
      props.onSubmit(currentValue);
    } else if (props.onBlur) {
      props.onBlur(currentValue);
    } else if (props.onChange) {
      props.onChange(currentValue);
    }
  };

  // check for enter key submissions
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.submitOnEnter && props.onSubmit && e.keyCode === 13) {
      props.onSubmit(currentValue);
    }
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  };

  const onKeyUp = () => {
    if (props.onKeyUp) {
      props.onKeyUp();
    }
  };

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  // text inputs
  const type = props.numericOnly ? 'number' : props.type || 'text';

  useEffect(() => {
    if (props.forceUpdateValue) {
      setCurrentValue(value);
    }
  }, [props.value]);

  return (
    <TextField
      key={props.parentKey + value}
      type={type}
      inputRef={inputRef}
      className={`textField ${props.className}`}
      onChange={updateValue}
      onBlur={onBlur}
      onKeyDown={onKeyPress}
      onKeyUp={onKeyUp}
      onClick={onClick}
      placeholder={props.placeholder}
      value={currentValue}
      InputProps={props.inputProps}
      data-cy={props.dataCy}
    />
  );
}

export default SherpaTextField;
