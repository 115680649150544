/* eslint-disable valid-jsdoc */
import {
  selectOriginalSnapshot,
  selectSnapshot,
  setStatus,
  updatePendingQueue,
  updateQueue,
} from '../../Redux/Slices/SyncSlice';
import { doPatchValidation } from './../SyncThunks';
import {
  PatchSet,
  immerToJSONPatches,
  addSliceNameToPatches,
} from './../PatchGenerator';
import { log } from './../SyncLog';
import { SyncListenerApi, addSyncListener, context } from '../SyncListener';
import { UnknownAction } from '@reduxjs/toolkit';
import { SyncError } from './../SyncError';
import { RootState } from '@/Redux/store';
import { rollbackQueue } from './RollbackListener';
import {
  validateFailListener,
  validateSuccessListener,
} from './ValidatePatchesListener';

/**
 * Queue listener -- listens to the queue to start sending updates
 */
export const addQueueListener = (startListening: Function) => {
  startListening({
    predicate: (_: UnknownAction, currentState: RootState) => {
      const { queue, status, enabled } = currentState.sync;

      /**
       * We only care about the queue listener when there is actually
       * something in the queue
       */
      return (
        queue.length > 0 &&
        status !== 'pending' &&
        status !== 'disconnected' &&
        enabled
      );
    },
    effect: async (
      _: UnknownAction,
      { dispatch, getState, fork }: SyncListenerApi
    ) => {
      const { sync } = getState();
      const { queue } = sync;
      const snapshot = selectSnapshot();
      log('Syncing queue...', { ...context, queue }, 'debug');

      const newQueue = [] as PatchSet[];
      const newPendingQueue = [...queue] as PatchSet[];

      if (newPendingQueue && newPendingQueue.length > 0) {
        dispatch(setStatus('pending'));
        dispatch(updateQueue(newQueue));
        dispatch(updatePendingQueue(newPendingQueue));

        const patchesToSend = addSliceNameToPatches(newPendingQueue);
        const jsonPatches = immerToJSONPatches(patchesToSend);
        if (!jsonPatches) {
          const originalSnapshot = selectOriginalSnapshot() || selectSnapshot();
          dispatch(setStatus('error'));

          rollbackQueue(dispatch, newPendingQueue, [], originalSnapshot);
          throw new SyncError(
            'bad_patches',
            'listener',
            'Unable to convert immer to JSON patches',
            undefined,
            { jsonPatches, snapshot }
          );
        }

        // forking this allows it to be queued to run in a new microtask,
        // preventing it from blocking the ui
        fork(async () => {
          dispatch(addSyncListener(validateSuccessListener));
          dispatch(addSyncListener(validateFailListener));
          dispatch(
            doPatchValidation({
              snapshot: JSON.parse(JSON.stringify(snapshot)),
              jsonPatches,
              patchesToSend,
            })
          );
        });
      }
    },
  });
};
