import { createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';

import {
  updateServerSHA,
  loginShaperHub,
  getLoginState,
  logoutShaperHub,
  readShaperHubFolder,
  updateShaperHubWorkspace,
  getShaperSubscriptions,
  getShaperHubFiles,
  getShaperHubExternalItem,
  getLocale,
  getUser,
  getExportAccess,
  startTrial,
  getFirstWorkspace,
  downloadBlob,
  getStudioShare,
  syncToMyFiles,
  StudioShare,
} from '../../ShaperHub/ShaperHubThunks';

import {
  clearDemoStorage,
  loadServerSHA,
  saveServerSHA,
} from '@/Redux/localStorage';

import { defaultSubscriptionOptions, defaultLocale } from '../../defaults';

import ImportGeometryAction from '../../Actions/ImportGeometry';
import {
  addAttributeToUser,
  addAttributesToUser,
  identifyUser,
  removeAttributeFromUser,
} from '../../Utility/userflow.js';
import LogOutAction from '../../Actions/LogOut';
import { setUser } from '@/Sync/SyncLog';
import {
  Environment,
  ExportAccess,
  ExportAccessResponse,
  FilesystemObject,
  Licenses,
  LocaleResponse,
  Project,
  Subscription,
  User,
  UserspaceExternalItemFileObject,
} from '../../@types/shaper-types';
import { AppDispatch, RootState } from '../store';
import { getDeveloperSettings } from '@/Utility/developer-settings';
import { useSelector } from '@/Actions/useAction';
import { setSnapshot } from './SyncSlice';
import { kebabCase } from 'lodash';
import { setAlert } from './UISlice';

export {
  updateServerSHA,
  loginShaperHub,
  logoutShaperHub,
  getLoginState,
  readShaperHubFolder,
  updateShaperHubWorkspace,
  getShaperSubscriptions,
  getShaperHubFiles,
  getShaperHubExternalItem,
  getLocale,
  getUser,
  getExportAccess,
  startTrial,
  getFirstWorkspace,
};

export type ShaperHubState = {
  loggedIn: boolean;
  status: 'idle' | 'pending';
  decodedPayload: null;
  userId: string | null;
  user: User | null;
  username: string;
  serverEnv: Environment;
  serverSHA: string;
  shaperLicenses: Licenses;
  syncURL: string;
  paymentsURL: string;
  marketingURL: string;
  localeURL: string;
  entitlementsURL: string;
  iconsURL: string;
  currentPath: string[];
  currentFolder: FilesystemObject[];
  subscriptions: Subscription;
  recentFiles: UserspaceExternalItemFileObject[];
  exportAccess: ExportAccess;
  workspace: UserspaceExternalItemFileObject | null;
  project?: Project;
  creator?: User;
  locale: LocaleResponse;
  loggingToken: string | null;
  lastUploadSuccessful?: boolean;
};

export const initialShaperHubState: ShaperHubState = {
  loggedIn: false,
  status: 'idle',
  decodedPayload: null,
  userId: null,
  username: '',
  user: null,
  serverEnv: 'development',
  serverSHA: 'new_state',
  shaperLicenses: { tosVersion: '1.0', ppVersion: '1.0' },
  entitlementsURL: import.meta.env.VITE_SHAPER_URL_ENTITLEMENTS,
  syncURL: import.meta.env.VITE_SHAPER_URL_WORKSPACES,
  paymentsURL: import.meta.env.VITE_SHAPER_URL_PAYMENTS,
  marketingURL: import.meta.env.VITE_SHAPER_URL_MARKETING,
  localeURL: import.meta.env.VITE_SHAPER_URL_LOCALE,
  iconsURL: import.meta.env.VITE_SHAPER_URL_ICONS,
  currentPath: [],
  currentFolder: [],
  subscriptions: defaultSubscriptionOptions,
  recentFiles: [],
  workspace: null,
  locale: defaultLocale as LocaleResponse,
  loggingToken: null,
  exportAccess: {
    allowed: false,
  },
};

export const slice = createSlice({
  name: 'shaperHub',
  initialState: initialShaperHubState,
  reducers: {
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    endSession: () => {
      //Log session end for analytics. Nothing to do as middleware intercepts and handles it.
    },
    setServerSHA: (state, action: PayloadAction<string>) => {
      state.serverSHA = action.payload;
    },
    setProject: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
    clearProject: (state) => {
      delete state.project;
      // remove url param since we no longer need it
      history.pushState(null, '', window.location.pathname);
    },
    setLocale: (state, action: PayloadAction<Partial<LocaleResponse>>) => {
      state.locale = {
        ...state.locale,
        ...action.payload,
      };
    },
    setSubscription: (state, action: PayloadAction<Subscription>) => {
      state.subscriptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        loginShaperHub.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loggedIn = true;
          state.userId = action.payload;
          clearDemoStorage();
        }
      )
      .addCase(getLoginState.fulfilled, (state) => {
        state.loggedIn = true;
        clearDemoStorage();
      })
      .addCase(getLoginState.rejected, (state) => {
        state.loggedIn = false;
      })
      .addCase(logoutShaperHub.fulfilled, (state) => {
        state.loggedIn = false;
        state.userId = null;
        state.decodedPayload = null;
        state.subscriptions = defaultSubscriptionOptions;
      })
      .addCase(
        updateServerSHA.fulfilled,
        (state, action: PayloadAction<{ commit: string }>) => {
          state.serverSHA = action.payload.commit;
        }
      )
      .addCase(
        readShaperHubFolder.fulfilled,
        (
          state,
          action: PayloadAction<{
            currentFolder: FilesystemObject[];
            currentPath: string[];
          }>
        ) => {
          const { currentFolder, currentPath } = action.payload;
          state.currentPath = currentPath;
          state.currentFolder = currentFolder;
        }
      )
      .addCase(
        getShaperSubscriptions.fulfilled,
        (state, action: PayloadAction<{ subscriptions: Subscription }>) => {
          state.subscriptions = action.payload.subscriptions;
          addAttributesToUser({
            is_subscriber: action.payload.subscriptions.isSubscriber,
            is_trial: action.payload.subscriptions.isTrial,
            subscription_type: action.payload.subscriptions.type,
            is_expired: action.payload.subscriptions.isExpired,
            days_left: action.payload.subscriptions.daysLeft,
          });
        }
      )
      .addCase(
        getShaperHubFiles.fulfilled,
        (state, action: PayloadAction<UserspaceExternalItemFileObject[]>) => {
          state.recentFiles = action.payload;
        }
      )
      .addCase(
        getShaperHubExternalItem.fulfilled,
        (state, action: PayloadAction<UserspaceExternalItemFileObject>) => {
          state.workspace = action.payload;
          document.title = `Shaper Studio | ${action.payload.name}`;
          if (action.payload.duplicatedFromStudioShare) {
            addAttributeToUser(
              'duplicated_from_studio_share',
              action.payload.duplicatedFromStudioShare
            );
          } else {
            removeAttributeFromUser('duplicated_from_studio_share');
          }
        }
      )
      .addCase(getShaperHubExternalItem.rejected, () => {
        document.title = `Shaper Studio`;
      })
      .addCase(
        getLocale.fulfilled,
        (state, action: PayloadAction<LocaleResponse>) => {
          state.locale = action.payload;
        }
      )
      .addCase(
        getExportAccess.fulfilled,
        (state, action: PayloadAction<ExportAccessResponse>) => {
          try {
            if (action.payload.hasUnlimitedExports) {
              state.exportAccess = {
                allowed: true,
                unlimited: true,
              };
              addAttributesToUser({
                unlimited_exports: true,
                exports_remaining: null,
              });
            } else {
              state.exportAccess = {
                allowed: true,
                unlimited: false,
                remaining: action.payload.remainingExports,
                limit: action.payload.limit ?? action.payload.exportLimit,
              };
              addAttributesToUser({
                unlimited_exports: null,
                exports_remaining: action.payload.remainingExports,
              });
            }
          } catch (ex) {
            // if this fails to load, just disable exporting
            state.exportAccess = {
              allowed: false,
            };
            addAttributesToUser({
              unlimited_exports: null,
              exports_remaining: null,
            });
          }
        }
      )
      .addCase(getUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.user = action.payload;
        identifyUser(action.payload);
        const { _id, email } = state.user;
        setUser({
          id: _id,
          email,
        });
      })
      .addCase(
        getFirstWorkspace.fulfilled,
        (_, action: PayloadAction<{ created: string } | null>) => {
          if (action.payload) {
            addAttributeToUser('studio_start', action.payload.created);
          } else {
            addAttributeToUser('studio_start', new Date().toISOString());
          }
        }
      )
      .addCase(
        getStudioShare.fulfilled,
        (state, action: PayloadAction<StudioShare>) => {
          state.project = action.payload.project;
          state.creator = action.payload.creator;
        }
      );
  },
});

export const {
  setUsername,
  endSession,
  setServerSHA,
  setProject,
  clearProject,
  setLocale,
  setSubscription,
} = slice.actions;

export const selectServerEnv = (state: RootState) => state.shaperHub.serverEnv;

export const selectShaperHubLoggedIn = (state: RootState) =>
  state.shaperHub.loggedIn;

export const selectExportAccess = (state: RootState) =>
  state.shaperHub.exportAccess;

export const selectUsername = (state: RootState) => state.shaperHub.username;

export const selectServerSHA = (state: RootState) => state.shaperHub.serverSHA;

export const selectSyncUrl = (state: RootState) => state.shaperHub.syncURL;

export const selectLoggingToken = (state: RootState) =>
  state.shaperHub.loggingToken;

export const selectLoggedIn = (state: RootState) => {
  return state.shaperHub.loggedIn;
};

export const selectShaperHubStatus = (state: RootState) =>
  state.shaperHub.status;

export const selectShaperHubPath = (state: RootState) =>
  state.shaperHub.currentPath;

export const selectShaperHubFolder = (state: RootState) =>
  state.shaperHub.currentFolder;

export const selectShaperSubscriptions = (state: RootState) =>
  state.shaperHub.subscriptions;

export const selectRecentShaperHubFiles = (state: RootState) =>
  state.shaperHub.recentFiles;

export const selectWorkspaceInformation = (state: RootState) =>
  state.shaperHub.workspace;

export const selectWorkspaceName = (state: RootState) =>
  state.shaperHub.workspace?.name || '';

export const selectShaperMarketingUrl = (state: RootState) =>
  state.shaperHub.marketingURL;

export const selectSherpaClientUrl = () => window.location.href;

export const selectLocale = (state: RootState) => {
  if (state.featureFlags.featureFlags['studio-developer-settings']) {
    const languageOverride = getDeveloperSettings('locale');
    return {
      ...state.shaperHub.locale,
      language: languageOverride,
    };
  }
  return state.shaperHub.locale;
};

export const selectWorkspaceFileName = (state: RootState) =>
  `${kebabCase(state.shaperHub.workspace?.name || 'Untitled')}.svg`;

export const selectUserIsVerified = (state: RootState) =>
  !!state.shaperHub.user?.emailIsVerified || false;

export const selectUser = (state: RootState) => state.shaperHub.user;

export const selectProject = (state: RootState) => state.shaperHub.project;
export const selectCreator = (state: RootState) => state.shaperHub.creator;
export const selectStudioShare = (state: RootState) =>
  state.shaperHub.workspace && 'studioShare' in state.shaperHub.workspace
    ? state.shaperHub.workspace?.studioShare
    : undefined;

// updateServerSHA is called whenever app goes from Idle to Active. If SHA does
// not match, then reload client with up to date version and current workspace.
export const middleware: Middleware = (store) => (next) => (action) => {
  const { shaperHub, sync } = store.getState() as RootState;
  const appDispatch = store.dispatch as AppDispatch;

  if (updateServerSHA.fulfilled.match(action)) {
    const oldSHA = loadServerSHA();
    const newSHA = action.payload.commit;

    if (oldSHA !== 'new_state' && oldSHA !== newSHA) {
      appDispatch(setServerSHA(newSHA));

      //Tab has been open a while and client has been updated, so refresh
      if (sync.workspace && 'id' in sync.workspace) {
        const url = `${window.location.origin}/?workspaceId=${sync.workspace?.id}`;
        window.location.replace(url);
      }
    }
  }

  // This should be type AppDispatch, but that type depends on the store,
  // which depends on the middleware, creating a circular type dependency
  if (
    downloadBlob.fulfilled.match(action) &&
    !action.meta.arg.skipImportScreen
  ) {
    const importGeometryAction = new ImportGeometryAction(
      appDispatch,
      useSelector
    );
    importGeometryAction.importSVG(action.payload, { fromTrace: true });
  }
  if (getUser.rejected.match(action)) {
    const logOutAction = new LogOutAction(appDispatch, useSelector);
    logOutAction.logOut();
  }
  if (setSnapshot.match(action)) {
    saveServerSHA(shaperHub.serverSHA);
  }
  if (getShaperHubExternalItem.fulfilled.match(action)) {
    const externalItem = action.payload;
    if ('studioShare' in externalItem) {
      appDispatch(
        getStudioShare({
          projectId: externalItem.studioShare._id,
          fromWorkspace: true,
        })
      );
    }
  }
  if (syncToMyFiles.fulfilled.match(action)) {
    const projectId = shaperHub.project?._id;
    if (projectId) {
      appDispatch(getStudioShare({ projectId: projectId }));
    }
  }
  if (syncToMyFiles.rejected.match(action)) {
    appDispatch(
      setAlert({
        msg: 'Project is already synced to My Files',
        i18nKey: 'already-synced-error',
        type: 'error-dismissible',
        icon: 'alert-warning',
        duration: 5000,
      })
    );
    const { project } = shaperHub;
    if (project) {
      appDispatch(
        setProject({
          ...project,
          viewingUserHasSynced: true,
        })
      );
    }
  }
  if (getStudioShare.rejected.match(action)) {
    const { fromWorkspace } = action.meta.arg;
    if (!fromWorkspace) {
      appDispatch(
        setAlert({
          msg: 'Unable to load project',
          i18nKey: 'unable-load-project',
          type: 'error',
          icon: 'alert-warning',
        })
      );
    }
  }

  next(action);
};

export const actions = slice.actions;
export default slice.reducer;
