import React from 'react';
import Tab from './Components/Tab';

export type ToggleButtonOption = {
  icon: string;
  label: string;
  i18nKey: string;
  onClick?: (opt: ToggleButtonOption) => void;
};

type Props = {
  options: ToggleButtonOption[];
  value?: ToggleButtonOption;
  className: string;
  onClick?: (opt: ToggleButtonOption) => void;
};

export default function ToggleButton(props: Props) {
  const { options, value, className } = props;

  const onClick = (opt: ToggleButtonOption) => {
    if (opt.onClick) {
      opt.onClick(opt);
    } else if (props.onClick) {
      props.onClick(opt);
    }
  };

  const selected = options.find((opt) =>
    value === null
      ? options[0].i18nKey === opt.i18nKey
      : opt.i18nKey === value?.i18nKey
  );

  return (
    <div className={`toggle-button ${className || ''}`}>
      <div className='toggle-button-options'>
        <div className='toggle-button-options--container'>
          {options.map((opt: ToggleButtonOption) => (
            <Tab
              {...opt}
              key={opt.label}
              selected={opt === selected}
              onClick={() => onClick(opt)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
