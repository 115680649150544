/* eslint-disable valid-jsdoc */
import {
  isAnyOf,
  isFulfilled,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  setSnapshot,
  setStatus,
  updateQueue,
} from '../../Redux/Slices/SyncSlice';
import {
  addSVGGeometry,
  addSimplePolygonGeometry,
  deleteSvgGroup,
  addSvgGroups,
  updateSvgGroup,
  deleteGroupIdsAndAddSimplePolygonGeometry,
  undoCanvas,
  redoCanvas,
  clearCanvas,
  updatePathsCutParams,
  duplicateSelectedGroups,
  setSvgGroupAnchor,
  setCanvasCustomAnchor,
  updateVersion,
  getActionLookup,
} from '@/CanvasContainer/CanvasActions';
import * as LineToolActions from '@/LineTool/LineToolActions';
import { generatePatchAndHistory } from './../PatchGenerator';
import { log, sanitizeCanvasState, sanitizePatchState } from './../SyncLog';
import { context, SyncListenerApi } from '../SyncListener';
import { SyncError } from '../SyncError';
import * as Sentry from '@sentry/react';
import { setCanvasState } from '@/Redux/Slices/CanvasSlice';
import { getActionName } from '../SyncHelpers';

// TODO: eventually allow multiple locations for look up?
const lookUpActionMerged = (key: string | number) => {
  return getActionLookup(key) || LineToolActions.getActionLookup(key);
};

/**
 * A immer listener to create patches and add them to the queue after any of the
 * canvas actions completes. Canvas action has a state mutator function that helps to generate the patches.
 * If sync is enabled, the patches will be sent as an update, otherwise it will only be saved in local storage
 */
export const addCanvasActionListener = (startListening: Function) => {
  startListening({
    matcher: isAnyOf(
      isFulfilled(addSVGGeometry),
      isFulfilled(addSimplePolygonGeometry),
      isFulfilled(deleteSvgGroup),
      isFulfilled(addSvgGroups),
      isFulfilled(updateSvgGroup),
      isFulfilled(deleteGroupIdsAndAddSimplePolygonGeometry),
      isFulfilled(undoCanvas),
      isFulfilled(redoCanvas),
      isFulfilled(clearCanvas),
      isFulfilled(updatePathsCutParams),
      isFulfilled(duplicateSelectedGroups),
      isFulfilled(setSvgGroupAnchor),
      isFulfilled(setCanvasCustomAnchor),
      isFulfilled(updateVersion),

      // include extra actions
      ...Object.values(LineToolActions.actions)
    ),
    effect: async (
      action: PayloadAction,
      { getState, dispatch, fork }: SyncListenerApi
    ) => {
      if (isRejected(action)) {
        throw new SyncError(
          'immer',
          'listener',
          `An error occurred in ${action.type}: `,
          action.error
        );
      }

      const state = getState();
      const { canvas } = state;
      const actionType = getActionName(action);

      // to find the function that is actually being called by `action` (i.e. `canvas/addSVGGeometry`)
      // look in `CanvasActions.ts`
      const createAction = lookUpActionMerged(actionType);

      const createActionFcn = createAction(action);
      const { stateMutator, addUndoPatches, manualPatchGenerator, payloadLog } =
        createActionFcn;

      log(
        `Immer canvas action listener: queueing patches for action: ${action.type}`,
        {
          ...context,
          payload: payloadLog ? payloadLog(action.payload) : undefined,
        },
        'debug'
      );

      try {
        const forkedProcess = fork(() => {
          const [patches, nextState] = generatePatchAndHistory(
            dispatch,
            canvas,
            state,
            stateMutator,
            addUndoPatches,
            manualPatchGenerator
          );
          return { patches, nextState };
        });

        const results = await forkedProcess.result;

        if (results.status === 'ok') {
          const { patches, nextState } = results.value;
          if (patches) {
            log(
              `Patches and next state for workspace`,
              {
                ...context,
                patches: sanitizePatchState(patches),
                nextState: sanitizeCanvasState(nextState),
              },
              'debug'
            );

            const { sync } = getState();
            const { queue, enabled } = sync;
            if (enabled) {
              const newQueue = [...queue, patches];
              log('Sync is enabled: queuing updates...', {
                ...context,
                newQueueLength: newQueue.length,
              });
              dispatch(updateQueue(newQueue));
              dispatch(
                setCanvasState({ state: nextState, fromAction: action })
              );
            } else {
              dispatch(
                setCanvasState({ state: nextState, fromAction: action })
              );
              dispatch(setSnapshot({ slice: 'canvas', value: nextState }));
            }
          }
        }
      } catch (error) {
        console.error(error);
        if (Sentry.isInitialized()) {
          Sentry.captureException(error);
        }
        dispatch(setStatus('error'));
        throw new SyncError(
          'canvas-action',
          'listener',
          'Error occurred in canvas action.',
          error as Error
        );
      }
    },
  });
};
