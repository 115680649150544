import React from 'react';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  label: string;
};

export default function Section(props: Props) {
  return (
    <div className='action-list-menu--section'>
      {props.label && (
        <div className='action-list-menu--section-label'>{props.label}</div>
      )}
      <div className='action-list-menu--section-children'>{props.children}</div>
    </div>
  );
}
