import React from 'react';
import SvgGroup from '@/UILayer/Components/SvgGroup';
import { getTypeProperty } from '@shapertools/sherpa-svg-generator/PathTypes';
import UIState from '../State/UIState';
import SvgGroupCls from '../State/Helpers/SvgGroup';

// shared layer cache?
// eslint-disable-next-line no-unused-vars
let layerCache: { [key in string]?: React.ReactNode } = {};

type Props = {
  className?: string;
  ui: UIState;
  mode?: string;

  extendGroup?: (group: SvgGroupCls) => object;
};

// renders a view of
export default function SvgGroups(props: Props) {
  const { ui } = props;
  const renderMode = ui.store.ui;
  const { changedGroupIds, selectedGroupIds } = ui;

  // clear the cache when the UI is just a full
  // re-render of the view
  if (!ui.hasChanges) {
    layerCache = {};
  }

  const groupSort = (a: SvgGroupCls, b: SvgGroupCls) => {
    const typeSort =
      getTypeProperty(b.data?.type, 'selectionOrder') >
      getTypeProperty(a.data?.type, 'selectionOrder')
        ? 1
        : -1;
    const areaSort = b.area - a.area;
    return typeSort || areaSort;
  };

  // create each svg group
  function renderGroups() {
    return ui.groups
      .sort((a, b) => groupSort(a, b))
      .filter((g) => getTypeProperty(g.data?.type, renderMode))
      .map((group) => {
        let layer = layerCache[group.id];

        // checks if the layer has been generated, or if it doesn't exist
        // yet - if needed, the layer is rendered now
        if (!layer || changedGroupIds[group.id]) {
          const extend = props.extendGroup ? props.extendGroup(group) : {};
          layer = (
            <SvgGroup
              selected={selectedGroupIds.includes(group.id)}
              group={group}
              ui={ui}
              key={`group_${group.id}`}
              {...extend}
            />
          );

          // saves the instance
          // layerCache.current[group.id] = layer;
          layerCache[group.id] = layer;
        }

        return layer;
      });
  }

  return renderGroups();
}
