import { isString, uniq, groupBy } from 'lodash';

// selectors
import {
  selectMostRecentlyAddedGroup,
  selectSelectedGroupIds,
  selectSelectedPathIds,
  setSelection,
  replaceSelection,
  setSelectedLine,
  setMostRecentlyMovedPoint,
  GroupId,
  PathId,
} from '@/Redux/Slices/SelectionSlice';
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';
import { selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';

import BaseAction from './BaseAction';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import UIState from '@/UILayer/State/UIState';
import { Action } from '@reduxjs/toolkit';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

const isPathId = (arg: GroupId | PathId): arg is PathId =>
  isString((arg as any)?.groupId);
export type Selection = (GroupId | PathId)[];
export const groupIdForSelectionItem = (arg: GroupId | PathId) =>
  isPathId(arg) ? arg.groupId : arg;

function normalizeGroupIds(collection: Selection) {
  const ids = collection
    .map((id) => (isPathId(id) ? id.groupId : id))
    .filter((id) => isString(id));
  return uniq(ids);
}

export default class SetSelectionAction extends BaseAction {
  set = (selection: Selection, refresh = true) => {
    return this._apply(setSelection(selection), refresh);
  };

  resetSelectedLine = () => {
    const selectedLine = this.useSelector(selectSelectedLine) as string[];
    UIState.reset();
    this.setSelectedLine(selectedLine);
  };

  setSelectedLine(points: string[]) {
    const { dispatch, useSelector } = this;
    UIState.reset();

    // clear the current selection
    if (!points?.length) {
      const current = useSelector(selectSelectedLine);
      if (current?.length) {
        this.remove(current);
      }
    }

    dispatch(setSelectedLine(points));
  }

  setMostRecentlyMovedPoint(pointId: string | null) {
    const { dispatch } = this;
    dispatch(setMostRecentlyMovedPoint(pointId));
  }

  selectAllPaths(refresh = true) {
    const { useSelector } = this;
    const allObjects = useSelector(selectSvgGroupSet);
    const filteredObjects = allObjects.filter(
      (o) => o.type !== PATH_TYPE.REFERENCE && o.tool.type !== Shape.POINT
    );

    let selection: Selection = [];
    for (const group of filteredObjects) {
      selection = [
        ...selection,
        ...group.basePathSet.map((path) => ({
          pathId: path.id,
          groupId: group.id,
        })),
      ];
    }

    this.set(selection, refresh);
  }

  // selectAllGroups() { }

  // expands out a selection to include other paths in the same
  // group. This only applies to some types
  expandSelection() {
    const { useSelector } = this;
    const groups = useSelector(selectSvgGroupSet);
    const selected = [...this.useSelector(selectSelectedPathIds)];

    // add more types to expand out, if needed
    const newSelected = ['text-insert'].reduce<Selection>(
      (previousToolResult, tool) => {
        const selectedByGroup = groupBy(selected, (s) => s.groupId);
        return [
          ...previousToolResult,
          ...Object.keys(selectedByGroup).reduce<Selection>(
            (previousGroupResult, groupId) => {
              const group = groups.find((item) => item.id === groupId);
              const selectedForThisGroup = selectedByGroup[groupId];

              return [
                ...previousGroupResult,
                // If the group is a matching tool type
                ...(group?.tool.type === tool
                  ? // Get every path in the group
                    group.basePathSet.map((item) => ({
                      pathId: item.id,
                      groupId,
                    }))
                  : // Otherwise use what's already in the selection
                    selectedForThisGroup),
              ];
            },
            []
          ),
        ];
      },
      []
    );

    // update the selection
    this.dispatch(setSelection(newSelected));
  }

  merge = (selection: Selection, refresh = true) => {
    const current = this.useSelector(selectSelectedGroupIds);
    const ids = normalizeGroupIds([...current, ...selection]);
    return this._apply(setSelection(ids), refresh);
  };

  remove = (selection: Selection, refresh = true) => {
    const selected = this.useSelector(selectSelectedPathIds);

    const updated = selected.filter((existing) =>
      selection.some(
        (toRemove) => existing.groupId === groupIdForSelectionItem(toRemove)
      )
    );

    return this._apply(setSelection(updated), refresh);
  };

  clear = (refresh = true) => {
    return this._apply(setSelection([]), refresh);
  };

  mostRecent = (refresh = true) => {
    return this._apply(selectMostRecentlyAddedGroup({}), refresh);
  };

  // slightly smarter version of selection management -- this will remove or add selection
  // but also account for paths as part of the selection possibilities
  resolve = (
    update: { pathId?: string; groupId?: GroupId }[] = [],
    action?: string
  ) => {
    const { useSelector } = this;

    // get the current selection state
    let groups = useSelector(selectSelectedGroupIds);
    let paths = useSelector(selectSelectedPathIds);

    // always remove the selection first
    for (const { pathId, groupId } of update) {
      paths = paths.filter(({ pathId: id }) => id !== pathId);

      // when providing a group, remove it
      if (groupId) {
        paths = paths.filter(({ groupId: id }) => id !== groupId);
        groups = groups.filter((id) => id !== groupId);
      }

      // if only targeting the group as a whole
      if (!pathId) {
        groups = groups.filter((id) => id !== groupId);
      }
    }

    if (action === 'add') {
      const all = useSelector(selectSvgGroupSet);

      // add all items as possible
      for (const { pathId, groupId } of update) {
        // including both a group and path
        if (groupId && pathId) {
          groups = [...groups, groupId];
          paths = [...paths, { groupId, pathId }];
        }

        // with just a group, include all paths
        else if (groupId) {
          const source = all.find((group) => group.id === groupId);
          if (source) {
            groups = [...groups, groupId];
            paths = [
              ...paths,
              ...source.basePathSet.map((path) => ({
                groupId,
                pathId: path.id,
              })),
            ];
          }
        }
        // just a path, select the parent group
        else if (pathId) {
          const source = all.find((group) =>
            group.basePathSet.find((path) => path.id === pathId)
          );
          if (source) {
            paths = [...paths, { pathId, groupId: source.id }];
            groups = [...groups, source.id];
          }
        }
      }
    }
    this.dispatch(replaceSelection({ groupIds: groups, pathIds: paths }));
    // this.refresh();
  };

  refresh = () => {
    console.log('unused function refresh (remove invocation from callee)');
    // return this.dispatch(updateSelectionBox());
  };

  _apply = <A extends Action<any>>(action: A, refresh: boolean) => {
    this.dispatch(action);
    // if (refresh) {
    //   this.refresh();
    // }
  };
}
