import BaseAction from './BaseAction';
import ApplySvgChangeSetAction, {
  SvgChangeSet,
} from '@/Actions/ApplySvgChangeSet';
import SetSelectionAction from '@/Actions/SetSelection';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';
import {
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';

export default class UpdateToolParamsAction extends BaseAction {
  selectedGroups: SvgGroup[];
  // eslint-disable-next-line no-unused-vars
  pendingChanges: { [key in Shape]?: Partial<Tool['params']> } = {};

  constructor(
    dispatch: AppDispatch,
    useSelector: UseSelector,
    selectedGroups: SvgGroup[]
  ) {
    super(dispatch, useSelector);

    this.dispatch = dispatch;
    this.selectedGroups = selectedGroups;
  }

  apply<T extends Shape>(target: T, params: Tool<T>['params']) {
    this.pendingChanges[target] = params;
  }

  async resolve() {
    const changes: SvgChangeSet = {};

    // apply to all selected groups, but limit
    // to the target types
    for (const group of this.selectedGroups) {
      const { id, tool } = group;
      const apply = this.pendingChanges[tool?.type];

      // nothing was found
      if (!apply) {
        console.error(
          `No shape params of type "${
            tool?.type || 'none'
          }" found for group ${id}`
        );
        continue;
      }

      // save the change set
      changes[id] = {
        toolParams: {
          ...tool.params,
          ...apply,
        },
      };
    }

    // apply updates
    const changeSet = this.createAction(ApplySvgChangeSetAction);
    await changeSet.apply(changes);

    // update the selection
    const selection = this.createAction(SetSelectionAction);
    selection.refresh();
  }
}
