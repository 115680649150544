import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import BaseAction from './BaseAction';
import SetSelectionAction, {
  Selection,
  groupIdForSelectionItem,
} from './SetSelection';
import { PathId, selectSelectedGroupIds } from '@/Redux/Slices/SelectionSlice';
import { compact } from 'lodash';
import {
  Shape,
  SvgGroup,
  ToolParams,
} from '@shapertools/sherpa-svg-generator/SvgGroup';

function getId(arg: null | PathId | string) {
  return (arg as PathId)?.groupId ?? (arg as string);
}

export default class ResolveGroupSelectionAction extends BaseAction {
  _resolve(selections: Selection) {
    const select = this.createAction(SetSelectionAction);
    const getGroupById = this.useSelector(selectGetGroupById);

    // resolve shapes and points
    // -- if a shape is selected, then select all points and de-select the shape
    const revised = [] as PathId[];
    for (const selection of selections as PathId[]) {
      const groupId = getId(selection);
      const group = getGroupById(groupId) as SvgGroup;

      // if a shape is selected, it means all of the points were
      // also selected - go ahead and replace the shape with the points
      if (group.tool.type === Shape.SHAPE) {
        const params = group.tool.params as ToolParams<Shape.SHAPE>;
        revised.push(
          ...params.points.map((point) => ({
            groupId: point,
            pathId: 'ignore',
          }))
        );
      }
      // this is not a shape so it can be included
      else {
        revised.push(selection);
      }
    }

    const groups = compact(revised);

    // update the selection
    select.set(groups);
    return groups;
  }

  // groups may be a collection of group objects or IDs
  resolve(
    newSelection: Selection,
    {
      appendToSelection,
      didSelectLine,
    }: { appendToSelection?: boolean; didSelectLine?: boolean } = {}
  ) {
    const { useSelector } = this;
    const getGroupById = useSelector(selectGetGroupById) as (
      id: string
    ) => SvgGroup;
    let groupIds = [...useSelector(selectSelectedGroupIds)];

    // start by checking if any points are selected on their own
    let selection = [...newSelection] as (PathId | null)[];
    let groups = [] as PathId[];

    // if all points for a shape is selected, then replace it with
    // the shape itself
    for (let i = selection.length; i-- > 0; ) {
      const item = selection[i];

      // this space has been cleared out
      if (!item) {
        continue;
      }

      // grab group info
      const group = getGroupById(getId(item));

      // if this is a point, check and see if the shape should be
      // selected for this test instead
      if (group.tool.type === Shape.POINT && !didSelectLine) {
        const params = group.tool.params as ToolParams<Shape.POINT>;
        const shape = getGroupById(params.belongsTo);
        const { points } = shape.tool.params as ToolParams<Shape.SHAPE>;

        // check if all points are selected
        const found = points.map((id) =>
          selection.find((f) => getId(f) === id)
        );

        // if all points were found, go ahead and remove all other points
        // from the selection and replace it with the shape
        if (found.length === points.length) {
          // for everything that was valid
          for (let j = selection.length; j-- > 0; ) {
            if (
              getId(selection[j]) === shape.id ||
              points.includes(getId(selection[j]))
            ) {
              selection[j] = null;
            }
          }

          // then replace this space with the shape ID
          selection[i] = { groupId: shape.id, pathId: 'ignore' };
        }
      }

      groups = compact(selection).map((f) => ({
        groupId: getId(f),
        pathId: 'ignore',
      }));
    }

    // if more items were selected
    if (groups.length > newSelection.length) {
      return this._resolve(groups);
    }

    // we're going to replace the selection
    if (appendToSelection) {
      // start by looping and adding the first new unique group
      for (const group of groups) {
        const appendId = groupIdForSelectionItem(group);
        const index = groupIds.indexOf(appendId);

        // if it's not found in the selection, add it and resolve
        if (index === -1) {
          groupIds.push(appendId);
          return this._resolve(groupIds);
        }
      }

      // since nothing new was added, check if we should remove something
      for (const group of groups) {
        const removeId = groupIdForSelectionItem(group);
        const index = groupIds.indexOf(removeId);

        // this was found in the selection, so it can
        // be removed
        if (index > -1) {
          groupIds.splice(index, 1);
          return this._resolve(groupIds);
        }
      }
    }

    // if there's a selection, find the index of the currently selected
    // item in the collection
    if (groupIds.length && !didSelectLine) {
      // since there's groups already selected, we're going to
      // use this to figure out where to cycle next
      for (let i = groupIds.length; i-- > 0; ) {
        const id = groupIds[i];
        const index = groups.findIndex(
          (group) => groupIdForSelectionItem(group) === id
        );

        // can cycle
        if (index > -1) {
          const use = groups[(index + 1) % groups.length];
          return this._resolve([groupIdForSelectionItem(use)]);
        }
      }
    }

    // if there's nothing at all, just resolve that
    if (!groups?.length) {
      return [];
    }

    // either we failed to cycle, or there was nothing
    // to cycle through. Just use the first group that
    // we're currently over
    return this._resolve(didSelectLine ? groups : [groups[0]]);
  }
}
