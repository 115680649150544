import { subtract, norm, crossMag, dot, comparePoints } from './PointOps';
import { pathCloseTolerance } from '@/defaults';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { Path } from '@shapertools/sherpa-svg-generator/Path';

function getPathSegments(path: Path) {
  const { closed, points } = path;
  const lastIdx = points.length - 1;

  const getNextIdx = (i: number) => {
    if (i === lastIdx) {
      if (closed) {
        return 0;
      }
      return undefined;
    }
    return i + 1;
  };

  const getSegment = (firstPtIdx: number | undefined) => {
    if (firstPtIdx === undefined) {
      return undefined;
    }
    const secondPtIdx = getNextIdx(firstPtIdx);
    return secondPtIdx !== undefined
      ? [points[firstPtIdx], points[secondPtIdx]]
      : undefined;
  };

  const segments = [];
  for (let idx = 0; idx <= lastIdx; idx++) {
    const seg = getSegment(idx);
    if (seg !== undefined) {
      segments.push(seg);
    }
  }
  return segments;
}

function segmentsParallel(s0: Point[], s1: Point[]) {
  //Segments are parallel if cross product mag / s0.norm / s1.norm  ~ 0
  const v0 = subtract(s0[1], s0[0]);
  const v1 = subtract(s1[1], s1[0]);

  return Math.abs(crossMag(v0, v1) / norm(v0) / norm(v1)) < 0.01;
}

function pointOnSegment(seg: Point[], p: Point) {
  //If l0,p x l0,l1 is ~= 0, then these p is on the line
  const v_s0_p = subtract(p, seg[0]);
  const v_s0_s1 = subtract(seg[1], seg[0]);
  const segLength = norm(v_s0_s1);

  if (segLength <= pathCloseTolerance) {
    return false;
  }

  const crossMagVal = Math.abs(crossMag(v_s0_s1, v_s0_p) / segLength);
  if (crossMagVal > pathCloseTolerance) {
    return false;
  }

  const dotProd = dot(v_s0_p, v_s0_s1) / segLength;
  if (dotProd < 0) {
    return false;
  }

  if (dotProd > segLength) {
    return false;
  }
  return true;
}

// Collinear segments can only be merged if all segment points are in the replacement segment
// Returns joined segment or original segment pair if cannot join segments
function joinAdjacentSegments(s0: Point[], s1: Point[]) {
  //If segments aren't adjacent, don't join
  const middlePoints = [s0[1], s1[0]];
  if (comparePoints(middlePoints[0], middlePoints[1])) {
    const joinedSeg = [s0[0], s1[1]]; //Need to maintain continuity with surrounding path

    //All middle points must be on the joined segment
    if (pointOnSegment(joinedSeg, middlePoints[0])) {
      return [joinedSeg];
    }
  }
  return [s0, s1];
}

//http://paulbourke.net/geometry/pointlineplane/javascript.txt
function getIntersectionPoint(s0: Point[], s1: Point[]) {
  const [p00, p01] = s0;
  const [p10, p11] = s1;
  const { x: x0, y: y0 } = p00;
  const { x: x1, y: y1 } = p01;
  const { x: x2, y: y2 } = p10;
  const { x: x3, y: y3 } = p11;
  // Check if none of the lines are of length 0
  if ((x0 === x1 && y0 === y1) || (x2 === x3 && y2 === y3)) {
    return false;
  }

  const denominator = (y3 - y2) * (x1 - x0) - (x3 - x2) * (y1 - y0);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  let ua = ((x3 - x2) * (y0 - y2) - (y3 - y2) * (x0 - x2)) / denominator;
  let ub = ((x1 - x0) * (y0 - y2) - (y1 - y0) * (x0 - x2)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  let x = x0 + ua * (x1 - x0);
  let y = y0 + ua * (y1 - y0);

  return new Point(x, y);
}

function segmentsIntersect(s0: Point[], s1: Point[]) {
  //check if parallel
  if (segmentsParallel(s0, s1)) {
    //if parallel, check if any s1 in s0,
    if (s1.some((p) => pointOnSegment(s0, p))) {
      return true;
    }
    //else return false
    return false;
  }
  //if not parallel, get intersection point. receives false if no intersection
  return !!getIntersectionPoint(s0, s1);
}

export {
  getPathSegments,
  segmentsParallel,
  pointOnSegment,
  joinAdjacentSegments,
  getIntersectionPoint,
  segmentsIntersect,
};
