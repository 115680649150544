import { ServerSHA } from '@/@types/shaper-types';
import { apiTimeouts, SANDBOX_WORKSPACE_KEY } from '@/defaults';
import { Snapshot } from '@/Sync/SyncConstants';
import axios from 'axios';

const thisAxios = axios.create({
  timeout: apiTimeouts.localStorage,
});

const getServerSHA = async () => {
  const response = await thisAxios.get<{ commit: string; version: string }>(
    '/version.json',
    {
      headers: {
        'Cache-Control': 'no-cache',
      },
    }
  );
  const { data } = response;
  return data.commit;
};

const loadKey = <T>(
  stateKey: string,
  storageSrv = localStorage
): T | undefined => {
  try {
    const serializedState = storageSrv.getItem(stateKey);
    if (serializedState === null) {
      return undefined;
    }
    const savedState = JSON.parse(serializedState);
    return savedState;
  } catch (err) {
    return undefined;
  }
};

const saveKey = (stateKey: string, state: any, storageSrv = localStorage) => {
  try {
    const serializedState = JSON.stringify(state);
    storageSrv.setItem(stateKey, serializedState);
  } catch {
    // ignore write errors
  }
};

const loadLocalKey = <T>(stateKey: string) => {
  return loadKey<T>(stateKey, localStorage);
};

const loadSessionKey = <T>(stateKey: string) => {
  return loadKey<T>(stateKey, sessionStorage);
};

const saveLocalKey = (stateKey: string, state: any) => {
  return saveKey(stateKey, state, localStorage);
};

const saveSessionKey = (stateKey: string, state: any) => {
  return saveKey(stateKey, state, sessionStorage);
};

export const clearDemoStorage = () => {
  localStorage.removeItem(SANDBOX_WORKSPACE_KEY);
  sessionStorage.removeItem(SANDBOX_WORKSPACE_KEY);
};

export const saveServerSHA = (serverSha: string) => {
  saveSessionKey('server_sha', { server_sha: serverSha });
  saveLocalKey('server_sha', { server_sha: serverSha });
};

export const loadServerSHA = () => {
  let serverSHA = loadSessionKey<ServerSHA>('server_sha');
  if (serverSHA === undefined) {
    serverSHA = loadLocalKey<ServerSHA>('server_sha');
  }
  return serverSHA?.server_sha;
};

export const loadSandboxWorkspace = async (): Promise<Snapshot> => {
  const emptySnapshot = {
    canvas: null,
  };

  const newServerSHA = await getServerSHA();
  const oldServerSHA = loadServerSHA();
  if (newServerSHA !== oldServerSHA) {
    clearDemoStorage();
    return emptySnapshot;
  }
  let savedState = loadSessionKey<Snapshot>(SANDBOX_WORKSPACE_KEY);
  if (savedState === undefined) {
    savedState = loadLocalKey<Snapshot>(SANDBOX_WORKSPACE_KEY);
  }

  if (savedState === undefined) {
    return emptySnapshot;
  }

  return savedState;
};

export const checkBrowserForSavedState = () => {
  return (
    Object.keys(sessionStorage).includes(SANDBOX_WORKSPACE_KEY) ||
    Object.keys(localStorage).includes(SANDBOX_WORKSPACE_KEY)
  );
};

export const saveSandboxWorkspace = (workspace: Snapshot) => {
  saveSessionKey(SANDBOX_WORKSPACE_KEY, workspace);
};
