import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector, useStore } from 'react-redux';
import type { AppDispatch, AppStore, RootState } from './store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AnyAsyncThunk,
  FulfilledActionFromAsyncThunk,
  PendingActionFromAsyncThunk,
  RejectedActionFromAsyncThunk,
} from 'node_modules/@reduxjs/toolkit/src/matchers';
import { SyncError } from '@/Sync/SyncError';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: SyncError;
}>();

export type AppAsyncThunk = ReturnType<typeof createAppAsyncThunk>;

export type FulfilledAction = FulfilledActionFromAsyncThunk<AnyAsyncThunk>;
export type RejectedAction = RejectedActionFromAsyncThunk<AnyAsyncThunk>;
export type PendingAction = PendingActionFromAsyncThunk<AnyAsyncThunk>;
