import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//selectors
import {
  selectShaperHubPath,
  selectShaperHubFolder,
  selectRecentShaperHubFiles,
} from '@/Redux/Slices/ShaperHubSlice';

//actions
import { useAction } from '@/Actions/useAction';
import FileSystemAction from '@/Actions/FileSystem';

//components
import Icon from '@/Styles/Icons/Icon';
import FileSystemRow from './FileSystemRow';
import classNames from 'classnames';
import { FILETYPES } from '../../../defaults';
import { useTranslation } from 'react-i18next';
import TranslationText from '../../TranslationText/TranslationText';
import {
  FilesystemObject,
  UserspaceExternalItemFileObject,
  UserspaceFileObject,
} from '@/@types/shaper-types';

type Props = {
  mode: 'open' | 'move' | null;

  onClick: () => void;
};

export default function FileBrowser(props: Props) {
  const { mode } = props;

  const [selected, setSelected] = useState<FilesystemObject | null>(null);

  const shaperHubPath = useSelector(selectShaperHubPath);
  const shaperHubFolder = useSelector(selectShaperHubFolder);
  const recentShaperHubFiles = useSelector(selectRecentShaperHubFiles);
  const { t, i18n } = useTranslation();

  const fileSystemAction = useAction(FileSystemAction);

  const files = mode === 'move' ? shaperHubFolder : recentShaperHubFiles;

  const atRoot = shaperHubPath && shaperHubPath.length === 0;
  const directoryName = atRoot
    ? mode === 'move'
      ? i18n.exists('my-projects')
        ? t('my-projects')
        : 'My Files'
      : i18n.exists('recents')
      ? t('recents')
      : 'Recents'
    : shaperHubPath[shaperHubPath.length - 1];
  const directoryIcon = mode === 'move' ? 'my-projects' : 'recents';

  function clearDirectory() {
    fileSystemAction.clearDirectory(atRoot);
  }

  const headerCx = classNames('file-system--header-icon', {
    back: !atRoot,
  });

  function onClick() {
    props.onClick();
  }

  function moveWorkspace() {
    fileSystemAction.moveWorkspace();
  }

  function selectFile(file: FilesystemObject) {
    setSelected(file);
  }

  function openWorkspaceOrFile() {
    if (mode === 'open') {
      if (selected?.type === FILETYPES.FILE) {
        fileSystemAction.openFile(selected as UserspaceFileObject);
      } else if (selected?.type === FILETYPES.EXTERNAL) {
        fileSystemAction.openWorkspace(
          selected as UserspaceExternalItemFileObject
        );
      }
    }
  }

  const buttonCx = classNames('file-system--footer--content-move-button', {
    disabled: mode === 'open' && !selected,
  });

  return (
    <div className={`file-system ${mode}`}>
      <div className='file-system--header'>
        <div className={headerCx}>
          {!atRoot ? (
            <Icon icon='arrow-left' onClick={clearDirectory} />
          ) : (
            <Icon icon={directoryIcon} />
          )}
        </div>
        <div className='file-system--header-title'>{directoryName}</div>
      </div>
      <div className={`file-system--content scrollable ${mode}`}>
        {files.map((f, i) => (
          <FileSystemRow
            key={i}
            file={f}
            mode={mode}
            selected={f === selected}
            onClick={selectFile}
          />
        ))}
      </div>
      <div className='file-system--footer'>
        <div className='file-system--footer--content'>
          <div
            className={buttonCx}
            onClick={mode === 'move' ? moveWorkspace : openWorkspaceOrFile}
          >
            {mode === 'move'
              ? i18n.exists('move-here')
                ? t('move-here')
                : 'Move Here'
              : i18n.exists('open')
              ? t('open')
              : 'Open'}
          </div>
          <div
            className='file-system--footer--content-cancel-button'
            onClick={onClick}
          >
            <Icon
              icon='close'
              className='file-system--content--footer-close-icon'
            />
          </div>
        </div>
        {mode === 'open' && (
          <div className='file-system--footer--content-links'>
            <div className='file-system--footer--content-links-text'>
              <a href='https://hub.shapertools.com/cloud'>
                <TranslationText i18nKey='browse-all'>
                  Browse all my files
                </TranslationText>
                <Icon
                  icon='open-in-new-window'
                  className='file-system--footer--content-links-icon'
                />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
