import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unitToMMNum } from '@/Geometry/UnitOps';
import UIState from '@/UILayer/State/UIState';

// slices
import {
  selectDisplayUnits,
  selectToFormattedDisplayUnitValue,
} from '@/Redux/Slices/SherpaContainerSlice';
import { selectMostRecentlyMovedPoint } from '@/Redux/Slices/SelectionSlice';
import { setPolylinePointPositions } from '@/LineTool/LineToolActions';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import TranslationText from '@/Components/TranslationText/TranslationText';

export default function DistanceAdjustment({ points }) {
  const [a, b] = points;
  const dispatch = useDispatch();
  const distance = Math.hypot(
    b.position.x - a.position.x,
    b.position.y - a.position.y
  );
  const displayUnits = useSelector(selectDisplayUnits);
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );
  const displayDistance = toFormattedDisplayUnitValue(distance);
  const mostRecentlyMovedPoint = useSelector(selectMostRecentlyMovedPoint);

  // check for line movements
  const movedA = mostRecentlyMovedPoint === a.id;
  const movedB = mostRecentlyMovedPoint === b.id;
  const moveBoth = !movedA && !movedB;
  const moved = {
    [a.id]: movedA,
    [b.id]: movedB,
  };

  async function onSetDistance(value) {
    const equidistance = unitToMMNum(value, displayUnits) * 0.5;
    const cx = (a.position.x + b.position.x) * 0.5;
    const cy = (a.position.y + b.position.y) * 0.5;

    // calculate each point
    const updates = {};
    points.forEach((point, i) => {
      const other = points[(i + 1) % points.length];
      const radians = Math.atan2(cy - point.position.y, cx - point.position.x);
      const selfMoved = moved[point.id];

      // if there was movement, but it was not
      // this point, this can be skipped
      if (!moveBoth && !selfMoved) {
        return;
      }

      // calculate the new position
      let travel = equidistance;
      let nx = cx - Math.cos(radians) * travel;
      let ny = cy - Math.sin(radians) * travel;

      // if this point moved, but not both of them
      // then this moves double the distance
      // to achieve the same effect
      if (!moveBoth && selfMoved) {
        travel *= 2;
        nx = other.position.x - Math.cos(radians) * travel;
        ny = other.position.y - Math.sin(radians) * travel;
      }

      // update the points
      updates[point.id] = { x: nx, y: ny };
    });

    // apply changes
    dispatch(setPolylinePointPositions({ points: updates }));

    // clear UI changes
    UIState.reset();
  }

  return (
    <>
      <FloatingPanel.Label icon='size'>
        <TranslationText i18nKey='length'>Length</TranslationText>
      </FloatingPanel.Label>

      <FloatingPanel.Input
        calculate
        suffix={displayUnits}
        onCommit={onSetDistance}
        number
        value={displayDistance}
      />
    </>
  );
}
