import React, {
  useState,
  useEffect,
  useRef,
  TouchEvent,
  PointerEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTranslateMtx } from '@shapertools/sherpa-svg-generator/Matrix33';
import { getSizeAndCenterFromAABB } from '@/Geometry/AABBOps';
import { scalarMul } from '@/Geometry/PointOps';
import { uniq } from 'lodash';
import { cancelEvent } from '@/Utility/events';
import { getAABB, transform } from '@/Geometry/BasePathOps';

// Actions and Selectors
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import {
  selectGroupsFragmentsSvgs,
  selectGroupsFragmentsAABB,
  addFragmentSet,
  selectAllPendingShapesSimplePolygons,
} from '@/Redux/Slices/ShapeShifterSlice';

import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';

// Components
import FooterMenu from '@/Components/FooterMenu/FooterMenu';
import MultiShapeSelector, {
  MultiShapeSelectorItem,
} from '@/Components/MultiShapeSelector/MultiShapeSelector';
import AddGeometryAction from '@/Actions/AddGeometry';

import Toggle from '@/Components/ToggleSwitch/ToggleSwitch';
import Icon from '@/Styles/Icons/Icon';

// Styling
import UIModeAction from '@/Actions/UIMode';
import { useAction } from '@/Actions/useAction';
import { SHAPE_SHIFTER_FILL_ACTIVATION_TIME } from '@/Constants/UI';
import Viewport from '@/UILayer/Components/Viewport';
import TranslationText from '../../../Components/TranslationText/TranslationText';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../../Utility/userflow';
import UIState from '@/UILayer/State/UIState';
import { AppDispatch } from '@/Redux/store';
import { Shape, Tool } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { SelectionItem } from '@/Selection/SelectionEditor/SelectionEditor';
import {
  DISPLAY_AREA,
  getCoordinateFromEvent,
  getFragmentFromEvent,
  isMultiTouch,
  isTouch,
  PendingShapePair,
  generateRawSVG,
} from './ShapeShifterHelpers';
import { setLoading } from '@/Redux/Slices/SherpaContainerSlice';

//Enables shopping cart for multiple shape creation from single shape shifter session
const ENABLE_MULTIPLE_SHAPES = false;

type Props = {
  ui: UIState;
};

type Interaction = {
  mode: any;
  activate: number;
  id: string;
  x: number;
  y: number;
};

export default function ShapeShifterUI(props: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const previewRef = useRef<SVGGElement>(null);
  const pendingInteraction: React.MutableRefObject<Interaction | null> =
    useRef(null);

  const selectedGroups = useSelector(selectSelectedGroups);

  const initialSelectedGroupIds = selectedGroups
    .filter(
      (s) =>
        !(
          s.tool.type === 'text-insert' &&
          s.basePathSet.some((b) =>
            b.outerPath ? !b.outerPath.closed : !b.closed
          )
        )
    )
    .map((g) => g.id);

  const uiModeAction = useAction(UIModeAction);
  const { t, i18n } = useTranslation();
  const [keepExistingShapes, setKeepExistingShapes] = useState(false);
  const [fillMode, setFillMode] = useState<string | null>(null);
  const [fillChanges, setFillChanges] = useState([] as string[]);
  const [commit, setCommit] = useState(false);
  const [selectedFragments, setSelectedFragments] = useState<string[]>([]);
  const nsFactor = useSelector(selectNonScalingPixelFactor);
  const fragmentsSvgs = useSelector(selectGroupsFragmentsSvgs);
  const fragmentsAABB = useSelector(selectGroupsFragmentsAABB);
  const { size: fragmentsSize, centerPosition: fragmentsCenterPos } =
    getSizeAndCenterFromAABB(fragmentsAABB);

  const commitShapesSimplePolygons = useSelector(
    selectAllPendingShapesSimplePolygons
  );

  const pendingShapesPairs: PendingShapePair[] = [];

  const addGeometryAction = useAction(AddGeometryAction);

  const hasSelectedAll = fragmentsSvgs.length === selectedFragments.length;

  const hasSelection = selectedFragments.length > 0;
  const selectionActionLabel = hasSelectedAll
    ? i18n.exists('clear-selection')
      ? t('clear-selection')
      : 'Clear Selection'
    : i18n.exists('select-all')
    ? t('select-all')
    : 'Select All';
  const canMakeShape = hasSelection;

  // checks if a fragment is currently selected
  const isSelected = (id: string) => selectedFragments.indexOf(id) > -1;

  // add a fragment to the selection
  function addFragment(id: string) {
    setSelectedFragments(uniq([...selectedFragments, id]));
  }

  // remove from the selection
  function removeFragment(id: string) {
    const selection = [...selectedFragments];
    const index = selection.indexOf(id);

    // for some reason this was called without
    // the ID actually being present
    if (index > -1) {
      selection.splice(index, 1);
    }

    // remove
    setSelectedFragments(uniq(selection));
  }

  // selects all fragments for use
  function selectAll() {
    const ids = fragmentsSvgs.map((pair) => pair.fragmentIdStr);
    setSelectedFragments(ids);
  }

  // clears the selection
  function clearSelection() {
    setSelectedFragments([]);
  }

  function onToggleSelection() {
    if (hasSelectedAll) {
      clearSelection();
    } else {
      selectAll();
    }
  }

  function onCancelShapeShifter() {
    //TODO - set canvas viewport to match settings from shapeshifter viewport on cancel and commit
    // dispatch(clearFragmentSet());
    uiModeAction.toDefault();
  }

  //Add all shapes in checkout cart to canvas
  function onCommitShapes() {
    const simplePolygonsParams = commitShapesSimplePolygons.map(
      (simplePolygonsBPArray) => {
        const fragmentCenterPosition = getSizeAndCenterFromAABB(
          getAABB(simplePolygonsBPArray)
        ).centerPosition;
        const inverseFragmentCenterPosition = scalarMul(
          fragmentCenterPosition,
          -1
        );
        const centeredSimplePolygons = transform(
          simplePolygonsBPArray,
          createTranslateMtx(inverseFragmentCenterPosition)
        );

        return {
          simplePolygon: centeredSimplePolygons,
          position: fragmentCenterPosition,
          tool: new Tool(Shape.SHAPESHIFTER),
        };
      }
    );

    addGeometryAction.addShapeShifterCommits({
      simplePolygonsParams,
      deleteGroupIds: !keepExistingShapes ? initialSelectedGroupIds : [],
    });
  }

  //Add current selectedFragments to checkout cart
  function onMakeShape() {
    dispatch(setLoading(true));
    const selectedFragmentIds = selectedFragments.map((str) =>
      str.replace('fragment-id-', '')
    );

    // if nothing is selected, don't bother
    if (!selectedFragmentIds.length) {
      return;
    }

    //TODO - ideally .then could call onCommit directly, but commitShapesSimplePolygon selector is not updated right away
    dispatch(addFragmentSet({ selectedFragmentIds })).then(() =>
      setCommit(true)
    );

    trackEvent('objects_shapeshifted', {});
  }

  // after the shape has been made, commit the shape
  // TODO: this is temporary as it's not 100% decided if this view should
  // resolve immediately, or allow multiple shapes to be created
  useEffect(() => {
    if (commit && ENABLE_MULTIPLE_SHAPES === false) {
      onCommitShapes();
    }
  }, [commit]);

  function processFragment(event: PointerEvent | TouchEvent) {
    const at = getCoordinateFromEvent(event);

    // check the mode
    const isFilling = fillMode === 'fill';

    // get all fragment elements
    const fragments = previewRef.current!.querySelectorAll('[data-fragmentid]');
    for (const fragment of fragments) {
      const id = fragment.getAttribute('data-fragmentid');

      // already processed
      if (!id || (id && fillChanges.indexOf(id) > -1)) {
        continue;
      }

      // hit test all elements that haven't been looked at
      const path = fragment.querySelector('path');
      const hit = !!document
        .elementsFromPoint(at.x, at.y)
        .find((match) => match === path);

      // HitDetection.hitTestSvgPathElement(path, event.clientX, event.clientY);

      // if hit, toggle the fill mode
      if (hit) {
        setFillChanges([...fillChanges, id]);

        // change the fill mode
        if (isFilling) {
          addFragment(id);
        } else {
          removeFragment(id);
        }
      }
    }
  }

  // checks how to handle
  function activateMode(
    event: PointerEvent | TouchEvent,
    mode: string,
    onActivate: (id: string) => void
  ) {
    // ignore multi-touch
    if (isMultiTouch(event)) {
      return;
    }

    cancelEvent(event);

    // track when to activate fill mode
    const activate = Date.now() + SHAPE_SHIFTER_FILL_ACTIVATION_TIME;
    const id = getFragmentFromEvent(event);
    const { x, y } = getCoordinateFromEvent(event);
    pendingInteraction.current = {
      mode,
      activate,
      id,
      x,
      y,
    };

    // make sure this is still pending
    if (pendingInteraction.current?.activate !== activate) {
      return;
    }

    // begin the fill attempt
    setFillMode(mode);
    onActivate(id);
  }

  function onActivateFillMode(event: PointerEvent | TouchEvent) {
    activateMode(event, 'fill', (id: string) => {
      addFragment(id);
      setFillChanges([id]);
    });
  }

  // handles attempting to clear
  function onActivateClearMode(event: PointerEvent | TouchEvent) {
    activateMode(event, 'clear', (id: string) => {
      removeFragment(id);
      setFillChanges([id]);
    });
  }

  // when moving, clear any pending interactions
  function onPointerMove(event: TouchEvent | PointerEvent) {
    // clear as needed
    if (!fillMode) {
      let reject = true;

      // check if needing to ignore the pending mouse down
      if (!isTouch(event)) {
        if (!pendingInteraction.current) {
          reject = true;
        } else {
          const x = pendingInteraction.current?.x - event.clientX;
          const y = pendingInteraction.current?.y - event.clientY;
          const dist = Math.sqrt(x * x + y * y);
          reject = !isNaN(dist) && dist > 5;
        }
      }

      // clear the pending action
      if (reject) {
        pendingInteraction.current = null;
      }

      // don't process
      return;
    }

    // capture this for filling
    event.stopPropagation();
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    // begin fill
    processFragment(event);
    return;
  }

  // if tapping is released, just apply the event
  function onReleasePointer() {
    if (pendingInteraction.current) {
      const { mode, id } = pendingInteraction.current;
      if (mode === 'fill') {
        addFragment(id);
      } else {
        removeFragment(id);
      }
      setFillChanges([id]);
    }

    pendingInteraction.current = null;
    setFillMode(null);
  }

  function onToggleRemoveShapes() {
    setKeepExistingShapes(!keepExistingShapes);
  }

  return (
    <div
      onTouchEnd={onReleasePointer}
      onPointerUp={onReleasePointer}
      onPointerMove={onPointerMove}
      onTouchMove={onPointerMove}
    >
      <style type='text/css'>{`
        .shape-shifter__fragment path, 
        .shape-shifter__fragment__selected path {
          stroke-width: ${2 * nsFactor};
        }

        .shape-shifter__fragment path {
          stroke-dasharray: ${2 * nsFactor} ${2 * nsFactor};
        }
      `}</style>
      <div className='shapeshifter-ui-header' data-cy='shapeshifter-ui'>
        <Icon icon='shape-shifter' />
        <div>
          <TranslationText i18nKey='shape-shifter'>
            Shapeshifter
          </TranslationText>
        </div>
      </div>
      <Viewport viewport={props.ui.viewport}>
        <g id='shape-shifter-workspace' ref={previewRef}>
          {fragmentsSvgs.map((fragment) => {
            const selected = isSelected(fragment.fragmentIdStr);
            return (
              <g
                key={`${fragment.fragmentIdStr}__outline`}
                data-fragmentid={fragment.fragmentIdStr}
                onTouchStart={onActivateFillMode}
                onPointerDown={onActivateFillMode}
                className={`shape-shifter__fragment ${
                  selected ? 'selected' : ''
                }`}
                dangerouslySetInnerHTML={{ __html: fragment.fragmentSvgStr }}
              />
            );
          })}
          {fragmentsSvgs.map((fragment) => {
            const selected = isSelected(fragment.fragmentIdStr);
            return (
              selected && (
                <g
                  key={`${fragment.fragmentIdStr}__selected`}
                  data-fragmentid={fragment.fragmentIdStr}
                  onTouchStart={onActivateClearMode}
                  onPointerDown={onActivateClearMode}
                  className='shape-shifter__fragment__selected'
                  dangerouslySetInnerHTML={{ __html: fragment.fragmentSvgStr }}
                />
              )
            );
          })}
        </g>
      </Viewport>

      <FooterMenu>
        <FooterMenu.Row>
          <FooterMenu.Label icon='shape-shifter'>
            <TranslationText i18nKey='shape-shifter'>
              Shapeshifter
            </TranslationText>
          </FooterMenu.Label>
          <FooterMenu.Button onClick={onToggleSelection}>
            {selectionActionLabel}
          </FooterMenu.Button>
          <FooterMenu.Button onClick={onMakeShape} disabled={!canMakeShape}>
            <TranslationText i18nKey='make-shape'>Make Shape</TranslationText>
          </FooterMenu.Button>
          <FooterMenu.Close onClick={onCancelShapeShifter} />
        </FooterMenu.Row>
        <FooterMenu.Row>
          <Toggle onToggle={onToggleRemoveShapes} active={keepExistingShapes}>
            <TranslationText i18nKey='keep-existing'>
              Keep existing shapes
            </TranslationText>
          </Toggle>
        </FooterMenu.Row>
      </FooterMenu>

      {ENABLE_MULTIPLE_SHAPES && pendingShapesPairs.length > 0 && (
        <MultiShapeSelector
          style={{
            position: 'absolute',
            left: '50%',
            width: 'auto',
            top: '65px',
            transform: 'translateX(-50%)',
          }}
        >
          {pendingShapesPairs.map((pendingShapePair) => {
            const ratio = Math.min(
              DISPLAY_AREA / fragmentsSize.x,
              DISPLAY_AREA / fragmentsSize.y
            );

            const item: SelectionItem = {
              id: `group_${pendingShapePair.id}`,
              group: pendingShapePair,

              //translate centers shape within bubble
              //div element is 61px high, not 56px, so add 2.5 to svg y position to center vertically.
              rawSVG: generateRawSVG(
                pendingShapePair,
                ratio,
                fragmentsCenterPos
              ),
            };

            return (
              <MultiShapeSelectorItem
                key={item.id}
                groupId={pendingShapePair.id}
                item={item}
              />
            );
          })}
        </MultiShapeSelector>
      )}
    </div>
  );
}
