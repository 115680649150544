/* access the main grid viewport */
export function getViewport() {
  return document.querySelector<HTMLElement>(
    '[data-viewport]'
  ) as SVGSVGElement | null;
}

/* access individual paths */
export function getPathById(
  gid: string,
  pid: string | string[],
  type = 'pathGroup'
) {
  return getViewport()?.getElementById(`${type}-sg-${gid}-pg-${pid}`);
}

/* access individual layers */
export function getGroupById(gid: string, prefix = 'sg') {
  return document.getElementById(`${prefix}-${gid}`);
}

export function getIntTranslateGroup(gid: string) {
  return document.getElementById(`sg-${gid}-int-translate`);
}

export function getIntRotateStretchGroup(gid: string) {
  return document.getElementById(`sg-${gid}-int-rotate-stretch`);
}

export function prepareInputPreselect() {
  const input = document.getElementById('temporary-input-preselect');
  if (input) {
    input.click();
    input.focus();
  }
}

export function getAlignmentGuide(axis: 'x' | 'y', index: number) {
  return document.getElementById(
    `alignment-guide-${axis}-axis--${index}`
  ) as HTMLElement;
}

// hides all alignment guides from view
export function clearAlignmentGuides() {
  for (const guide of document.querySelectorAll('.alignment-guide')) {
    if (guide instanceof HTMLElement) {
      guide.style.display = 'none';
    }
  }
}

// handles updating alignment guides
export function applyAlignmentGuides(
  guides: {
    x?: number;
    y?: number;
    left?: number;
    top?: number;
    length?: number;
  }[]
) {
  let x = 0;
  let y = 0;

  for (const guide of guides) {
    const isX = 'x' in guide;
    const prop = isX ? 'x' : 'y';
    const index = Math.min(isX ? x++ : y++, 4);
    const el = getAlignmentGuide(prop, index);
    el.style.display = 'block';

    // align as needed
    if ('x' in guide) {
      el.style.left = `${guide.x}px`;
    }
    if ('y' in guide) {
      el.style.top = `${guide.y}px`;
    }
    if ('left' in guide) {
      el.style.left = `${guide.left}px`;
      el.style.width = `${guide.length}px`;
    }
    if ('top' in guide) {
      el.style.top = `${guide.top}px`;
      el.style.height = `${guide.length}px`;
    }
  }
}

export function getSelectionNet() {
  return document.getElementById('selection-net');
}

export function getBoundingBox() {
  return document.getElementById('bounding-box');
}

export function getBoundingBoxHandle(handle: string) {
  return document.getElementById(`bounding-box-handle--${handle}`);
}

export function getBoundingBoxHandles() {
  return [...document.querySelectorAll('#bounding-box .handle')];
}

export function getRotationConnector() {
  return document.querySelector('#bounding-box line');
}

export function getBoundingBoxOutline() {
  return getBoundingBox()?.querySelector('.outline');
}

export function getSelectionBoxDimensions() {
  const bb = getBoundingBox()!;
  const x = bb.querySelector('.width')!;
  const y = bb.querySelector('.height')!;
  return {
    x: {
      text: x.querySelector('text'),
      rect: x.querySelector('rect'),
      root: x.querySelector('g'),
    },
    y: {
      text: y.querySelector('text'),
      rect: y.querySelector('rect'),
      root: y.querySelector('g'),
    },
  };
}

export function getViewportBounds() {
  const viewport = getViewport();
  return viewport?.getBoundingClientRect();
}

export function getViewBox() {
  const viewport = getViewport();
  return viewport
    ?.getAttribute('viewBox')
    ?.replace(/[^0-9.\- ]/g, '')
    .replace(/(^ *| *$)/g, '')
    .split(/ +/g)
    .map(parseFloat);
}

export function getTransformsForGroup(el: Element) {
  const transforms: {
    translate?: Element;
    rotate?: Element;
    root?: Element;
  } = {};
  const container = el.querySelector('svg');
  const elements = container?.querySelectorAll('[transform]');

  // map each one
  for (const element of elements || []) {
    if (/group-translate/.test(element.id)) {
      transforms.translate = element;
    } else if (/group-rotate/.test(element.id)) {
      transforms.rotate = element;
    } else if (/-root/.test(element.id)) {
      transforms.root = element;
    }
  }

  return { container, transforms };
}

export function getSelectionEditor() {
  return document.getElementById('selection-editor');
}
