import { getTypeProperty } from '@shapertools/sherpa-svg-generator/PathTypes';
import { CanvasState } from '@/Redux/Slices/CanvasSlice';
import { GroupAndPathId, SelectionState } from '@/Redux/Slices/SelectionSlice';
import { addAttributeToUser } from '@/Utility/userflow';
import { compact } from 'lodash';
import { getId } from '@/Geometry/BasePathOps';

export const doSetSelection = (
  ids: (string | GroupAndPathId)[],
  canvas: CanvasState
) => {
  let selection = compact(ids || []);

  const groupsAdded: {
    [key: string]: boolean;
  } = {};
  const newPathIds: GroupAndPathId[] = [];
  for (const item of selection) {
    // appears to be only a group ID string
    // we may want to refactor this to look up the string
    // id so we can have it determine groups vs paths
    if (typeof item === 'string') {
      groupsAdded[item] = true;
      // add each individual path belonging to this group
      const group = canvas.canvas.svgGroupSet.find((g) => g.id === item);
      if (group) {
        newPathIds.push(
          ...group.basePathSet.map((path) => ({
            groupId: item,
            pathId: path.id,
          }))
        );
      }
    }
    // appears to be a selection object
    else if ('groupId' in item && 'pathId' in item) {
      newPathIds.push(item);
      groupsAdded[item.groupId] = true;
    }
    // we need to debug these, so crash on this
    else {
      console.error('Unknown selection type', item);
      throw new Error('Unknown selection type');
    }
  }
  const groups = Object.keys(groupsAdded);
  const groupIds = groups.map((gIdKey) => gIdKey);
  const pathIds = newPathIds;
  addAttributeToUser(
    'objects_selected',
    groups.length === 0 ? null : groups.length
  );
  return { groupIds, pathIds };
};

export const doUpdateActiveSelection = (
  state: SelectionState,
  canvas: CanvasState,
  mode: string
) => {
  const svgGroupSetFilteredByMode = canvas?.canvas?.svgGroupSet.filter((s) =>
    getTypeProperty(s.type, mode)
  );
  // create a map of groups
  const groupIdMap: {
    [key: string]: boolean;
  } = {};
  for (const group of svgGroupSetFilteredByMode) {
    groupIdMap[group.id] = true;
  }
  // remove all selected objects that has a group
  // that has been removed
  const newGroupIds = [],
    newPathIds = [];
  for (const groupId of state.groupIds) {
    if (groupIdMap[groupId] === true) {
      newGroupIds.push(groupId);
      for (const pathIdPair of state.pathIds) {
        if (pathIdPair.groupId === groupId) {
          newPathIds.push(pathIdPair);
        }
      }
    }
  }
  return { groupIds: newGroupIds, pathIds: newPathIds };
};

export const doRefreshPathSelection = (
  state: SelectionState,
  canvas: CanvasState,
  mode: string
) => {
  const svgGroupSetFilteredByMode = canvas?.canvas?.svgGroupSet.filter((s) =>
    getTypeProperty(s.type, mode)
  );

  // create a map of groups
  const groupIdMap: {
    [key: string]: boolean;
  } = {};
  for (const group of svgGroupSetFilteredByMode) {
    groupIdMap[group.id] = true;
  }

  const newGroupIds = [],
    newPathIds = [];
  for (const groupId of state.groupIds) {
    if (groupIdMap[groupId] === true) {
      newGroupIds.push(groupId);
      const group = svgGroupSetFilteredByMode.find((s) => s.id === groupId);
      if (group) {
        for (const path of group.basePathSet) {
          newPathIds.push({ groupId: group.id, pathId: path.id });
        }
      }
    }
  }

  return { ...state, groupIds: newGroupIds, pathIds: newPathIds };
};

export const doSelectMostRecentlyAddedGroup = (
  payload: { duplicateCount?: number } | void,
  canvas: CanvasState
) => {
  let groupIds;

  const addedGroupCount =
    payload && payload.duplicateCount ? payload.duplicateCount : 1;

  const svgGroupSet = canvas.canvas.svgGroupSet;
  const mostRecentSvgGroups = svgGroupSet.slice(-addedGroupCount);
  groupIds = mostRecentSvgGroups.map((g) => g.id);

  // default to first path as selected
  let groupAndPathIds: GroupAndPathId[] = [];
  groupIds.forEach((id) => {
    const group = canvas.canvas.svgGroupSet.find(
      (existing) => existing.id === id
    );
    if (group) {
      const pathIds = getId(group.basePathSet) as string[];
      pathIds.forEach((pathId) => {
        groupAndPathIds.push({ groupId: id, pathId });
      });
    }
  });

  return { groupIds: groupIds, pathIds: groupAndPathIds };
};
