import { parseToRgba } from 'color2k';
import { defaultCutType } from '@/defaults';
import {
  CutParams,
  CutType,
} from '@shapertools/sherpa-svg-generator/CutParams';
import { parseSVG } from 'svg-path-parser';
import { Path } from '@shapertools/sherpa-svg-generator/Path';

// SVGConstants from OriginApp
// Note alpha needs to a float between 0 and 1
const ORIGIN_SVG_COLORS = {
  SVG_COLOR_INTERIOR_CUT: [255, 255, 255, 255 / 255], // White (fill)
  SVG_COLOR_EXTERIOR_CUT: [0, 0, 0, 255 / 255], // Black (fill)
  SVG_COLOR_GRAY: [122, 122, 122, 255 / 255], // Gray (stroke) = online / gray (fill) = pocketing
  SVG_COLOR_GUIDE: [0, 0, 255, 255 / 255], // Blue (stroke OR fill)

  // NOT SUPPORTED
  // SVG_COLOR_LEGACY_INTERIOR_CUT : [0,0,255,255],      // Blue (fill)
  // SVG_COLOR_LEGACY_EXTERIOR_CUT : [0,255,0,255],      // Green (fill)
  // SVG_COLOR_LEGACY_ON_LINE_CUT : [0,255,0,255],       // Green (stroke)
  // SVG_COLOR_LEGACY_POCKETING_CUT : [255,0,0,255],     // Red (fill)
  // SVG_COLOR_LEGACY_GUIDE : [255,255,0,255]           // Yellow (stroke OR fill)
};

const OriginColors = new Map();

Object.entries(ORIGIN_SVG_COLORS).forEach(([k, v]) => {
  OriginColors.set(k, v);
});

const MAX_COLOR_MATCH_ERROR = 1;

//This is not a great way compare colors, but it's consistent with Origin
const computeColorSimilarity = (a: number[], b: number[]) =>
  255 * 4 -
  Math.abs(a[0] - b[0]) -
  Math.abs(a[1] - b[1]) -
  Math.abs(a[2] - b[2]) -
  Math.abs(a[3] - b[3]) * 255;

//It seems sensible to limit color matching if error is large
const computeBoundedColorSimilarity = (a: number[], b: number[]) => {
  const similarity = computeColorSimilarity(a, b);
  const errorBound = 255 * 4 * (1 - MAX_COLOR_MATCH_ERROR);
  return similarity >= errorBound ? similarity : 0;
};

const isRgbValue = (v: any) => Number.isInteger(v) && v >= 0 && v <= 255;
const isAlphaValue = (v: any) => !isNaN(v) && v >= 0 && v <= 1.0;

const isRgbaArray = (c: any) => {
  return (
    Array.isArray(c) &&
    c.length === 4 &&
    isRgbValue(c[0]) &&
    isRgbValue(c[1]) &&
    isRgbValue(c[2]) &&
    isAlphaValue(c[3])
  );
};

const toColorArrOrThrow = (c: any) => {
  if (isRgbaArray(c)) {
    return c;
  }

  const cArr = parseToRgba(c);
  if (isRgbaArray(cArr)) {
    return cArr;
  }

  throw new Error(`Cannot convert input ${c} to rgba array`);
};

const isNone = (c: any) => {
  return (
    (typeof c === 'string' || c instanceof String) && c.toLowerCase() === 'none'
  );
};

const compareColors = (a: any, b: any) => {
  if (isNone(a) || isNone(b)) {
    return 0;
  }

  const aColorArr = toColorArrOrThrow(a);
  const bColorArr = toColorArrOrThrow(b);

  return computeBoundedColorSimilarity(aColorArr, bColorArr);
};

const getMostSimilarColorKey = (candidate: any) => {
  const result = [];
  for (const [colorKey, colorVal] of OriginColors) {
    result.push({ name: colorKey, score: compareColors(candidate, colorVal) });
  }
  result.sort((a, b) => b.score - a.score);

  return result[0].score !== 0 ? result[0].name : false;
};

// Detects if pathNode represents a custom anchor point in an Origin workpiece SVG
// For consistency, this uses the same logic as on Tool at: https://github.com/ShaperTools/shaper-vision-library/blob/43ff3ac40e779ba25393db596e54b171f238c4be/libbase/libbase/origin/workpiece/cut/DesignNodeSvgSerialization.cpp#L72-L91

const isOriginCustomAnchorPoint = (pathNode: Element, paths: Path[]) => {
  try {
    const pathString = pathNode.getAttribute('d');
    if (pathString === null) {
      throw new Error('Cannot access path data in path node');
    }

    // Path needs to be 5 or less commands
    // (M)ove, two or three (L)ines, and an optionally a close (Z) command, which may be redundant if the SVG came from Affinity or Vectr
    const pathCommands = parseSVG(pathString);
    if (pathCommands.length > 5) {
      return false;
    }

    // Path data can only represent one path.
    if (paths.length > 1) {
      return false;
    }

    // Path should lines only, no curves
    if (
      pathCommands.findIndex(
        (cmd) =>
          cmd.code.toUpperCase() === 'C' ||
          cmd.code.toUpperCase() === 'Q' ||
          cmd.code.toUpperCase() === 'S' ||
          cmd.code.toUpperCase() === 'T' ||
          cmd.code.toUpperCase() === 'A'
      ) !== -1
    ) {
      return false;
    }

    // Path should be closed
    if (!paths[0].closed) {
      return false;
    }

    // If we made it here, then we have a triangle. Now we look for a red fill
    const fillString = pathNode.getAttribute('fill');
    if (fillString === null) {
      throw new Error('Cannot access fill data in path node');
    }

    const fillColor = parseToRgba(fillString);
    if (!isRgbaArray(fillColor)) {
      throw new Error('Cannot parse fill string to RGBA');
    }

    if (fillColor[0] > 150 && fillColor[1] < 50 && fillColor[2] < 50) {
      // Red enough
      return true;
    }

    return false;
  } catch (error) {
    const message = error instanceof Error ? error.message : `${error}`;
    console.log(message);
    return false;
  }
};

export const getCutEncoding = function (
  pathNode: Element,
  paths: Path[],
  originWorkpiece: boolean
) {
  /* 
    Due to Origin's current limitations of 
      1) only allowing 1 custom anchor point per imported SVG; and 
      2) not being able to import a grid, except when represented as a custom anchor point, 
      
      we need to convert any custom anchor points from an imported Origin workpiece to guide paths, which essentially deactivates them.

      We only do this on imported SVGs that are marked as coming from Origin, so that users can still edit other SVGs, such as Shaper's hardware library files, without modifying their custom anchors.

      (Although Studio actually converts custom anchors to cut paths anyways, which is something future work should address.)
  */
  const cutParams = new CutParams();
  if (originWorkpiece && isOriginCustomAnchorPoint(pathNode, paths)) {
    cutParams.cutType = CutType.GUIDE;
    return cutParams;
  }

  const fillColor = getMostSimilarColorKey(
    pathNode.getAttribute('fill') ?? OriginColors.get('SVG_COLOR_EXTERIOR_CUT')
  );
  const strokeColor = getMostSimilarColorKey(
    pathNode.getAttribute('stroke') ??
      OriginColors.get('SVG_COLOR_EXTERIOR_CUT')
  );

  // TODO - need to modify bullet parser to pass shaper NS attributes through to usvg to enable depth encoding
  // const shaperDepthAttr = pathNode.getAttribute('shaper:cutDepth');
  // if(shaperDepthAttr !== null){
  //   cutParams.cutDepth = UnitOps.convertUnitNumToMM(shaperDepthAttr, 'm'); //Legacy Fusion plugin defaults to m for depth
  // }
  // const shaperCutType = pathNode.getAttribute('stroke');
  // const shaperBitSizeType = pathNode.getAttribute('stroke');

  let cutType;
  switch (true) {
    //SVG path nodes have a default fill of black, which unfortunately corresponds to the fill color for an exterior cut
    //There does not appear to be a way to query the pathNode to determine if its fill is a default or specified explicitly.
    //So we have to test for SVG_COLOR_EXTERIOR_CUT after all stroke dependent cutTypes, else we may accidentally ignore the user specified stroke in favor of a default fill type.
    case fillColor === 'SVG_COLOR_GUIDE':
    case strokeColor === 'SVG_COLOR_GUIDE':
      cutType = CutType.GUIDE;
      break;

    case strokeColor === 'SVG_COLOR_GRAY':
      cutType = CutType.ONLINE;
      break;

    case fillColor === 'SVG_COLOR_INTERIOR_CUT':
      cutType = CutType.INSIDE;
      break;

    case fillColor === 'SVG_COLOR_GRAY':
      cutType = CutType.POCKET;
      break;

    case fillColor === 'SVG_COLOR_EXTERIOR_CUT':
      cutType = CutType.OUTSIDE;
      break;

    default:
      cutType = defaultCutType.keyEnum;
  }
  cutParams.cutType = cutType;

  return cutParams;
};
