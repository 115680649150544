import UIState from '@/UILayer/State/UIState';

// common state management
export default class DefaultState extends UIState {
  // prepare relevant state
  constructor(...args: ConstructorParameters<typeof UIState>) {
    super(...args);
    const { selectedGroupIds } = this;

    // predefine a few things
    this.isSingleSelection = selectedGroupIds.length === 1;
    this.isMultiSelection = selectedGroupIds.length > 1;
    this.hasSelection = selectedGroupIds.length >= 1;

    // define required data
    this.defineGroups();
    this.defineBoundingBox();
  }
}
