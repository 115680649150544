import 'react-app-polyfill/stable';

//IMPORTANT - POLYFILL IMPORT MUST BE FIRST IMPORT
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/react';
import userflow from 'userflow.js';

import { setStore } from './Actions/useAction';

import App from './App';
import BrowserError from '@/Components/BrowserError/BrowserError';
import MaintenancePage from './Components/MaintenancePage/MaintenancePage';
import minimumSupportedBrowsers from '@/supportedBrowsers.js';
import { isSupportedBrowser } from '@shapertools/supported-browsers';

import './index.css';
import './i18n';

import { init } from './Sync/SyncLog';
import setupStore, { ReduxStore } from './Redux/store';
import { handleUnsupportedBrowser } from './Utility/unsupported-browser';
import { FeatureFlagClient } from './Helpers/FeatureFlagClient';
import clipper from './Geometry/offset/Clipper';
import { StudioUrlPathParams } from './Actions/InitializeApp';

//ClipperLib logs errors using browser alert FFS, so override alert prototype to throw an error instead.
window.alert = (msg) => {
  throw new Error(msg);
};

//Used to load workspace from link
function getStudioURLParams(): StudioUrlPathParams {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const projectId = urlSearchParams.get('projectId') ?? null;

  if (projectId) {
    return {
      projectId,
      preview: true,
    };
  }

  //Reset history
  history.pushState(null, '', window.location.pathname);

  //workspaceId, newWorkspace, and blobId are mutually exclusive
  const workspaceId = urlSearchParams.get('workspaceId') ?? null;
  const blobId = urlSearchParams.get('blobId') ?? null;
  const preview = urlSearchParams.has('preview');

  if (workspaceId) {
    const duplicate = urlSearchParams.get('duplicate') ?? null;
    if (duplicate) {
      return { workspaceId, duplicate, ...(blobId && { blobId }) };
    }
    return {
      workspaceId,
      ...(blobId && { blobId }),
      ...(preview && { preview }),
    };
  }

  const newWorkspace = !!(urlSearchParams.get('newWorkspace') ?? null);
  if (newWorkspace) {
    const pathParam = urlSearchParams.get('path');
    return {
      ...(newWorkspace && { newWorkspace }),
      ...(pathParam && {
        path: pathParam,
      }),
    };
  }

  if (blobId) {
    return { blobId, ...(preview && { preview }) };
  }

  const success = urlSearchParams.get('success') ?? null;
  if (success !== null) {
    //will be an empty string, need to explicitly check for null
    history.pushState(null, document.title, '/?success');
  }

  return {
    ...(preview && { preview }),
  };
}

function renderApplication(
  store: ReduxStore,
  studioUrlParams: StudioUrlPathParams
) {
  // render the view
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container);
    root.render(
      <Provider store={store}>{rootComponent(studioUrlParams)}</Provider>
    );
  }
}

let rootComponent: (studioUrlParams: StudioUrlPathParams) => React.JSX.Element;
(async () => {
  await clipper.initialize();
  const studioUrlParams = getStudioURLParams();

  // @ts-ignore: Function signature
  const supportedBrowser = isSupportedBrowser([], minimumSupportedBrowsers);

  rootComponent = (() => {
    if (import.meta.env.VITE_ENABLE_MAINTENANCE_PAGE === 'true') {
      return () => <MaintenancePage />;
    } else if (supportedBrowser.isSupported) {
      return (urlParams: StudioUrlPathParams) => (
        <App studioUrlParams={urlParams} />
      );
    }
    return () => <BrowserError />;
  })();

  const store = setupStore({
    environment: import.meta.env.MODE,
  });

  if ('__playwright__' in window && window.__playwright__) {
    window.__store__ = store;
  }

  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      tracesSampleRate: 0.1,
      normalizeDepth: 10,
      environment: import.meta.env.VITE_SENTRY_ENV || 'development',
      ignoreErrors: [
        'Uncaught ReferenceError: ClipperLib is not defined',
        'Could not load Userflow.js',
        'ResizeObserver loop limit exceeded',
        'UserflowError',
        "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
        /extensions/,
        /^chrome:\/\//,
        /^safari-extension:\/\//,
        'CredentialsInvalid',
        'TypeError:',
        'Transaction aborted',
        '{}',
      ],
    });
  }

  init(import.meta.env.VITE_LOGGLY_TOKEN);

  if (supportedBrowser && !supportedBrowser.isSupported) {
    handleUnsupportedBrowser(supportedBrowser);
  }

  userflow.init(import.meta.env.VITE_USERFLOW_KEY);
  userflow.on('flowEnded', (e) => {
    history.pushState(null, '', window.location.pathname);
  });

  setStore(store);
  FeatureFlagClient.init(store);
  renderApplication(store, studioUrlParams);
})();
