import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { uniq } from 'lodash';

// consts
import { CUT_DEPTH_PRESETS } from '@/Constants/UI';

// helpers
import { unitToFormattedDisplayUnit } from '@/Geometry/UnitOps';

// selectors
import { selectSelectedPaths } from '@/Redux/Slices/SelectionSlice';
import {
  selectDisplayUnits,
  selectToFormattedDisplayUnitValue,
} from '@/Redux/Slices/SherpaContainerSlice';

// actions
import UpdateSvgPathAction from '@/Actions/UpdateSvgPath';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import { unitDefaults } from '../../../../defaults';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { useTranslation } from 'react-i18next';

export default function CutTypeSelector() {
  const [focused, setFocused] = useState(false);

  // selectors
  const displayUnits = useSelector(selectDisplayUnits);
  const selectedPaths = useSelector(selectSelectedPaths);
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );
  const { t, i18n } = useTranslation();

  // actions
  const updateSvgPathOperations = useAction(UpdateSvgPathAction);

  // computed
  const selectedDepths = selectedPaths.map((sel) =>
    unitToFormattedDisplayUnit(sel.path.cutParams.cutDepth, displayUnits)
  );

  // if no depths were found, just default to nothing
  if (!selectedDepths.length) {
    selectedDepths.push('0.000');
  }

  const isMixed = uniq(selectedDepths).length > 1;
  const depth = isMixed
    ? i18n.exists('mixed')
      ? t('mixed')
      : 'mixed'
    : toFormattedDisplayUnitValue(selectedDepths[0], { preformatted: true });

  // stepper props - hide this is the values are mixed
  const stepper = !isMixed
    ? displayUnits === 'in'
      ? { min: 0, max: 5 * unitDefaults.imperial, step: 0.1 }
      : { min: 0, max: 5 * unitDefaults.metric, step: 1 }
    : null;
  // if (isMM) { ... }

  function updateCutDepth(value: number) {
    if (!isNaN(value)) {
      //Depth is stored as a string with in or mm appended at the end. "####in" or "####mm"
      updateSvgPathOperations.setDepth(
        selectedPaths,
        `${value}${displayUnits}`
      );
    }
  }

  // handlers
  function onCommit(input: string) {
    //ignore characters that aren't numbers (e.g. you can't type '1in' or '5mm' to override display units right now)
    const inputFloat = parseFloat(input);
    updateCutDepth(inputFloat);
  }

  function onFocusInput() {
    setFocused(true);
  }

  function onBlurInput() {
    setFocused(false);
  }

  function onSelectPreset(value: number) {
    updateCutDepth(value);
  }

  function renderPresets() {
    const useMM = /mm/i.test(displayUnits);

    return CUT_DEPTH_PRESETS.map((preset, i) => {
      const { n = 1, d, mm } = preset;
      const value = useMM ? mm : n / d;
      const label = useMM ? mm : `${n}&frasl;${d}`;

      return (
        <div
          key={i}
          onClick={() => onSelectPreset(value)}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      );
    });
  }

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='depth'>
        <TranslationText i18nKey='depth'>Depth</TranslationText>
      </FloatingPanel.Label>

      {focused && (
        <FloatingPanel.PopOut className='pop-out--presets'>
          <div className='pop-out--presets--heading'>
            <TranslationText i18nKey='presets'>Presets</TranslationText>
          </div>
          <div className='pop-out--presets--grid'>{renderPresets()}</div>
        </FloatingPanel.PopOut>
      )}

      <FloatingPanel.Input
        calculate
        value={depth}
        onCommit={onCommit}
        {...stepper}
        suffix={displayUnits}
        precision={4}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
      />
    </FloatingPanel.Group>
  );
}
