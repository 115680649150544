import { getTypeProperty } from '@shapertools/sherpa-svg-generator/PathTypes';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';

const cache: Record<string, number> = {};

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d')!;

// // DEBUGGING HELPER
// canvas.width = window.innerWidth;
// canvas.height = window.innerHeight;
// document.body.appendChild(canvas);
// Object.assign(canvas.style, {
// 	position: 'absolute',
// 	pointerEvents: 'none',
// 	zIndex: 99999,
// 	top: '0',
// 	right: '0'
// });

// calculates the estimated area
function calculateArea(group: SvgGroup) {
  try {
    const el = document.querySelector(
      `#layer-${group.id}`
    ) as SVGGraphicsElement;
    if (!el) {
      return 0;
    }

    const { left, right, top, bottom } = el.getBoundingClientRect();
    const paths = el.querySelectorAll('path');

    // reduce the size of the pixel space since
    // we just need an approximation and this will
    // reduce the amount of pixels that need to be
    // processed
    const scaleBy = 0.25;
    const centerTo = 0.5;

    // reset the drawing space
    ctx.resetTransform();
    canvas.width = (right - left) * scaleBy;
    canvas.height = (bottom - top) * scaleBy;

    // this appears to be in an error state, possibly a NaN or negative size shape, or a shape too large to process?
    if (canvas.width === 0 || canvas.height === 0) {
      return 0;
    }

    // draw each path?
    for (const path of paths) {
      const { a, b, c, d } = el.getScreenCTM()!;
      const { width, height } = el.getBBox();
      ctx.setTransform(
        a * scaleBy,
        b * scaleBy,
        c * scaleBy,
        d * scaleBy,
        width * centerTo * a * scaleBy,
        height * centerTo * d * scaleBy
      );

      // check if we can render the shape
      const render = path.getAttribute('d');
      if (render) {
        ctx.fillStyle = 'black';
        const path2d = new Path2D(render);
        ctx.fill(path2d);
      }
    }

    // now calculate the estimated coverage
    // looking for opacity changes in pixels
    let coverage = 0;

    let { data } = ctx.getImageData(0, 0, canvas.width, canvas.height);
    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] > 0) {
        coverage += 1;
      }
    }

    const ratio = coverage / (canvas.width * canvas.height);
    return ratio;
  } catch {
    return 0;
  }
}

// intended to estimate the area for an SVG object without performing
// boolean operations. This can be done once to get an estimate of how
// much of a shape is actually covered, and then used to calculate the
// area of the total shape by multiplying the percent of the covered
// area by the size of the shape. This is intended to be an estimate
// only and used to help create layers from groups
export default function estimateArea(group: SvgGroup) {
  // check if the ratio has already been calculated - ideally this
  // should be roughly correct even if the size has been changed
  let scale = cache[group.id];
  if (!scale) {
    scale = cache[group.id] = calculateArea(group);
  }

  if (!scale) {
    return 0;
  }

  // calculate the final size
  const width = group.unrotatedAABB.maxPoint.x - group.unrotatedAABB.minPoint.x;
  const height =
    group.unrotatedAABB.maxPoint.y - group.unrotatedAABB.minPoint.y;
  return width * height * scale;
}

// helper function that will sort a series of groups
export function sortGroupsByArea(groups: SvgGroup[]) {
  return groups
    .map((group) => ({
      group,
      area: estimateArea(group),
      priority: getTypeProperty(group.type, 'selectionOrder') || 0,
    }))
    .sort((a, b) => a.priority - b.priority || a.area - b.area)
    .map((result) => ({ ...result, ...result.group }));
}
