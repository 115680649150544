export const FEATURES = Object.freeze({
  DOWNLOAD: 'SVG Download',
  DESIGN_MODE: 'Design Mode',
  SHAPE_SHIFTER: 'Shape Shifter',
  FIND_ART: 'Find Art',
  FIND_ART_LITE: 'Find Art (Limited)',
  TEXT: 'Text',
  TEXT_LITE: 'Text (Limited)',
  IMPORT: 'SVG + DXF Import',
  DELETE: 'Delete',
  DUPLICATE: 'Duplicate',
  PRECISION_SIZE: 'Precision Size',
  PRECISION_ROTATION: 'Precision Rotate',
  PRECISION_POSITION: 'Precision Position',
  ANCHORS: 'Anchors',
  SHAPESHIFTER: 'Shapeshifter',
  ALIGN_TO_OBJ: 'Align to Object',
  GRID_COORDINATES: 'Grid Coordinate System (0,0)',
  GRID_VISIBILITY: 'Grid Visibility',
  ALIGN_TO_GRID: 'Align to Grid',
  CUT_PATH: 'Cut Path Display',
  PLAN_MODE: 'Plan Mode',
  REVIEW_MODE: 'Review Mode',
  CTA: 'Review Mode',
  INSTAPLACE: 'Instaplace',
  TRACE: 'Trace',
  SYNC: 'Sync',
  SECRET_MENU: 'Secret Menu',
  CUSTOM_ANCHOR: 'Custom Anchor',
  SELECTION_MANAGER: 'Selection Manager',
});

export const FEATURE_MODES = Object.freeze({
  DEMO: 'demo',
  LITE: 'lite',
  FULL: 'full',
  DEVELOPMENT: 'development',
});
