import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

const EVENT_MAPPINGS = {
  mousedown: 'onMouseDown',
  mouseup: 'onMouseUp',
  click: 'onClick',
  touchstart: 'onTouchStart',
  touchend: 'onTouchEnd',
  pointerup: 'onPointerUp',
  pointerdown: 'onPointerDown',
} as const;

type EventTypes = keyof typeof EVENT_MAPPINGS;
type EventTypeFunctions = (typeof EVENT_MAPPINGS)[EventTypes];

const EVENTS = Object.keys(EVENT_MAPPINGS);
const MAPPINGS = Object.values(EVENT_MAPPINGS);

type Props = {
  className: string;
  onMouseDown?: (e: Event) => void;
  onMouseUp?: (e: Event) => void;
  onClick?: (e: Event) => void;
  onTouchStart?: (e: Event) => void;
  onTouchEnd?: (e: Event) => void;
  onPointerUp?: (e: Event) => void;
  onPointerDown?: (e: Event) => void;
} & PropsWithChildren;

export type EventMapping = (typeof MAPPINGS)[number];

type Handler = (e: Event) => void;

type EventMap = {
  // eslint-disable-next-line no-unused-vars
  [key in EventMapping]: Handler;
};

export default function InteractionCapture(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { className } = props;
  const [identity] = useState('');

  const events: Partial<EventMap> = {};

  useEffect(() => {
    // attach events
    EVENTS.forEach((event) => {
      const handler: Handler = (e: Event) => {
        const isCapture = containerRef.current === e.target;

        // if the target is itself, check for a capture event
        if (isCapture) {
          e.preventDefault();
          e.stopPropagation();
          invoke(event as EventTypes, e);
        }
      };

      // handle capture events
      events[event as EventTypeFunctions] = handler;
      containerRef.current?.addEventListener(event, handler);
    });

    return () => {
      for (const [id, fcn] of Object.entries(events)) {
        containerRef.current?.removeEventListener(id, fcn);
      }
    };
  }, []);

  // invokes a method, if found
  function invoke(name: EventTypes, event: Event) {
    const eventHandlerName = EVENT_MAPPINGS[name];

    const invokeFcn = props[eventHandlerName];
    if (invokeFcn) {
      invokeFcn(event);
    }
  }
  return (
    <div ref={containerRef} className={`${identity} ${className || ''}`}>
      {props.children}
    </div>
  );
}
