import * as DOM from '@/Helpers/DOM';

import { isPointInAABB, transform as AABBTransform } from '@/Geometry/AABBOps';
import { getId, getAABB } from '@/Geometry/BasePathOps';
import { transform } from '@/Geometry/PointOps';
import { IPoint, Point } from '@shapertools/sherpa-svg-generator/Point';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import { ViewportState } from '@/Redux/Slices/ViewportSlice';
import {
  ISvgGroup,
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';

const INITIAL_BOUNDING_BOX_PADDING = 1;
const POINT_HIT_DETECTiON_DISTANCE = 20;
const LINE_HIT_DETECTION_DISTANCE = 20;

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d', { willReadFrequently: true })!;
canvas.width = canvas.height = 1;

// // DEBUGGING HELPER
// canvas.width = window.innerWidth;
// canvas.height = window.innerHeight;
// document.body.appendChild(canvas);
// Object.assign(canvas.style, {
// 	position: 'absolute',
// 	pointerEvents: 'none',
// 	zIndex: 99999,
// 	top: '0',
// 	right: '0'
// });

interface HitTestOptions {
  extendBy?: number;
  hitDetectFill?: boolean;
  useBoundingBox?: boolean;
  useCutPath?: boolean;
}

// handles checking hit detection in a document
export default class HitDetection {
  groups: SvgGroup[];
  // registers all instances of a layer
  constructor(groups: SvgGroup[]) {
    this.groups = groups;
  }

  // performs a hit test and returns all matching layers
  hitTest(
    x: number,
    y: number,
    options: HitTestOptions = {},
    viewportState: ViewportState
  ) {
    //Transform hit point from screen space to canvas space
    const { useBoundingBox, useCutPath } = options;
    const hitPtScreenSpace = new Point(x, y);
    const { screenToCanvasTransform } = viewportState;
    const hitPtCanvasSpace = transform(
      hitPtScreenSpace,
      screenToCanvasTransform
    );
    const usePreciseHitDetection = useCutPath || useBoundingBox !== true;

    const pathHits = [];

    // check points first
    for (const group of this.groups) {
      // if this is a point, we just need distance
      if (group.tool?.type === Shape.POINT) {
        const distance = Math.hypot(
          group.position.x - hitPtCanvasSpace.x,
          group.position.y - hitPtCanvasSpace.y
        );

        if (
          distance <
          POINT_HIT_DETECTiON_DISTANCE / viewportState.canvasToScreenScale
        ) {
          pathHits.push({ pathId: 'none', groupId: group.id });
          continue;
        }
      }
    }

    // stop at points
    if (pathHits.length) {
      pathHits.reverse();
      return pathHits;
    }

    // start checking each group
    for (const group of this.groups) {
      //Does hitPt intersect group's AABB? If not, skip all the paths in this group.

      // skip points - TODO: clean this all up later
      if (group.tool?.type === Shape.POINT) {
        continue;
      }

      if (!usePreciseHitDetection) {
        if (
          !isPointInAABB(
            group.transformedAABB,
            hitPtCanvasSpace,
            INITIAL_BOUNDING_BOX_PADDING
          )
        ) {
          //No hit, so skip whole group
          continue;
        }
      }

      // if this is a shape, we just check the element alone
      if (group.tool?.type === Shape.SHAPE) {
        let onEdge;

        // check for edge hit detection
        const edges = HitDetection.hitTestShapeEdges(
          group,
          hitPtCanvasSpace,
          viewportState.canvasToScreenScale
        );
        if (edges?.length) {
          onEdge = true;
          edges.forEach((id) => {
            pathHits.push({ pathId: 'none', groupId: id });
          });
        }

        // check for the element, if needed
        const el = !onEdge && document.querySelector(`#sg-${group.id} path`);

        // found an element to work with
        if (el) {
          const hit = HitDetection.hitTestSVG(el as SVGGraphicsElement, x, y, {
            stroke: options.extendBy,
            alwaysFill: options.hitDetectFill,
          });

          if (hit) {
            pathHits.push({ pathId: 'none', groupId: group.id });
            continue;
          }
        }
      }

      //Because group intersects hitPt, test all paths in group against hitPt

      // check each sub layer starting in reverse order
      for (const thisBasePath of group.basePathSet) {
        const basePathId = getId(thisBasePath);
        const isReference = group.type === PATH_TYPE.REFERENCE;

        //1)Test this cutPath's AABB against hit point.
        //2) If there is a hit, follow-up with more accurate DOM-based test.

        //Test 1:
        // ToolPaths are hidden in canvas mode, so use base path only for testing hits

        //basePath needs to be scaled, rotated, translated by parent group's transformation to get to canvas space.

        const basePathAABBCanvasSpace = AABBTransform(
          getAABB(thisBasePath),
          group.TRSMtx
        );

        const isOverAABB = isPointInAABB(
          basePathAABBCanvasSpace,
          hitPtCanvasSpace,
          INITIAL_BOUNDING_BOX_PADDING
        );

        // if this isn't over the AABB, it won't be over any part
        // of the path so we can skip it for precise checking as well
        if (!isOverAABB) {
          continue;
        }

        let isPreciseHit = false;

        // if all we need is the general hit test, we can stop
        if (isOverAABB && !usePreciseHitDetection) {
          isPreciseHit = true;
          continue;
        }

        //Test 2: test hitPt against actual path geometry
        const isText = group.tool?.type === Shape.TEXT;

        // TODO: for some reason, text gets captured every time - this is a
        // quick fix to just the screen bounds for now. We want to revisit this
        if (!isPreciseHit && isText && !usePreciseHitDetection) {
          const el = document.getElementById(`layer-${group.id}`);
          const { left, right, top, bottom } = el!.getBoundingClientRect();

          if (x < left || x > right || y < top || y > bottom) {
            isPreciseHit = false;
          }
        }

        // check for complex shape hit detection, if required
        if (!isPreciseHit && usePreciseHitDetection) {
          //NB: could also transform pathAABB to screen space
          // get the layer boundaries

          // TODO: inside and outside paths don't always get captured
          // this uses the base path and the larger toolWidth path to
          // ensure paths are captured for now
          // const paths = useCutPath ? ['cutPreviewOnline', 'basePath'] : ['basePath'];
          const paths = ['basePath'];
          for (const path of paths) {
            const el = DOM.getPathById(
              group.id,
              basePathId,
              path
            ) as SVGGraphicsElement;

            // // make sure it's even over the shape at all
            // if (!HitDetection.hitTestDOMElement(el, x, y)) {
            //   continue
            // }

            // found an element to work with
            if (el) {
              isPreciseHit = HitDetection.hitTestSVG(el, x, y, {
                stroke: options.extendBy,
                alwaysFill: isReference ? false : options.hitDetectFill,
              });
            }

            // no need to check more
            if (isPreciseHit) {
              break;
            }
          }
        }

        // include if this is a hit
        if (isPreciseHit) {
          pathHits.push({ pathId: basePathId, groupId: group.id });
        }
      }
    }

    // match SVG order
    pathHits.reverse();

    return pathHits;
  }

  // quick test for SVG hit testing
  static hitTestSVG(
    el: SVGGraphicsElement,
    x: number,
    y: number,
    { stroke = 0, alwaysFill = false } = {}
  ) {
    const { a, b, c, d, e, f } = el.getScreenCTM()!;
    canvas.width = canvas.height;
    ctx.setTransform(a, b, c, d, e - x, f - y);

    // apply the path
    const render = el.getAttribute('d');
    if (render) {
      const path = new Path2D(render);
      if (stroke) {
        ctx.lineWidth = stroke;
        ctx.stroke(path);
      }

      if (!stroke || alwaysFill) {
        ctx.lineWidth = 0;
        ctx.fill(path);
      }
    }
    // just check the box itself - for now
    // this assumes always using center point
    else {
      const { width, height } = el.getBBox();
      ctx.fillRect(width * -0.5, height * -0.5, width, height);
    }

    // stroke to increase clickable area
    // needs calculated line width
    // ctx.lineWidth = ???
    // ctx.stroke(path);

    // check if there's any opacity on this spot
    const { data } = ctx.getImageData(0, 0, 1, 1);
    const [, , , pixel] = data;
    return pixel > 0;
  }

  static hitTestShapeEdges(shape: ISvgGroup, { x, y }: IPoint, scale: number) {
    const distance = LINE_HIT_DETECTION_DISTANCE / scale;
    const params = (shape.tool as Tool<Shape.SHAPE>).params;
    const points = params.points as string[];
    const closed = params.closed as boolean;

    // start checking each point
    for (let i = 0; i < points.length; i++) {
      // don't loop around when not closed
      if (!closed && i === points.length - 1) {
        break;
      }

      // check the points
      const start = points[i];
      const end = points[(i + 1) % points.length];
      const a = DOM.getGroupById(start, 'point')!;
      const b = DOM.getGroupById(end, 'point')!;

      // doesn't need to be done
      if (!(a && b)) {
        return;
      }

      const ax = parseFloat(a.getAttribute('cx')!);
      const ay = parseFloat(a.getAttribute('cy')!);
      const bx = parseFloat(b.getAttribute('cx')!);
      const by = parseFloat(b.getAttribute('cy')!);
      const ap = { x: ax, y: ay };
      const bp = { x: bx, y: by };

      // interpolate
      for (let j = 0; j < 100; j++) {
        const pt = interpolate(ap, bp, j * 0.01);
        const dist = Math.hypot(pt.x - x, pt.y - y);
        if (dist < distance) {
          return [start, end];
        }
      }
    }
  }
}

function interpolate(a: IPoint, b: IPoint, t: number) {
  return {
    x: a.x + (b.x - a.x) * t,
    y: a.y + (b.y - a.y) * t,
  };
}
