import React from 'react';
import Icon from '@/Styles/Icons/Icon';
import { useSelector, useDispatch, batch } from 'react-redux';

//Components
import ActionMenuList from '../ActionMenuList/ActionMenuList';

//selectors
import {
  toggleHelpMenu,
  selectIsShowingHelpMenu,
  selectIsMobile,
} from '@/Redux/Slices/UISlice';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { helpMenuLinks } from '../../defaults';
import { selectHasSelection } from '@/Redux/Slices/SelectionSlice';
import UIFeatureAction from '../../Actions/UIFeature';
import { useAction } from '../../Actions/useAction';

export default function HelpMenu() {
  const dispatch = useDispatch();

  const isShowingHelpMenu = useSelector(selectIsShowingHelpMenu);
  const hasSelection = useSelector(selectHasSelection);
  const isMobile = useSelector(selectIsMobile);

  const uiAction = useAction(UIFeatureAction);

  const { t, i18n } = useTranslation();

  // activates an action
  const onToggleHelpMenu = () => {
    batch(() => {
      if (isShowingHelpMenu && hasSelection) {
        uiAction.toggleEditSelectionProperties(true);
      }
      dispatch(toggleHelpMenu(!isShowingHelpMenu));
    });
  };

  const options = [
    {
      label: 'Help Center',
      icon: 'question-mark-circle',
      url: helpMenuLinks.HELP_CENTER,
      target: '_blank',
      type: 'Resource',
      i18nKey: 'help-center',
    },
    {
      label: 'Youtube Videos',
      icon: 'video',
      url: helpMenuLinks.YOUTUBE,
      target: '_blank',
      type: 'Resource',
      i18nKey: 'youtube-videos',
    },
    {
      label: 'Shortcuts & Controls',
      icon: 'shortcuts',
      url: helpMenuLinks.SHORTCUTS,
      target: '_blank',
      type: 'Resource',
      i18nKey: 'shortcuts-controls',
    },
    {
      label: 'Community Forum',
      icon: 'forum',
      url: helpMenuLinks.COMMUNITY,
      target: '_blank',
      type: 'Feedback',
      i18nKey: 'community-forum',
    },
    {
      label: 'Submit Feedback',
      icon: 'feedback-closed',
      url: helpMenuLinks.FEEDBACK,
      target: '_blank',
      type: 'Feedback',
      i18nKey: 'submit-feedback',
    },
  ];

  const icon = isShowingHelpMenu ? 'close' : 'question-mark';
  const contextCx = classNames('help-menu--content', {
    show: isShowingHelpMenu,
  });

  const helpMenuTranslation = i18n.exists('help-resources')
    ? t('help-resources')
    : 'Help Resources';
  const resourcesTranslation = i18n.exists('resources')
    ? t('resources')
    : 'Resources';
  const feedbackTranslation = i18n.exists('feedback')
    ? t('feedback')
    : 'Feedback';

  return (
    <div className='help-menu' role='menu'>
      <div
        className={`help-menu--launcher ${icon}`}
        data-cy='help-menu-button'
      />

      {isShowingHelpMenu && (
        <div className={contextCx}>
          {isMobile && (
            <Icon
              icon='close'
              className='help-menu--content-close'
              onClick={onToggleHelpMenu}
            />
          )}
          <ActionMenuList menuName={helpMenuTranslation} icon='forum'>
            <ActionMenuList.Section label={resourcesTranslation}>
              {options
                .filter((o) => o.type === 'Resource')
                .map((o, i) => (
                  <ActionMenuList.SectionOption key={i} {...o} />
                ))}
            </ActionMenuList.Section>
            <ActionMenuList.Section label={feedbackTranslation}>
              {options
                .filter((o) => o.type === 'Feedback')
                .map((o, i) => (
                  <ActionMenuList.SectionOption key={i} {...o} />
                ))}
            </ActionMenuList.Section>
          </ActionMenuList>
        </div>
      )}
      {isMobile && isShowingHelpMenu && (
        <div className='overlay' onClick={onToggleHelpMenu} />
      )}
    </div>
  );
}
