import React, { MutableRefObject, useRef, useState } from 'react';

// components
import Button from '@/Components/Button';
import TextField from '@/Components/TextField/TextField';
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '../TranslationText/TranslationText';
import LogInAction from '../../Actions/LogIn';
import { useAction } from '../../Actions/useAction';
import ModalActions from '../../Actions/Modal';
import { CustomErrors } from '../../Constants/Errors';

type Props = {
  hideHelpText?: boolean;
};

export default function LoginForm({ hideHelpText = false }: Props) {
  const usernameRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const [username] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [customError, setCustomError] = useState('');
  const [disabled, setDisabled] = useState(true);

  const logInAction = useAction(LogInAction);
  const modalActions = useAction(ModalActions);

  // checks the field for a value
  const getInputValue = (
    ref: MutableRefObject<HTMLInputElement | undefined>
  ) => {
    if (ref.current) {
      return ref.current.value;
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // performs a login attempt
  const onSubmitLogin = async () => {
    const u = getInputValue(usernameRef);
    const p = getInputValue(passwordRef);
    if (u && p) {
      const hasLoginError = await logInAction.logIn(u, p);
      if (hasLoginError) {
        if (typeof hasLoginError === 'boolean') {
          setHasError(true);
          setCustomError('');
        }
        if (typeof hasLoginError === 'string') {
          setCustomError(hasLoginError);
          setHasError(false);
        }
        setPassword('');
      } else {
        setHasError(false);
        modalActions.closeModal();
      }
    }
  };

  const hasWhitespace = (str: string) => /^\s+$/.test(str);

  const updateSubmitButton = () => {
    const u = getInputValue(usernameRef);
    const p = getInputValue(passwordRef);
    const hasUsername = u && !hasWhitespace(u) && u.length > 0;
    const hasPassword = p && !hasWhitespace(p) && p.length > 0;

    setDisabled(!(hasPassword && hasUsername));
  };

  const renderWhitelistHelpText = () => {
    return 'Your computer\'s IP address is not in the allowed list for staging. Visit <a href="https://staging.shapertools.com" target="_blank">https://staging.shapertools.com</a> to add your IP address to the list or use the VPN.';
  };

  return (
    <div className='component__login-form'>
      <form>
        <div className='component__login-form__fields'>
          <div className='component__login-form__label'>
            <TranslationText i18nKey='email'>Email</TranslationText>
          </div>
          <TextField
            placeholder='name@shapertools.com'
            type='text'
            inputRef={usernameRef}
            value={username}
            onChange={updateSubmitButton}
            submitOnEnter={true}
            onSubmit={onSubmitLogin}
            inputProps={{
              'data-cy': 'email-login-input',
            }}
          />

          <div className='component__login-form__label'>
            <TranslationText i18nKey='password'>Password</TranslationText>
          </div>
          <div className='component__login-form__container'>
            <TextField
              placeholder='*******************'
              type={showPassword ? 'text' : 'password'}
              inputRef={passwordRef}
              value={password}
              onChange={updateSubmitButton}
              submitOnEnter={true}
              onSubmit={onSubmitLogin}
              inputProps={{
                'data-cy': 'password-login-input',
              }}
            />
            <div
              className='component__login-form__visibility'
              onClick={togglePasswordVisibility}
            >
              <Icon icon={showPassword ? 'visible-eye' : 'eye'} />
            </div>
          </div>
        </div>
        {!hideHelpText && (
          <div className='component__login-form__info'>
            <TranslationText i18nKey='password-help'>
              Passwords are case-sensitive and must be at least 8 characters
              long
            </TranslationText>
          </div>
        )}
        {hasError && (
          <div
            className='component__login-form__error'
            data-cy='login-error-text'
          >
            <TranslationText i18nKey='incorrect-password'>
              Your email or password is incorrect.
            </TranslationText>
          </div>
        )}
        {customError && (
          <div
            className='component__login-form__error'
            data-cy='login-error-text'
          >
            {customError === CustomErrors.WHITELIST_IP && (
              <span
                dangerouslySetInnerHTML={{ __html: renderWhitelistHelpText() }}
              />
            )}
          </div>
        )}
        <div className='component__login-form__actions'>
          <Button
            className='pill login-form'
            onClick={onSubmitLogin}
            disabled={disabled}
            data-cy='login-form-submit'
          >
            <TranslationText i18nKey='sign-in'>Sign In</TranslationText>
          </Button>
        </div>
      </form>
    </div>
  );
}
