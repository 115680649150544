import BaseAction from './BaseAction';
import { useActionWithDispatch } from './useAction';

// selectors
import { selectSVGViewbox } from '@/Redux/Slices/ViewportSlice';

// helpers
import { getPositionFromSvgViewbox } from '@/Utility/viewbox';
import { createSvgForTool } from '@/Helpers/ShapeCreator';

// actions
import AddGeometryAction from '@/Actions/AddGeometry';
import { trackEvent } from '../Utility/userflow';
import { Shape, Tool } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { IPoint } from '@shapertools/sherpa-svg-generator/Point';

export default class BuildBasicShapeAction extends BaseAction {
  build(type: Shape, insertAt?: IPoint) {
    const { dispatch, useSelector } = this;

    // create the svg content
    const tool = new Tool(type);
    const { svg } = createSvgForTool(tool);

    // determine where to add
    const position = (() => {
      if (!insertAt) {
        const viewbox = useSelector(selectSVGViewbox);
        return getPositionFromSvgViewbox(viewbox);
      }

      return insertAt;
    })();

    // build the svg
    const build = useActionWithDispatch(AddGeometryAction, dispatch);
    const shape = { rawSVG: svg, tool: tool };
    build.addSvg('icon', shape, position);

    trackEvent('shape_added', { shape_added: true });
  }
}
