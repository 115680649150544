import { UnknownAction } from '@reduxjs/toolkit';
import { getSnapshot } from '../SyncThunks';
import { FulfilledAction } from '@/Redux/hooks';
import { SyncListenerApi } from '../SyncListener';
import { selectSnapshot, setSnapshot } from '@/Redux/Slices/SyncSlice';
import { getAndApplyUpdates } from './OpenListener';

export const addGetSnapshotListener = (startListening: Function) => {
  startListening({
    predicate: (action: UnknownAction) => {
      return getSnapshot.fulfilled.match(action);
    },
    effect: async (action: FulfilledAction, listenerApi: SyncListenerApi) => {
      const { dispatch } = listenerApi;
      const { workspace, blobId, isDuplicate } = action.meta.arg;

      const snapshot = selectSnapshot();
      dispatch(setSnapshot(snapshot));
      await getAndApplyUpdates(workspace, listenerApi, snapshot, {
        blobId,
        isDuplicate,
      });
    },
  });
};
