import { setSelection as setSelectionAction } from '@/Redux/Slices/SelectionSlice';
import { AppDispatch } from '@/Redux/store';
import { Selection } from './SetSelection';

export default class UpdateSelectionAction {
  dispatch: AppDispatch;
  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  setSelection = (selection: Selection) => {
    const { dispatch } = this;
    dispatch(setSelectionAction(selection));
  };

  refresh = () => {};
}
