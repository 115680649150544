import { Cache, GenericPathCache } from './GenericCache';
import { getCutDepthMM } from '../../CutParamsOps';
import { CutPreview } from '../../CutPreviewOps';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { CutParams } from '@shapertools/sherpa-svg-generator/CutParams';
import { NSimplePolygonUnionsAsync } from '../../SimplePolygonOps';
import { getReviewPathCache, getReviewPathSvg } from '../ReviewPathCache';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { AppDispatch } from '@/Redux/store';
import { setReviewModeLoading } from '@/Redux/Slices/UISlice';
import { ToolPathsCache } from '../SvgCache';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';

export const getDepthClippedReviewPaths = async (pathData: BasePath[]) => {
  const cutPreviews = pathData.map(
    (path) =>
      new CutPreview(path, getCutDepthMM(path.cutParams || new CutParams()))
  );

  // Union paths at same depth
  const pathsByDepth = cutPreviews.reduce((acc, sp) => {
    const { depth } = sp;
    if (!acc[depth]) {
      acc[depth] = [];
    }
    acc[depth].push(sp);
    return acc;
  }, {} as Record<number, CutPreview[]>);

  const mergedPaths = await Promise.all(
    Object.entries(pathsByDepth)
      .sort(([a], [b]) => Number(b) - Number(a))
      .map(async ([depth, paths]) => {
        if (paths.length > 1) {
          const unionPaths = await NSimplePolygonUnionsAsync(paths, {
            simplifyPolys: false,
          });
          return unionPaths.map((cp) => ({
            ...cp,
            depth: Number(depth),
            cutParams: new CutParams({
              cutDepth: `${depth}mm`,
            }),
          }));
        }

        return paths;
      })
  );

  return mergedPaths.flat();
};

export const CACHE_KEY = 'all';
const DEBUG = false;
const log = DEBUG ? console.log : () => {};

export class DepthClippedPathCache extends GenericPathCache<string> {
  pendingPromises: { [key: string]: Promise<Cache<string>>[] };
  activeTimestamp: number;
  dispatch?: AppDispatch;

  constructor() {
    super();
    this.pendingPromises = {};
    this.activeTimestamp = 0;
  }

  private getMaxGroupTs = (svgGroupSet: SvgGroup[]) => {
    const groupTs = svgGroupSet.map((sg) => sg.generatedTs);
    if (groupTs.length === 0) {
      return 0;
    }

    return groupTs.reduce((acc, ts) => acc + ts);
  };

  private updateDepthClippedSvg(timestamp: number) {
    const latestCacheElement = this.getCachePath(CACHE_KEY);
    if (latestCacheElement) {
      return (
        latestCacheElement.generatedTs !== timestamp &&
        this.activeTimestamp !== timestamp
      );
    }
    return true;
  }

  private clearPendingPromises() {
    log('pending promises: ', this.pendingPromises);
    this.pendingPromises = {};
    log('cleared pending promises: ', this.pendingPromises);
  }

  private setLoading(isLoading: boolean) {
    if (this.dispatch) {
      this.dispatch(setReviewModeLoading(isLoading));
    }
  }

  async updateCache(
    svgGroupSet: SvgGroup[],
    toolPathCache: ToolPathsCache,
    hasTessellationFeatureFlag: boolean
  ) {
    const maxGroupTs = this.getMaxGroupTs(svgGroupSet);
    const shouldUpdateDepthClippedSvg = this.updateDepthClippedSvg(maxGroupTs);
    if (shouldUpdateDepthClippedSvg) {
      log('getting review path again: ', maxGroupTs);
      this.setLoading(true);
      this.activeTimestamp = maxGroupTs;
      this.clearPendingPromises();
      this.pendingPromises[maxGroupTs] = [];

      for (const svgGroup of svgGroupSet) {
        if (svgGroup.tool?.type === Shape.POINT) {
          continue;
        }

        for (const basePath of svgGroup.basePathSet) {
          const pathDataPromise = getReviewPathCache(basePath, svgGroup, {
            toolPathCache,
            hasTessellationFeatureFlag,
          });
          this.pendingPromises[maxGroupTs].push(pathDataPromise);
        }
      }
      log('new pending promises: ', this.pendingPromises);
      const allReviewPaths = await Promise.all(
        this.pendingPromises[maxGroupTs]
      );
      const reviewPaths = allReviewPaths.flatMap((p) => p.pathData);

      const pathData = await getDepthClippedReviewPaths(reviewPaths);
      const pathSvg = pathData.map((pd) =>
        getReviewPathSvg('depth-clipped-group', pd.id, pd.cutParams, [pd])
      );
      if (this.pendingPromises[maxGroupTs]) {
        this.setLoading(false);
        this.setCache(CACHE_KEY, {
          pathData,
          pathSvg: pathSvg.join(' '),
          generatedTs: maxGroupTs,
        });
        log('finished getting review path: ', maxGroupTs);
      } else {
        log('cancelled pending promises for ', maxGroupTs);
      }
    }
  }

  getCache(
    svgGroupSet: SvgGroup[],
    toolPathCache: ToolPathsCache,
    hasTessellationFeatureFlag: boolean
  ): Cache<string> {
    const maxGroupTs = this.getMaxGroupTs(svgGroupSet);

    const existingCacheLine = this.cache[CACHE_KEY];

    if (!existingCacheLine || existingCacheLine.generatedTs !== maxGroupTs) {
      this.updateCache(svgGroupSet, toolPathCache, hasTessellationFeatureFlag);
      return {
        pathData: [],
        pathSvg: '',
      };
    }

    return existingCacheLine;
  }

  setDispatch(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }
}
