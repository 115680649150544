import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { extractPath, getSvgShape, clearCache } from '@/Utility/svgCache';

// selectors
import { selectSelectedPathIds } from '@/Redux/Slices/SelectionSlice';

// actions
import SetSelectionAction from '@/Actions/SetSelection';
import useSelectionEditorHoverState from './useSelectionEditorHoverState';
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

export default function PathSelector() {
  const setSelection = useAction(SetSelectionAction);
  let selectedGroups = useSelector(selectSelectedGroups);
  selectedGroups = selectedGroups.filter(
    (group) => group.tool?.type !== Shape.POINT
  );

  const selectedGroupIds = selectedGroups.map((g) => g.id);
  const selectedPathIds = useSelector(selectSelectedPathIds);
  const { isHovering, createHoverEventHandler } =
    useSelectionEditorHoverState();

  // keep track of all path ids, including those that are removed
  const [pathSelection, setPathSelection] = useState(() => ({
    selected: selectedPathIds.map((path) => path.pathId),
    paths: selectedPathIds,
  }));

  // clear when mounted
  useEffect(clearCache);

  // handle changes to the path selection
  useEffect(() => {
    setPathSelection((current) => ({
      selected: selectedPathIds.map((path) => path.pathId),
      paths: uniqBy(
        [...current.paths, ...selectedPathIds],
        (path) => path.pathId
      ),
    }));
  }, [selectedPathIds]);

  // checks if a path is selected
  function isSelected(pathId) {
    return pathSelection.selected.includes(pathId);
  }

  // handle toggling a path selection
  function onToggle(groupId, pathId) {
    if (isHovering) {
      return;
    }

    const action = isSelected(pathId) ? 'remove' : 'add';
    setSelection.resolve([{ pathId }], action);
  }

  // gather up all group sets
  const groups = {};
  pathSelection.paths
    .filter((p) => selectedGroupIds.includes(p.groupId))
    .forEach(({ pathId, groupId }) => {
      groups[groupId] = groups[groupId] || [];
      const shape = getSvgShape(
        extractPath,
        `#basePath-sg-${groupId}-pg-${pathId}`
      );

      // couldn't find anything
      if (!shape) {
        return;
      }

      groups[groupId].push({ groupId, pathId, shape });
    });

  // gather up all paths - the shared path is a collection
  // of included paths that are the only shape in the collection
  const collection = [];
  {
    const shared = [];
    Object.values(groups).forEach((paths, i) => {
      if (paths.length > 1) {
        collection.push(paths);
      } else {
        shared.push(paths[0]);
      }
    });

    // only include the shared group if there's anything to include
    if (shared.length) {
      collection.push(shared);
    }
  }

  // map out the UI
  return collection.map((paths, i) => (
    <div key={`group_${i}`}>
      {!!i && <hr className='selection-editor--selection-separator' />}

      <div className='selection-editor--selection-set'>
        {paths.map(({ groupId, pathId, shape, elementId }, j) => {
          const selected = isSelected(pathId);
          const group = selectedGroups.find((g) => g.id === groupId);
          const closed = group?.basePathSet.every((x) =>
            x.outerPath ? x.outerPath.closed : x.closed
          );
          const pathCx = classNames(
            'selection-editor--selection-item',
            selected && 'is-selected',
            closed && 'closed',
            group.type
          );

          return (
            <div
              id={`selection-editor--sg-${groupId}-pg-${pathId}`}
              className={pathCx}
              key={`item_${j}`}
              onClick={() => onToggle(groupId, pathId)}
              {...createHoverEventHandler({ groupId, pathId })}
            >
              {shape}
            </div>
          );
        })}
      </div>
    </div>
  ));
}
