import { clearSelection } from '@/Redux/Slices/SelectionSlice';
import { toggleShapeCreator } from '@/Redux/Slices/UISlice';
import { AppDispatch } from '@/Redux/store';

export default class ToggleShapeCreatorModeAction {
  dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  activate = () => {
    const { dispatch } = this;

    dispatch(toggleShapeCreator(true));
    dispatch(clearSelection());
  };

  deactivate = () => {
    const { dispatch } = this;

    dispatch(toggleShapeCreator(false));
  };
}
