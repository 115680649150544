/* eslint-disable valid-jsdoc */
import {
  setSequence,
  setStatus,
  updatePendingQueue,
} from '../../Redux/Slices/SyncSlice';
import { UpdateObject, update } from './../SyncThunks';

import { log } from './../SyncLog';
import { SyncListenerApi, context } from '../SyncListener';
import { PayloadAction, UnknownAction } from '@reduxjs/toolkit';
import { getStudioShare } from '@/ShaperHub/ShaperHubThunks';

/**
 * Update listener -- listens the update thunk and routes here if the thunk is fulfilled
 */
export const addUpdateListener = (startListening: Function) => {
  startListening({
    predicate: (action: UnknownAction) => update.fulfilled.match(action),
    effect: async (
      action: PayloadAction<UpdateObject>,
      { dispatch, getState }: SyncListenerApi
    ) => {
      const { sync, shaperHub } = getState();
      const { queue } = sync;
      const { project } = shaperHub;
      log('Syncing queue...', { ...context, queue }, 'debug');

      const { payload } = action;
      const { latestUpdateSequence } = payload;
      dispatch(setSequence(latestUpdateSequence));
      log(
        `Update was successful, updating workspace to ${latestUpdateSequence}`,
        { ...context }
      );
      dispatch(updatePendingQueue([]));
      dispatch(setStatus('edit'));

      if (project) {
        dispatch(
          getStudioShare({ projectId: project._id, fromWorkspace: true })
        );
      }
    },
  });
};
